import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Box } from 'jsxstyle';

import {
  Tooltip,
  withStyles,
  Select,
  MenuItem,
  ClickAwayListener,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import { SingleDatePicker as DatePicker } from 'react-dates';
import pick from 'lodash/pick';

import { quartz, santasGrey } from '@hero/styles/colors-v4';
import Avatar from '@hero/tfs/src/shared/UserAvatar';
import { TableRow, TableCell, styles } from '@hero/tfs/src/shared/table';
import FormattedNumber from '@hero/tfs/src/shared/FormattedNumber';

import Input from '@hero/core/Input';
import UserAutocompleteInput from '@hero/core/UserAutocompleteInput';
import ClickOutside from '@hero/tfs/src/shared/ClickOutside';
import TrackingErrorBadge from '../12-initiative/TrackingErrorBadge';

const USER_INPUT_WIDTH = '220px';

export const rowStyles = theme => ({
  ...styles(theme),
  title: {
    minWidth: '280px',
    maxWidth: '280px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingLeft: '32px !important',
    position: 'sticky',
    left: 0,
    backgroundColor: 'white',
    borderRight: `1px solid ${santasGrey}`,
  },
});

const StyledTableRow = styled(TableRow)``;

export const TableRowCell = styled(TableCell)`
  ${StyledTableRow}:hover & {
    background-color: #dcdcdc !important;
  }
  ${StyledTableRow}:nth-child(odd) &:first-child {
    background-color: ${quartz};
  }
`;

const TableRowFirstCell = styled(({ badgeColor, ...rest }) => (
  <TableRowCell {...rest} />
))(({ badgeColor }) => ({
  borderLeft: badgeColor ? `6px solid ${badgeColor} !important` : 'transparent',
}));

const CenteredCell = styled.div`
  display: flex;
  align-items: center;
`;

const UserName = styled.span`
  margin-left: 12px;
`;

function ListViewRow({
  rowData,
  classes,
  editedField,
  onEdit,
  onClick,
  onCommit,
  onCancel,
  onFieldChange,
  configuration,
  usersList,
  isEditMode,
  currencyUnit,
}) {
  const [recurringTltpId, setRecurringTltpId] = React.useState(null);

  const {
    business_unit: businessUnitList,
    region: regionList,
    resource_type: resourceTypeList,
    stage_gate: stageGateList,
    difficulty: difficultyList,
    workstream: workstreamList,
  } = configuration;

  const isEdited = fieldName => {
    return (
      isEditMode &&
      fieldName === editedField.fieldName &&
      rowData.ref_id === editedField.value.ref_id &&
      editedField.editing
    );
  };

  const keyDownHandler = event => {
    if (isEditMode) {
      if (event.keyCode === 27) {
        onCancel();
      }

      if (event.keyCode === 13) {
        onCommit();
      }
    }
  };

  const onLeadChange = id => {
    onFieldChange({
      lead: [usersList.find(user => user.user_id === id)],
    });
  };

  const onSponsorChange = id => {
    onFieldChange({
      sponsor: [usersList.find(user => user.user_id === id)],
    });
  };

  const onApproverChange = id => {
    onFieldChange({
      approver: [usersList.find(user => user.user_id === id)],
    });
  };

  const onOutcomeStartDateChange = outcomeDate => {
    outcomeDate &&
      onFieldChange({
        outcome_duration: [outcomeDate.format(), rowData.outcome_duration[1]],
      });
  };

  const onOutcomeEndDateChange = outcomeDate => {
    outcomeDate &&
      onFieldChange({
        outcome_duration: [rowData.outcome_duration[0], outcomeDate.format()],
      });
  };

  const onBudgetStartDateChange = budgetDate => {
    budgetDate &&
      onFieldChange({
        budget_duration: [budgetDate.format(), rowData.budget_duration[1]],
      });
  };

  const onBudgetEndDateChange = budgetDate => {
    budgetDate &&
      onFieldChange({
        budget_duration: [rowData.budget_duration[0], budgetDate.format()],
      });
  };

  const handleEditClick = field => () => {
    if (isEditMode) {
      onEdit(field);
    }
  };

  const handleRecurringTooltipOpn = id => () => {
    setRecurringTltpId(id);
  };

  const handleRecurringTooltipClose = () => {
    setRecurringTltpId(null);
  };

  const isRecurringTypeOutcome = rowData.outcome_type === 'recurring';

  return (
    <StyledTableRow
      className={`${classes.tableRow}`}
      hover
      onClick={e => onClick(rowData.ref_id)}
      onKeyDown={keyDownHandler}
    >
      <TableRowFirstCell
        className={`${classes.title}`}
        badgeColor={rowData.badgeColor}
      >
        <TrackingErrorBadge
          {...pick(rowData, [
            'outcome_value',
            'outcome_duration',
            'outcome_tracking',
            'outcome_period',
            'cost',
            'budget_duration',
            'budget_tracking',
            'budget_period',
          ])}
        />

        {!isEdited('name') ? (
          <Tooltip title={rowData.name} placement="top-start">
            <span onClick={handleEditClick('name')}>{rowData.name}</span>
          </Tooltip>
        ) : (
          <ClickOutside onClickOutside={onCommit}>
            <Input
              autoFocus
              name="name"
              value={rowData.name}
              onChange={e => onFieldChange({ name: e.target.value })}
              style={{
                width: '180px',
              }}
            />
          </ClickOutside>
        )}
      </TableRowFirstCell>

      <TableRowCell className={classes.rowCell}>
        {!isEdited('stageGate') ? (
          <span onClick={handleEditClick('stageGate')}>
            {rowData.stageGate}
          </span>
        ) : (
          <SelectEditField
            field="stage_gate"
            options={stageGateList}
            id="initiative-stage-gate"
            value={rowData.stage_gate}
            onChange={stage_gate => onFieldChange({ stage_gate })}
            onSave={onCommit}
          />
        )}
      </TableRowCell>

      <TableRowCell className={classes.rowCell}>
        {!isEdited('outcomeValue') ? (
          <FormattedNumber
            value={rowData.outcome_value}
            onClick={handleEditClick('outcomeValue')}
          />
        ) : (
          <ClickOutside onClickOutside={onCommit}>
            <FormattedNumber.Input
              autoFocus
              width="120px"
              value={rowData.outcome_value || ''}
              onChange={e =>
                onFieldChange({
                  outcome_value: e.target.value,
                  outcome_period: 'monthly',
                })
              }
            />
          </ClickOutside>
        )}
      </TableRowCell>

      <TableRowCell className={classes.rowCell}>
        <Box display="flex" alignItems="center">
          <ClickAwayListener onClickAway={handleRecurringTooltipClose}>
            <div>
              <Tooltip
                onClose={handleRecurringTooltipClose}
                open={recurringTltpId === `AI-${rowData.ref_id}`}
                title="Please edit in the initiative page."
                placement="left-end"
                style={{ marginLeft: '2px' }}
              >
                <FormattedNumber
                  onClick={
                    !isRecurringTypeOutcome
                      ? handleEditClick('actualOutcome')
                      : isEditMode
                      ? handleRecurringTooltipOpn(`AI-${rowData.ref_id}`)
                      : null
                  }
                  value={rowData.actualOutcome}
                />
              </Tooltip>
            </div>
          </ClickAwayListener>
        </Box>
      </TableRowCell>
      <TableRowCell className={classes.rowCell}>
        {!isEdited('outcomeStartDateText') ? (
          <span onClick={handleEditClick('outcomeStartDateText')}>
            {rowData.outcomeStartDateText}
          </span>
        ) : (
          <DateEditField
            id="initiative-outcome-start-date"
            onChange={onOutcomeStartDateChange}
            onSave={onCommit}
            value={
              rowData.outcome_duration &&
              rowData.outcome_duration[0] &&
              moment(rowData.outcome_duration[0])
            }
          />
        )}
      </TableRowCell>
      <TableRowCell className={classes.rowCell}>
        {!isEdited('outcomeEndDateText') ? (
          <span onClick={handleEditClick('outcomeEndDateText')}>
            {rowData.outcomeEndDateText}
          </span>
        ) : (
          <DateEditField
            id="initiative-outcome-end-date"
            onChange={onOutcomeEndDateChange}
            onSave={onCommit}
            value={
              rowData.outcome_duration &&
              rowData.outcome_duration[1] &&
              moment(rowData.outcome_duration[1])
            }
          />
        )}
      </TableRowCell>

      <TableRowCell className={classes.rowCell}>
        {!isEdited('cost') ? (
          <FormattedNumber
            onClick={handleEditClick('cost')}
            value={rowData.cost}
            format={{ type: 'currency', unit: currencyUnit }}
          />
        ) : (
          <ClickOutside onClickOutside={onCommit}>
            <FormattedNumber.Input
              autoFocus
              width="120px"
              value={rowData.cost || ''}
              onChange={e =>
                onFieldChange({
                  cost: Number(e.target.value),
                  budget_period: 'monthly',
                })
              }
              format={{ type: 'currency', unit: currencyUnit }}
            />
          </ClickOutside>
        )}
      </TableRowCell>

      <TableRowCell className={classes.rowCell}>
        <ClickAwayListener onClickAway={handleRecurringTooltipClose}>
          <div>
            <Tooltip
              onClose={handleRecurringTooltipClose}
              open={recurringTltpId === `AB-${rowData.ref_id}`}
              title="Please edit in the initiative page."
              placement="left-end"
              style={{ marginLeft: '2px' }}
            >
              <FormattedNumber
                onClick={handleRecurringTooltipOpn(`AB-${rowData.ref_id}`)}
                value={rowData.actualBudget}
                format={{ type: 'currency', unit: currencyUnit }}
              />
            </Tooltip>
          </div>
        </ClickAwayListener>
      </TableRowCell>

      <TableRowCell className={classes.rowCell}>
        {!isEdited('budgetStartDateText') ? (
          <span onClick={handleEditClick('budgetStartDateText')}>
            {rowData.budgetStartDateText}
          </span>
        ) : (
          <DateEditField
            id="initiative-budget-start-date"
            onChange={onBudgetStartDateChange}
            onSave={onCommit}
            value={
              rowData.budget_duration &&
              rowData.budget_duration[0] &&
              moment(rowData.budget_duration[0])
            }
          />
        )}
      </TableRowCell>
      <TableRowCell className={classes.rowCell}>
        {!isEdited('budgetEndDateText') ? (
          <span onClick={handleEditClick('budgetEndDateText')}>
            {rowData.budgetEndDateText}
          </span>
        ) : (
          <DateEditField
            id="initiative-budget-end-date"
            onChange={onBudgetEndDateChange}
            onSave={onCommit}
            value={
              rowData.budget_duration &&
              rowData.budget_duration[1] &&
              moment(rowData.budget_duration[1])
            }
          />
        )}
      </TableRowCell>

      <TableRowCell className={classes.rowCell}>
        {!isEdited('workstream') ? (
          <span onClick={handleEditClick('workstream')}>
            {rowData.workstreamName}
          </span>
        ) : (
          <SelectEditField
            field="workstream"
            options={workstreamList}
            id="initiative-workstream"
            value={rowData.workstream}
            onChange={workstream => onFieldChange({ workstream })}
            onSave={onCommit}
          />
        )}
      </TableRowCell>

      <TableRowCell className={classes.rowCell}>
        {!isEdited('businessUnit') ? (
          <span onClick={handleEditClick('businessUnit')}>
            {rowData.businessUnitName}
          </span>
        ) : (
          <SelectEditField
            field="business_unit"
            options={businessUnitList}
            id="initiative-business-unit"
            value={rowData.business_unit}
            onChange={business_unit => onFieldChange({ business_unit })}
            onSave={onCommit}
          />
        )}
      </TableRowCell>

      <TableRowCell className={classes.rowCell}>
        {!isEdited('region') ? (
          <span onClick={handleEditClick('region')}>{rowData.regionName}</span>
        ) : (
          <SelectEditField
            field="region"
            options={regionList}
            id="initiative-region"
            value={rowData.region}
            onChange={region => onFieldChange({ region })}
            onSave={onCommit}
          />
        )}
      </TableRowCell>

      <TableRowCell className={classes.rowCell}>
        {!isEdited('sponsorUser') ? (
          <span onClick={handleEditClick('sponsorUser')}>
            {rowData.sponsorName && (
              <CenteredCell>
                <Avatar user={rowData.sponsorUser} />
                <UserName>{rowData.sponsorName}</UserName>
              </CenteredCell>
            )}
          </span>
        ) : (
          <ClickOutside onClickOutside={onCommit}>
            <Box width={USER_INPUT_WIDTH}>
              <UserAutocompleteInput
                value={rowData.sponsor.user_id}
                users={usersList}
                placeholder="Initiative sponsor"
                onChange={onSponsorChange}
                id="initiative-sponsor"
                name="sponsor"
                autocomplete="off"
              />
            </Box>
          </ClickOutside>
        )}
      </TableRowCell>

      <TableRowCell className={classes.rowCell}>
        {!isEdited('approver') ? (
          <span onClick={handleEditClick('approver')}>
            {rowData.approverName && (
              <CenteredCell>
                <Avatar user={rowData.approverUser} />
                <UserName>{rowData.approverName}</UserName>
              </CenteredCell>
            )}
          </span>
        ) : (
          <ClickOutside onClickOutside={onCommit}>
            <Box width={USER_INPUT_WIDTH}>
              <UserAutocompleteInput
                value={rowData.approver.user_id}
                users={usersList}
                placeholder="Initiative approver"
                onChange={onApproverChange}
                id="initiative-approver"
                name="approver"
                autocomplete="off"
              />
            </Box>
          </ClickOutside>
        )}
      </TableRowCell>

      <TableRowCell className={classes.rowCell}>
        {!isEdited('lead') ? (
          <span onClick={handleEditClick('lead')}>
            {rowData.leadName && (
              <CenteredCell>
                <Avatar user={rowData.leadUser} />
                <UserName>{rowData.leadName}</UserName>
              </CenteredCell>
            )}
          </span>
        ) : (
          <ClickOutside onClickOutside={onCommit}>
            <Box width={USER_INPUT_WIDTH}>
              <UserAutocompleteInput
                value={rowData.lead.user_id}
                users={usersList}
                placeholder="Initiative lead"
                onChange={onLeadChange}
                id="initiative-lead"
                name="lead"
                autocomplete="off"
              />
            </Box>
          </ClickOutside>
        )}
      </TableRowCell>

      <TableRowCell className={classes.rowCell}>
        {!isEdited('difficulty') ? (
          <span onClick={handleEditClick('difficulty')}>
            {rowData.difficultyName}
          </span>
        ) : (
          <SelectEditField
            field="difficulty"
            options={difficultyList}
            id="initiative-difficulty"
            value={rowData.difficulty}
            onChange={difficulty => onFieldChange({ difficulty })}
            onSave={onCommit}
          />
        )}
      </TableRowCell>

      <TableRowCell className={classes.rowCell}>
        {!isEdited('requiredCapacity') ? (
          <span onClick={handleEditClick('requiredCapacity')}>
            {rowData.require_capacity}
          </span>
        ) : (
          <ClickOutside onClickOutside={onCommit}>
            <Input
              autoFocus
              type="number"
              name="require_capacity"
              value={rowData.require_capacity}
              onChange={e =>
                onFieldChange({ require_capacity: Number(e.target.value) })
              }
              style={{ width: '90px' }}
            />
          </ClickOutside>
        )}
      </TableRowCell>
      <TableRowCell className={classes.rowCell}>
        {!isEdited('availableCapacity') ? (
          <span onClick={handleEditClick('availableCapacity')}>
            {rowData.available_capacity}
          </span>
        ) : (
          <ClickOutside onClickOutside={onCommit}>
            <Input
              autoFocus
              type="number"
              name="available_capacity"
              value={rowData.available_capacity}
              onChange={e =>
                onFieldChange({ available_capacity: Number(e.target.value) })
              }
              style={{ width: '90px' }}
            />
          </ClickOutside>
        )}
      </TableRowCell>

      <TableRowCell className={classes.rowCell}>
        {!isEdited('resourceType') ? (
          <span onClick={handleEditClick('resourceType')}>
            {rowData.resourceTypeName}
          </span>
        ) : (
          <SelectEditField
            field="resource_type"
            options={resourceTypeList}
            id="initiative-resource-type"
            value={rowData.resource_type}
            onChange={resource_type => onFieldChange({ resource_type })}
            onSave={onCommit}
          />
        )}
      </TableRowCell>
    </StyledTableRow>
  );
}

ListViewRow.propTypes = {
  onClick: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCommit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func,
  currencyUnit: PropTypes.string,
  rowData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    businessUnit: PropTypes.string,
    startDateText: PropTypes.string,
    endDateText: PropTypes.string,
    leadName: PropTypes.string,
    sponsorName: PropTypes.string,
    approverName: PropTypes.string,
    outcome_value_unit: PropTypes.string,
    cost: PropTypes.number,
    outcome_value: PropTypes.number,
    stageGate: PropTypes.string,
    outcomeDateText: PropTypes.string,
    difficultyName: PropTypes.string,
    status: PropTypes.string,
    require_capacity: PropTypes.number,
    available_capacity: PropTypes.number,
    resourceTypeName: PropTypes.string,
    workstreamName: PropTypes.string,
    regionName: PropTypes.string,
    badgeColor: PropTypes.string.isRequired,
  }),
};

ListViewRow.defaultProps = {
  rowData: {
    businessUnitName: '',
    startDateText: '',
    endDateText: '',
    leadName: '',
    sponsorName: '',
    approverName: '',
    cost: 0,
    outcome_value_unit: '',
    outcome_value: 0,
    stage_gate: '',
    outcomeDateText: '',
    difficulty: '',
    status: '',
    require_capacity: 0,
    available_capacity: 0,
    resourceType: '',
    workstream: '',
    region: '',
  },
};

function SelectEditField({ onSave, value, onChange, field, options, id }) {
  return (
    <ClickOutside onClickOutside={onSave} exclude={`#menu-${id}`}>
      <Select
        name={field}
        fullWidth
        value={value}
        onChange={e => onChange(e.target.value)}
        inputProps={{
          id,
          name: id,
        }}
        IconComponent={props => (
          <ExpandMore
            {...props}
            style={{
              marginRight: '9px',
            }}
          />
        )}
      >
        {options.map(({ name, _id }) => (
          <MenuItem key={_id} value={_id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </ClickOutside>
  );
}

function DateEditField({ onSave, value, onChange, id, ...props }) {
  const [focusedDate, setFocusedDate] = useState(true);

  return (
    <ClickOutside onClickOutside={onSave}>
      <DatePicker
        date={value}
        onDateChange={onChange}
        onFocusChange={({ focused }) => setFocusedDate(focused)}
        focused={focusedDate}
        numberOfMonths={1}
        id={id}
        displayFormat="MMM D, YYYY"
        {...props}
      />
    </ClickOutside>
  );
}

export default withStyles(rowStyles)(ListViewRow);
