import React from 'react';
import Input from '@hero/core/Input';
import NumberFormat from 'react-number-format';
import { DEFAULT_CURRENCY } from '@hero/tfs/src/shared/currencies';
import { useLocale } from '@hero/tfs/src/shared/locale';

export default function CurrencyInput({ currency, value, onChange, ...props }) {
  const [locale] = useLocale();
  const currencyFormatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    minimumFractionDigits: 0,
    currency,
  });

  return (
    <NumberFormat
      value={value}
      format={value => currencyFormatter.format(value)}
      customInput={Input}
      displayType="input"
      onValueChange={({ value }) => {
        onChange({
          target: {
            name: props.name,
            value: Number(value),
          },
        });
      }}
      {...props}
    />
  );
}

CurrencyInput.defaultProps = {
  currency: DEFAULT_CURRENCY,
};
