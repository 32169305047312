import React from 'react';
import styled from 'styled-components/macro';
import zxcvbn from 'zxcvbn';

import { strengthColors } from '@hero/styles/colors';
import { xxsRegular } from '@hero/styles/typography';

const Wrapper = styled.div`
  position: relative;
  width: calc(100% - 2px);
  margin: 8px 0 12px 1px;
`;

const Bar = styled.div`
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background-color: ${strengthColors.default};
`;

const ColorBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 10px;
  border-radius: 5px;
  width: 0;
  background-color: ${strengthColors.default};
  transition: width 0.5s ease-in-out;
  &[data-score='0'] {
    background-color: ${strengthColors.red};
    width: 25%;
  }
  &[data-score='1'] {
    background-color: ${strengthColors.orange};
    width: 50%;
  }
  &[data-score='2'] {
    background-color: ${strengthColors.blue};
    width: 75%;
  }
  &[data-score='3'] {
    background-color: ${strengthColors.green};
    width: 100%;
  }
  &[data-score='4'] {
    background-color: ${strengthColors.green};
    width: 100%;
  }
`;

const Label = styled.div`
  text-align: right;
  padding-right: 2px;
  font-weight: bold;
  min-height: 13px;
  &[data-score='0'] {
    color: ${strengthColors.red};
  }
  &[data-score='1'] {
    color: ${strengthColors.orange};
  }
  &[data-score='2'] {
    color: ${strengthColors.blue};
  }
  &[data-score='3'] {
    color: ${strengthColors.green};
  }
  &[data-score='4'] {
    color: ${strengthColors.green};
  }
  ${xxsRegular};
`;

function getLabel(score) {
  let label = '';
  switch (score) {
    case 'null':
      label = 'Weak';
      break;
    case 0:
      label = 'Weak';
      break;
    case 1:
      label = 'So-so';
      break;
    case 2:
      label = 'Good';
      break;
    case 3:
      label = 'Great';
      break;
    case 4:
      label = 'Great';
      break;
    default:
      label = '';
      break;
  }
  return label;
}

export default function PasswordStrength({ password }) {
  const { score } = !password ? 0 : zxcvbn(password);

  return (
    <Wrapper>
      <Bar />
      <ColorBar data-score={score} />
      <Label data-score={score}>{getLabel(score)}</Label>
    </Wrapper>
  );
}
