import React from 'react';
import styled from 'styled-components/macro';
import { md } from '@hero/styles/typography';
import { bayOfMany } from '@hero/styles/colors-v4';
import { scaffolding } from '@hero/styles/spacings';

const LabelWrapper = styled.label`
  display: block;
  ${md};
  color: ${bayOfMany};
  font-weight: bold;
  margin-top: ${props => (props.noMargin ? 0 : scaffolding.sm)};
  margin-bottom: ${scaffolding.xxs};
`;

export default function Label({ optional, children, noMargin, ...props }) {
  return (
    <LabelWrapper noMargin={noMargin} {...props}>
      {children} {optional && <span>(optional)</span>}
    </LabelWrapper>
  );
}
