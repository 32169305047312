import React from 'react';
import PropTypes from 'prop-types';
import { rgba } from 'polished';
import styled from 'styled-components/macro';
import { Box } from 'jsxstyle';
import { Text } from '@hero/styles/typography-v5';
import { bayOfMany, creamCan, romanRed } from '@hero/styles/colors-v4';
import { createDefaultBlocker } from '@hero/tfs/src/03-blockers/shared';

import { BlockedRiskPopover } from './AddBlockerSection';

const KeyRiskButton = styled.button`
  display: inline-block;
  box-sizing: border-box;
  padding: 4px 12px;
  border: none;
  border-radius: 1px;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  min-height: 28px;
  min-width: 80px;
  transition: all 0.5s;
  background-color: ${props => props.color};
  cursor: pointer;
  &:hover {
    background-color: ${props => props.hoverColor};
  }
  &:disabled {
    cursor: not-allowed;
  }
  outline: none;
  margin-right: 2px;
`;

export default function KeyRiskStatus({
  tfs,
  initiative,
  text,
  type,
  onChange,
  onAddBlocker,
  ...props
}) {
  const [isBlockerFormOpen, setBlockerFormOpen] = React.useState(false);
  const isRisk = type === 'risk';
  const isIssue = type === 'issue';

  return (
    <Box display="flex" marginRight="auto" {...props}>
      <KeyRiskButton
        color={rgba(bayOfMany, isRisk ? 0.6 : 0.3)}
        hoverColor={rgba(bayOfMany, 0.6)}
        onClick={() => onChange('risk')}
      >
        <Label>{isRisk ? 'Risk' : 'Convert to Risk'}</Label>
      </KeyRiskButton>
      <KeyRiskButton
        color={isIssue ? creamCan : rgba(bayOfMany, 0.3)}
        hoverColor={creamCan}
        onClick={() => onChange('issue')}
      >
        <Label>{isIssue ? 'Issue' : 'Convert to Issue'}</Label>
      </KeyRiskButton>
      <KeyRiskButton
        color={rgba(bayOfMany, 0.3)}
        hoverColor={romanRed}
        onClick={() => setBlockerFormOpen(true)}
      >
        <Label>Create a Blocker</Label>
      </KeyRiskButton>
      <BlockedRiskPopover
        tfsId={tfs.ref_id}
        config={tfs.configuration}
        isOpen={isBlockerFormOpen}
        blocker={{
          ...createDefaultBlocker({
            configData: tfs.configuration,
            initiatives: [initiative],
          }),
          name: text,
        }}
        onClose={() => setBlockerFormOpen(false)}
        onAddBlocker={blocker => {
          setBlockerFormOpen(false);
          onAddBlocker(blocker);
        }}
      />
    </Box>
  );
}

KeyRiskStatus.propTypes = {
  type: PropTypes.oneOf(['risk', 'issue', 'blocker']),
};

function Label(props) {
  return <Text size="p1" color="white" {...props} />;
}
