import React from 'react';

export const InitiativeContext = React.createContext(null);

export default function InitiativeEditorHost({ children }) {
  const [refreshedOn, refreshInitiatives] = React.useState(Date.now());

  return (
    <InitiativeContext.Provider value={{ refreshedOn, refreshInitiatives }}>
      {children}
    </InitiativeContext.Provider>
  );
}
