import React from 'react';
import PropTypes from 'prop-types';
import { puertoRico, bayOfMany } from '@hero/styles/colors-v4';
import FormattedNumber, {
  NumberFormatContext,
} from '@hero/tfs/src/shared/FormattedNumber';

import ProgressChart, { Canvas } from '@hero/tfs/src/02-overview/ProgressChart';
import DataTooltip from '@hero/tfs/src/02-overview/DataTooltip';
import { TYPES } from './shared';

const Area = ({ data, type, tfs, target, planned }) => {
  return (
    <>
      <FormattedNumberProvider type={type} tfs={tfs}>
        <ProgressChart
          tfsId={tfs.ref_id}
          data={data}
          planned={planned}
          target={target}
          containerHeight="auto"
        >
          <Canvas showAxis>
            <Canvas.Threshold reverse={type === TYPES.budget} />
            <Canvas.PlannedArea />
            <Canvas.ActualArea />
            <Canvas.ProjectedLine />
            <Canvas.TargetLine value={target} color={bayOfMany} />
            <Canvas.TodayLine color={puertoRico} />
            <Canvas.FocusLine showPlanned />
          </Canvas>
          <DataTooltip showPlanned />
        </ProgressChart>
      </FormattedNumberProvider>
    </>
  );
};

Area.propTypes = {
  type: PropTypes.string.isRequired,
};

export default React.memo(Area);

export function FormattedNumberProvider({ type, tfs, children }) {
  if (type === 'budget') {
    return (
      <NumberFormatContext.Provider
        value={{ type: 'currency', unit: tfs.currency_unit }}
        children={children}
      />
    );
  }

  return (
    <NumberFormatContext.Provider
      value={FormattedNumber.getNumberFormat(tfs)}
      children={children}
    />
  );
}
