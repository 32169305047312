import React from 'react';
import { Box } from 'jsxstyle';
import { useSpring, animated } from 'react-spring';
import { blueRhino, bayOfMany } from '@hero/styles/colors-v4';
import { MD, LLato } from '@hero/styles/typography';

function Line({ width, background, offsetY }) {
  const style = useSpring({ width });

  return (
    <animated.div
      style={{
        transform: `translate(0, ${offsetY}px)`,
        width: style.width.interpolate(x => x + '%'),
        height: 6,
        background,
        borderRadius: 1,
      }}
    />
  );
}

export default function Progress({ children, ...props }) {
  return (
    <Box position="relative" {...props}>
      {children}
    </Box>
  );
}

Progress.Line = function Line({ progress, background = bayOfMany }) {
  return (
    <Box position="relative" width="100%">
      <Progress.BgLine />
      <Progress.MainLine progress={progress} background={background} />
    </Box>
  );
};

Progress.BgLine = function BgLine({ background = 'white' }) {
  return <Line background={background} width={100} />;
};

Progress.MainLine = function MainLine({ progress, background }) {
  return (
    <Line
      offsetY={-6}
      background={background}
      width={(progress < 1 ? progress : 1) * 100}
    />
  );
};

Progress.Header = function ProgressHeader({ children }) {
  return <Box padding="5px 0">{children}</Box>;
};

Progress.Title = function Title({ children }) {
  return (
    <Box component={LLato} color={bayOfMany} marginRight={10}>
      {children}
    </Box>
  );
};

Progress.BoldTitle = function Title({ children }) {
  return (
    <Box
      component={LLato}
      color={bayOfMany}
      marginRight={10}
      style={{ fontWeight: 'bold' }}
    >
      {children}
    </Box>
  );
};

Progress.Current = function Current({ children }) {
  return (
    <LLato color={blueRhino} bold>
      {children}
    </LLato>
  );
};

Progress.Total = function Total({ color, children }) {
  return (
    <Box
      component={LLato}
      style={{ fontWeight: 'bold', color }}
      display="inline"
      float="right"
    >
      {children}
    </Box>
  );
};

Progress.AtTheTime = function AtTheTime({ progress, children }) {
  const style = useSpring({ progress });

  return (
    <animated.div
      style={{
        transform: style.progress.interpolate(x => `translate(${x * 100}%, 0)`),
      }}
    >
      <MD color={blueRhino}>{children}</MD>
    </animated.div>
  );
};
