import styled, { css } from 'styled-components/macro';
import { rgba } from 'polished';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import TextInput from '@hero/core/Input';

import {
  primary,
  white,
  black,
  grey900,
  colorError,
  progressBarCyan,
} from '@hero/styles/colors';
import { xxxlMedium, s2Regular } from '@hero/styles/typography';

import { FORM_WIDTH } from './SignInTabs/styles';

export const TAB_CONTAINER_SPACE = 10;

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
`;

export const Section = styled.section`
  flex: 0 1 50%;
  width: 50%;
`;

export const BannerSection = styled(Section)`
  background-color: ${primary};
  color: ${white};
  box-shadow: 2px 0px 15px 5px ${grey900};
  z-index: 1;
  height: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  ${xxxlMedium};
`;

export const TabContentWrapper = styled.div`
  width: ${FORM_WIDTH}px;
  padding: 50px ${TAB_CONTAINER_SPACE}px 10px ${TAB_CONTAINER_SPACE}px;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const Input = styled(TextInput)`
  margin-bottom: 22px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-top: 14px;
`;

export const ShowHideIconWrapper = styled.span`
  position: absolute;
  right: 10px;
  top: 8px;
  cursor: pointer;
`;

export const ErrorSection = styled.div`
  display: flex;
  width: 100%;
  margin: 30px 0 10px 0;
`;

export const ErrorLabel = styled.div`
  transform: translate(0, -21px);
  text-align: left;
  color: ${colorError};
  transition: opacity 0.5s linear;
  ${s2Regular};
`;

export const SuccessLabel = styled.span`
  text-align: left;
  min-height: 24px;
  transition: all 0.5s linear;
  margin-bottom: 5px;
  color: ${progressBarCyan};
  ${s2Regular};
  margin: 10px 0;
`;

const VisibilityIconCss = css`
  color: ${rgba(black, 0.3)};
  width: 20px;
  height: 20px;
`;

//The below &&{} is done to control priority over the material icon css.
// In some other places !important is being used
export const StyledVisibilityOff = styled(VisibilityOff)`
  && {
    ${VisibilityIconCss};
  }
`;

export const StyledVisibility = styled(Visibility)`
  && {
    ${VisibilityIconCss};
  }
`;
