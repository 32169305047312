import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row } from 'jsxstyle';
import Close from './icons/Close';

import {
  ghostGrey,
  romanRed,
  bayOfMany,
  santasGrey,
} from '@hero/styles/colors-v4';
import { rgba } from 'polished';

const TagContainer = styled.div`
  box-sizing: border-box;
  display: inline-flex;
  flex-wrap: wrap;
  position: relative;
  padding: ${props => (props.hasButton ? '0 0 0 16px' : '0 16px')};
  width: 100%;
  min-height: 42px;
  border-radius: 1px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => (props.focused ? bayOfMany : ghostGrey)};
  cursor: text;
  color: ${bayOfMany};
  background-color: white;
`;

const Input = styled.input`
  padding: 14px 0 12px 0;
  max-height: 40px;
  border: 0;
  outline: 0;
  min-width: 100px;
  flex-basis: 100px;
  flex-grow: 1;
  color: ${bayOfMany};
`;

const TagBox = styled.span`
  display: inline-flex;
  box-sizing: border-box;
  padding: 4px 2px 4px 12px;
  margin: 6.5px 5px 6.5px 0;
  border-radius: 1px;
  background-color: ${props =>
    props.invalid ? romanRed : rgba(bayOfMany, 0.3)};
  color: ${props => (props.invalid ? 'white' : bayOfMany)};
`;

const CloseButton = styled.button`
  outline: none;
  border: 0;
  border-radius: 50%;
  background-color: transparent;
  vertical-align: middle;
  padding: 1px 0;
  margin-left: 3px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  svg {
    transition: all 0.2s;
    color: ${props => (props.invalid ? 'white' : santasGrey)};
    &:hover {
      color: ${bayOfMany};
    }
  }
`;

function Tag({ text, invalid, onRemove }) {
  return (
    <TagBox invalid={invalid}>
      {text}
      <CloseButton type="button" invalid={invalid} onClick={onRemove}>
        <Close width="14px" height="14px" />
      </CloseButton>
    </TagBox>
  );
}

function last(array) {
  const length = array == null ? 0 : array.length;
  return length ? array[length - 1] : undefined;
}

export default class TagInput extends Component {
  state = {
    currentText: '',
    focused: false,
  };

  inputRef = React.createRef();

  render() {
    const { value, onChange, renderTag, right, ...remainingProps } = this.props;
    const { currentText, focused } = this.state;

    const hasButton = Boolean(right);

    return (
      <TagContainer focused={focused} hasButton={hasButton}>
        {value.length > 0 && (
          <Row flexWrap="wrap" marginRight="5px">
            {value.map((text, index) =>
              renderTag({ text, index, onRemove: () => this.removeAt(index) })
            )}
          </Row>
        )}

        <Input
          ref={this.inputRef}
          type="text"
          value={currentText}
          onChange={e => this.setState({ currentText: e.target.value })}
          onKeyPress={this.handleKeyPress}
          onKeyDown={this.handleKeyDown}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          {...remainingProps}
        />
        {hasButton && right()}
      </TagContainer>
    );
  }

  handleFocus = () => {
    this.setState({ focused: true });
  };

  handleBlur = () => {
    this.setState({ focused: false });
    this.commit();
  };

  handleKeyPress = e => {
    const { tagTrigger } = this.props;

    if (tagTrigger.includes(e.key) && this.state.currentText) {
      e.preventDefault();
      this.commit();
    }
  };

  handleKeyDown = e => {
    if (
      e.key === 'Backspace' &&
      this.state.currentText === '' &&
      this.props.value.length > 0
    ) {
      this.setState({
        currentText: last(this.props.value) || '',
      });
      this.inputRef.current.focus();
      // Remove previous value
      this.props.onChange({
        ...e,
        target: {
          ...e.target,
          value: this.props.value.slice(0, -1),
        },
      });
    }
  };

  commit = () => {
    if (this.state.currentText) {
      // Add new value
      const parts = this.state.currentText
        .split(this.props.separator)
        .filter(str => str.trim());

      this.props.onChange({
        target: {
          value: this.props.value.concat(parts),
        },
      });

      this.setState({
        currentText: '',
      });
    }
  };

  removeAt = index => {
    const { value } = this.props;
    this.props.onChange({
      target: {
        value: value.slice(0, index).concat(value.slice(index + 1)),
      },
    });
  };
}

TagInput.Tag = Tag;

TagInput.propTypes = {
  right: PropTypes.func,
};

TagInput.defaultProps = {
  value: [],
  separator: /,/,
  tagTrigger: ['Enter', ',', ' '],
  renderTag: props => <Tag key={props.index} {...props} />,
};
