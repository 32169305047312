import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ScreenClassProvider, setConfiguration } from 'react-grid-system';

import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/core/styles';
import LoginContextProvider from '@hero/tfs/src/shared/LoginContextProvider';
import ProtectedRoute from '@hero/tfs/src/shared/ProtectedRoute';
import Login from './01-account/Login';
import AccountVerify, { VerifyEmail } from './01-account/AccountVerify';
import Unauthorized from './01-account/Unauthorized';
import ChangePassword from './01-account/Login/ChangePassword';
import { greenCarribean } from '@hero/styles/colors-v4';
import theme from '@hero/styles/mui-theme';

import GlobalStyles from '@hero/tfs/src/shared/styles/GlobalStyles';
import Page404 from '@hero/tfs/src/shared/Page404';

import TfsHost from '@hero/tfs/src/10-transformations-editor/TfsHost';

import Fetch from '@hero/tfs/src/shared/Fetch';
import LoadingIndicator from '@hero/tfs/src/shared/LoadingIndicator';
import HeroApp from '@hero/tfs/src/HeroApp';
import ListViewHost from '@hero/tfs/src/07-list/ListViewHost';
import InitiativeEditorHost from '@hero/tfs/src/12-initiative/InitiativeEditorHost';
import BlockersHost from '@hero/tfs/src/03-blockers/BlockersHost';

import Logger from '@hero/tfs/src/shared/Logger';
import ErrorBoundary from '@hero/tfs/src/shared/ErrorBoundary';
import { withStyles } from '@material-ui/core/styles';
import { LocaleProvider } from '@hero/tfs/src/shared/locale';

// Log setup information
console.log(`REACT_APP_API_URL: ${process.env.REACT_APP_API_URL}`);
console.log(`REACT_APP_BUILD_NUMBER: ${process.env.REACT_APP_BUILD_NUMBER}`);
console.log(`REACT_APP_COMMIT: ${process.env.REACT_APP_COMMIT}`);

function App() {
  setConfiguration({
    containerWidths: [540, 750, 960, 1450],
    gutterWidth: 32,
  });
  return (
    <ErrorBoundary>
      <GlobalProviders>
        <Switch>
          <Route
            exact
            path="/(login|signup|reset-password)"
            component={Login}
          />
          <Route exact path="/account/verify" component={AccountVerify} />
          <Route
            exact
            path="/account/secondary-email-verify"
            component={VerifyEmail}
          />
          <Route exact path="/unauthorized" component={Unauthorized} />
          <Route
            exact
            path="/account/forgotpassword"
            component={ChangePassword}
          />

          <Route
            exact
            path="/"
            render={() => (
              <ProtectedRoute>
                <Fetch.POST
                  url="/api/transformation/list"
                  render={({ data }) =>
                    data.length > 0 ? (
                      <Redirect
                        replace
                        to={`/transformations/${data[0].ref_id}/overview`}
                      />
                    ) : (
                      <NoTransformations />
                    )
                  }
                />
              </ProtectedRoute>
            )}
          />

          <Route
            exact
            path="/transformations/blockers"
            render={() => (
              <ProtectedRoute>
                <Fetch.POST
                  url="/api/transformation/list"
                  render={({ data }) =>
                    data.length > 0 ? (
                      <Redirect
                        replace
                        to={`/transformations/${data[0].ref_id}/blockers`}
                      />
                    ) : (
                      <NoTransformations />
                    )
                  }
                />
              </ProtectedRoute>
            )}
          />

          <Route
            exact
            path="/transformations/initiatives"
            render={() => (
              <ProtectedRoute>
                <Fetch.POST
                  url="/api/transformation/list"
                  render={({ data }) =>
                    data.length > 0 ? (
                      <Redirect
                        replace
                        to={`/transformations/${data[0].ref_id}/initiatives`}
                      />
                    ) : (
                      <NoTransformations />
                    )
                  }
                />
              </ProtectedRoute>
            )}
          />

          <Route
            path={`/transformations/:tfsId`}
            render={({ match }) => (
              <ProtectedRoute>
                <HeroApp tfsId={match.params.tfsId} baseUrl={match.url} />
              </ProtectedRoute>
            )}
          />

          <Page404 />
        </Switch>
      </GlobalProviders>
    </ErrorBoundary>
  );
}

function NoTransformations() {
  return (
    <div>
      We're still working on initial screen for when there is no transformation
      created
    </div>
  );
}

const HeroSnackBarProvider = withStyles({
  success: { backgroundColor: greenCarribean },
})(({ classes, ...props }) => (
  <SnackbarProvider
    {...props}
    classes={{
      variantSuccess: classes.success,
    }}
  />
));

function GlobalProviders({ children }) {
  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <ScreenClassProvider>
          <LoginContextProvider>
            <LocaleProvider>
              <IntlProvider>
                <BrowserRouter>
                  <Logger>
                    <Fetch.Provider fallback={<LoadingIndicator loading />}>
                      <HeroSnackBarProvider
                        maxSnack={3}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                      >
                        <TfsHost>
                          <InitiativeEditorHost>
                            <BlockersHost>
                              <ListViewHost>{children}</ListViewHost>
                            </BlockersHost>
                          </InitiativeEditorHost>
                        </TfsHost>
                      </HeroSnackBarProvider>
                    </Fetch.Provider>
                  </Logger>
                </BrowserRouter>
              </IntlProvider>
            </LocaleProvider>
          </LoginContextProvider>
        </ScreenClassProvider>
      </ThemeProvider>
    </>
  );
}

ReactDOM.render(<App />, document.getElementById('app'));
