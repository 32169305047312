import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components/macro';
import { catskillWhite } from '@hero/styles/colors-v4';
import { Text } from '@hero/styles/typography-v5';
import { rgba } from 'polished';

const CardHeader = styled.div`
  padding: 9px 32px;
  background: white;
`;

const ColumnItem = styled.div`
  display: inline-flex;
  flex-direction: column;
  vertical-align: top;
  margin-right: 32px;
  background-color: ${props => props.backgroundColor};
  border-radius: 1px;
  height: 100%;
  min-width: ${props => props.width || '357px'};
  width: 100%;
  &:last-child {
    margin-right: 0;
  }
`;

const ColumnDroppable = styled.div`
  transition: background-color 0.2s ease;
  background: ${props =>
    props.isDraggingOver ? rgba(props.backgroundColor, 0.5) : 'inherit'};
  padding: 16px 8px 0px 8px;
  width: 100%;
  min-height: 200px;
`;

export class Board extends Component {
  onDragEnd = ({ source, destination }) => {
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      this.props.onChange(
        {
          [source.droppableId]: reorder(
            this.props.value[source.droppableId],
            source.index,
            destination.index
          ),
        },
        { source, destination }
      );
    } else {
      this.props.onChange(
        move(
          this.props.value[source.droppableId],
          this.props.value[destination.droppableId],
          source,
          destination
        ),
        { source, destination }
      );
    }
  };

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        {this.props.children}
      </DragDropContext>
    );
  }
}

export function Column({ backgroundColor, id, header, children }) {
  return (
    <ColumnItem backgroundColor={backgroundColor}>
      <CardHeader>
        <Text size="h6" as="span" fontWeight="600">
          {header}
        </Text>
      </CardHeader>
      <Droppable droppableId={id}>
        {(provided, snapshot) => (
          <ColumnDroppable
            backgroundColor={backgroundColor}
            ref={provided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {children}
            {provided.placeholder}
          </ColumnDroppable>
        )}
      </Droppable>
    </ColumnItem>
  );
}

export function Card({ id, index, children, onClick }) {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <div
          onClick={onClick}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            userSelect: 'none',
            backgroundColor: snapshot.isDragging ? catskillWhite : 'white',
            height: '100%',
            marginBottom: '16px',
            ...provided.draggableProps.style,
          }}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
}

function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

function move(source, destination, droppableSource, droppableDestination) {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
}
