import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { WebPrimaryBtn } from '@hero/core/Button';

import { LoginContext } from '@hero/tfs/src/shared/LoginContextProvider';

import LoadingIndicator from '@hero/tfs/src/shared/LoadingIndicator';

import {
  TabContentWrapper,
  InputWrapper,
  Input,
  ButtonWrapper,
  ErrorSection,
  ErrorLabel,
  SuccessLabel,
} from '../styles';

const EMAIL_REGEX = /^([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})$/i;
const EMPTY_STRING = '';

const DEFAULT_STATE = {
  errorMessage: EMPTY_STRING,
  resetMessage:
    'We have sent you an email, please check your email to reset password.',
  email: EMPTY_STRING,
  emailError: false,
  forgotPasswordSuccess: false,
  showLoading: false,
};

class Reset extends Component {
  static propTypes = {
    index: PropTypes.number,
    isForgotPassword: PropTypes.bool,
    errorMessage: PropTypes.string,
    forgotPassword: PropTypes.func.isRequired,
    resendVerificationEmail: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isForgotPassword: true,
  };

  state = DEFAULT_STATE;

  render() {
    const {
      email,
      emailError,
      forgotPasswordSuccess,
      resetMessage,
      resetError,
      errorMessage,
      showLoading,
    } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <TabContentWrapper>
          <InputWrapper>
            <Input
              value={email}
              error={emailError}
              placeholder={'Email'}
              name={'email'}
              onChange={this.handleChange}
            />
          </InputWrapper>
          {forgotPasswordSuccess && (
            <ErrorSection>
              <SuccessLabel>{resetMessage}</SuccessLabel>
            </ErrorSection>
          )}
          {resetError && (
            <ErrorSection>
              <ErrorLabel error={resetError}>{errorMessage}</ErrorLabel>
            </ErrorSection>
          )}
          <ButtonWrapper>
            <WebPrimaryBtn type="submit">
              {forgotPasswordSuccess ? 'Resend' : 'Reset password'}
            </WebPrimaryBtn>
          </ButtonWrapper>
          <LoadingIndicator loading={showLoading} label={'Please wait....'} />
        </TabContentWrapper>
      </form>
    );
  }

  componentDidUpdate(prevProps) {
    const { emailError } = this.state;
    const { index: prevIndex } = prevProps;
    const { index } = this.props;
    if (index !== prevIndex && emailError) {
      this.setState({
        ...DEFAULT_STATE,
      });
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
      [event.target.name + 'Error']: false,
      resetError: false,
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { isForgotPassword, forgotPassword } = this.props;
    this.validateDetails(() => {
      const { emailError } = this.state;
      if (emailError) {
        return;
      }
      this.setState({
        showLoading: true,
      });
      if (isForgotPassword) {
        const { email } = this.state;
        forgotPassword({ email }).then(res => {
          this.setState({
            showLoading: false,
          });
          const { ok, message } = res;
          if (ok) {
            this.setState({
              forgotPasswordSuccess: true,
              resetError: false,
              resetMessage: message,
            });
          } else {
            this.setState({
              forgotPasswordSuccess: false,
              resetError: true,
              errorMessage: message,
            });
          }
        });
      } else {
      }
    });
  };

  validateDetails = cb => {
    const { email } = this.state;
    const emailError = !email || !EMAIL_REGEX.test(email);

    this.setState({ emailError }, () => {
      cb();
    });
  };
}

const ResetWithProps = withRouter(routeProps => (
  <LoginContext.Consumer>
    {({ forgotPassword, resendVerificationEmail }) => (
      <Reset
        {...routeProps}
        forgotPassword={forgotPassword}
        resendVerificationEmail={resendVerificationEmail}
      />
    )}
  </LoginContext.Consumer>
));

export default props => <ResetWithProps {...props} />;
