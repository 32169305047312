import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'jsxstyle';
import { format } from 'date-fns';
import styled from 'styled-components/macro';
import TimerIcon from '@material-ui/icons/Timer';
import UpdateIcon from '@material-ui/icons/Update';
import BuildIcon from '@material-ui/icons/Build';

import {
  santasGrey,
  mahoganyRed,
  romanRed,
  bayOfMany,
  chambray,
  waikawaGrey,
} from '@hero/styles/colors-v4';
import { Text } from '@hero/styles/typography-v5';
import FormattedNumber from '@hero/tfs/src/shared/FormattedNumber';
import { getBlockerStats } from './shared';

const BlockerTitle = styled.span`
  font-family: Lato, sans-serif;
  color: ${bayOfMany};
  font-size: 15px;
  line-height: 18px;
  word-break: break-word;

  ${({ fixedHeight }) =>
    fixedHeight &&
    `overflow: hidden;
     text-overflow: ellipsis;
     display: -webkit-box;
     -webkit-box-orient: vertical;
     -webkit-line-clamp: 2;
     height: 36px;
  `}
`;

const LastUpdated = styled.div`
  text-align: right;
  min-width: 110px;
  margin-left: 10px;
  ${({ fixedHeight }) =>
    fixedHeight &&
    `display: inline-block;
     line-height: 24px;
     max-width: 110px;
     font-size: 12px;
     text-overflow: ellipsis;
     white-space: nowrap;
     overflow: hidden;
  `}
`;

const Crumb = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
  margin-right: 5px;
  background-color: ${props => props.color};
  min-width: ${props => props.width || '119px'};
  transition: all 0.2s ease-in;
  &::after {
    content: '';
    position: absolute;
    right: -10px;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-left: 10px solid ${props => props.color};
    transition: all 0.2s ease-in;
  }
`;

const timeUnit = {
  second: 's',
  seconds: 's',
  minute: 'm',
  minutes: 'm',
  hours: 'h',
  day: 'd',
  days: 'd',
  week: 'w',
  weeks: 'w',
  month: 'm',
  months: 'm',
  year: 'y',
  years: 'y',
};

function Stat({ stat, children }) {
  const [value, unit] = stat ? stat.split(' ') : ['――', ''];
  return (
    <Box
      color="white"
      display="flex"
      alignItems="center"
      minWidth="63px"
      marginLeft="20px"
      marginRight="20px"
      fontFamily="Montserrat, Lato, san-serif"
      fontWeight="600"
    >
      {children}
      <Box marginLeft="12px">
        {value}
        {timeUnit[unit]}
      </Box>
    </Box>
  );
}

function BlockerStats({ done, createdAt, state, ...props }) {
  const { leadTime, responseTime, cycleTime } = getBlockerStats({
    created_at: createdAt,
    state,
  });
  return (
    <Box
      display="flex"
      height="32px"
      justifyContent="space-between"
      backgroundColor={done ? chambray : mahoganyRed}
      transition="all .2s ease-in"
      {...props}
    >
      <Crumb width="119px" color={done ? waikawaGrey : romanRed}>
        <Stat stat={leadTime}>
          <TimerIcon opacity="0.5" />
        </Stat>
      </Crumb>
      <Stat stat={responseTime}>
        <UpdateIcon opacity="0.5" />
      </Stat>
      <Stat stat={cycleTime}>
        <BuildIcon opacity="0.5" />
      </Stat>
    </Box>
  );
}

export default function BlockerCard({
  name,
  initiatives,
  totalOutcome,
  done,
  lastUpdatedAt,
  createdAt,
  deadline,
  status,
  state,
  fixedHeight,
  ...props
}) {
  const outcome = initiatives
    .map(x => x.outcome_value)
    .reduce((x, y) => x + y, 0);

  return (
    <Box
      boxShadow="0 1px 2px 0 rgba(49,66,96,0.1)"
      height={fixedHeight ? '145px' : 'inherit'}
      display="flex"
      flexDirection="column"
      {...props}
    >
      <BlockerStats done={done} createdAt={createdAt} state={state} />
      <Box
        display="flex"
        flexDirection="column"
        backgroundColor="#FFFFFF"
        padding="8px 24px"
        flexGrow="1"
      >
        <BlockerTitle fixedHeight={fixedHeight}>{name}</BlockerTitle>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexGrow="1"
        >
          <Text as="div" size="h3" fontWeight="bold">
            <FormattedNumber value={outcome} />
          </Text>

          <LastUpdated fixedHeight={fixedHeight}>
            <Text size="p2" as="div" color={santasGrey} marginBottom="2px">
              From: {formatDate(createdAt, 'MMM d, YYYY')}
            </Text>
            <Text size="p2" as="div" color={santasGrey}>
              End: {formatDate(deadline, 'MMM d, YYYY')}
            </Text>
          </LastUpdated>
        </Box>
      </Box>
    </Box>
  );
}

BlockerCard.propTypes = {
  name: PropTypes.string.isRequired,
  initiatives: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      ref_id: PropTypes.string.isRequired,
      outcome_value: PropTypes.number.isRequired,
    })
  ),
  totalOutcome: PropTypes.number.isRequired,
  lastUpdatedAt: PropTypes.string.isRequired,
};

BlockerCard.Row = function BlockerCardRow({
  children,
  width,
  itemTop,
  ...props
}) {
  return (
    <Box {...props} display="flex" alignItems="stretch" flexFlow="row wrap">
      {React.Children.map(children, (x, i) =>
        React.cloneElement(x, {
          display: 'inline-block',
          minWidth: `${width ? width : '100%'}`,
          maxWidth: '500px',
          verticalAlign: 'top',
          flex: children.length > 0 ? 1 : 'initial',
          marginRight: i === React.Children.count(children) - 1 ? 0 : '25px',
          marginTop: itemTop,
        })
      )}
    </Box>
  );
};

function formatDate(date, formatter) {
  if (!date) {
    return '';
  }
  return format(new Date(date), formatter, { awareOfUnicodeTokens: true });
}
