import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import Fetch from '@hero/tfs/src/shared/Fetch';
import { TfsEditorContext } from '@hero/tfs/src/10-transformations-editor/TfsHost';
import { InitiativeContext } from '@hero/tfs/src/12-initiative/InitiativeEditorHost';
import ChartContainer from '@hero/tfs/src/shared/ChartContainer';
import GanttChart from './GanttChart';

export default function GanttChartContainer({ tfs, groupBy, view }) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const workstream = params.get('workstream');
  const tfsLastUpdatedAt = React.useContext(TfsEditorContext);
  const { refreshedOn } = useContext(InitiativeContext);

  return (
    <ChartContainer>
      {({ width, height }) => (
        <Fetch.POST
          key={tfsLastUpdatedAt + refreshedOn} // need this to force update bridge data after edit/create
          url="/api/chart/gantt"
          body={{
            transformation_id: tfs.ref_id,
            group_by: groupBy,
            workstream,
          }}
        >
          {({ data }) =>
            data && (
              <Fetch.POST url="/api/initiative/update" manual>
                {({ doRequest: updateInitiative }) => (
                  <Fetch
                    url={`/api/blocker/${tfs.ref_id}/read-by-transformation-id`}
                  >
                    {({ data: blockersData }) => (
                      <GanttChart
                        data={data.data.chartdata}
                        width={width}
                        height={height}
                        tfs={tfs}
                        currencyType={tfs.currency_unit}
                        timeType={tfs.time_unit}
                        statusList={tfs.configuration.status}
                        blockers={blockersData ? blockersData.data : []}
                        onInitiaveUpdate={changes =>
                          updateInitiative({
                            body: changes,
                          })
                        }
                        view={view}
                      />
                    )}
                  </Fetch>
                )}
              </Fetch.POST>
            )
          }
        </Fetch.POST>
      )}
    </ChartContainer>
  );
}
