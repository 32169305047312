import React from 'react';
import { Row } from 'jsxstyle';
import { Container, Row as GridRow, Col as GridCol } from 'react-grid-system';

import { scaffolding } from '@hero/styles/spacings';

import { getPrefix } from '@hero/tfs/src/shared/FormattedNumber';

import StatsWidget from './StatsWidget';

import { TYPES } from './shared';

export default function StatsLegend({
  type,
  target,
  planned,
  actual,
  projected,
  ...props
}) {
  const prefix = getPrefix(Math.max(target, planned, actual, projected));

  return (
    <Row marginBottom={scaffolding.md} {...props}>
      <Container fluid style={{ margin: 0, width: '100%', padding: 0 }}>
        <GridRow align="center">
          <GridCol xs={12} md={6}>
            <StatsWidget
              text={type === TYPES.budget ? 'Budget' : 'Target'}
              value={target}
              lineStyle="line"
              prefix={prefix}
            />
          </GridCol>
          <GridCol xs={12} md={6}>
            <StatsWidget
              text={type === TYPES.budget ? 'Planned spend' : 'Planned'}
              value={planned}
              lineStyle="dashed"
              prefix={prefix}
            />
          </GridCol>
          <GridCol xs={12} md={6}>
            <StatsWidget
              text={type === TYPES.budget ? 'Actual spend' : 'Actual'}
              value={actual}
              lineStyle="solid"
              prefix={prefix}
            />
          </GridCol>
          <GridCol xs={12} md={6}>
            <StatsWidget
              text={
                type === TYPES.budget ? 'Projected spend' : 'Projected outcome'
              }
              value={projected}
              lineStyle="projected"
              prefix={prefix}
            />
          </GridCol>
        </GridRow>
      </Container>
    </Row>
  );
}
