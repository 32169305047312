import qs from 'qs';
import { mapValues, first } from 'lodash';

export const BOOLEAN_WHITELIST = [];
export const NUMBER_WHITELIST = [];
export const ARRAY_WHITELIST = ['token'];

export function getQueryParams(location) {
  return qs.parse(location.search.replace(/^\?/, ''), {
    strictNullHandling: true,
  });
}

function getBooleanParam(key, value) {
  const isBoolean = BOOLEAN_WHITELIST.includes(key);
  return isBoolean ? value === 'true' : value;
}

function getNumberParam(key, value) {
  const isNumber = NUMBER_WHITELIST.includes(key);
  return isNumber ? Number(value) : value;
}

function getArrayParam(key, value) {
  const getParsedValue = value => {
    let newValue = value;
    newValue = getBooleanParam(key, newValue);
    newValue = getNumberParam(key, newValue);
    return newValue;
  };

  const isArray = ARRAY_WHITELIST.includes(key);
  return isArray ? [].concat(value).map(getParsedValue) : getParsedValue(value);
}

function getParsedParams(params) {
  return mapValues(params, (value, key) => {
    if (typeof first(value) !== 'string') {
      const children = getParsedParams(value);
      return getArrayParam(key, children);
    }
    return getArrayParam(key, value);
  });
}

export function appendDataContextToPath(path, context) {
  let location = getLocationFromPath(path);
  if (context && context.active) {
    location = addQueryParams(location, { context: context.id });
  }
  return `${location.pathname}${location.search}`;
}

export function omitDataContextFromPath(path) {
  let location = getLocationFromPath(path);
  location = addQueryParams(location, { context: undefined });
  return `${location.pathname}${location.search}`;
}

export function getStateFromLocation(location) {
  const queryParams = getQueryParams(location);
  return getParsedParams(queryParams);
}

export function getStateFromPath(path) {
  const location = getLocationFromPath(path);
  return getStateFromLocation(location);
}

export function getLocationFromPath(path = '') {
  const searchIndex = path.indexOf('?');
  const hasSearch = searchIndex > -1;
  const pathname = hasSearch ? path.substr(0, searchIndex) : path;
  const search = hasSearch ? path.substring(searchIndex) : '';
  return { pathname, search };
}

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

export function addQueryParams(location, queryParams) {
  const currentQueryParams = getQueryParams(location);
  const queryStringState = { ...currentQueryParams, ...queryParams };
  const locationState = location.state || {};
  const search = qs.stringify(queryStringState, {
    strictNullHandling: true,
    arrayFormat: 'repeat',
    sort: alphabeticalSort,
  });
  return {
    ...location,
    search: search ? `?${search}` : '',
    state: {
      ...locationState,
      ...queryStringState,
    },
  };
}

export function setQueryParams(location, queryParams) {
  return {
    ...location,
    search: `?${queryParams}`,
  };
}
