import React from 'react';
import { Box } from 'jsxstyle';
import styled from 'styled-components/macro';

import TimerIcon from '@material-ui/icons/Timer';
import UpdateIcon from '@material-ui/icons/Update';
import BuildIcon from '@material-ui/icons/Build';

import { scaffolding } from '@hero/styles/spacings';
import { romanRed, bayOfMany } from '@hero/styles/colors-v4';

import { formatDuration } from '@hero/tfs/src/03-blockers/BlockerTime';
import { Text } from '@hero/styles/typography-v5';

const statStyles = {
  small: {
    icon: {
      width: '32px',
      height: '32px',
    },
    value: 'h6',
  },
  big: {
    icon: {
      width: '48px',
      height: '48px',
    },
    value: 'h2',
  },
};

const BlockerStatIcon = styled.div(
  {
    opacity: 0.7,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: scaffolding.sm,
  },
  ({ color, variant }) => ({
    color,
    ...statStyles[variant].icon,
    svg: statStyles[variant].icon,
  })
);

export function BlockerStat({
  variant = 'small',
  icon,
  title,
  value,
  iconColor = 'white',
  textColor = 'white',
  valueColor = 'white',
  naLabel = '--',
  ...props
}) {
  return (
    <Box display="flex" alignItems="center" {...props}>
      <BlockerStatIcon color={iconColor} variant={variant}>
        {icon}
      </BlockerStatIcon>
      <Box display="flex" flexDirection="column">
        <Text size="overline" color={textColor} textTransform="uppercase">
          {title}
        </Text>
        <Text
          size={statStyles[variant].value}
          color={valueColor}
          marginTop={scaffolding.xxs}
          opacity={value ? 1 : 0.3}
          fontWeight={600}
        >
          {value || naLabel}
        </Text>
      </Box>
    </Box>
  );
}

export default function BlockerStatsRow({
  leadTime,
  reactionTime,
  cycleTime,
  statRowProps,
  naLabel,
  doFormat,
  variant,
  ...props
}) {
  const statProps = {
    iconColor: romanRed,
    textColor: bayOfMany,
    valueColor: bayOfMany,
    marginRight: '56px',
    ...statRowProps,
  };
  return (
    <Box
      display="flex"
      marginLeft="auto"
      alignItems="center"
      justifyContent="flex-end"
      {...props}
    >
      <BlockerStat
        title="Lead time:"
        icon={<TimerIcon />}
        value={doFormat ? formatDuration(leadTime) : leadTime}
        naLabel={naLabel}
        variant={variant}
        {...statProps}
      />
      <BlockerStat
        title="Response time:"
        value={doFormat ? formatDuration(reactionTime) : reactionTime}
        icon={<UpdateIcon />}
        naLabel={naLabel}
        variant={variant}
        {...statProps}
      />
      <BlockerStat
        title="Cycle time:"
        value={doFormat ? formatDuration(cycleTime) : cycleTime}
        icon={<BuildIcon />}
        naLabel={naLabel}
        variant={variant}
        {...statProps}
      />
    </Box>
  );
}
