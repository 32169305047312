import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Box } from 'jsxstyle';
import { useSnackbar } from 'notistack';
import { Container, Row, Col } from 'react-grid-system';

import Dialog, { Actions, MainActionBtn, ActionBtn } from '@hero/core/Dialog';
import Label from '@hero/core/Label';
import Input from '@hero/core/Input';
import CircularProgress from '@hero/core/CircularProgress';

import { bayOfMany, mountainMeadow, puertoRico } from '@hero/styles/colors-v4';
import { Text } from '@hero/styles/typography-v5';

import FormattedNumber, {
  NumberFormatContext,
} from '@hero/tfs/src/shared/FormattedNumber';

import ObjectInput from '@hero/tfs/src/shared/ObjectInput';
import Menu from '@hero/tfs/src/shared/Menu';
import Fetch from '@hero/tfs/src/shared/Fetch';
import Editor from '@hero/tfs/src/shared/Editor';
import UserAvatar from '@hero/tfs/src/shared/UserAvatar';
import UserMenu, { DL, DT } from '@hero/tfs/src/shared/UserMenu';
import useUsers from '@hero/tfs/src/shared/hooks/useUsers';
import useTransformation from '@hero/tfs/src/shared/hooks/useTransformation';
import useInitiativeRouting from '@hero/tfs/src/shared/hooks/useInitiativeRouting';

import {
  createInitiative,
  serialize,
  allocate,
} from '@hero/tfs/src/09-initiatives-editor/shared';
import { InitiativeContext } from '@hero/tfs/src/12-initiative/InitiativeEditorHost';
import { Crumb, Border } from '@hero/tfs/src/03-blockers/BlockerForm.js';

const InputBlock = styled.div`
  width: 100%;
  min-width: 230px;
  display: inline-block;
  input:disabled {
    background-color: rgba(139, 147, 166, 0.2);
  }
`;

const ProgressSpinner = styled(CircularProgress)`
  margin-left: 8px;
`;

export default function NewInitiative({ isOpen, tfsId, onCancel }) {
  const [tfs] = useTransformation(tfsId);
  const users = useUsers();
  const { startEditing } = useInitiativeRouting();
  const { enqueueSnackbar } = useSnackbar();
  const { refreshInitiatives } = useContext(InitiativeContext);

  if (!tfs || users.length === 0) {
    return null;
  }

  const handleCloseInit = () => {
    onCancel();
    refreshInitiatives(Date.now());
  };

  const { workstream } = tfs.configuration;

  return (
    <Dialog
      zIndex={10}
      isOpen={isOpen}
      width="auto"
      isDismissable
      headerColor={mountainMeadow}
      header={
        <Box display="flex" alignItems="center" flex="1">
          <Crumb color={puertoRico}>
            <Text size="h3" color={bayOfMany} fontWeight="700">
              New initiative
            </Text>
          </Crumb>
          <Border />
        </Box>
      }
      onClose={onCancel}
    >
      <InitiativeEditor
        tfs={tfs}
        users={users}
        onCreate={init => {
          startEditing(tfsId, init.ref_id);
        }}
      >
        {({ value, onChange, doCreate, creating }) => {
          const { unit: outcomeUnit } = React.useContext(NumberFormatContext);

          return (
            <Box display="flex">
              <Container fluid style={{ padding: 0, width: '100%' }}>
                <Row>
                  <Col xs={12}>
                    <InputBlock>
                      <Label htmlFor="new-init">Name</Label>
                      <ObjectInput
                        as={Input}
                        autoFocus
                        id="new-init-name"
                        name="name"
                        placeholder="Enter initiative name"
                        value={value}
                        onChange={e => onChange(e.target.value)}
                        form="initiative-form"
                      />
                    </InputBlock>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} lg={6}>
                    <InputBlock>
                      <Label htmlFor="new-init-cost">Budget</Label>
                      <FormattedNumber.CurrencyProvider
                        unit={tfs.currency_unit}
                      >
                        <FormattedNumber.Input
                          id="new-init-cost"
                          showUnit={false}
                          width="100%"
                          placeholder={`Enter budget in ${tfs.currency_unit}`}
                          value={value.cost || ''}
                          onChange={e => {
                            onChange({
                              ...value,
                              cost: Number(e.target.value),
                              budget_tracking: allocate(
                                Number(e.target.value),
                                value.budget_period,
                                value.budget_duration
                              ),
                            });
                          }}
                        />
                      </FormattedNumber.CurrencyProvider>
                    </InputBlock>
                  </Col>
                  <Col sm={12} lg={6}>
                    <InputBlock>
                      <Label htmlFor="new-init-outcome">Outcome</Label>
                      <FormattedNumber.Input
                        id="new-init-outcome"
                        showUnit={false}
                        width="100%"
                        placeholder={`Enter outcome in ${outcomeUnit}`}
                        value={value.outcome_value || ''}
                        onChange={e => {
                          onChange({
                            ...value,
                            outcome_value: Number(e.target.value),
                            outcome_tracking: allocate(
                              Number(e.target.value),
                              value.outcome_period,
                              value.outcome_duration
                            ),
                          });
                        }}
                      />
                    </InputBlock>
                  </Col>
                </Row>
                <NewInitSetup
                  value={value}
                  users={users}
                  workstream={workstream}
                  onChange={changes =>
                    onChange(latestValue => ({ ...latestValue, ...changes }))
                  }
                />
              </Container>
              <Box flex="1">
                <Actions>
                  <ActionBtn
                    disabled={!isInitiativeValid(value) || creating}
                    type="button"
                    onClick={() => {
                      handleCreateInit({
                        initiative: value,
                        enqueueSnackbar,
                        doCreate,
                        onCreate: handleCloseInit,
                      });
                    }}
                  >
                    {creating ? <ProgressSpinner size={20} /> : 'Create'}
                  </ActionBtn>
                  <MainActionBtn
                    form="create-initiative-form"
                    disabled={!isInitiativeValid(value) || creating}
                    type="submit"
                  >
                    {creating ? <ProgressSpinner size={20} /> : 'Create & Open'}
                  </MainActionBtn>
                </Actions>
              </Box>
            </Box>
          );
        }}
      </InitiativeEditor>
    </Dialog>
  );
}

NewInitiative.propTypes = {
  tfsId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

function NewInitSetup({ value, users, workstream, onChange }) {
  return (
    <Row>
      <Col sm={12} lg={6}>
        <DL>
          <Box float="left" marginRight={10}>
            <UserAvatar user={getValidUser(value.lead, users)} />
          </Box>

          <DT>Lead</DT>
          <UserMenu
            users={users}
            selected={value.lead && value.lead.user_id}
            onSelect={id => {
              onChange({
                lead: users.find(x => x.user_id === id),
              });
            }}
          />
        </DL>
      </Col>
      <Col sm={12} lg={6}>
        <DL>
          <DT>Workstream</DT>
          <Menu
            items={workstream}
            selected={value.workstream}
            onSelect={id => {
              onChange({ workstream: id });
            }}
          />
        </DL>
      </Col>
    </Row>
  );
}

function InitiativeEditor({ tfs, onCreate, children }) {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Fetch.POST
      manual
      url="/api/initiative/create"
      onError={() =>
        enqueueSnackbar('Failed to create the initiative', {
          variant: 'error',
        })
      }
    >
      {({ loading, doRequest: doCreate }) => (
        <FormattedNumber.TfsProvider tfs={tfs}>
          <Editor
            id="create-initiative-form"
            initialValue={createInitiative(tfs)}
            onSubmit={initiative =>
              handleCreateInit({
                initiative,
                doCreate,
                onCreate,
                enqueueSnackbar,
              })
            }
          >
            {props => children({ ...props, doCreate, creating: loading })}
          </Editor>
        </FormattedNumber.TfsProvider>
      )}
    </Fetch.POST>
  );
}

function handleCreateInit({ initiative, doCreate, onCreate, enqueueSnackbar }) {
  if (isInitiativeValid(initiative)) {
    doCreate({
      body: serialize(initiative),
    }).then(rsp => {
      enqueueSnackbar('Initiative created Successfully', {
        variant: 'success',
      });
      onCreate(rsp.data);
    });
  }
}

function isInitiativeValid({ name, cost }) {
  return (
    name && name.trim() && parseFloat(cost) !== 0 && isFinite(parseFloat(cost))
  );
}

function getValidUser(user, users) {
  if (!user) {
    return {};
  }

  const { first_name, last_name, profile_picture } = user;

  return profile_picture || (first_name && last_name)
    ? user
    : users.find(x => x.user_id === user.user_id);
}
