import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Box } from 'jsxstyle';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import MaterialSwitch from '@hero/core/MaterialSwitch';

import { Text } from '@hero/styles/typography-v5';
import { greyManatee } from '@hero/styles/colors-v4';

import SearchParams from '@hero/tfs/src/shared/SearchParams';
import { DL, DT } from '@hero/tfs/src/shared/UserMenu';
import Menu from '@hero/tfs/src/shared/Menu';

export const ToolbarOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .icon {
    width: 20px;
    height: 20px;
    color: ${greyManatee};
    margin-right: 20px;
  }
`;

const menuButtonStyles = {
  fontWeight: 600,
};

export const GROUP_OPTIONS = [
  { name: 'Workstream', _id: 'workstream', pluralText: 'workstreams' },
  { name: 'Stage', _id: 'stage_gate', pluralText: 'stages' },
  { name: 'Status', _id: 'status', pluralText: 'status' },
  {
    name: 'Business unit',
    _id: 'business_unit',
    pluralText: 'business units',
  },
  { name: 'Region', _id: 'region', pluralText: 'regions' },
  { name: 'Difficulty', _id: 'difficulty', pluralText: 'difficulties' },
  { name: 'Lead', _id: 'lead', pluralText: 'leads' },
];

export const NONE_OPTN_ID = 'none';

const LIST_GROUP_OPTIONS = [
  { name: 'None', _id: NONE_OPTN_ID },
  { name: 'Workstream', _id: 'workstream' },
  { name: 'Stage', _id: 'stage_gate' },
  { name: 'Status', _id: 'status' },
  {
    name: 'Business unit',
    _id: 'business_unit',
  },
  { name: 'Region', _id: 'region' },
  { name: 'Difficulty', _id: 'difficulty' },
  { name: 'Lead', _id: 'lead' },
];

export const CHART_TYPE_LIST = 'list';

export function GroupSelect({ value, chartType, onChange }) {
  return (
    <DL marginRight={32}>
      <DT>Group</DT>
      <Menu
        items={
          chartType === CHART_TYPE_LIST ? LIST_GROUP_OPTIONS : GROUP_OPTIONS
        }
        selected={value}
        onSelect={id => onChange({ group: id })}
        buttonStyles={menuButtonStyles}
        iconSize="30px"
      />
    </DL>
  );
}

export const SIZE_OPTIONS = [
  { name: 'Outcome', _id: 'outcome_value' },
  { name: 'Budget', _id: 'cost' },
];

export function SizeSelect({ value, onChange }) {
  return (
    <DL>
      <DT>Size</DT>
      <Menu
        items={SIZE_OPTIONS}
        selected={value}
        onSelect={id => onChange({ size: id })}
        buttonStyles={menuButtonStyles}
        iconSize="30px"
      />
    </DL>
  );
}

export const VIEW_OPTIONS = [
  { name: 'All', _id: 'all' },
  { name: 'Months', _id: 'months' },
  { name: 'Weeks', _id: 'weeks' },
];

export function ViewSelect({ value, onChange }) {
  return (
    <DL marginRight={32}>
      <DT>View</DT>
      <Menu
        items={VIEW_OPTIONS}
        selected={value}
        onSelect={id => onChange({ view: id })}
        buttonStyles={menuButtonStyles}
        iconSize="30px"
      />
    </DL>
  );
}

export const VIEW_BY_OPTIONS = [
  { name: 'Budget', _id: 'cost' },
  { name: 'Duration', _id: 'duration' },
];

export function ViewBySelect({ value, onChange }) {
  return (
    <>
      <DL>
        <DT>View By</DT>
        <Menu
          items={VIEW_BY_OPTIONS}
          selected={value}
          onSelect={id => onChange({ viewBy: id })}
          buttonStyles={menuButtonStyles}
          iconSize="30px"
        />
      </DL>
    </>
  );
}

export function ShowZeroItemsToggle({ group, value, onChange }) {
  return (
    <Box marginLeft="36px">
      <FormControlLabel
        labelPlacement="end"
        control={
          <MaterialSwitch
            checked={String(value) === '1'}
            onChange={() =>
              onChange({ showZeroItems: String(value) === '1' ? 0 : 1 })
            }
            value="showZeroItems"
          />
        }
        label={
          <Text size="p2" fontWeight="bold" marginLeft="12px">
            Show non-contributing{' '}
            {group === NONE_OPTN_ID
              ? ''
              : GROUP_OPTIONS.find(x => x._id === group).pluralText}
          </Text>
        }
      />
    </Box>
  );
}

const DEFAULT_GROUP_OPTION = {
  'effort-outcome': 'workstream',
  list: NONE_OPTN_ID,
};
class ChartConfiguration extends Component {
  state = {
    savedFilters: readFromLocalStorage(),
  };

  componentDidUpdate(prevProps) {
    if (
      !isEqual(this.props.params, prevProps.params) &&
      !isEmpty(this.props.params)
    ) {
      const filters = {
        ...this.state.savedFilters,
        ...this.props.params,
      };

      localStorage.setItem('chartFilters', JSON.stringify(filters));
      this.setState({ savedFilters: filters });
    }
  }

  render() {
    const { params, setParams, chartType } = this.props;
    const { savedFilters } = this.state;

    return this.props.children({
      // default values
      group: DEFAULT_GROUP_OPTION[chartType] || 'stage_gate',
      size: 'outcome_value',
      view: 'all',
      viewBy: 'cost',
      showZeroItems: 1,
      chartType,
      setChartOption: setParams,

      ...ensureCorrectFilters(savedFilters, chartType),
      ...params,
    });
  }
}

export default props => (
  <SearchParams>
    {({ params, setParams }) => (
      <ChartConfiguration params={params} setParams={setParams} {...props}>
        {props.children}
      </ChartConfiguration>
    )}
  </SearchParams>
);

ChartConfiguration.propTypes = {
  chartType: PropTypes.oneOf(['bridge', 'gantt', 'effort-outcome', 'list'])
    .isRequired,
  children: PropTypes.func.isRequired,
};

function readFromLocalStorage() {
  // Clean old chart filters
  ['bridge-filters', 'gantt-filters', 'effort-outcome-filters'].forEach(key => {
    localStorage.removeItem(key);
  });

  if (localStorage.hasOwnProperty('chartFilters')) {
    try {
      return JSON.parse(localStorage.getItem('chartFilters'));
    } catch (e) {
      console.warn(
        `Failed to read chart filters from localStorage. ` +
          `Reason: ${e.toString()}`
      );

      return {};
    }
  }
}

function ensureCorrectFilters(filters, chartType) {
  return pickBy(filters, (value, key) => {
    if (key === 'group') {
      return GROUP_OPTIONS.some(x => x._id === value);
    }

    if (key === 'view') {
      return VIEW_OPTIONS.some(x => x._id === value);
    }

    if (key === 'viewBy') {
      return VIEW_BY_OPTIONS.some(x => x._id === value);
    }

    if (key === 'size') {
      return SIZE_OPTIONS.some(x => x._id === value);
    }

    if (key === 'showZeroItems') {
      return ['0', '1'].includes(String(value));
    }

    return false;
  });
}
