import React from 'react';

const Bird = ({ size }) => (
  <svg width={size} height={size} viewBox="0 0 22 21">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-80.000000, -842.000000)">
        <g transform="translate(40.000000, 814.000000)">
          <g transform="translate(40.000000, 28.000000)">
            <path
              d="M21.8076923,10.8800905 C21.8076923,16.4428235 17.0729558,20.9524887 11.2316742,20.9524887 C5.39039258,20.9524887 0.655656109,16.4428235 0.655656109,10.8800905 C0.655656109,5.31735752 5.39039258,0.807692308 11.2316742,0.807692308 C17.0729558,0.807692308 21.8076923,5.31735752 21.8076923,10.8800905"
              fill="#FFE014"
            />
            <path
              d="M17.778733,6.34802226 C17.778733,7.18086005 17.1022287,7.85837104 16.2678733,7.85837104 C15.4325375,7.85837104 14.7570136,7.18086005 14.7570136,6.34802226 C14.7570136,5.51314069 15.4325375,4.83665158 16.2678733,4.83665158 C17.1022287,4.83665158 17.778733,5.51314069 17.778733,6.34802226"
              fill="#565656"
            />
            <polygon
              fill="#FFE014"
              points="0.655656109 20.9524887 9.72081448 20.9524887 9.72081448 9.87285068 0.655656109 9.87285068"
            />
            <polygon
              fill="#FFE014"
              points="17.778733 2.82217195 18.7859729 2.82217195 18.7859729 1.81493213 17.778733 1.81493213"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Bird.defaultProps = {
  size: '22px',
};

export default Bird;
