import React from 'react';
import { Box, Inline } from 'jsxstyle';
import { Link } from 'react-router-dom';
import { T2, LLato } from '@hero/styles/typography';
import { greenCarribean } from '@hero/styles/colors-v4';
import BirdIcon from '@hero/core/icons/Bird';

export default function Page404() {
  const url = new URL('/create', window.location.toString());
  const parts = url.hostname.split('.');
  url.hostname = (parts.length > 2 ? parts.slice(1) : parts).join('.');

  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box transform="rotate(-180deg)">
        <BirdIcon size="50px" />
      </Box>

      <Inline
        padding="7px 20px 7px 10px"
        borderColor="#CCC"
        borderStyle="solid"
        borderWidth="0 1px 0 0"
      >
        <T2>404</T2>
      </Inline>

      <Box padding="0 20px" maxWidth="500px">
        <p>
          <LLato>
            Oh dear... you tried to find something that does not exist.{' '}
          </LLato>
        </p>
        <p>
          <LLato>
            Click{' '}
            <Link to="/" style={{ color: greenCarribean }}>
              here
            </Link>{' '}
            to go to the homepage or{' '}
            <a href={url.toString()} style={{ color: greenCarribean }}>
              here
            </a>{' '}
            to create a new company.
          </LLato>
        </p>
      </Box>
    </Box>
  );
}
