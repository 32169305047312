import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Box } from 'jsxstyle';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import ArrowDropDown from '@material-ui/icons/ArrowDropDownRounded';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@hero/core/icons/Check';
import { Text } from '@hero/styles/typography-v5';
import {
  blueRhino,
  greyManatee,
  greenCarribean,
  bayOfMany,
} from '@hero/styles/colors-v4';
import UserAvatar from '../../tfs/src/shared/UserAvatar';
import { ButtonBase } from '@material-ui/core';

const ListItem = withStyles({
  root: {
    position: 'relative',
  },

  button: {
    '&:hover': {
      background: 'white',
      fontWeight: 'bold',
    },
  },

  selected: {
    backgroundColor: 'white !important',

    '&:hover': {
      backgroundColor: 'rgab(0,0,0, 0.1) !important',
    },
  },
})(MuiListItem);

const List = withStyles({
  root: {
    padding: '5px 0',
  },
})(MuiList);

const ArrowDropDownIcon = withStyles({
  root: {
    alignSelf: 'center',
    color: greyManatee,
    fontSize: '19px',
  },
})(ArrowDropDown);

const SelectMenuContext = React.createContext({
  selected: null,
});

export default function SelectMenu({
  selected,
  target,
  closeOnSelect,
  onChange,
  children,
  ...props
}) {
  const [open, setOpen] = React.useState(false);
  const [ctx, setCtx] = React.useState({
    selected,
    select,
    close,
  });

  const select = React.useCallback(
    newSelected => {
      onChange(newSelected);

      if (closeOnSelect) {
        setTimeout(() => setOpen(false), 100);
      }
    },
    [closeOnSelect, setOpen]
  );

  const onClick = React.useCallback(() => setOpen(true), [setOpen]);
  const close = React.useCallback(() => setOpen(false), [setOpen]);

  React.useEffect(() => {
    if (target.current) {
      target.current.addEventListener('click', onClick, false);
    }

    return () => {
      if (target.current) {
        target.current.removeEventListener('click', onClick, false);
      }
    };
  }, [target.current]);

  React.useEffect(() => {
    setCtx({
      selected,
      select,
      close,
    });
  }, [selected, select, close]);

  return (
    <SelectMenuContext.Provider value={ctx}>
      <Popover
        id="select-menu"
        open={open}
        anchorEl={target.current}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box width={250} padding="0.5em 0" {...props}>
          {children}
        </Box>
      </Popover>
    </SelectMenuContext.Provider>
  );
}

SelectMenu.propTypes = {
  selected: PropTypes.any,
  closeOnSelect: PropTypes.bool,
  onChange: PropTypes.func,
};

SelectMenu.defaultProps = {
  closeOnSelect: true,
  onChange: () => {},
};

SelectMenu.Header = function({ title }) {
  const { close } = React.useContext(SelectMenuContext);

  return (
    <Box
      position="sticky"
      top={0}
      height="50px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding="0 10px"
      boxShadow="0 1px 1px rgba(0,0,0, 0.15)"
      backgroundColor="white"
      zIndex={1}
    >
      <Text size="h6" color={blueRhino}>
        {title}
      </Text>

      <Box
        position="absolute"
        right="10px"
        top="0"
        height="100%"
        display="flex"
        alignItems="center"
        color={greyManatee}
      >
        <ButtonBase onClick={close}>
          <CloseIcon />
        </ButtonBase>
      </Box>
    </Box>
  );
};

SelectMenu.List = function({ options }) {
  return (
    <List>
      {options.map(({ id, text }) => (
        <SelectMenu.ListItem key={id} id={id} text={text} />
      ))}
    </List>
  );
};

SelectMenu.ListItem = function({ id, text }) {
  const { selected, select } = React.useContext(SelectMenuContext);
  const isSelected = selected == id;

  return (
    <ListItem button={true} selected={isSelected} onClick={() => select(id)}>
      <IconBox visibility={isSelected ? 'visible' : 'hidden'}>
        <CheckIcon />
      </IconBox>
      <Text paddingLeft={32} size="p1" fontWeight="inherit">
        {text}
      </Text>
    </ListItem>
  );
};

SelectMenu.UserList = function({ users }) {
  return (
    <List>
      {users
        .filter(user => user.status === 'active')
        .map(user => (
          <SelectMenu.UserItem key={user.user_id} user={user} />
        ))}
    </List>
  );
};

SelectMenu.UserItem = function({ user }) {
  const { selected, select } = React.useContext(SelectMenuContext);
  const isSelected = selected == user.user_id;

  return (
    <ListItem
      button={true}
      selected={isSelected}
      onClick={() => select(user.user_id)}
    >
      <IconBox padding={0}>
        <UserAvatar user={user} />
      </IconBox>
      <IconBox
        visibility={isSelected ? 'visible' : 'hidden'}
        backgroundColor="rgba(255,255,255, 0.4)"
      >
        <CheckIcon />
      </IconBox>
      <Text
        size="p1"
        fontWeight={isSelected ? 'bold' : 'normal'}
        paddingLeft={37}
      >
        {user.first_name + ' ' + user.last_name}{' '}
      </Text>
    </ListItem>
  );
};

SelectMenu.Divider = React.forwardRef(function(props, ref) {
  return <Box props={{ ref }} height="1px" background="#e2e2e2" width="100%" />;
});

const Btn = styled.button`
  border: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  position: relative;
  outline: 0;
  background-color: transparent;
  color: ${props => (props.color ? props.color : bayOfMany)};
  pointer-events: ${props => (props.readOnly ? 'none' : 'initial')};
`;

SelectMenu.TargetButton = React.forwardRef(function(
  { children, title, iconSize, showIcon, maxWidth = '165px', ...props },
  ref
) {
  return (
    <Btn ref={ref} {...props} type="button" title={title}>
      <Box display="flex" alignItems="center">
        <Box
          maxWidth={maxWidth}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {children}
        </Box>
        {showIcon && <ArrowDropDownIcon style={{ fontSize: iconSize }} />}
      </Box>
    </Btn>
  );
});

function IconBox({ isSelected, ...props }) {
  return (
    <Box
      position="absolute"
      top={3}
      left={10}
      width={33}
      height={33}
      padding={8}
      color={greenCarribean}
      {...props}
    />
  );
}
