import React, { useEffect } from 'react';

export default function ClickOutside({ children, onClickOutside, exclude }) {
  const refs = React.Children.map(children, () => React.createRef());

  const shouldExclude = e => {
    if (exclude) {
      try {
        const nodes = Array.from(document.querySelectorAll(exclude));

        for (let excludedNode of nodes) {
          if (excludedNode.contains(e.target)) {
            return true;
          }
        }

        return false;
      } catch (err) {
        console.error('ClickOutside: Invalid exclude selector syntax.', err);
      }
    }

    return false;
  };

  const handleClick = e => {
    const isOutside = refs.every(ref => {
      return ref.current && !ref.current.contains(e.target);
    });

    if (isOutside && !shouldExclude(e)) {
      onClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return function() {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  return React.Children.map(children, (element, idx) => (
    <div ref={refs[idx]}>{React.cloneElement(element)}</div>
  ));
}
