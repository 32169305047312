import styled, { css } from 'styled-components';

/* v4 typography */

const base = styled.span`
  font-weight: ${props => (props.bold ? 700 : 400)};
  color: ${props => props.color};
`;

// title1
export const t1 = css`
  font-family: Lato;
  font-size: 40px;
  line-height: 49px;
  letter-spacing: -1.5px;
`;

// title2
export const t2 = css`
  font-family: Lato;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.7px;
`;

// title3
export const t3 = css`
  font-family: Lato;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.2px;
`;

export const tm3 = css`
  font-family: Montserrat, Lato, san-serif;
  font-size: 18px;
  line-height: 22px;
`;

// x-large
export const xl = css`
  font-family: Lato;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0;
`;

// large-mont
export const lmont = css`
  font-family: Montserrat, Lato, san-serif;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
`;

// large-lato
export const llato = css`
  font-family: Lato;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0;
`;

// medium
export const md = css`
  font-family: Lato;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0;
`;

// x-small
export const xs = css`
  font-family: Lato;
  font-size: 8px;
  line-height: 8px;
  letter-spacing: 0.5px;
`;

export const T1 = styled(base)`
  ${t1};
`;
export const T2 = styled(base)`
  ${t2};
`;
export const T3 = styled(base)`
  ${t3};
`;

export const TM3 = styled(base)`
  ${tm3}
`;

export const XL = styled(base)`
  ${xl};
`;
export const LMont = styled(base)`
  ${lmont};
`;
export const LLato = styled(base)`
  ${llato};
`;
export const MD = styled(base)`
  ${md};
`;
export const XS = styled(base)`
  ${xs};
`;

/* v3 typography */

export const sizes = {
  xxxl: '83px',
  xxl2: '40px',
  xxl: '36px',
  xl: '28px',
  l: '23px',
  m: '19px',
  m2: '18px',
  m3: '16px',
  s: '15px',
  s2: '14px',
  xs: '13px',
  xxs2: '12px',
  xxs: '10px',
  xxsx: '8px',
};

export const weights = {
  bolder: 900,
  bold: 700,
  medium: 500,
  regular: 400,
  light: 300,
};

// Icons
export const IconCSS = css`
  margin-right: 20px;
  margin-bottom: -5px;
`;

// Custom (Logo)
export const sBoldLogo = css`
  font-size: ${sizes.s};
  font-weight: ${weights.bold};
  letter-spacing: normal;
  line-height: 18px;
`;

// H0 (Home)
export const xxxlMedium = css`
  font-size: ${sizes.xxxl};
  font-weight: ${weights.medium};
  letter-spacing: 0.75px;
  line-height: 83px;
`;

// H1
export const xxlMedium = css`
  font-size: ${sizes.xxl};
  font-weight: ${weights.medium};
  letter-spacing: 0.3px;
  line-height: 41px;
`;

export const xxlBold = css`
  font-size: ${sizes.xxl};
  font-weight: ${weights.bold};
  line-height: 44px;
`;

// H2
export const xlBold = css`
  font-size: ${sizes.xl};
  font-weight: ${weights.bold};
  letter-spacing: 0.3px;
  line-height: 35px;
`;

// H3
export const lBold = css`
  font-size: ${sizes.l};
  font-weight: ${weights.bold};
  letter-spacing: 0.5px;
  line-height: 31px;
`;

// Main navigation
export const lMedium = css`
  font-size: ${sizes.l};
  font-weight: ${weights.medium};
  letter-spacing: normal;
  line-height: 31px;
`;

// H4
export const mMedium = css`
  font-size: ${sizes.m};
  font-weight: ${weights.medium};
  letter-spacing: normal;
  line-height: 27px;
`;

export const m3Bold = css`
  font-size: ${sizes.m3};
  font-weight: ${weights.bold};
  line-height: 19px;
`;

// Paragraph regular (Root)
export const sRegular = css`
  font-size: ${sizes.s};
  font-weight: ${weights.regular};
  letter-spacing: 0.3px;
  line-height: 24px;
`;

export const sBold = css`
  font-size: ${sizes.s};
  font-weight: ${weights.bold};
  letter-spacing: 0.3px;
  line-height: 24px;
`;

export const s2Regular = css`
  font-size: ${sizes.s2};
  font-weight: ${weights.regular};
  letter-spacing: 0.3px;
  line-height: 19px;
`;

// Paragraph small
export const xsRegular = css`
  font-size: ${sizes.xs};
  font-weight: ${weights.regular};
  letter-spacing: 0.8px;
  line-height: 21px;
`;

// Button / Tab / Dropdown
export const xsMediumUppercase = css`
  font-size: ${sizes.xs};
  font-weight: ${weights.medium};
  letter-spacing: 0.3px;
  line-height: 13px;
  text-transform: uppercase;
`;

// Overline
export const xxsRegularUppercase = css`
  font-size: ${sizes.xxs};
  font-weight: ${weights.regular};
  letter-spacing: 0.8px;
  line-height: 12px;
  text-transform: uppercase;
`;

// Caption
export const xxsRegular = css`
  font-size: ${sizes.xxs};
  font-weight: ${weights.regular};
  letter-spacing: normal;
  line-height: 13px;
`;

export const LXxsRegular = styled(base)`
  font-family: Lato, san-serif;
  ${xxsRegular}
`;

export const MXXLBold = styled(base)`
  font-family: Montserrat, Lato, san-serif;
  ${xxlBold};
`;

export const xxs2Lato = css`
  font-family: Lato, san-serif;
  font-size: ${sizes.xxs2};
  font-weight: ${weights.regular};
  line-height: 12px;
`;

export const M3Bold = styled.b`
  ${m3Bold};
`;

export const XXSUpperCase = styled(base)`
  ${xxsRegularUppercase};
  letter-spacing: initial;
`;
