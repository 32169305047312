import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { grey150 } from '@hero/styles/colors';
import { sizes } from '@hero/styles/typography';

const Img = styled.div`
  border-radius: 1px;
  display: inline-block;
  font-size: ${sizes.xxs};
  height: 33px;
  line-height: 33px;
  overflow: hidden;
  text-align: center;
  background-image: url("${props => props.picture}");
  background-size: 100%;
  width: 33px;
  box-shadow: inset 0px 1px 3px hsla(0, 0%, 0%, 0.2);
`;

const Initials = styled.span`
  border-radius: 1px;
  display: inline-block;
  font-size: ${sizes.xxs};
  height: 33px;
  line-height: 33px;
  overflow: hidden;
  text-align: center;
  background-color: ${grey150};
  width: 33px;
  text-transform: uppercase;
`;

export default function UserAvatar({ user }) {
  if (!user.profile_picture) {
    const initials = [user.first_name, user.last_name]
      .filter(x => Boolean(x))
      .map(x => x[0])
      .join('');

    return <Initials>{initials}</Initials>;
  }

  return (
    <Img
      picture={user.profile_picture}
      title={`${user.first_name} ${user.last_name}`}
    />
  );
}

UserAvatar.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    profile_picture: PropTypes.string,
  }),
};

UserAvatar.defaultProps = {
  user: {},
};
