import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import useApiUrl from './useApiUrl';
import useToken from './useToken';

export default function useRiskIssueBlockers(initId, key) {
  const [blockers, setBlockers] = useState(null);
  const token = useToken();
  const apiUrl = useApiUrl();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    fetch(new URL(`/api/blocker/${initId}/read-by-initiative-id/all`, apiUrl), {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    })
      .then(rsp => rsp.json())
      .then(data => {
        if (data.ok) {
          setBlockers(data.data);
        } else {
          console.log('Broken data');
          setBlockers([]);
        }
      })
      .catch(err => {
        console.log(err);
        enqueueSnackbar('Failed to fetch the risk, issue and blockers', {
          variant: 'error',
        });
        setBlockers([]);
      });
  }, [initId, token, apiUrl, key]);

  return blockers;
}
