import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTheme, withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Register from '../Register';
import SignIn from '../SignIn';
import Reset from '../Reset';

import { TabWrapper, TabContainer, TabLabel, FORM_WIDTH } from './styles';

export const LOGIN_TAB_INDEX = 0;
export const JOIN_TAB_INDEX = 1;
export const RESET_TAB_INDEX = 2;

const indexToTab = {
  login: LOGIN_TAB_INDEX,
  signup: JOIN_TAB_INDEX,
  'reset-password': RESET_TAB_INDEX,
};

const StyledTabs = withStyles({
  root: {
    width: FORM_WIDTH,
  },
  flexContainer: {
    justifyContent: 'space-between',
  },
})(Tabs);

function SignInTabs({ theme, match, history }) {
  const value = indexToTab[match.params[0]];

  return (
    <TabWrapper>
      <StyledTabs
        value={value}
        indicatorColor="primary"
        variant="fullWidth"
        textColor="primary"
      >
        <TabLink to="/login">Login</TabLink>
        <TabLink to="/signup">Join</TabLink>
        <TabLink to="/reset-password">Reset password</TabLink>
      </StyledTabs>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={newIndex => history.push(indexToTab[newIndex])}
      >
        <TabContainer dir={theme.direction}>
          <SignIn index={value} />
        </TabContainer>
        <TabContainer dir={theme.direction}>
          <Register index={value} onSuccess={() => history.push('/login')} />
        </TabContainer>
        <TabContainer dir={theme.direction}>
          <Reset index={value} />
        </TabContainer>
      </SwipeableViews>
    </TabWrapper>
  );
}

SignInTabs.propTypes = {
  theme: PropTypes.object.isRequired,
};

function TabLink({ to, children }) {
  return (
    <Tab
      component={Link}
      to={to}
      style={{ minWidth: 47, padding: 0 }}
      label={<TabLabel>{children}</TabLabel>}
    />
  );
}

export default withTheme(SignInTabs);
