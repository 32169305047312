import styled from 'styled-components/macro';
import { fadeAnim } from '@hero/tfs/src/shared/styles/animation';
import { blueRhino, greyManatee, pattensBlue } from '@hero/styles/colors-v4';
import { lmont } from '@hero/styles/typography';

export const NavItem = styled.li`
  ${lmont};
  padding: 25px 20px 15px 20px;
  border-bottom: 4px solid transparent;
  color: ${greyManatee};
`;

export const ToolbarLinks = styled.div`
  position: sticky;
  top: 0;
  margin: 0;
  padding: 10px 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${pattensBlue};
  animation: ${fadeAnim} ease 0.3s;
  min-height: 64px;
  z-index: 1302; //Material Dialog z-index + config drag and drop z-index
`;

export const NavLink = styled.div`
  cursor: pointer;
  color: ${props => (props.isActive ? blueRhino : greyManatee)};
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-bottom-color: ${props => (props.isActive ? blueRhino : 'white')};

  &[disabled] {
    pointer-events: none;
  }
`;

export const ToolbarIcon = styled.span`
  vertical-align: bottom;
  margin: 0 5px;
  width: 20px;
  height: 20px;
`;
