import jwtDecode from 'jwt-decode';

export function getUsername() {
  const token = localStorage.getItem('id_token');

  const decodedToken = jwtDecode(token);
  return decodedToken.username ? decodedToken.username : '';
}

export function setToken(token) {
  localStorage.setItem('id_token', token);
}
