import React from 'react';

const SvgCodeBlock = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 18" {...props}>
    <path
      d="M23 3a1 1 0 0 1 .993.883L24 4v13a1 1 0 0 1-.883.993L23 18H5a1 1 0 0 1-.993-.883L4 17V8h2v8h16V5h-9V3h10zM3.5-.036L4.914 1.38 2.793 3.5l2.121 2.121L3.5 7.036-.036 3.5 3.5-.036zm5 0L12.036 3.5 8.5 7.036 7.086 5.62 9.207 3.5 7.086 1.379 8.5-.036z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgCodeBlock;
