import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fetch from '@hero/tfs/src/shared/Fetch';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Box } from 'jsxstyle';
import styled from 'styled-components/macro';
import Popover from '@material-ui/core/Popover';
import SearchInput from '@hero/tfs/src/shared/SearchInput';
import ConfirmModal from '@hero/tfs/src/shared/ConfirmModal';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';

import {
  greyManatee,
  greenCarribean,
  greyScorpion,
} from '@hero/styles/colors-v4';
import { sizes } from '@hero/styles/typography';
import { Text } from '@hero/styles/typography-v5';
import { bridgeTextColor } from '@hero/styles/colors';
import ArrowDropDown from '@material-ui/icons/ArrowDropDownRounded';
import { TfsEditorContext } from '@hero/tfs/src/10-transformations-editor/TfsHost';

const HoverIcon = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 28px;
  position: relative;
  width: 28px;

  ::before {
    background-color: #405572;
    border-radius: 50%;
    height: 28px;
    content: '';
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.1s ease-in-out;
    width: 28px;
  }

  :hover::before {
    opacity: 0.15;
  }
`;

const TransformationItem = styled.div`
  -webkit-line-clamp: 3; /* no of lines */
  cursor: pointer;
  flex-grow: 1;
  margin: 9px 10px 9px 0;
  color: ${greyScorpion};
  font-family: Montserrat;
  font-size: 14px;
  line-height: 18px;
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  word-break: break-word;

  &.active {
    font-weight: 800;
  }

  &:hover {
    font-weight: 800;
  }
`;

const iconStyles = `
  color: #8B93A6;
  fill: ${bridgeTextColor};
  font-size: 16px;
  height: 16px;
  margin: auto;
  width: 16px;
`;

const StyledCheckIcon = styled(CheckIcon)`
  ${iconStyles};
  color: ${greenCarribean};
`;
const StyledEditIcon = styled(EditIcon)`
  ${iconStyles};
`;
const StyledDeleteIcon = styled(DeleteIcon)`
  ${iconStyles};
`;

const styles = theme => ({
  add: {
    fontSize: sizes.m,
    marginRight: '12px',
  },
});

const ArrowDropDownMUI = props => (
  <ArrowDropDown classes={{ root: props.classes.arrow }} />
);

const ArrowDropDownIcon = withStyles(() => ({
  arrow: {
    alignSelf: 'center',
    color: greyManatee,
    fontSize: '28px',
  },
}))(ArrowDropDownMUI);

const AddIconMUI = props => (
  <AddIcon fontSize="small" classes={{ root: props.classes.add }} />
);
const StyledAddIcon = withStyles(styles)(AddIconMUI);

const NewTransformationButton = styled.button`
  align-items: center;
  outline: none;
  background-color: #f5f7fa;
  border: 1px dashed #8b93a6;
  border-radius: 1px;
  color: #8b93a6;
  cursor: pointer;
  display: flex;
  font-family: Montserrat;
  font-size: 14px;
  height: 39px;
  justify-content: center;
  margin: 0 auto;
  opacity: 0.8;
  white-space: nowrap;
  width: 300px;

  &:hover {
    border: 1px solid #8b93a6;
    opacity: 1;
  }
`;

const TransformationNameContainer = styled.div`
  overflow: hidden;
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export default class TfsDropdown extends Component {
  state = {
    anchorEl: null,
    storedAnchorEl: null,
    filterValue: '',
    isArchiveConfirmationModalOpen: false,
    recentUpdate: null,
    transformationToArchive: {
      ref_id: 0,
    },
  };

  handleDropdownClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleDropdownClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleOnArchiveClick = transformationToArchive => {
    const storedAnchorEl = this.state.anchorEl;
    this.setState({
      isArchiveConfirmationModalOpen: true,
      anchorEl: null,
      transformationToArchive,
      storedAnchorEl,
    });
  };

  closeArchiveConfirmationModal = (restoreList = true) => {
    const storedAnchorEl = restoreList ? this.state.storedAnchorEl : null;
    this.setState({
      isArchiveConfirmationModalOpen: false,
      anchorEl: storedAnchorEl,
      storedAnchorEl: null,
      transformationToArchive: {},
    });
  };

  onArchiveSuccess = remainingTfs => {
    this.closeArchiveConfirmationModal(false);
    this.setState({ recentUpdate: Date.now(), filterValue: '' });
    this.props.onArchived(this.state.transformationToArchive, remainingTfs);
  };

  filterAction = filterValue => {
    this.setState({ filterValue });
  };

  filterTransformationsList = (data, filterValue) => {
    return data.filter(
      transformation =>
        transformation.name.toLowerCase().indexOf(filterValue.toLowerCase()) >
        -1
    );
  };

  renderTransformationsList = (tfsList, filterValue) => {
    const { value, onChange } = this.props;
    const filteredData = this.filterTransformationsList(tfsList, filterValue);
    const selectedTfs = tfsList.find(x => String(x.ref_id) === String(value));
    let content = <NoItemsMessage />;

    if (filteredData.length) {
      content = (
        <TransformationList
          list={filteredData}
          activeTransformationName={selectedTfs ? selectedTfs.name : ''}
          onSelect={tfsId => {
            onChange(tfsId);
            this.handleDropdownClose();
          }}
          onArchive={this.handleOnArchiveClick}
          onEdit={this.handleDropdownClose}
        />
      );
    }

    return content;
  };

  render() {
    const { value } = this.props;
    const { anchorEl, recentUpdate, filterValue } = this.state;
    const lastUpdatedAt = this.context; // transformations
    const open = Boolean(anchorEl);

    return (
      <Box minWidth={280} maxWidth={400}>
        <Fetch.POST
          url="/api/transformation/list"
          params={{ at: recentUpdate, lastUpdatedAt }}
          body={{ query: '' }}
          render={rsp => (
            <>
              {value && (
                <Box
                  align-items="center"
                  cursor="pointer"
                  display="flex"
                  maxWidth="600px"
                  flexShrink="0"
                  props={{ onClick: this.handleDropdownClick }}
                >
                  <TransformationNameContainer>
                    <Text size="h5" fontWeight="bold" color="white">
                      {rsp.data.find(x => String(x.ref_id) === String(value)) &&
                        rsp.data.find(x => String(x.ref_id) === String(value))
                          .name}
                    </Text>
                  </TransformationNameContainer>
                  <ArrowDropDownIcon />
                </Box>
              )}
              <Box>
                <>
                  <Popover
                    id="transformations-popover"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleDropdownClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <Box margin="20px">
                      <SearchInput
                        classes={{ root: '' }}
                        filterAction={this.filterAction}
                        filterText={filterValue}
                        autoFocus
                      />
                      <Box
                        margin="20px 0"
                        max-height="450px"
                        overflow-y="scroll"
                        min-height="36px"
                        width="300px"
                      >
                        {this.renderTransformationsList(rsp.data, filterValue)}
                      </Box>
                      <NavLink
                        to={{ search: '?action=create-tfs' }}
                        onClick={() =>
                          this.setState({
                            anchorEl: null,
                          })
                        }
                      >
                        <NewTransformationButton>
                          <StyledAddIcon />
                          Add new transformation
                        </NewTransformationButton>
                      </NavLink>
                    </Box>
                  </Popover>
                  <Fetch.POST
                    manual
                    url="/api/transformation/archive"
                    body={{ ref_id: this.state.transformationToArchive.ref_id }}
                    onFetch={() => {
                      this.onArchiveSuccess(rsp.data);
                    }}
                  >
                    {({ doRequest }) => (
                      <ConfirmModal
                        closeModal={this.closeArchiveConfirmationModal}
                        isOpen={this.state.isArchiveConfirmationModalOpen}
                        isDanger
                        onConfirm={() => doRequest()}
                        title="Archive Transformation"
                        confirmBtnTitle="Archive"
                        message={`Are you sure you want to archive Transformation${
                          this.state.transformationToArchive
                            ? ` ${this.state.transformationToArchive.name}`
                            : ''
                        }?`}
                      />
                    )}
                  </Fetch.POST>
                </>
              </Box>
            </>
          )}
        />
      </Box>
    );
  }
}

TfsDropdown.contextType = TfsEditorContext;

TfsDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onArchived: PropTypes.func.isRequired,
};

function NoItemsMessage() {
  return (
    <Box
      color={greyScorpion}
      font-family="Montserrat"
      padding-top="7px"
      text-align="center"
    >
      No Transformations found.
    </Box>
  );
}

function TransformationList({
  list,
  activeTransformationName,
  onSelect,
  onArchive,
  onEdit,
}) {
  return list.map(transformation => {
    const isActive = transformation.name === activeTransformationName;
    return (
      <Box
        align-items="center"
        display="flex"
        key={transformation.ref_id}
        vertical-align="middle"
      >
        <TransformationListItem
          isActive={isActive}
          transformation={transformation}
          onSelect={() => onSelect(transformation.ref_id)}
          onArchive={onArchive}
          onEdit={onEdit}
          canArchive={list.length > 1}
        />
      </Box>
    );
  });
}

function TransformationListItem({
  isActive,
  transformation,
  onSelect,
  onArchive,
  onEdit,
  canArchive,
}) {
  return (
    <>
      <Box
        flex="0 0 20px"
        margin-right="10px"
        visibility={isActive ? 'visible' : 'hidden'}
        width="20px"
      >
        <StyledCheckIcon />
      </Box>
      <TransformationItem
        className={isActive ? 'active' : null}
        onClick={onSelect}
      >
        {transformation.name}
      </TransformationItem>
      <Box display="flex">
        {canArchive && (
          <HoverIcon onClick={() => onArchive(transformation)}>
            <StyledDeleteIcon fontSize="small" />
          </HoverIcon>
        )}
        <NavLink
          to={{ search: `?action=update-tfs&tfsId=${transformation.ref_id}` }}
          onClick={() => onEdit(transformation)}
        >
          <HoverIcon>
            <StyledEditIcon fontSize="small" />
          </HoverIcon>
        </NavLink>
      </Box>
    </>
  );
}
