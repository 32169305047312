import React from 'react';
import { Box } from 'jsxstyle';

import MaterialSwitch from '@hero/core/MaterialSwitch';
import Fetch from '@hero/tfs/src/shared/Fetch';
import { scaffolding } from '@hero/styles/spacings';
import { Text } from '@hero/styles/typography-v5';
import Editor, { TOOLBAR_OPTIONS } from '@hero/tfs/src/shared/slate/Editor';
import { quartz } from '@hero/styles/colors-v4';
import { htmlSerialize } from '@hero/tfs/src/shared/slate/EditorPreview';

import {
  Comment,
  StatusChangeEvent,
} from '@hero/tfs/src/03-blockers/ActivityBox';

export default function InitActivityBox({ refId }) {
  const [commentsOnly, setCommentsOnly] = React.useState('all');
  return (
    <Box background="white" width="100%" padding="16px 32px 24px 32px">
      <Box display="flex" justifyContent="space-between">
        <Text size="h4">Activity</Text>

        <Box display="flex" alignItems="center">
          <MaterialSwitch
            id="show-comments"
            checked={commentsOnly === 'comment'}
            onChange={ev =>
              setCommentsOnly(ev.target.checked ? 'comment' : 'all')
            }
          />
          <Text htmlFor="show-comments" marginLeft="4px">
            Show comments
          </Text>
        </Box>
      </Box>
      <Box marginTop={scaffolding.sm}>
        <Fetch url={`/api/initiative/${refId}/activity`}>
          {({ data, doRequest: reloadActivityFeed }) => (
            <>
              <Fetch.POST
                url={`/api/blocker/${refId}/activity`}
                manual
                onFetch={() => {
                  reloadActivityFeed();
                }}
              >
                {({ doRequest: addComment }) => (
                  <Box padding="12px 0 11px 0" backgroundColor={quartz}>
                    <Fetch.POST url="/api/user/get-users">
                      {({ data }) => (
                        <Editor
                          toolbarOptions={{
                            ...TOOLBAR_OPTIONS,
                            showStrikeThrough: false,
                            showTitles: false,
                          }}
                          placeholder="Enter comment..."
                          bgColor={quartz}
                          mentionList={
                            data
                              ? data.users
                                  .filter(user => user.status === 'active')
                                  .map(user => ({
                                    ...user,
                                    id: user.email,
                                    display: [
                                      user.first_name,
                                      user.last_name,
                                    ].join(' '),
                                  }))
                              : []
                          }
                          onSubmit={message => {
                            addComment({
                              body: {
                                type: 'comment',
                                message,
                                messageText: htmlSerialize({
                                  children: message,
                                }),
                              },
                            });
                          }}
                        />
                      )}
                    </Fetch.POST>
                  </Box>
                )}
              </Fetch.POST>

              {data && (
                <Box padding="8px 0">
                  {data.data
                    .filter(e =>
                      commentsOnly === 'all' ? true : e.type === commentsOnly
                    )
                    .map(event => {
                      switch (event.type) {
                        case 'comment':
                          return (
                            <Comment
                              key={event.event_id}
                              id={event.event_id}
                              url={`/api/initiative/${refId}/activity`}
                              author={event.created_by}
                              date={new Date(event.time)}
                              text={event.message}
                              reloadActivityFeed={reloadActivityFeed}
                            />
                          );
                        case 'create':
                          return (
                            <Comment
                              key={event.event_id}
                              author={event.created_by}
                              date={new Date(event.time)}
                              text={event.message}
                            />
                          );
                        case 'move':
                          return (
                            <StatusChangeEvent
                              key={event.event_id}
                              author={event.author}
                              date={event.date}
                              from={event.from}
                              to={event.to}
                            />
                          );
                        default:
                          return null;
                      }
                    })}
                </Box>
              )}
            </>
          )}
        </Fetch>
      </Box>
    </Box>
  );
}
