import React from 'react';
import { Box } from 'jsxstyle';

import { PlaceholderLoader } from '@hero/tfs/src/shared/Loaders';

export function ListViewLoader({ noOfRows = 12 }) {
  const rows = new Array(noOfRows).fill(0).map((r, index) => index);

  return (
    <Box display="flex" flexDirection="column">
      <PlaceholderLoader type="tableHeader" />
      {rows.map(r => (
        <PlaceholderLoader key={r} type="tableRow" />
      ))}
    </Box>
  );
}
