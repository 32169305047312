import { isAfter, isSameDay } from 'date-fns';
import map from 'lodash/map';
import last from 'lodash/last';

export const TYPES = {
  budget: 'budget',
  outcome: 'outcome',
};

export function getTfsTimeInterval(initiatives) {
  const dates = initiatives
    .map(i => {
      const data = [];
      data.push(...i.budget_duration);
      data.push(...i.outcome_duration);
      data.push(...i.outcome_tracking.map(x => x.date));
      data.push(...i.budget_tracking.map(x => x.date));
      return data;
    })
    .reduce((result, tuple) => result.concat(tuple), [])
    .filter(Boolean)
    .map(string => new Date(string));

  dates.sort((a, b) => a.getTime() - b.getTime());

  if (dates.length === 0) {
    dates.push(new Date());
  }

  return {
    start: dates[0],
    end: dates[dates.length - 1],
  };
}

export function getPTValuesDiff({
  yMax,
  targetOutcome,
  plannedOutcome,
  height,
}) {
  const ty = yMax > 0 ? height * (1 - targetOutcome / yMax) : height - 1;
  const py = yMax > 0 ? height * (1 - plannedOutcome / yMax) : height - 1;
  return { ty, py, diff: ty - py };
}

export function getPositionWithoutCollision({ ty, py, diff }) {
  if (diff === 0) {
    return {
      ty: ty - 12,
      py: py + 12,
    };
  }

  if (Math.abs(diff) < 10 && diff < 0) {
    return {
      ty: ty - 3,
      py: py + 12,
    };
  }

  if (Math.abs(diff) < 20 && diff < 0) {
    return {
      ty: ty - 8,
      py: py + 8,
    };
  }

  if (Math.abs(diff) < 10 && diff > 0) {
    return {
      ty: ty + 3,
      py: py - 12,
    };
  }

  if (Math.abs(diff) < 20 && diff > 0) {
    return {
      ty: ty + 8,
      py: py - 8,
    };
  }

  return { ty, py };
}

export function getFutureBudgetTotal(budgetData, date = new Date()) {
  const asOfBudget = budgetData.find(b => isSameDay(new Date(b.date), date));
  const lastBudget = last(budgetData);
  const asOfPlanned = asOfBudget ? asOfBudget.planned : 0;
  const lastPlanned =
    lastBudget && isAfter(new Date(lastBudget.date), new Date())
      ? lastBudget.planned
      : 0;

  return lastPlanned - asOfPlanned;
}

export function getActualOutcomeByDate(outcomeData, date = new Date()) {
  const outcomeByDate = outcomeData.find(o =>
    isSameDay(new Date(o.date), date)
  );
  return outcomeByDate ? outcomeByDate.actual : 0;
}

export function getActualCostByDate(budgetData, date = new Date()) {
  const actualCost = budgetData.find(o => isSameDay(new Date(o.date), date));
  return actualCost ? actualCost.actual : 0;
}

export function getROIData(outcomeProgress, budgetProgress) {
  const roiMap = {};
  outcomeProgress.forEach(op => {
    roiMap[op.date] = {
      date: op.date,
      oPlanned: op.planned,
      oActual: op.actual,
    };
  });
  budgetProgress.forEach(bp => {
    roiMap[bp.date] = {
      ...roiMap[bp.date],
      bPlanned: bp.planned,
      bActual: bp.actual,
    };
  });
  const roiData = map(roiMap, e => {
    const { oActual, oPlanned, bActual, bPlanned } = e;
    return {
      ...e,
      roi: isAfter(e.date, new Date())
        ? getFractionNumber(oPlanned, bPlanned)
        : getFractionNumber(oActual, bActual),
    };
  });
  return roiData;
}

export function getFractionNumber(a, b, precision = 2) {
  if (!a || b <= 0) {
    return 0;
  }

  return Number(a / b).toPrecision(precision);
}

export function getProjectedOutcome(planned, outcomeData, date = new Date()) {
  const asOfData = outcomeData.find(o => isSameDay(new Date(o.date), date));

  return asOfData ? planned + asOfData.actual - asOfData.planned : 0;
}

export function getProjectedBudget(planned, budgetData, date = new Date()) {
  const asOfData = budgetData.find(o => isSameDay(new Date(o.date), date));
  return asOfData ? planned - asOfData.planned + asOfData.actual : 0;
}
