import React from 'react';
import styled from 'styled-components/macro';
import { rotate } from '@hero/styles/animations';
import { rgba } from 'polished';
import { Col } from 'jsxstyle';
import { Text } from '@hero/styles/typography-v5';

const Spinner = styled.div`
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  border-top: ${props => `${props.lineWidth}px solid ${props.backgroundColor}`};
  border-right: ${props =>
    `${props.lineWidth}px solid ${props.backgroundColor}`};
  border-bottom: ${props =>
    `${props.lineWidth}px solid ${props.backgroundColor}`};
  border-left: ${props => `${props.lineWidth}px solid ${props.color}`};
  transform: translateZ(0);
  animation: ${rotate} 1.1s infinite linear;
`;

export default function CircularProgress({
  color = 'white',
  size = 19,
  lineWidth = 3,
  backgroundColor = rgba('#FFFFFF', 0.2),
  showLoader,
  loaderText = 'Loading...',
  textProps,
  ...props
}) {
  return (
    <Col alignItems="center">
      <Spinner
        color={color}
        size={size}
        lineWidth={lineWidth}
        backgroundColor={backgroundColor}
        {...props}
      />
      {showLoader && (
        <Text color="white" marginTop="8px" {...textProps}>
          {loaderText}
        </Text>
      )}
    </Col>
  );
}
