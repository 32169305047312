import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { WebPrimaryBtn } from '@hero/core/Button';

import { LoginContext } from '@hero/tfs/src/shared/LoginContextProvider';

import PasswordStrength from '@hero/core/PasswordStrength';
import PasswordInput from '@hero/core/PasswordInput';
import PasswordHint from '@hero/core/PasswordHint';
import CircularProgress from '@material-ui/core/CircularProgress';

import { LOGIN_TAB_INDEX } from '../SignInTabs';
import {
  TabContentWrapper,
  InputWrapper,
  Input,
  ButtonWrapper,
  ErrorSection,
  ErrorLabel,
} from '../styles';

const EMAIL_REGEX = /^([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})$/i;
const EMPTY_STRING = '';
const DEFAULT_STATE = {
  firstName: EMPTY_STRING,
  lastName: EMPTY_STRING,
  email: EMPTY_STRING,
  password: EMPTY_STRING,
  confirmPassword: EMPTY_STRING,
  firstNameError: false,
  lastNameError: false,
  emailError: false,
  passwordError: false,
  notMatchError: false,
  showLoading: false,
};

const isEmpty = string => {
  return !string || string.trim().length === 0;
};

class Register extends Component {
  static propTypes = {
    index: PropTypes.number,
    registerError: PropTypes.bool.isRequired,
    registerErrorMsg: PropTypes.string,
    register: PropTypes.func.isRequired,
    clearRegisterMessage: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
  };

  static defaultProps = {
    registerErrorMsg: '',
  };

  state = getInitialState();

  render() {
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      firstNameError,
      lastNameError,
      emailError,
      passwordError,
      confirmPasswordError,
      notMatchError,
      showLoading,
      disableEmailEditing,
    } = this.state;

    const { registerError, registerErrorMsg } = this.props;
    return (
      <form autoComplete="off" onSubmit={this.handleSubmit}>
        <TabContentWrapper>
          <InputWrapper>
            <Input
              value={firstName}
              error={firstNameError}
              placeholder={'First name'}
              name="firstName"
              onChange={this.handleChange}
            />
            {firstNameError && <ErrorLabel>First name is required.</ErrorLabel>}
          </InputWrapper>
          <InputWrapper>
            <Input
              value={lastName}
              error={lastNameError}
              placeholder={'Last name'}
              name="lastName"
              onChange={this.handleChange}
            />
            {lastNameError && <ErrorLabel>Last name is required.</ErrorLabel>}
          </InputWrapper>
          <InputWrapper>
            <Input
              autoComplete="false"
              value={email}
              error={emailError}
              placeholder={'Email'}
              name="email"
              onChange={this.handleChange}
              disabled={disableEmailEditing}
            />
            {emailError && <ErrorLabel>Please enter valid Email.</ErrorLabel>}
          </InputWrapper>
          <PasswordInput
            autoComplete="false"
            error={passwordError}
            errorMessage="Password is required."
            placeholder={'Password'}
            name="password"
            value={password}
            onChange={this.handleChange}
          />
          <PasswordStrength password={password} />
          <PasswordInput
            autoComplete="false"
            error={confirmPasswordError || notMatchError}
            errorMessage={
              confirmPasswordError
                ? 'Confirm password is required.'
                : notMatchError
                ? 'Passwords do not match.'
                : ''
            }
            placeholder={'Confirm password'}
            name="confirmPassword"
            value={confirmPassword}
            onChange={this.handleChange}
          />
          <PasswordHint />
          {registerError && (
            <ErrorSection>
              <ErrorLabel>{registerErrorMsg}</ErrorLabel>
            </ErrorSection>
          )}
          <ButtonWrapper>
            <WebPrimaryBtn
              type="submit"
              disabled={
                showLoading ||
                notMatchError ||
                passwordError ||
                emailError ||
                lastNameError ||
                firstNameError
              }
            >
              {showLoading ? (
                <CircularProgress size={14} color={'white'} />
              ) : !password || notMatchError || passwordError ? (
                'Waiting for password'
              ) : (
                'Join'
              )}
            </WebPrimaryBtn>
          </ButtonWrapper>
        </TabContentWrapper>
      </form>
    );
  }

  componentDidUpdate(prevProps) {
    const { index: prevIndex } = prevProps;

    const {
      registrationSuccess,
      clearRegistationSuccessMessage,
      index,
    } = this.props;
    if (
      prevIndex !== index &&
      index !== LOGIN_TAB_INDEX &&
      registrationSuccess
    ) {
      clearRegistationSuccessMessage();
    }
  }

  handleChange = e => {
    const { clearRegisterMessage } = this.props;
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'Error']: false,
      notMatchError: false,
    });
    clearRegisterMessage();
  };

  handleSubmit = event => {
    event.preventDefault();

    const { register, onSuccess } = this.props;

    this.validateDetails(() => {
      const {
        firstName,
        lastName,
        email,
        password,
        lastNameError,
        firstNameError,
        emailError,
        passwordError,
        confirmPasswordError,
        notMatchError,
      } = this.state;
      if (
        lastNameError ||
        firstNameError ||
        emailError ||
        passwordError ||
        confirmPasswordError ||
        notMatchError
      ) {
        return;
      }
      this.setState({
        showLoading: true,
      });
      //TODO - Need to set the rule to have camelCase for the properties
      const first_name = firstName;
      const last_name = lastName;
      const invite = new URLSearchParams(window.location.search).get('invite');

      register({
        first_name,
        last_name,
        email,
        password,
        ...(invite && { invite }),
      }).then(response => {
        const { ok } = response;
        if (ok) {
          this.setState({
            ...DEFAULT_STATE,
          });
          onSuccess(LOGIN_TAB_INDEX);
        } else {
          this.setState({
            showLoading: false,
          });
        }
      });
    });
  };

  validateDetails = cb => {
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
    } = this.state;

    const firstNameError = isEmpty(firstName);
    const lastNameError = isEmpty(lastName);
    const emailError = !email || !EMAIL_REGEX.test(email);
    const passwordError = isEmpty(password);
    const confirmPasswordError = isEmpty(confirmPassword);
    const notMatchError =
      !passwordError && !confirmPasswordError && password !== confirmPassword;

    this.setState(
      {
        firstNameError,
        lastNameError,
        emailError,
        passwordError,
        confirmPasswordError,
        notMatchError,
      },
      () => {
        cb();
      }
    );
  };
}

const RegisterWithRouter = withRouter(routeProps => (
  <LoginContext.Consumer>
    {({
      registerError,
      registrationSuccess,
      registerErrorMsg,
      register,
      clearRegisterMessage,
      clearRegistationSuccessMessage,
    }) => (
      <Register
        {...routeProps}
        registerError={registerError}
        registrationSuccess={registrationSuccess}
        registerErrorMsg={registerErrorMsg}
        register={register}
        clearRegisterMessage={clearRegisterMessage}
        clearRegistationSuccessMessage={clearRegistationSuccessMessage}
      />
    )}
  </LoginContext.Consumer>
));

function getInitialState() {
  const email = new URLSearchParams(window.location.search).get('email');

  return {
    ...DEFAULT_STATE,
    ...(email && { email, disableEmailEditing: true }),
  };
}

export default props => <RegisterWithRouter {...props} />;
