import React from 'react';

const SvgNotification = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <g fill="currentColor" fillRule="evenodd">
      <path d="M5.62 8.24a.623.623 0 0 1-.547-.928 5.913 5.913 0 0 1 2.302-2.304.623.623 0 0 1 .85.244.625.625 0 0 1-.243.85 4.658 4.658 0 0 0-1.815 1.815c-.11.199-.32.322-.547.322zM3.25 7.605a.627.627 0 0 1-.6-.796 6.003 6.003 0 0 1 4.158-4.16.627.627 0 0 1 .717.906.62.62 0 0 1-.375.296 4.763 4.763 0 0 0-3.3 3.3.628.628 0 0 1-.6.454z" />
      <path
        d="M5.75 17.625a.621.621 0 0 1-.13-.014l-.033-.01a.614.614 0 0 1-.201-.094l-.032-.025a.682.682 0 0 1-.137-.158.528.528 0 0 1-.053-.11.611.611 0 0 1-.037-.181c-.002-.014-.002-.019-.002-.033 0-.013 0-.026.002-.038l.005-.05a.564.564 0 0 1 .051-.174.58.58 0 0 1 .027-.05l.034-.053.037-.046a.559.559 0 0 1 .037-.039l.024-.023c0-.001 1.033-1.05 1.033-4.526 0-3.331 2.173-6.112 5-6.474V4.5a.625.625 0 0 1 1.25 0v1.026c2.827.362 5 3.143 5 6.474 0 3.462 1.036 4.528 1.046 4.538.01.009.013.013.02.021a.688.688 0 0 1 .098.127c.013.023.021.039.028.055a.761.761 0 0 1 .04.115.409.409 0 0 1 .009.054l.006.056a.523.523 0 0 1-.021.193c-.004.018-.011.037-.018.057a.873.873 0 0 1-.052.109.479.479 0 0 1-.035.05c-.008.012-.018.026-.026.034-.01.012-.02.02-.026.027a.937.937 0 0 1-.08.07l-.048.03a.47.47 0 0 1-.06.032l-.049.018a.488.488 0 0 1-.122.029l-.05.005a.519.519 0 0 1-.035.002H5.75v.003zm11.373-1.25c-.382-.848-.748-2.223-.748-4.375 0-2.903-1.963-5.265-4.375-5.265S7.625 9.097 7.625 12c0 2.156-.364 3.53-.744 4.375h10.242z"
        fillRule="nonzero"
      />
      <path d="M12 21.375a1.877 1.877 0 0 1-1.875-1.875.625.625 0 0 1 1.25 0 .625.625 0 0 0 1.25 0 .625.625 0 0 1 1.25 0c0 1.033-.84 1.875-1.875 1.875zM18.38 8.24a.626.626 0 0 1-.547-.323A4.688 4.688 0 0 0 16.017 6.1a.624.624 0 1 1 .61-1.09c.48.267.92.6 1.309.987.39.39.723.833.99 1.313a.623.623 0 0 1-.546.93zM20.75 7.605a.627.627 0 0 1-.6-.454 4.763 4.763 0 0 0-3.3-3.3.626.626 0 0 1 .343-1.203 6.005 6.005 0 0 1 4.159 4.16.625.625 0 0 1-.602.797z" />
    </g>
  </svg>
);

export default SvgNotification;
