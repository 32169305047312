import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'jsxstyle';
import { withStyles } from '@material-ui/core/styles';

import SearchIcon from '@material-ui/icons/Search';
import debounce from 'lodash/debounce';
import Input from '@hero/core/Input';

const styles = {
  icon: {
    position: 'absolute',
    right: '13px',
    top: '7px',
    width: '20px',
  },
  input: {
    flex: 1,
    paddingRight: '34px',
  },
};

function SearchInput({
  classes,
  filterAction,
  filterText,
  containerProps,
  ...props
}) {
  const handleSearch = debounce(text => {
    filterAction && filterAction(text);
  }, 300);

  return (
    <Row position="relative" {...containerProps}>
      <Input
        className={classes.input}
        placeholder="Filter list"
        onChange={e => handleSearch(e.currentTarget.value)}
        defaultValue={filterText}
        {...props}
      />
      <SearchIcon className={classes.icon} />
    </Row>
  );
}

SearchInput.propTypes = {
  classes: PropTypes.object.isRequired,
  filterAction: PropTypes.func,
  filterText: PropTypes.string,
};

SearchInput.defaultProps = {
  filterText: '',
};

export default withStyles(styles)(SearchInput);
