import React from 'react';
import PropTypes from 'prop-types';

import Nav from '@hero/core/Nav';
import LoadingIndicator from '@hero/tfs/src/shared/LoadingIndicator';
import { FormattedMessageWithHelmet } from '@hero/tfs/src/shared/FormattedMessage';

import { LoginContext } from '@hero/tfs/src/shared/LoginContextProvider';
import { getQueryParams } from '@hero/core/utils/query-string';

import { Wrapper } from './styles';
import messages from './messages';

export { default as VerifyEmail } from './VerifyEmail';

class AccountVerify extends React.Component {
  static propTypes = {
    verifying: PropTypes.bool,
    success: PropTypes.bool.isRequired,
    accountVerify: PropTypes.func.isRequired,
  };

  static defaultProps = {
    verifying: true,
  };

  render() {
    const { verifying } = this.props;

    return (
      <React.Fragment>
        <FormattedMessageWithHelmet {...messages['title']} />
        <Nav fixed />
        <LoadingIndicator
          loading={verifying}
          label={'Please wait, Your account is being verified'}
          render={() => <Wrapper>Redirecting to login page</Wrapper>}
        />
      </React.Fragment>
    );
  }

  componentDidMount() {
    const { history, location, accountVerify } = this.props;
    const { token } = getQueryParams(location);
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      accountVerify({ token }).then(res => {
        const { success } = this.props;
        if (success) {
          history.push('/login');
        } else {
          history.push('/unauthorized');
        }
      });
    }, 200);
  }
}

export default props => (
  <LoginContext.Consumer>
    {({ verifying, success, accountVerify }) => (
      <AccountVerify
        {...props}
        verifying={verifying}
        success={success}
        accountVerify={accountVerify}
      />
    )}
  </LoginContext.Consumer>
);
