import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useWindowSize from '@hero/tfs/src/shared/useWindowSize';

export default function ChartContainer({ children }) {
  const [chartSize, setChartSize] = useState(useWindowSize());
  const containerRef = useRef(null);
  const handleResize = () => {
    if (!containerRef.current) {
      return;
    }

    setChartSize({
      width: containerRef.current.getBoundingClientRect().width,
      height: containerRef.current.getBoundingClientRect().height,
    });
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      {children(chartSize)}
    </div>
  );
}

ChartContainer.propTypes = {
  children: PropTypes.func.isRequired,
};
