import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Group } from '@vx/group';
import { darken } from 'polished';

import { getInitiativeColor } from '@hero/styles/colors';
import { romanRed, puertoRico } from '@hero/styles/colors-v4';
import ChartTooltip from '@hero/tfs/src/shared/ChartTooltip';

import { getInitiativeStatus } from '@hero/tfs/src/09-initiatives-editor/shared';

const Rect = styled.rect`
  transition: stroke 0.2s ease-in;
  stroke: transparent;
  stroke-width: 1px;
  &:hover {
    stroke: ${props => darken(0.3, props.bg)};
  }
`;

export default function TreeMap({
  tfs,
  statusList,
  styles,
  blockers,
  sizeBy,
  projected,
  target,
  onClick,
}) {
  return (
    <>
      {styles.map(config => {
        const { node } = config.data;
        const { data: initiativeData, parent: parentData } = node;
        const statusItem = statusList.find(
          x => x.name === initiativeData.status
        );

        const status = getInitiativeStatus(
          {
            ref_id: initiativeData.ref_id,
            status: statusItem ? statusItem._id : -1,
          },
          blockers
        );

        const color = getInitiativeColor({
          is_blocked: initiativeData.is_blocked,
          status,
          statusList,
          stage_gate: initiativeData.stage_gate_id,
          is_shortfall: initiativeData.is_shortfall,
        });

        return (
          <Group
            key={config.key}
            id={`initiative-${initiativeData.ref_id}`}
            transform={`translate(${config.style.x}, ${config.style.y})`}
            onClick={event => {
              if (!parentData || parentData.data.is_shortfall) {
                return;
              }
              onClick(event, initiativeData);
            }}
          >
            {initiativeData.is_shortfall ? (
              <Shortfall
                width={config.style.width}
                height={config.style.height}
                isSurplus={
                  sizeBy === 'cost' ? projected < target : target < projected
                }
              />
            ) : (
              <ChartTooltip init={initiativeData} tfs={tfs}>
                <Rect
                  rx={1}
                  ry={1}
                  x={0}
                  y={0}
                  height={config.style.height}
                  width={config.style.width}
                  transform={`scale(${config.style.scale})`}
                  id={initiativeData.name}
                  fill={initiativeData.is_shortfall ? '#333' : color}
                  bg={color}
                  style={{ cursor: 'pointer' }}
                />
              </ChartTooltip>
            )}
          </Group>
        );
      })}
    </>
  );
}

TreeMap.propTypes = {
  styles: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      data: PropTypes.object.isRequired,
    })
  ).isRequired,
  tfs: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  statusList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

TreeMap.defaultProps = {
  onClick: () => {},
};

function Shortfall({ width, height, isSurplus }) {
  return (
    <g>
      <defs>
        <pattern
          id="diagonalHatch"
          width="4"
          height="4"
          patternUnits="userSpaceOnUse"
          patternTransform="rotate(45)"
        >
          <rect
            width="2"
            height="5"
            transform="translate(0,0)"
            fill={isSurplus ? puertoRico : romanRed}
          />
        </pattern>
      </defs>
      <rect x={0} y={0} width={width} height={height} fill="white" />
      <rect
        x={0}
        y={0}
        width={width}
        height={height}
        fill="url(#diagonalHatch)"
      />
    </g>
  );
}
