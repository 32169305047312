import React from 'react';
import { rgba } from 'polished';
import { Box } from 'jsxstyle';
import { bayOfMany } from '@hero/styles/colors-v4';
import { scaffolding } from '@hero/styles/spacings';

export default function Card(props) {
  return (
    <Box
      boxShadow={`0 1px 2px 0 ${rgba(bayOfMany, 0.1)}`}
      border-radius="1px"
      width="100%"
      {...props}
    />
  );
}

export function Header(props) {
  return (
    <Box
      padding={`${scaffolding.sm} ${scaffolding.lg} ${scaffolding.xs} ${
        scaffolding.lg
      }`}
      position="relative"
      {...props}
    />
  );
}

export function Content(props) {
  return (
    <Box
      padding={`${scaffolding.xs} ${scaffolding.lg} ${scaffolding.md} ${
        scaffolding.lg
      }`}
      {...props}
    />
  );
}
