import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-grid-system';
import lowerCase from 'lodash/lowerCase';
import { getMonth } from 'date-fns';
import ErrorIcon from '@material-ui/icons/Error';

import { MainActionBtn } from '@hero/core/Dialog';
import Label from '@hero/core/Label';

import { mistyRose, romanRed } from '@hero/styles/colors-v4';
import { Text } from '@hero/styles/typography-v5';

import FormattedNumber from '@hero/tfs/src/shared/FormattedNumber';
import { allocate } from '@hero/tfs/src/09-initiatives-editor/shared';
import { Input } from './OutcomeSection';

export default function DistributeTracking({
  editTracking,
  type,
  value,
  duration,
  period,
  tracking,
  unit,
  onDistribute,
}) {
  const [newValue, setNewValue] = React.useState(value);

  React.useEffect(() => {
    setNewValue(value);
  }, [value]);

  const {
    show,
    durationLeft,
    durationRight,
    periodNew,
  } = determineRedistribute({
    value,
    duration,
    period,
    tracking,
    editTracking,
  });

  if (!show) {
    return null;
  }

  const hanldeDistribute = () => {
    const duration = [durationLeft, durationRight];

    const tracking = allocate(newValue, periodNew, [
      durationLeft,
      durationRight,
    ]);
    onDistribute({
      value: newValue,
      tracking,
      duration,
      period: periodNew,
    });
  };

  return (
    <Container
      fluid
      style={{
        margin: 0,
        padding: '10px 32px',
        backgroundColor: mistyRose,
        minHeight: '80px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Row style={{ flex: 1 }} justify="between" align="center" nogutter>
        <Col sm={2} md={2} xl={0.5}>
          <ErrorIcon style={{ color: romanRed, fontSize: 32 }} />
        </Col>
        <Col sm={10} md={10} xl={5.5}>
          <Text as="p2" fontWeight="bold" letterSpacing="0">
            There is a discrepancy in the {type} tracking. Press the button to
            re-distribute {type} automatically or do it manually in the grid
            below.
          </Text>
        </Col>
        <Col sm={9} md={5} xl={3}>
          {type === 'Budget' ? (
            <>
              <Label
                htmlFor="budget-new-planned"
                style={{ marginTop: '0', textAlign: 'right' }}
              >
                New planned {lowerCase(type)}
              </Label>
              <FormattedNumber.CurrencyProvider unit={unit}>
                <Input
                  id="budget-new-planned"
                  tabIndex={-1}
                  value={newValue}
                  showUnit={false}
                  style={{ minWidth: '180px', width: '100%' }}
                  onChange={e => setNewValue(e.target.value)}
                />
              </FormattedNumber.CurrencyProvider>
            </>
          ) : (
            <>
              <Label
                htmlFor="outcome-new-planned"
                style={{ marginTop: '0', textAlign: 'right' }}
              >
                New planned {lowerCase(type)}
              </Label>
              <Input
                id="outcome-new-planned"
                tabIndex={-1}
                value={newValue}
                showUnit={false}
                style={{ minWidth: '180px', width: '100%' }}
                onChange={e => setNewValue(e.target.value)}
              />
            </>
          )}
        </Col>
        <Col sm={3} md={4} xl={2}>
          <MainActionBtn
            onClick={hanldeDistribute}
            style={{ marginBottom: 0, marginTop: 19, color: 'white' }}
          >
            Re-distribute
          </MainActionBtn>
        </Col>
      </Row>
    </Container>
  );
}

DistributeTracking.propTypes = {
  onDistribute: PropTypes.func.isRequired,
};

export function determineRedistribute({
  value,
  duration,
  period,
  tracking,
  editTracking,
}) {
  const durationLeft =
    duration && duration[0] ? new Date(duration[0]) : new Date();
  const durationRight =
    duration && duration[1] ? new Date(duration[1]) : new Date();
  const drnStartMonth = getMonth(durationLeft);
  const drnEndMonth = getMonth(durationRight);

  const trackinghasData = tracking && tracking.length > 0;

  const trackingStartMonth = getMonth(
    trackinghasData ? new Date(tracking[0].date) : new Date()
  );
  const trackingEndMonth = getMonth(
    trackinghasData ? new Date(tracking[tracking.length - 1].date) : new Date()
  );
  const periodNew = period || 'monthly';

  const trackingPlndTotal = trackinghasData
    ? tracking.map(x => x.planned).reduce((a, b) => a + b, 0)
    : 0;
  const trackingBeforeDist = allocate(value, periodNew, [
    durationLeft,
    durationRight,
  ]);
  const trackingBeforeDistTtl = trackingBeforeDist
    .map(x => x.planned)
    .reduce((a, b) => a + b, 0);
  const isDurationDntMatch =
    drnStartMonth !== trackingStartMonth || drnEndMonth !== trackingEndMonth;

  const show = editTracking
    ? isDurationDntMatch
    : (trackingBeforeDistTtl !== trackingPlndTotal &&
        trackingPlndTotal !== value) ||
      isDurationDntMatch;
  return {
    show,
    durationLeft,
    durationRight,
    periodNew,
  };
}
