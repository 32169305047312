import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'jsxstyle';
import { Container, Row as GridRow, Col as GridCol } from 'react-grid-system';

import { scaffolding } from '@hero/styles/spacings';

import { getPrefix } from '@hero/tfs/src/shared/FormattedNumber';

import StatsWidget from './StatsWidget';
import { getFractionNumber } from './shared';

export default function ROILegends({
  outcome,
  cost,
  hurdleRate,
  actualOutcome,
  futureBudget,
  ...props
}) {
  const outcomeAchieved = actualOutcome > outcome;

  const forecastedOutcome = outcomeAchieved ? 0 : outcome - actualOutcome;

  const prefix = getPrefix(
    Math.max(cost, outcome, futureBudget, forecastedOutcome)
  );

  return (
    <Row marginBottom={scaffolding.md} {...props}>
      <Container fluid style={{ margin: 0, width: '100%', padding: 0 }}>
        <GridRow align="center">
          <GridCol xs={12} md={6} xl={2.8}>
            <StatsWidget
              text="Planned outcome"
              value={outcome}
              prefix={prefix}
            />
          </GridCol>
          <GridCol xs={12} md={6} xl={2.8}>
            <StatsWidget text="Budget" value={cost} prefix={prefix} />
          </GridCol>
          <GridCol xs={12} md={6} xl={2.8}>
            <StatsWidget
              text="Planned ROI"
              value={getFractionNumber(outcome, cost)}
              format={false}
            />
          </GridCol>
          <GridCol xs={12} md={6} xl={2.8}>
            <StatsWidget text="Hurlde rate" value={hurdleRate} format={false} />
          </GridCol>
        </GridRow>
        <GridRow align="center">
          <GridCol xs={12} md={6} xl={2.8}>
            <StatsWidget
              text="Projected outcome"
              value={forecastedOutcome}
              prefix={prefix}
            />
          </GridCol>
          <GridCol xs={12} md={6} xl={2.8}>
            <StatsWidget
              text="Projected budget"
              value={futureBudget}
              prefix={prefix}
            />
          </GridCol>
          <GridCol xs={12} md={6} xl={2.8}>
            <StatsWidget
              text="Projected ROI"
              format={false}
              value={
                outcomeAchieved
                  ? hurdleRate
                  : getFractionNumber(forecastedOutcome, futureBudget)
              }
            />
          </GridCol>
        </GridRow>
      </Container>
    </Row>
  );
}

ROILegends.propTypes = {
  outcome: PropTypes.number.isRequired,
  cost: PropTypes.number.isRequired,
};
