import React, { useState } from 'react';
import { Box } from 'jsxstyle';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';

import { Text } from '@hero/styles/typography-v5';
import { getInitiativeColor } from '@hero/styles/colors';
import { solitude, santasGrey } from '@hero/styles/colors-v4';

import Fetch from '@hero/tfs/src/shared/Fetch';
import FormattedNumber from '@hero/tfs/src/shared/FormattedNumber';
import QuickInitPanel from '@hero/tfs/src/shared/QuickInitPanel';
import { ColumnLoader } from '@hero/tfs/src/shared/Loaders';
import { getInitiativeStatus } from '@hero/tfs/src/09-initiatives-editor/shared';
import { getOutcomeValues } from '@hero/tfs/src/12-initiative/shared';

import {
  GROUP_BY_SEP,
  Grid,
  ColumnHead,
  Column,
  groupByMulti,
  Card,
  Board,
  Separator,
  GridHeadRow,
} from './board';

const groupPath = ['workstream', 'stage_gate'];

export default function TfsKanban({
  initiatives,
  tfs,
  selectedWorkstream,
  blockers,
}) {
  const [initByWS, setInitByWS] = useState({});
  const [quickInit, setQuickInit] = useState(null);

  React.useEffect(() => {
    setInitByWS(groupByMulti(initiatives, groupPath, GROUP_BY_SEP));
  }, [initiatives]);

  const { stage_gate, workstream, status: statusList } = tfs.configuration;
  const noStageGateInit = initiatives.find(init => init.stage_gate === '-1');
  const noWorkStreamInit = initiatives.find(init => init.workstream === '-1');
  const filteredStageGates = noStageGateInit
    ? [...stage_gate]
    : stage_gate.filter(e => e._id !== '-1');
  const filteredWorkstreams = selectedWorkstream
    ? workstream
        .filter(
          e =>
            String(e._id) === selectedWorkstream ||
            (noWorkStreamInit && String(e._id) === '-1')
        )
        .reverse()
    : noWorkStreamInit
    ? [...workstream]
    : workstream.filter(e => e._id !== '-1');

  const noOfColumns = filteredStageGates.length;
  const loadingInits = isEmpty(initByWS);
  const groupedInits = groupBy(flatten(values(initByWS)), 'workstream');

  return (
    <FormattedNumber.TfsProvider tfs={tfs}>
      <Fetch.POST manual url="/api/initiative/update">
        {({ doRequest: updateInitiative }) => (
          <Board
            value={initByWS}
            onChange={changes => {
              const { init, initByWS: newInitByWS } = changes;

              setInitByWS(newInitByWS);
              updateInitiative({
                body: {
                  ref_id: init.ref_id,
                  parent_container_id: init.parent_container_id,
                  stage_gate: init.stage_gate,
                  workstream: init.stage_workstream,
                },
              });
            }}
          >
            <Box paddingRight="10px">
              {filteredWorkstreams.map(({ _id: workstreamId, name }) => {
                return (
                  <Grid noOfColumns={noOfColumns} key={workstreamId}>
                    <GridHeadRow
                      name={name}
                      count={
                        groupedInits[workstreamId] &&
                        groupedInits[workstreamId].length
                      }
                      noOfColumns={noOfColumns}
                    />
                    <Separator noOfColumns={noOfColumns} height="16px" />

                    {filteredStageGates.map(({ _id: stageGateId, name }) => {
                      const wsKey = [workstreamId, stageGateId].join(
                        GROUP_BY_SEP
                      );

                      if (loadingInits) {
                        return (
                          <ColumnLoader
                            columnNumber={wsKey}
                            key={wsKey}
                            width="100%"
                            columnColor={solitude}
                            noOfCards={3}
                          />
                        );
                      }
                      return (
                        <Box key={stageGateId}>
                          <ColumnHead id={stageGateId}>
                            <Text size="h6" fontWeight="600">
                              {name}&nbsp;
                              {initByWS[wsKey] &&
                                initByWS[wsKey].length > 0 && (
                                  <span>({initByWS[wsKey].length})</span>
                                )}
                            </Text>
                          </ColumnHead>
                          <Column id={wsKey}>
                            {initByWS[wsKey] &&
                              initByWS[wsKey].map((init, index) => {
                                return (
                                  <Card
                                    key={init._id}
                                    _id={init._id}
                                    index={index}
                                    onClick={() => {
                                      setQuickInit(init);
                                    }}
                                    color={getInitiativeColor({
                                      is_blocked: init.is_blocked,
                                      status: getInitStatus({
                                        init,
                                        statusList,
                                        blockers,
                                      }),
                                      statusList,
                                      stage_gate: init.stage_gate,
                                    })}
                                  >
                                    <Text wordBreak="break-word">
                                      {init.name}
                                    </Text>
                                    <Box>
                                      <Box
                                        marginTop={9}
                                        display="inline-flex"
                                        flexDirection="column"
                                        minWidth="50%"
                                        paddingRight="10px"
                                      >
                                        <Text size="p2" color={santasGrey}>
                                          Planned
                                        </Text>
                                        <Text size="h5" fontWeight="600">
                                          <FormattedNumber
                                            value={
                                              getOutcomeValues(init).planned
                                            }
                                          />
                                        </Text>
                                      </Box>
                                      <Box
                                        marginTop={9}
                                        display="inline-flex"
                                        flexDirection="column"
                                        flex="1"
                                      >
                                        <Text size="p2" color={santasGrey}>
                                          Actual
                                        </Text>
                                        <Text size="h5" fontWeight="600">
                                          <FormattedNumber
                                            value={
                                              getOutcomeValues(init).actual
                                            }
                                          />
                                        </Text>
                                      </Box>
                                    </Box>
                                  </Card>
                                );
                              })}
                          </Column>
                        </Box>
                      );
                    })}

                    <Separator noOfColumns={noOfColumns} height="32px" />
                  </Grid>
                );
              })}
            </Box>
          </Board>
        )}
      </Fetch.POST>
      <QuickInitPanel
        init={quickInit}
        tfs={tfs}
        onClose={() => {
          setQuickInit(null);
        }}
      />
    </FormattedNumber.TfsProvider>
  );
}

function getInitStatus({ statusList, init, blockers }) {
  const statusItem = statusList.find(x => x._id === init.status);

  const status = getInitiativeStatus(
    {
      ref_id: init.ref_id,
      status: statusItem ? statusItem._id : -1,
    },
    blockers
  );

  return status;
}
