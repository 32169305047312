import React from 'react';
import styled from 'styled-components/macro';
import Input from '@hero/core/Input';
import Label from '@hero/core/Label';
import { redMandy } from '@hero/styles/colors-v4';

const ErrorLabel = styled.label`
  display: none;
  font-size: 13px;
  color: ${redMandy};
  line-height: 23px;
`;

const InputBlock = styled.div`
  display: flex;
  flex-flow: column;
  margin: 0 0 3px 0;

  input:invalid + ${ErrorLabel} {
    display: block;
  }
`;

export default function AccountForm({ value, onChange }) {
  return (
    <>
      <InputBlock>
        <Label htmlFor="manage-my-account-email">Email</Label>
        <span>{value.email}</span>
      </InputBlock>

      <InputBlock>
        <Label htmlFor="manage-my-account-fname">First name</Label>
        <Input
          value={value.first_name}
          onChange={e => onChange({ ...value, first_name: e.target.value })}
          autoFocus
          required
          type="text"
          id="manage-my-account-fname"
          name="firstName"
          placeholder="Enter first name…"
        />
        <ErrorLabel>Please fill in</ErrorLabel>
      </InputBlock>

      <InputBlock>
        <Label htmlFor="manage-my-account-lname">Last name</Label>
        <Input
          value={value.last_name}
          onChange={e => onChange({ ...value, last_name: e.target.value })}
          required
          type="text"
          id="manage-my-account-lname"
          name="lastName"
          placeholder="Enter last name…"
        />
        <ErrorLabel>Please fill in</ErrorLabel>
      </InputBlock>
    </>
  );
}
