import React, { Component } from 'react';
import styled from 'styled-components/macro';
import Input from '@hero/core/Input';
import Label from '@hero/core/Label';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { Container, Row, Col } from 'react-grid-system';
import UserAutocompleteInput from '@hero/core/UserAutocompleteInput';
import CurrencyInput from '@hero/tfs/src/shared/CurrencyInput';
import CurrencySelect from '@hero/tfs/src/shared/CurrencySelect';
import { greyManatee } from '@hero/styles/colors-v4';
import { Text } from '@hero/styles/typography-v5';

import {
  TIME_TARGET_TYPE,
  FINANCE_TARGET_TYPE,
} from '@hero/tfs/src/shared/targetTypes';

const TIME_UNIT_OPTIONS = [
  {
    text: 'Days (d)',
    value: 'Days',
  },
  {
    text: 'Weeks (w)',
    value: 'Weeks',
  },
  {
    text: 'Months (m)',
    value: 'Months',
  },
  {
    text: 'Years (y)',
    value: 'Years',
  },
];

export const DropdownIcon = styled(ArrowDropDownIcon)`
  margin: 2px 9px 0 0;
  color: ${greyManatee};
  font-size: 21px;
`;

export const InputBlock = styled.div`
  display: flex;
  flex-flow: column;
`;

export default class TfsBaseEditor extends Component {
  render() {
    const { usersList } = this.props;

    const {
      name,
      purpose,
      description,
      cost,
      currency_unit,
      time_unit,
      target_type,
      target_current,
      target_target,
      hurdle_rate,
      ws_hurdle_rate,
      ref_id,
    } = this.props.transformation;

    const {
      custom_target_types = [],
    } = this.props.transformation.configuration;

    const lead = usersList.find(
      user =>
        this.props.transformation.lead.length > 0 &&
        user.user_id === this.props.transformation.lead[0].user_id
    );

    const showTargetValueTypeSelect =
      target_type === undefined || target_type === TIME_TARGET_TYPE;

    return (
      <Container fluid style={{ margin: '32px 0 0 0' }}>
        <Row>
          <Col xs={12}>
            <Text size="h4">General</Text>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={6}>
            <InputBlock>
              <Label htmlFor="new-transformation-name">Name</Label>
              <Input
                width="100%"
                onChange={this.onInputChange}
                value={name}
                autoFocus
                type="text"
                id="new-transformation-name"
                name="name"
                placeholder="Enter transformation name"
              />
            </InputBlock>
            <InputBlock>
              <Label htmlFor="new-transformation-purpose" optional>
                Purpose
              </Label>
              <Input
                width="100%"
                onChange={this.onInputChange}
                value={purpose || ''}
                type="text"
                id="new-transformation-purpose"
                name="purpose"
                placeholder="Enter transformation purpose"
              />
            </InputBlock>
            <InputBlock>
              <Label htmlFor="new-transformation-description" optional>
                Description
              </Label>
              <Input
                width="100%"
                height="190px"
                as="textarea"
                onChange={this.onInputChange}
                value={description || ''}
                id="new-transformation-description"
                name="description"
                placeholder="Enter transformation description"
                style={{ resize: 'vertical' }}
              />
            </InputBlock>
          </Col>
          <Col md={12} lg={6}>
            <InputBlock>
              <Label htmlFor="new-transformation-lead">Lead</Label>
              <UserAutocompleteInput
                value={lead && lead.user_id}
                users={usersList}
                invalid={!!ref_id && !lead}
                placeholder="Choose transformation lead..."
                onChange={this.onLeadChange}
              />
            </InputBlock>
            <InputBlock>
              <Label htmlFor="new-transformation-cost">Budget</Label>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '49%' }}>
                  <CurrencyInput
                    width="100%"
                    onChange={this.onInputChange}
                    value={cost}
                    currency={currency_unit}
                    type="text"
                    id="new-transformation-cost"
                    name="cost"
                    placeholder="Enter transformation cost"
                  />
                </div>
                <div style={{ width: '49%' }}>
                  <CurrencySelect
                    value={currency_unit}
                    onChange={this.onCurrencyChange}
                    name="currency-unit"
                  />
                </div>
              </div>
            </InputBlock>
            <InputBlock>
              <Label>Target type</Label>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    width: showTargetValueTypeSelect ? '49%' : '100%',
                  }}
                >
                  <Select
                    fullWidth
                    value={target_type}
                    onChange={this.onTargetTypeChange}
                    IconComponent={DropdownIcon}
                    inputProps={{
                      name: 'target-type',
                    }}
                  >
                    <MenuItem value={TIME_TARGET_TYPE}>Time</MenuItem>
                    <MenuItem value={FINANCE_TARGET_TYPE}>Finance</MenuItem>

                    {custom_target_types.map(({ _id, name }) => (
                      <MenuItem key={_id} value={_id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                {showTargetValueTypeSelect && (
                  <div style={{ width: '49%' }}>
                    <Select
                      fullWidth
                      value={time_unit}
                      onChange={this.onCurrentTimeUnitChange}
                      IconComponent={DropdownIcon}
                      inputProps={{
                        name: 'target-type',
                      }}
                    >
                      {TIME_UNIT_OPTIONS.map(({ value, text }) => (
                        <MenuItem key={value} value={value}>
                          {text}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                )}
              </div>
            </InputBlock>

            <InputBlock>
              <Label htmlFor="new-transformation-target-current">
                Current value
              </Label>
              {target_type === FINANCE_TARGET_TYPE ? (
                <CurrencyInput
                  value={target_current}
                  onChange={this.onInputChange}
                  currency={currency_unit}
                  type="text"
                  name="target_current"
                  id="new-transformation-target-current"
                  placeholder="Enter current value"
                  width="100%"
                />
              ) : (
                <Input
                  value={target_current}
                  onChange={this.onInputChange}
                  type="number"
                  step="any"
                  name="target_current"
                  id="new-transformation-target-current"
                  placeholder="Enter target value"
                  width="100%"
                />
              )}
            </InputBlock>
            <InputBlock>
              <Label htmlFor="new-transformation-target-current">
                Target value
              </Label>
              {target_type === FINANCE_TARGET_TYPE ? (
                <CurrencyInput
                  value={target_target}
                  onChange={this.onInputChange}
                  currency={currency_unit}
                  type="text"
                  name="target_target"
                  id="new-transformation-target-target"
                  placeholder="Enter value…"
                  width="100%"
                />
              ) : (
                <Input
                  value={target_target}
                  onChange={this.onInputChange}
                  type="number"
                  step="any"
                  name="target_target"
                  id="new-transformation-target-target"
                  placeholder="Enter value…"
                  width="100%"
                />
              )}
            </InputBlock>
          </Col>
        </Row>
        {target_type === FINANCE_TARGET_TYPE && (
          <>
            <Row style={{ marginTop: '32px' }}>
              <Col xs={12}>
                <Text size="h4">ROI & HURDLE RATE</Text>
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={6}>
                <InputBlock>
                  <Label htmlFor="tfs-hurdle-rate">
                    Transformation hurdle rate
                  </Label>
                  <Input
                    value={hurdle_rate}
                    onChange={this.onInputChange}
                    type="number"
                    step="any"
                    name="hurdle_rate"
                    id="tfs-hurdle-rate"
                    placeholder="Enter transformation hurdle rate"
                    width="100%"
                  />
                </InputBlock>
              </Col>
              <Col md={12} lg={6}>
                <InputBlock>
                  <Label htmlFor="tfs-ws-hurdle-rate">
                    Workstream hurdle rate
                  </Label>
                  <Input
                    value={ws_hurdle_rate}
                    onChange={this.onInputChange}
                    type="number"
                    step="any"
                    name="ws_hurdle_rate"
                    id="tfs-ws-hurdle-rate"
                    placeholder="Enter hurdle rate"
                    width="100%"
                  />
                </InputBlock>
              </Col>
            </Row>
          </>
        )}
      </Container>
    );
  }

  onInputChange = event => {
    const { transformation } = this.props;
    this.props.onChange({
      ...transformation,
      [event.target.name]: event.target.value,
    });
  };

  onLeadChange = id => {
    const { transformation, usersList } = this.props;
    this.props.onChange({
      ...transformation,
      lead: [usersList.find(user => user.user_id === id)].map(
        ({ user_id }) => ({ user_id, is_active: 1 })
      ),
    });
  };

  onCurrentTimeUnitChange = event => {
    const { transformation } = this.props;
    this.props.onChange({
      ...transformation,
      time_unit: event.target.value,
    });
  };

  onTargetTypeChange = event => {
    const { transformation } = this.props;
    this.props.onChange({
      ...transformation,
      target_type: event.target.value,
    });
  };

  onCurrencyChange = event => {
    const { transformation } = this.props;
    this.props.onChange({
      ...transformation,
      currency_unit: event.target.value,
    });
  };
}
