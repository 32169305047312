import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { rgba, darken } from 'polished';
import { white } from '@hero/styles/colors';
import { sBold, lmont } from '@hero/styles/typography';
import {
  blueRhino,
  greyManatee,
  puertoRico,
  ghostGrey,
  romanRed,
  mahoganyRed,
  pictonBlue,
  mustard,
  bayOfMany,
} from '@hero/styles/colors-v4';
import { scaffolding } from '@hero/styles/spacings';
import { Text } from '@hero/styles/typography-v5';

const CommonBtnCss = css`
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  border: none;
  margin: 0;
  padding: 8px 16px;
  transition: all 0.2s ease-in;
  font-family: Lato, sans-serif;
  font-weight: 600;
  border-radius: 1px;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  min-height: 38px;
  min-width: ${props => props.minWidth || '100px'};
  &:disabled {
    cursor: not-allowed;
  }
`;

export const Button = styled.button`
  ${CommonBtnCss};
  width: ${props => props.width};
  background-color: ${props => (props.disabled ? ghostGrey : blueRhino)};
  color: ${white};
  border: 1px solid ${props => (props.disabled ? ghostGrey : blueRhino)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    border-color: ${props => (props.disabled ? ghostGrey : puertoRico)};
    background-color: ${props => (props.disabled ? ghostGrey : puertoRico)};
  }
  &:disabled {
    background-color: ${ghostGrey};
    border-color: ${ghostGrey};
    cursor: not-allowed;
  }
  ${sBold};
`;

Button.propTypes = {
  width: PropTypes.string,
};

Button.defaultProps = {
  type: 'button',
  intent: 'default',
};

export const PrimaryButton = styled(Button)`
  min-height: 38px;
`;

export const TextButton = styled.button`
  ${lmont};
  ${CommonBtnCss};
  padding: 0 8px;
  margin: 0 8px;
  color: ${greyManatee};
  background-color: transparent;
  :hover {
    box-shadow: 0 0 0 1px white;
  }
  :focus {
    font-weight: bold;
    outline: none;
  }
`;

export const SecondaryButton = styled(Button)`
  background: ${white};
  color: ${props => (props.disabled ? ghostGrey : blueRhino)};
  border: 1px solid ${props => (props.disabled ? ghostGrey : blueRhino)};
  cursor: pointer;
  margin-right: 12px;
  &:hover {
    background: ${white};
    color: ${props => (props.disabled ? ghostGrey : puertoRico)};
    border-color: ${props => (props.disabled ? ghostGrey : puertoRico)};
  }
  &:disabled {
    background-color: ${ghostGrey};
    border-color: ${ghostGrey};
    cursor: not-allowed;
  }
`;

export const DangerButton = styled(Button)`
  background: ${white};
  color: ${romanRed};
  border: 1px solid ${romanRed};
  margin-right: 12px;
  &:hover {
    background-color: white;
    color: ${props => (props.disabled ? ghostGrey : mahoganyRed)};
    border-color: ${props => (props.disabled ? ghostGrey : mahoganyRed)};
  }
  &:disabled {
    background-color: ${ghostGrey};
    border-color: ${ghostGrey};
    cursor: not-allowed;
  }
`;

export const IconButton = styled.button`
  ${CommonBtnCss};
  padding: ${scaffolding.xxs} 0;
  background: transparent;
  color: ${props => props.color || ghostGrey};
  border: 1px solid transparent;
  min-width: 32px;
  height: 32px;
  &:hover:not(:disabled),
  &:active:not(:disabled) {
    border-color: ${ghostGrey};
  }
`;

export const UndoButton = styled(Button)`
  ${CommonBtnCss};
  background: ${rgba(puertoRico, 0.2)};
  color: ${white};
  border: 1px solid ${rgba(white, 0.4)};
  &:hover {
    background: ${rgba(puertoRico, 0.6)};
    border-color: ${rgba(white, 0.8)};
  }
  &:disabled {
    background-color: ${ghostGrey};
    border-color: ${ghostGrey};
    cursor: not-allowed;
  }
`;

const StyledButtonLink = styled.button`
  ${CommonBtnCss};
  &:focus,
  &:hover,
  &:active {
    box-shadow: 0 0 0 1px ${pictonBlue};
  }
`;

export function ButtonLink({
  children,
  color = pictonBlue,
  size = 'link',
  ...props
}) {
  return (
    <StyledButtonLink {...props}>
      <Text size={size} color={color}>
        {children}
      </Text>
    </StyledButtonLink>
  );
}

const WebBtnCss = css`
  padding: 12px 30px;
  font-family: 'bebas-regular', Lato, sans-serif;
  font-size: 25px;
  letter-spacing: 1px;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 1px transparent;
  &:focus,
  &:hover,
  &:active {
    box-shadow: rgb(255, 255, 255) 0px 0px 0px 1px, ${mustard} 0px 0px 0px 2px;
  }
`;

export const WebPrimaryBtnCss = css`
  ${CommonBtnCss};
  ${WebBtnCss};
  background-color: ${mustard};
  color: ${bayOfMany};
  &:focus,
  &:hover,
  &:active {
    background-color: ${darken(0.1, mustard)};
  }
`;

export const WebPrimaryBtn = styled.button`
  ${WebPrimaryBtnCss};
`;

export const WebSecondaryBtnCss = css`
  ${CommonBtnCss};
  ${WebBtnCss};
  background-color: white;
  color: ${bayOfMany};
  &:focus,
  &:hover,
  &:active {
    background-color: ${mustard};
  }
`;

export const WebSecondaryBtn = styled.button`
  ${WebSecondaryBtnCss};
`;

export const WebTextBtn = styled.button`
  ${CommonBtnCss};
  ${WebBtnCss};
  background-color: transparent;
  color: ${mustard};
  &:focus,
  &:hover,
  &:active {
    background-color: ${mustard};
    color: ${bayOfMany};
  }
`;

export default Button;
