import React from 'react';
import styled from 'styled-components/macro';
import { getStatusColor } from '@hero/styles/colors';
import { MainInput } from '@hero/tfs/src/shared/ConfigEditor';

const StatusInputWrapper = styled.div`
  position: relative;

  ::after {
    content: '';
    position: absolute;
    left: -15px;
    top: 50%;
    margin-top: -5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${props => props.bgColor};
  }
`;

export default function StatusInput({ statusId, statusList, ...props }) {
  return (
    <StatusInputWrapper
      bgColor={getStatusColor(statusId, statusList).background}
    >
      <MainInput {...props} />
    </StatusInputWrapper>
  );
}
