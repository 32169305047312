import React from 'react';
import { Box, Inline } from 'jsxstyle';

import { T2, LLato } from '@hero/styles/typography';
import BirdIcon from '@hero/core/icons/Bird';
import Nav from '@hero/core/Nav';

import { FormattedMessageWithHelmet } from '@hero/tfs/src/shared/FormattedMessage';

import messages from './messages';

const Unauthorized = props => (
  <React.Fragment>
    <FormattedMessageWithHelmet {...messages['title']} />
    <Nav fixed />
    <Box
      width="100vw"
      height="100vh"
      backgroundImage="url(/error_401.jpg)"
      backgroundPosition="center center"
      backgroundRepeat="no-repeat"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom="70px"
      >
        <Box transform="rotate(-180deg)">
          <BirdIcon size="50px" />
        </Box>

        <Inline
          padding="7px 20px 7px 10px"
          borderColor="#CCC"
          borderStyle="solid"
          borderWidth="0 1px 0 0"
        >
          <T2>401</T2>
        </Inline>

        <Box padding="0 20px" maxWidth="500px">
          <p>
            <LLato>
              Oh dear... you tried to enter somewhere not allowed to.
            </LLato>
          </p>
        </Box>
      </Box>
    </Box>
  </React.Fragment>
);

export default Unauthorized;
