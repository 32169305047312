import React from 'react';

import { DngrActionBtn } from '@hero/core/Dialog';

import ConfirmModal from '@hero/tfs/src/shared/ConfirmModal';

export default function ArchiveBlocker({ onDelete }) {
  const [showConfirmation, setShowConfirmation] = React.useState(false);

  return (
    <>
      <DngrActionBtn onClick={() => setShowConfirmation(true)}>
        Archive
      </DngrActionBtn>
      <ConfirmModal
        zIndex={11}
        closeModal={() => setShowConfirmation(false)}
        isOpen={showConfirmation}
        isDanger
        onConfirm={onDelete}
        title="Archive Blocker"
        confirmBtnTitle="Archive"
        message="Are you sure you want to archive Blocker"
      />
    </>
  );
}
