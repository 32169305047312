import React from 'react';
import { Box } from 'jsxstyle';

import { initStatusColors } from '@hero/styles/colors';
import { Text } from '@hero/styles/typography-v5';

export const BACKLOG = 'backlog';
export const DONE = 'done';
export const IN_PROGRESS = 'inProgress';

const Legend = ({ color, title }) => (
  <Box marginLeft="16px" display="flex" alignItems="center">
    <Box
      marginRight={4}
      height="8px"
      width="8px"
      borderRadius="8px"
      backgroundColor={color}
    />
    <Text size="overline" textTransform="uppercase">
      {title}
    </Text>
  </Box>
);

export default function StatusLegend({
  left,
  statusList,
  stageGates,
  ...props
}) {
  const todo = stageGates.find(s => s._id === BACKLOG);
  const inProgressStage = stageGates.find(s => s._id === IN_PROGRESS);
  const done = stageGates.find(s => s._id === DONE);
  return (
    <Box display="flex" alignItems="center" {...props}>
      {left && left()}
      {todo && <Legend title={todo.name} color={initStatusColors[todo._id]} />}
      {inProgressStage && (
        <Legend title={inProgressStage.name} color={initStatusColors['2']} />
      )}
      {statusList.slice(1).map(status => (
        <Legend
          key={status._id}
          title={status.name}
          color={initStatusColors[status._id]}
        />
      ))}
      {done && <Legend title={done.name} color={initStatusColors[done._id]} />}
    </Box>
  );
}
