import React from 'react';
import PropTypes from 'prop-types';
import { rgba } from 'polished';
import styled from 'styled-components/macro';
import { animated, useTransition } from 'react-spring';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import '@reach/dialog/styles.css';
import Close from '@material-ui/icons/Close';
import useControlledState from '@quid/react-use-controlled-state';

import {
  santasGrey,
  bayOfMany,
  quartz,
  ghostGrey,
  romanRed,
  fairPink,
  puertoRico,
  mahoganyRed,
} from '@hero/styles/colors-v4';
import { scaffolding } from '@hero/styles/spacings';
import { llato } from '@hero/styles/typography';

const TriggerWrapper = styled.div({
  display: 'inline-flex',
});

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${scaffolding.xl};
  padding: ${scaffolding.xs} ${scaffolding.lg};
  font-family: Montserrat, Lato, san-serif;
  font-size: 21px;
  line-height: 25px;
  font-weight: 600;
  color: ${props => (props.headerColor ? 'white' : bayOfMany)};
  span {
    color: inherit;
  }
  background-color: ${props => props.headerColor || quartz};
  z-index: ${props => (props.zIndex ? props.zIndex + 1 : 'initial')};
  position: sticky;
  top: -${scaffolding.xl};
  transition: all 0.2s ease-in;
`;

const DismissIcon = styled(Close)`
  cursor: pointer;
  color: ${props => (props.headerColor ? 'white' : santasGrey)};
  &&.dismiss-icon {
    height: 32px;
    width: 32px;
  }
`;

const Body = styled.div`
  padding: ${scaffolding.sm} ${scaffolding.lg} ${scaffolding.lg};
`;

const Footer = styled.div`
  padding: ${scaffolding.sm} ${scaffolding.lg};
  position: sticky;
  bottom: 0;
  background-color: ${quartz};
`;

export const MainActionBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 152px;
  width: ${props => props.width || '100%'};
  background: ${bayOfMany};
  border-radius: 1px;
  cursor: pointer;
  color: white;
  padding: 10px 16px;
  height: 40px;
  font-weight: bold;
  border: none;
  outline: none;
  transition: opacity, color, background 0.1s ease-in;
  ${llato};
  &:hover {
    background-color: ${puertoRico};
  }
  &:disabled {
    background: ${rgba(bayOfMany, 0.3)};
    cursor: not-allowed;
  }
  margin-bottom: 8px;
`;

export const ActionBtn = styled(MainActionBtn)`
  background: ${rgba(bayOfMany, 0.1)};
  color: ${bayOfMany};
  :hover {
    background: ${rgba(bayOfMany, 0.35)};
  }
  &:disabled {
    background: ${rgba(bayOfMany, 0.15)};
    color: ${ghostGrey};
  }
`;

export const DngrActionBtn = styled(MainActionBtn)`
  background: ${fairPink};
  color: ${romanRed};
  :hover {
    background: ${rgba(romanRed, 0.35)};
    color: ${mahoganyRed};
  }
  &:disabled {
    background: ${rgba(bayOfMany, 0.15)};
    color: ${ghostGrey};
  }
`;

export const Actions = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: sticky;
  top: 0;
  padding-top: 35px;
  padding-left: 36px;
`;

const StyledDialogOverlay = styled(DialogOverlay)`
  && {
    background: none;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.position};
    align-items: center;
    padding-top: ${scaffolding.xl};
    z-index: ${props => props.zIndex};
    overflow-y: scroll;
    overflow-x: hidden;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  && {
    background-color: ${quartz};
    margin: 0;
    padding: 0;
    box-shadow: 0 2px 8px rgba(49, 66, 96, 0.2);
    width: auto;
    max-width: ${props =>
      props.isfluid ? `calc(100% - ${scaffolding.xl})` : '80vw'};
    min-width: ${props =>
      props.isfluid
        ? `calc(100% - ${scaffolding.xl})`
        : props.width || '600px'};
    border-radius: 1px;
  }
`;

const AnimatedDialogOverlay = animated(StyledDialogOverlay);
const AnimatedDialogContent = animated(StyledDialogContent);

export default function Dialog({
  trigger,
  isDefaultOpen,
  isOpen,
  isDismissable,
  isFluid,
  position = 'center',
  zIndex = 3,
  width,
  header,
  headerColor,
  footer,
  onClose,
  children,
}) {
  const [showDialog, setShowDialog] = useControlledState(
    isDefaultOpen,
    isOpen,
    onClose
  );

  const transitions = useTransition(showDialog, null, {
    from: { opacity: 0, y: -10 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 10 },
  });

  function handleDismiss() {
    setShowDialog(false);
  }

  return (
    <>
      {trigger && (
        <TriggerWrapper>
          {trigger({
            handleOpen: () => {
              setShowDialog(true);
            },
          })}
        </TriggerWrapper>
      )}
      {transitions.map(
        ({ item, key, props: styles }) =>
          item && (
            <AnimatedDialogOverlay
              key={key}
              style={{ opacity: styles.opacity }}
              position={position}
              zIndex={zIndex}
              onDismiss={handleDismiss}
            >
              <AnimatedDialogContent
                aria-label="dialog"
                isfluid={isFluid}
                width={width}
                style={{
                  transform: styles.y.interpolate(
                    value => `translate3d(0px, ${value}px, 0px)`
                  ),
                }}
              >
                {header && (
                  <Header headerColor={headerColor} zIndex={zIndex}>
                    {header}
                    {isDismissable && (
                      <DismissIcon
                        headerColor={headerColor}
                        className="dismiss-icon"
                        onClick={() => handleDismiss()}
                      />
                    )}
                  </Header>
                )}
                <Body className="dialog-body">{children}</Body>
                {footer && (
                  <Footer>
                    {footer({
                      handleClose: () => handleDismiss(),
                    })}
                  </Footer>
                )}
              </AnimatedDialogContent>
            </AnimatedDialogOverlay>
          )
      )}
    </>
  );
}

Dialog.propTypes = {
  trigger: PropTypes.node,
  isDefaultOpen: PropTypes.bool,
  isOpen: PropTypes.bool,
  isDismissable: PropTypes.bool,
  isFluid: PropTypes.bool,
  zIndex: PropTypes.number,
  position: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
  width: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  footer: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
};
