import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, Route, useLocation } from 'react-router-dom';
import { Box } from 'jsxstyle';
import uniqBy from 'lodash/uniqBy';
import flatten from 'lodash/flatten';
import isEqual from 'lodash/isEqual';
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';

import { solitude } from '@hero/styles/colors-v4';
import { Text } from '@hero/styles/typography-v5';
import { ButtonLink } from '@hero/core/Button';

import FormattedNumber from '@hero/tfs/src/shared/FormattedNumber';
import Fetch from '@hero/tfs/src/shared/Fetch';
import useStats from '@hero/tfs/src/shared/hooks/useStats';
import useBlockersByTfs from '@hero/tfs/src/shared/hooks/useBlockersByTfs';

import { BlockerProgressStats } from '@hero/tfs/src/02-overview/BlockerSection';
import { BlockersContext } from '@hero/tfs/src/03-blockers/BlockersHost';
import EditBlockerContainer from '@hero/tfs/src/03-blockers/BlockerForm';
import * as Kanban from '@hero/tfs/src/03-blockers/kanban';
import BlockerCard from '@hero/tfs/src/03-blockers/BlockerCard';
import BlockerStatsRow from '@hero/tfs/src/03-blockers/BlockerStatsRow';
import BlockersLoader, {
  BlockerKanbanLoader,
} from '@hero/tfs/src/03-blockers/BlockersLoader';

export default function TfsBlockers({ tfs }) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const workstream = params.get('workstream');
  const { refreshedOn, refreshBlockers } = useContext(BlockersContext);

  const blockersData = useBlockersByTfs(tfs.ref_id, workstream, refreshedOn);

  return (
    <FormattedNumber.TfsProvider tfs={tfs}>
      <BoardContainer
        tfsId={tfs.ref_id}
        workstream={workstream}
        blockersUpdatedAt={refreshedOn}
      >
        <Fetch.POST
          manual
          url={`/api/blocker/update`}
          onFetch={() => refreshBlockers(Date.now())}
        >
          {({ doRequest: updateBlocker }) => (
            <>
              {!blockersData ? (
                <BlockerKanbanLoader
                  noOfColumns={
                    tfs.configuration.blocker_state
                      ? tfs.configuration.blocker_state.length
                      : 3
                  }
                />
              ) : (
                <Board
                  blockersData={blockersData}
                  configData={tfs.configuration}
                  updateBlocker={updateBlocker}
                >
                  {blockers =>
                    tfs.configuration.blocker_state &&
                    tfs.configuration.blocker_state.map(({ name, _id }) => (
                      <Column
                        backgroundColor={solitude}
                        key={_id}
                        stateId={_id}
                        name={name}
                        blockers={blockers}
                      >
                        {blockers[_id] &&
                          blockers[_id].map((item, index) => (
                            <Card
                              key={item.ref_id}
                              index={index}
                              tfs={tfs}
                              blocker={item}
                              stateId={_id}
                            />
                          ))}
                      </Column>
                    ))
                  }
                </Board>
              )}

              <Route
                path="/transformations/:tfsId/blockers/:blockerId"
                render={({ match, history }) => {
                  const { tfsId, blockerId } = match.params;

                  if (!blockersData) {
                    return null;
                  }

                  const blocker = blockersData.find(
                    x => x.ref_id === blockerId
                  );

                  return (
                    <EditBlockerContainer
                      tfsId={tfsId}
                      isOpen={true}
                      configData={tfs.configuration}
                      closeModal={() =>
                        history.push(`/transformations/${tfsId}/blockers`)
                      }
                      onBlockerSubmit={() => refreshBlockers(Date.now())}
                      blockerValue={blocker}
                    />
                  );
                }}
              />
            </>
          )}
        </Fetch.POST>
      </BoardContainer>
    </FormattedNumber.TfsProvider>
  );
}

function BlockerMetrics({ blockersUpdatedAt, tfsId, workstream }) {
  const [showDetails, setShowDetails] = useState(false);
  const [stats] = useStats(tfsId, workstream, blockersUpdatedAt);

  const showLoading = !stats;

  if (showLoading) {
    return <BlockersLoader />;
  }

  const { reactionTime, cycleTime, leadTime } = stats.blockerStats;

  return (
    <>
      <Container
        fluid
        style={{ margin: '0 0 18px 0', padding: 0, width: '100%' }}
      >
        <Row
          align="center"
          justify="space-between"
          style={{
            minHeight: '64px',
            padding: '8px 32px',
            backgroundColor: 'white',
          }}
          nogutter
        >
          <Col sm={9} lg={4}>
            <Text size="h4" as="div" fontWeight="600">
              Blocker metrics
            </Text>
          </Col>
          <Hidden xs sm md>
            <Col lg={6.5}>
              <BlockerStatsRow
                doFormat
                leadTime={leadTime}
                cycleTime={cycleTime}
                reactionTime={reactionTime}
              />
            </Col>
          </Hidden>
          <Col sm={3} lg={1.5} style={{ textAlign: 'right' }}>
            <ButtonLink onClick={() => setShowDetails(!showDetails)}>
              {showDetails ? 'Hide details' : 'Show details'}
            </ButtonLink>
          </Col>
        </Row>
        <Visible xs sm md>
          <Row
            style={{
              minHeight: '64px',
              padding: '8px 32px',
              backgroundColor: 'white',
            }}
            nogutter
          >
            <Col sm={12}>
              <BlockerStatsRow
                doFormat
                leadTime={leadTime}
                cycleTime={cycleTime}
                reactionTime={reactionTime}
                marginLeft="0"
                justifyContent="flex-start"
                statRowProps={{ width: '30%' }}
              />
            </Col>
          </Row>
        </Visible>

        {showDetails && (
          <Box backgroundColor="white" padding="24px 32px">
            <BlockerProgressStats
              blockerStats={stats.blockerStats}
              paddingBottom={0}
            />
          </Box>
        )}
      </Container>
    </>
  );
}

function BoardContainer({ tfsId, workstream, blockersUpdatedAt, children }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="calc(100% - 90px)"
      width="100%"
    >
      <Box display="flex" flexWrap="wrap" marginTop="2px">
        <BlockerMetrics
          tfsId={tfsId}
          workstream={workstream}
          blockersUpdatedAt={blockersUpdatedAt}
        />
        {children}
      </Box>
    </Box>
  );
}

function Board({ configData, blockersData, updateBlocker, children }) {
  const data = configData.blocker_state.reduce((acc, { name, _id }) => {
    acc[_id] = blockersData.filter(
      blocker => blocker.state[blocker.state.length - 1].state_id === _id
    );

    return acc;
  }, {});
  const [blockers, setBlockers] = useState(data);
  const prevData = usePrevious(data);

  useEffect(() => {
    if (!isEqual(prevData, data)) {
      setBlockers(data);
    }
  }, [data]);

  const saveBlocker = (changes, { source, destination }) => {
    setBlockers({
      ...blockers,
      ...changes,
    });

    if (source.droppableId !== destination.droppableId) {
      updateBlocker({
        body: {
          ...blockers[source.droppableId][source.index],
          state: blockers[source.droppableId][source.index].state.concat({
            state_id: destination.droppableId,
            timestamp: new Date().toISOString(),
          }),
        },
      }).then(({ data: updatedBlocker }) => {
        setBlockers(prevBlockers => {
          const newBlockers = { ...prevBlockers };
          const currentStatus =
            updatedBlocker.state[updatedBlocker.state.length - 1].state_id;
          const updatedBlockerIndex = newBlockers[currentStatus].findIndex(
            blocker => updatedBlocker.ref_id === blocker.ref_id
          );

          newBlockers[currentStatus][updatedBlockerIndex] = {
            ...newBlockers[currentStatus][updatedBlockerIndex],
            ...updatedBlocker,
          };

          return newBlockers;
        });
      });
    }
  };

  return (
    <Box
      height="auto"
      position="relative"
      width="100%"
      maxWidth="90vw"
      overflowX="auto"
      overflowY="hidden"
    >
      <Box display="inline-flex" position="relative" width="100%">
        <Kanban.Board value={blockers} onChange={saveBlocker}>
          {children(blockers)}
        </Kanban.Board>
      </Box>
    </Box>
  );
}

function Column({ backgroundColor, stateId, name, blockers, children }) {
  const initiatives = uniqBy(
    flatten(blockers[stateId] ? blockers[stateId].map(x => x.initiatives) : []),
    'ref_id'
  );

  return (
    <Kanban.Column
      backgroundColor={backgroundColor}
      key={stateId}
      id={stateId}
      header={name}
      itemsCount={blockers[stateId].length}
      totalValue={initiatives
        .map(i => i.outcome_value)
        .reduce((x, y) => x + y, 0)}
      children={children}
    />
  );
}

function Card({ tfs, stateId, index, blocker }) {
  return (
    <Link to={`/transformations/${tfs.ref_id}/blockers/${blocker.ref_id}`}>
      <Kanban.Card key={blocker.ref_id} id={blocker.ref_id} index={index}>
        <BlockerCard
          name={blocker.name}
          initiatives={blocker.initiatives}
          totalOutcome={Math.abs(tfs.target_target - tfs.target_current)}
          lastUpdatedAt={blocker.last_update || new Date().toISOString()}
          createdAt={blocker.created_at}
          deadline={blocker.deadline}
          state={blocker.state}
          done={stateId === 'done'}
        />
      </Kanban.Card>
    </Link>
  );
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
