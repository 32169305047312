import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Route, Link, NavLink, useHistory } from 'react-router-dom';
import { Box, Row } from 'jsxstyle';
import styled from 'styled-components/macro';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MenuIcon from '@material-ui/icons/Menu';
import Popover from '@material-ui/core/Popover';
import { Hidden, Visible } from 'react-grid-system';

import {
  ghostGrey,
  santasGrey,
  mustard,
  blueRhino,
} from '@hero/styles/colors-v4';
import { Text } from '@hero/styles/typography-v5';
import { IconButton } from '@hero/core/Button';
import BirdIcon from '@hero/core/icons/Bird';
import ErrorBondary from '@hero/tfs/src/shared/ErrorBoundary';
import NotificationTarget from '@hero/tfs/src/11-notifications/NotificationTarget';
import StatusLegend from '@hero/tfs/src/shared/StatusLegend';

import AddNewButton from './AddNewButton';
import TfsDropdown from './TfsDropdown';
import WorkstreamDropdown from './WorkstreamDropdown';

const MenuLinksWrapper = styled.div`
  background-color: ${blueRhino};
`;

export const TfsNavLink = styled(NavLink)`
  outline: none;
  padding: 0px 16px 16px 16px;
  border-bottom: 4px solid transparent;
  transition: 200ms ease-in-out;
  font-family: Lato;
  transition-property: opacity, color, background-color, border-color;
  color: ${ghostGrey};
  margin-right: 3px;

  &:hover,
  &:focus,
  &.active {
    border-color: ${mustard};
    color: white;
  }
  &:last-child {
    margin-right: 0;
  }
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 18px;
  white-space: nowrap;
  ${MenuLinksWrapper} & {
    margin-bottom: 8px;
    padding: 0px 16px 8px 16px;
  }
`;

const BackLink = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export default function Page({ children }) {
  return children;
}

Page.Logo = function({ tfsId }) {
  return (
    <Link to={`/transformations/${tfsId}`}>
      <BirdIcon size={24} />
    </Link>
  );
};

Page.ArrowBack = function() {
  const history = useHistory();

  return (
    <Box>
      <BackLink onClick={() => history.goBack()}>
        <ArrowBackIcon size={32} style={{ color: santasGrey }} />
        <Text size="h6" fontWeight="600" marginLeft="4px">
          Back
        </Text>
      </BackLink>
    </Box>
  );
};

Page.LineDivider = styled.div`
  width: 1px;
  height: 36px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 12px;
`;

Page.SlashDivider = styled.div`
  height: 36px;
  width: 1px;
  transform: rotate(30deg);
  background-color: #4f6482;
  margin: 25px;
`;

Page.Header = function({ children }) {
  const domNode = React.useMemo(() => document.getElementById('header'));
  return ReactDOM.createPortal(children, domNode);
};

Page.InnerRow = function InnerRow(props) {
  return (
    <Box
      display="flex"
      margin="0 auto"
      maxWidth={1450}
      width="90vw"
      {...props}
    />
  );
};

Page.Main = function Main({ expand, ...props }) {
  const height = expand
    ? 'calc(100vh - 56px)'
    : `calc(100vh - 56px - 12px - 74px)`;
  const marginTop = expand ? 56 : 0;

  return (
    <ErrorBondary>
      <Page.InnerRow height={height} marginTop={marginTop} {...props} />
    </ErrorBondary>
  );
};

function MenuNavLinks({ tfsId, location }) {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <Box marginLeft="auto">
      <IconButton
        onClick={event => setAnchorEl(event.currentTarget)}
        color="white"
      >
        <MenuIcon />
      </IconButton>
      <Popover
        id="tfs-nav-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuLinksWrapper>
          <NavLinks
            tfsId={tfsId}
            location={location}
            flexDirection="column"
            marginTop={0}
            padding="10px 0"
          />
        </MenuLinksWrapper>
      </Popover>
    </Box>
  );
}

function NavLinks({ tfsId, location, flexDirection = 'row', ...props }) {
  return (
    <Box
      display="flex"
      flexDirection={flexDirection}
      marginTop="18px"
      marginLeft="auto"
      {...props}
    >
      <TfsNavLink
        to={{
          pathname: `/transformations/${tfsId}/overview`,
          search: location.search,
        }}
      >
        Overview
      </TfsNavLink>
      <TfsNavLink
        to={{
          pathname: `/transformations/${tfsId}/blockers`,
          search: location.search,
        }}
      >
        Blockers
      </TfsNavLink>
      <TfsNavLink
        to={{
          pathname: `/transformations/${tfsId}/bridge`,
          search: location.search,
        }}
      >
        Bridge
      </TfsNavLink>
      <TfsNavLink
        to={{
          pathname: `/transformations/${tfsId}/gantt`,
          search: location.search,
        }}
      >
        Gantt
      </TfsNavLink>
      <TfsNavLink
        to={{
          pathname: `/transformations/${tfsId}/effort-outcome`,
          search: location.search,
        }}
      >
        Effort/Outcome
      </TfsNavLink>
      <TfsNavLink
        to={{
          pathname: `/transformations/${tfsId}/list`,
          search: location.search,
        }}
      >
        List
      </TfsNavLink>
      <TfsNavLink
        to={{
          pathname: `/transformations/${tfsId}/kanban`,
          search: location.search,
        }}
      >
        Kanban
      </TfsNavLink>

      {Boolean(localStorage.getItem('feature/notification-feed')) && (
        <NotificationTarget />
      )}

      <Page.SettingsLink tfsId={tfsId} />
    </Box>
  );
}

Page.Nav = function({ tfsId }) {
  return (
    <Box boxShadow="0 1px 3px rgba(0,0,0, .12), 0 1px 2px rgba(0,0,0, .24);">
      <Page.InnerRow height={56} alignItems="center">
        <Route>
          {({ location, history }) => (
            <>
              <Page.Logo tfsId={tfsId} />
              <Page.LineDivider />

              <TfsDropdown
                value={tfsId}
                onChange={tfsId =>
                  history.push(replaceTfsInURL(location.pathname, tfsId))
                }
                onArchived={(archivedTfs, tfsList) => {
                  if (tfsId === archivedTfs.ref_id) {
                    const remainingTfs = tfsList.filter(
                      x => x.ref_id !== archivedTfs.ref_id
                    );

                    if (remainingTfs.length > 0) {
                      history.push(
                        `/transformations/${remainingTfs[0].ref_id}`
                      );
                    }
                  }
                }}
              />
              <Visible lg xl>
                <NavLinks tfsId={tfsId} location={location} />
              </Visible>
              <Hidden lg xl>
                <MenuNavLinks tfsId={tfsId} location={location} />
              </Hidden>
            </>
          )}
        </Route>
      </Page.InnerRow>
    </Box>
  );
};

Page.SettingsLink = function({ tfsId }) {
  return (
    <TfsNavLink
      to={{ pathname: `/transformations/${tfsId}/settings`, state: { tfsId } }}
    >
      Settings
    </TfsNavLink>
  );
};

Page.SectionNav = function({
  tfsId,
  workstreams,
  shouldShowLegend,
  statusList,
  stageGates,
  onInitiativeCreated,
  ...props
}) {
  return (
    <Page.InnerRow
      height={68}
      alignItems="center"
      marginTop={56}
      marginBottom={12}
      padding="24px 0"
      {...props}
    >
      <Box
        display="flex"
        flex="1"
        alignItems="center"
        justifyContent="space-between"
      >
        <WorkstreamDropdown list={workstreams} />
        {shouldShowLegend ? (
          <Row>
            <StatusLegend
              statusList={statusList}
              stageGates={stageGates}
              marginRight="24px"
            />
            <AddNewButton
              marginLeft="auto"
              display="flex"
              tfsId={tfsId}
              onInitiativeCreated={onInitiativeCreated}
            />
          </Row>
        ) : (
          <AddNewButton
            marginLeft="auto"
            display="flex"
            tfsId={tfsId}
            onInitiativeCreated={onInitiativeCreated}
          />
        )}
      </Box>
    </Page.InnerRow>
  );
};

function replaceTfsInURL(pathname, tfsId) {
  if (!pathname.startsWith('/')) {
    return replaceTfsInURL('/' + pathname, tfsId);
  }

  const segments = pathname.split('/');

  if (segments.length < 3) {
    return pathname;
  }

  if (segments[1] === 'transformations') {
    return segments
      .slice(0, 2)
      .concat(tfsId)
      .concat(segments.slice(3))
      .join('/');
  }

  return pathname;
}
