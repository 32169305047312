import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import useApiUrl from './useApiUrl';
import useToken from './useToken';

export default function useInitiativesByTfs({ tfsId, workstream, key }) {
  const [initiatives, setInitiatives] = useState(null);
  const token = useToken();
  const apiUrl = useApiUrl();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetch(new URL('/api/initiative/read-by-transformation-id', apiUrl), {
      body: JSON.stringify({
        query: {
          ref_id: tfsId,
        },
        workstream,
      }),
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    })
      .then(rsp => rsp.json())
      .then(data => {
        if (data.ok) {
          setInitiatives(data.data);
        } else {
          setInitiatives([]);

          console.log('Broken data');
        }
      })
      .catch(err => {
        console.log(err);
        setInitiatives([]);
        enqueueSnackbar('Failed to fetch the initiatives', {
          variant: 'error',
        });
      });
  }, [tfsId, workstream, token, apiUrl, key]);

  return initiatives;
}
