import { useState, useCallback, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';

export default function useAutosave(initialValue, onSubmit, timeout = 500) {
  const [value, setValue] = useState(initialValue);
  const [latestSaved, setLatestSaved] = useState(initialValue);

  const submit = useCallback(
    debounce((newValue, initialValue, onSubmit, setLatestSaved) => {
      Promise.resolve(onSubmit(newValue, initialValue)).then(() =>
        setLatestSaved(newValue)
      );
    }, timeout),
    [timeout]
  );

  useEffect(() => {
    let enabled = true;

    if (value !== latestSaved) {
      submit(value, initialValue, onSubmit, x => {
        if (enabled) {
          setLatestSaved(x);
        }
      });
    }

    return () => {
      enabled = false;
    };
  }, [value]);

  function onUnload(e) {
    if (!isEqual(latestSaved, value)) {
      e.preventDefault();
      e.returnValue = '';
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload, false);

    return () => {
      window.removeEventListener('beforeunload', onUnload, false);
    };
  }, [value, latestSaved]);

  return [value, setValue];
}
