import React from 'react';
import styled from 'styled-components/macro';
import { Row } from 'jsxstyle';
import pick from 'lodash/pick';
import omitBy from 'lodash/omitBy';
import isEqual from 'lodash/isEqual';
import { withSnackbar } from 'notistack';
import AccountForm from './AccountForm';
import AccountImage from './AccountImage';
import Button, { TextButton } from '@hero/core/Button';
import { grey400, grey150 } from '@hero/styles/colors';
import { Box as SettingsBox } from '../shared';
import Fetch, { withFetch } from '@hero/tfs/src/shared/Fetch';
import Editor from '@hero/tfs/src/shared/Editor';
import { LocaleContext } from '../../shared/locale';
import PasswordChangeDialog from './PasswordChangeDialog';

const SaveButton = styled(Button)`
  min-width: auto;
  width: 103px;
  margin: 46px 0 0 0;
  margin-right: 20px;
  outline: none;

  &[disabled] {
    color: ${grey400};
    pointer-events: none;
    background: ${grey150};
    border: 1px solid ${grey150};
    cursor: inherit;
  }

  &:hover {
    opacity: ${props => (props.disabled ? 0.8 : 1)};
  }
`;

const Form = styled(Editor)`
  position: relative;
  min-width: 520px;
  &[disabled] {
    opacity: 0.8;
    pointer-events: none;
  }

  &:invalid button {
    color: ${grey400};
    pointer-events: none;
    background: ${grey150};
    border: 1px solid ${grey150};
    cursor: inherit;
  }
`;

function ManageMyAccount({ fetch, enqueueSnackbar }) {
  const [file, setFile] = React.useState(null);
  const [currentUserImageDeleted, setCurrentUserImageDeleted] = React.useState(
    false
  );
  const [saving, setSaving] = React.useState(false);
  const [locale, setLocale] = React.useContext(LocaleContext); // eslint-disable-line no-unused-vars
  const [isPasswordDialogOpen, setPasswordDialogOpen] = React.useState(false);

  async function handleFormSubmit(user, reload) {
    setSaving(true);
    setLocale(user.locale);

    if (file) {
      const formData = new FormData();
      formData.append('image', file);
      await fetch({
        url: '/api/user/upload-profile-picture',
        method: 'POST',
        body: formData,
      });
    } else if (currentUserImageDeleted) {
      await fetch({
        url: '/api/user/delete-profile-picture',
        method: 'POST',
        body: file,
      });
    }

    await fetch({
      url: '/api/user/manage-account',
      method: 'POST',
      body: omitBy(
        pick(user, ['first_name', 'last_name', 'password', 'locale']),
        x => !x
      ),
    });

    reload();
    setSaving(false);
    enqueueSnackbar('Setttings updated successfully', { variant: 'success' });
  }

  return (
    <SettingsBox>
      <Fetch.POST
        url="/api/user/current"
        render={({ data: userData }, { doRequest }) => (
          <Form
            initialValue={pick(userData, [
              'first_name',
              'last_name',
              'email',
              'locale',
              'profile_picture',
            ])}
            disabled={saving}
            onSubmit={data => handleFormSubmit(data, doRequest)}
          >
            {({ value, onChange, initialValue }) => (
              <>
                <Row flexWrap="wrap">
                  <div style={{ marginRight: 50 }}>
                    <AccountForm value={value} onChange={onChange} />
                  </div>

                  <div>
                    <AccountImage
                      userData={value}
                      file={file}
                      onFileDrop={files => setFile(files[0])}
                      onClearFile={() => {
                        setFile(null);
                        setCurrentUserImageDeleted(true);
                      }}
                      onCurrentUserImageDelete={() =>
                        setCurrentUserImageDeleted(true)
                      }
                      currentUserImageDeleted={currentUserImageDeleted}
                    />
                  </div>
                </Row>

                <SaveButton
                  type="submit"
                  disabled={shouldDisableSubmit({
                    saving,
                    value,
                    initialValue,
                    file,
                    currentUserImageDeleted,
                  })}
                >
                  Save
                </SaveButton>

                <TextButton
                  type="button"
                  onClick={() => setPasswordDialogOpen(true)}
                >
                  Change password
                </TextButton>
              </>
            )}
          </Form>
        )}
      />
      <PasswordChangeDialog
        isOpen={isPasswordDialogOpen}
        onClose={() => setPasswordDialogOpen(false)}
      />
    </SettingsBox>
  );
}

function shouldDisableSubmit({
  saving,
  value,
  initialValue,
  file,
  currentUserImageDeleted,
}) {
  if (saving) {
    return true;
  }

  if (file) {
    return false;
  }

  if (currentUserImageDeleted) {
    return false;
  }

  return isEqual(value, initialValue);
}

export default withSnackbar(withFetch(ManageMyAccount));
