import { spring } from 'react-motion';
import { getInitiativeColor } from '@hero/styles/colors';
import { ghostGrey } from '@hero/styles/colors-v4';
import { getInitiativeStatus } from '@hero/tfs/src/09-initiatives-editor/shared';

export const BAR_WIDTH = 12;

const SPRING_CONFIG = {
  stiffness: 60,
  damping: 17,
};

const FIRST_BAR_POSITION = 18;
const LAST_BAR_RIGHT_POSIION = 6;
const NEXT_BAR_POSITION = 24;

const NONE_GROUP_OPTION = {
  value: 'none',
  text: 'All',
};

let prevBarPositions = {};

export const clearPreviousPositions = () => {
  prevBarPositions = {};
};

const constructValueBars = (
  index,
  valueBarDefaultStyles,
  valueBarStyles,
  initiative,
  width,
  chartHeight,
  barXPosition,
  topYScale,
  isGroupingAnimation
) => {
  let xPosition = 0;
  const y = topYScale(initiative.outcome_value);
  xPosition =
    index === 0 ? width + FIRST_BAR_POSITION : barXPosition + NEXT_BAR_POSITION;

  const height = chartHeight - y;

  const xPreviousBarPosition = prevBarPositions[initiative._id];
  prevBarPositions[initiative._id] = initiative._id;

  valueBarDefaultStyles.push({
    key: initiative._id,
    style: {
      y: height + y,
      height: 0,
      x:
        isGroupingAnimation && xPreviousBarPosition
          ? xPreviousBarPosition
          : xPosition,
    },
  });
  valueBarStyles.push({
    key: initiative._id,
    style: {
      height: spring(height, SPRING_CONFIG),
      y: spring(y, SPRING_CONFIG),
      x: spring(xPosition, SPRING_CONFIG),
    },
    data: {
      ...initiative,
      y,
      width: BAR_WIDTH,
      fill: ghostGrey,
    },
  });
  return xPosition;
};

const constructEffortBars = (
  index,
  effortBarDefaultStyles,
  effortBarStyles,
  initiative,
  width,
  bottomBarXPosition,
  bottomYScale,
  isGroupingAnimation,
  viewBy,
  statusList,
  blockers
) => {
  let xPosition = 0;
  const viewByValue = initiative[viewBy];

  const effortBarheight = bottomYScale(viewByValue);

  xPosition =
    index === 0
      ? width + FIRST_BAR_POSITION
      : bottomBarXPosition + NEXT_BAR_POSITION;

  const xPreviousBarPosition = prevBarPositions[initiative._id];
  prevBarPositions[initiative._id] = initiative._id;

  effortBarDefaultStyles.push({
    key: initiative._id,
    style: {
      height: 0,
      x:
        isGroupingAnimation && xPreviousBarPosition
          ? xPreviousBarPosition
          : xPosition,
    },
  });

  const statusItem = statusList.find(x => x.name === initiative.status);

  const status = getInitiativeStatus(
    {
      ref_id: initiative.ref_id,
      status: statusItem ? statusItem._id : -1,
    },
    blockers
  );

  effortBarStyles.push({
    key: initiative._id,
    style: {
      height: spring(effortBarheight, SPRING_CONFIG),
      x: spring(xPosition, SPRING_CONFIG),
    },
    data: {
      ...initiative,
      y: 0,
      width: BAR_WIDTH,
      fill: getInitiativeColor({
        status,
        statusList,
        stage_gate: initiative.stage_gate_id,
        is_blocked: initiative.is_blocked,
        is_shortfall: initiative.is_shortfall,
      }),
    },
  });

  return xPosition;
};

export const constructData = ({
  data,
  topYScale,
  bottomYScale,
  chartHeight,
  isGroupingAnimation,
  groupBy,
  viewBy,
  statusList,
  blockers,
}) => {
  let barXPosition = 0;
  let bottomBarXPosition = 0;
  let width = 0;
  const valueBarStyles = [];
  const valueBarDefaultStyles = [];
  const effortBarDefaultStyles = [];
  const effortBarStyles = [];
  const groupSeparateLines = [];

  data.forEach(group => {
    const { name, initiatives } = group;
    groupSeparateLines.push({
      _id: name,
      //TODO group by name should not null, the api should return null but not string null
      name: name !== 'null' && groupBy !== NONE_GROUP_OPTION.value ? name : '',
      left: width,
    });
    initiatives.forEach((initiative, index) => {
      const newValueBarX = constructValueBars(
        index,
        valueBarDefaultStyles,
        valueBarStyles,
        initiative,
        width,
        chartHeight,
        barXPosition,
        topYScale,
        isGroupingAnimation
      );
      barXPosition = newValueBarX;

      const newEffortBarX = constructEffortBars(
        index,
        effortBarDefaultStyles,
        effortBarStyles,
        initiative,
        width,
        bottomBarXPosition,
        bottomYScale,
        isGroupingAnimation,
        viewBy,
        statusList,
        blockers
      );
      bottomBarXPosition = newEffortBarX;
    });

    const groupWidth =
      FIRST_BAR_POSITION +
      LAST_BAR_RIGHT_POSIION +
      group.initiatives.length * NEXT_BAR_POSITION;

    //here making sure the min width of each group is at least 10 bars
    // 18 + 6 + 10 * 12
    width = groupWidth < 144 ? width + 144 : width + groupWidth;
  });
  return {
    width,
    valueBarDefaultStyles,
    valueBarStyles,
    effortBarDefaultStyles,
    effortBarStyles,
    groupSeparateLines,
  };
};
