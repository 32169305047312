import React, { Component } from 'react';
import LogRocket from 'logrocket';
import { Box } from 'jsxstyle';

export default class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    LogRocket.captureException(error, {
      extra: {
        ...info,
      },
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box width="100%" height="100%">
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            margin="20px 0"
          >
            <div>
              <span style={{ fontSize: '3em' }} role="img" aria-label="problem">
                🔥
              </span>
            </div>
            <h2>Houston, we have a problem</h2>
            <p style={{ textAlign: 'center' }}>
              Brace yourself till we get the error fixed.
              <br />
              You may also refresh the page or try again later.
            </p>
            <p>
              <a href={LogRocket.sessionURL}>{LogRocket.sessionURL}</a>
            </p>
          </Box>
        </Box>
      );
    }

    return this.props.children;
  }
}
