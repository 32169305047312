import { useState, useEffect } from 'react';
import useApiUrl from './useApiUrl';
import useToken from './useToken';

export default function useConfig(key) {
  const [config, setConfig] = useState();
  const token = useToken();
  const apiUrl = useApiUrl();

  useEffect(() => {
    fetch(new URL('/api/configuration/read', apiUrl), {
      method: 'POST',
      headers: {
        Authorization: token,
      },
    })
      .then(rsp => rsp.json())
      .then(data => {
        if (data.ok) {
          setConfig(data.data);
        } else {
          console.log('Broken data');
        }
      })
      .catch(err => console.log(err));
  }, [token, apiUrl, key]);

  return config;
}
