import React from 'react';

export default function useLastEventTime() {
  const [lastEventTime, setLastEventTime] = React.useState(Date.now());
  const url = new URL(process.env.REACT_APP_API_URL);
  url.protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
  url.pathname = '/';
  url.searchParams.append('token', localStorage.getItem('id_token'));

  React.useEffect(() => {
    const ws = new WebSocket(url);

    ws.onmessage = function(event) {
      const msg = JSON.parse(event.data);

      if (msg.type === 'notification') {
        setLastEventTime(Date.now());
      }
    };

    return () => {
      ws.close();
    };
  }, []);

  return lastEventTime;
}
