import React from 'react';
import PropTypes from 'prop-types';
import { scaleLinear } from 'd3-scale';
import { max } from 'd3-array';
import { AxisLeft } from '@vx/axis';
import { GridRows } from '@vx/grid';
import { TransitionMotion } from 'react-motion';
import { progressBarGray, toolbarLink } from '@hero/styles/colors';
import {
  formatNumber,
  NumberFormatContext,
} from '@hero/tfs/src/shared/FormattedNumber';

import { VIEW_BY_OPTIONS } from '@hero/tfs/src/shared/ChartConfiguration';
import QuickInitPanel from '@hero/tfs/src/shared/QuickInitPanel';
import { constructData, clearPreviousPositions } from './utils';
import {
  CHART_SECTION_PADDING_TOP,
  CHART_SECTION_PADDING_BOTTOM,
  CHART_AXIS_LABEL_WIDTH,
  CHART_AXIS_WIDTH,
  CHART_SECTION_PADDING_LEFT,
  Wrapper,
  AxisTypes,
  AxisType,
  AxisSection,
  ChartWrapper,
  SeparateLine,
  DefaultSeparateLine,
  GroupText,
  Rect,
} from './EffortValue.styles';

const HEIGHT_OFFSET = 6;
const WIDTH_FIX = 10;
const TOP_CHART_MARGIN = {
  top: 0,
  right: 40,
  bottom: 10,
  left: CHART_AXIS_WIDTH,
};
const BOTTOM_CHART_MARGIN = {
  top: 6,
  right: 40,
  bottom: 20,
  left: CHART_AXIS_WIDTH,
};

class EffortValue extends React.Component {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        initiatives: PropTypes.array.isRequired,
      })
    ).isRequired,
    tfs: PropTypes.shape({
      currency_unit: PropTypes.string.isRequired,
      time_unit: PropTypes.string.isRequired,
      configuration: PropTypes.shape({
        status: PropTypes.arrayOf(
          PropTypes.shape({
            _id: PropTypes.string,
            name: PropTypes.string,
          })
        ),
      }),
    }).isRequired,
    currencyType: PropTypes.string.isRequired,
    groupBy: PropTypes.string.isRequired,
    viewBy: PropTypes.string.isRequired,
  };

  static contextType = NumberFormatContext;

  state = {
    isGroupingAnimation: false,
    quickInit: null,
  };

  componentDidUpdate(prevProps) {
    const { groupBy: prevGroupBy, data: prevData } = prevProps;
    const { groupBy, data } = this.props;
    //Collapse animation
    if (prevGroupBy !== groupBy) {
      this.setState({ isGroupingAnimation: true });
    }
    if (prevData !== data) {
      this.setState({ isGroupingAnimation: false });
    }
  }

  render() {
    const { type, unit } = this.context;
    const {
      data,
      height,
      viewBy,
      groupBy,
      width: screenWidth,
      locale,
      blockers,
      tfs,
    } = this.props;

    const { currency_unit, configuration } = tfs;

    const availableWidth =
      screenWidth -
      (CHART_AXIS_WIDTH +
        CHART_AXIS_LABEL_WIDTH +
        WIDTH_FIX +
        CHART_SECTION_PADDING_LEFT); // TODO: fix this, not sure why 10px fix is needed here

    const { isGroupingAnimation, quickInit } = this.state;

    const HEIGHT =
      height -
      CHART_SECTION_PADDING_TOP -
      CHART_SECTION_PADDING_BOTTOM -
      HEIGHT_OFFSET * 2 -
      BOTTOM_CHART_MARGIN.bottom;

    const TOP_Y_MAX_VALUE = max(data, d =>
      max(d.initiatives, initiative => {
        return initiative.outcome_value;
      })
    );
    const BOTTOM_Y_MAX_VALUE = max(data, d =>
      max(d.initiatives, effort => {
        return effort[viewBy];
      })
    );

    const chartSvgHeight = (HEIGHT - HEIGHT_OFFSET) / 2;

    const valueChartHeight =
      chartSvgHeight - (TOP_CHART_MARGIN.top + TOP_CHART_MARGIN.bottom);
    const effortChartHeight =
      chartSvgHeight - (BOTTOM_CHART_MARGIN.top + BOTTOM_CHART_MARGIN.bottom);

    const numTicks = numTicksForHeight(valueChartHeight);

    const topYScale = scaleLinear()
      .domain([0, TOP_Y_MAX_VALUE])
      .range([valueChartHeight, 0]);

    const bottomYScale = scaleLinear()
      .domain([0, BOTTOM_Y_MAX_VALUE || 10])
      .range([0, effortChartHeight]);

    const {
      valueBarDefaultStyles,
      valueBarStyles,
      effortBarDefaultStyles,
      effortBarStyles,
      width,
      groupSeparateLines,
    } = constructData({
      data,
      topYScale,
      bottomYScale,
      chartHeight: valueChartHeight,
      isGroupingAnimation,
      groupBy,
      viewBy,
      statusList: configuration.status,
      blockers,
    });

    const lineWidth = width > availableWidth ? width + 18 : availableWidth;

    return (
      <Wrapper>
        <AxisTypes>
          <AxisType height={chartSvgHeight}>Outcome</AxisType>
          <AxisType height={chartSvgHeight}>
            {VIEW_BY_OPTIONS.find(x => x._id === viewBy).name}
          </AxisType>
        </AxisTypes>
        <AxisSection>
          <svg height={chartSvgHeight} width={TOP_CHART_MARGIN.left}>
            <AxisLeft
              hideZero={true}
              hideTicks={true}
              numTicks={numTicks}
              scale={topYScale}
              top={TOP_CHART_MARGIN.top + TOP_CHART_MARGIN.bottom}
              left={TOP_CHART_MARGIN.left}
              hideAxisLine={true}
              tickLabelProps={(value, index) => ({
                fill: toolbarLink,
                textAnchor: 'end',
                dy: 5,
              })}
              tickFormat={value =>
                formatNumber({
                  value,
                  type,
                  unit,
                  locale,
                })
              }
            />
          </svg>
          <svg height={chartSvgHeight} width={BOTTOM_CHART_MARGIN.left}>
            <AxisLeft
              hideZero={true}
              hideTicks={true}
              numTicks={numTicks}
              hideAxisLine={true}
              scale={bottomYScale}
              top={BOTTOM_CHART_MARGIN.top - 6}
              left={BOTTOM_CHART_MARGIN.left}
              tickLabelProps={(value, index) => ({
                fill: toolbarLink,
                textAnchor: 'end',
                dy: 10,
              })}
              tickFormat={value =>
                formatNumber({
                  value: type === 'time' ? daysToTimeUnit(value, unit) : value,
                  ...(viewBy === 'cost'
                    ? { type: 'currency', unit: currency_unit, locale }
                    : {
                        type: 'time',
                        unit: type === 'time' ? unit : 'Weeks',
                      }),
                })
              }
            />
          </svg>
        </AxisSection>
        {groupSeparateLines.length > 0 && (
          <DefaultSeparateLine height={HEIGHT - HEIGHT_OFFSET} />
        )}
        <ChartWrapper width={availableWidth}>
          <svg height={chartSvgHeight} width={lineWidth}>
            <g
              transform={`translate(${0},${TOP_CHART_MARGIN.top +
                TOP_CHART_MARGIN.bottom})`}
            >
              <GridRows
                scale={topYScale}
                stroke={progressBarGray}
                numTicks={numTicks}
                width={lineWidth}
              />
              <TransitionMotion
                defaultStyles={valueBarDefaultStyles}
                styles={valueBarStyles}
              >
                {mappedStyles => (
                  <React.Fragment>
                    {mappedStyles.map(mappedStyle => {
                      const {
                        key,
                        data: barData,
                        style: { height, y, x },
                      } = mappedStyle;
                      return (
                        <Rect
                          key={key}
                          x={x}
                          y={y}
                          height={height}
                          width={barData.width}
                          fill={barData.fill}
                          rx={1}
                          ry={1}
                          onClick={event => {
                            event.preventDefault();
                            this.handleSetQuickInit(barData);
                          }}
                        />
                      );
                    })}
                  </React.Fragment>
                )}
              </TransitionMotion>
            </g>
          </svg>
          <svg height={chartSvgHeight} width={lineWidth}>
            <g transform={`translate(${0},${BOTTOM_CHART_MARGIN.top})`}>
              <GridRows
                scale={bottomYScale}
                stroke={progressBarGray}
                numTicks={numTicks}
                width={lineWidth}
              />
              <TransitionMotion
                defaultStyles={effortBarDefaultStyles}
                styles={effortBarStyles}
              >
                {mappedStyles => (
                  <React.Fragment>
                    {mappedStyles.map(mappedStyle => {
                      const {
                        key,
                        data: barData,
                        style: { height, x },
                      } = mappedStyle;
                      return (
                        <Rect
                          key={key}
                          x={x}
                          y={barData.y}
                          height={height}
                          width={barData.width}
                          fill={barData.fill}
                          rx={1}
                          ry={1}
                          onClick={event => {
                            event.preventDefault();
                            this.handleSetQuickInit(barData);
                          }}
                        />
                      );
                    })}
                  </React.Fragment>
                )}
              </TransitionMotion>
            </g>
          </svg>
          {groupSeparateLines.map((separateLine, index) => (
            <React.Fragment key={separateLine._id}>
              <SeparateLine
                index={index}
                height={HEIGHT - HEIGHT_OFFSET}
                left={separateLine.left}
              />
              <GroupText left={separateLine.left} title={separateLine.name}>
                {separateLine.name}
              </GroupText>
            </React.Fragment>
          ))}
        </ChartWrapper>
        <QuickInitPanel
          init={quickInit}
          tfs={tfs}
          onClose={() => this.handleSetQuickInit(null)}
        />
      </Wrapper>
    );
  }

  componentWillUnmount() {
    clearPreviousPositions();
  }

  handleSetQuickInit = quickInit => {
    this.setState(prevState => ({
      ...prevState,
      quickInit,
    }));
  };
}

function daysToTimeUnit(days, timeUnit) {
  switch (timeUnit) {
    case 'Days':
      return days;
    case 'Weeks':
      return Math.round(days / 7);
    case 'Months':
      return Math.round(days / 30);
    case 'Years':
      return Math.round(days / 365);
    default:
      return days;
  }
}

function numTicksForHeight(height) {
  if (height <= 200) {
    return 2;
  }
  if (200 < height && height <= 400) {
    return 4;
  }
  if (400 < height && height <= 600) {
    return 6;
  }
  return 8;
}

export default EffortValue;
