import React, { useContext } from 'react';
import { Box, Row, Col } from 'jsxstyle';
import { ScreenClassRender } from 'react-grid-system';

import { Text } from '@hero/styles/typography-v5';
import { bayOfMany, neonCarrot } from '@hero/styles/colors-v4';
import { screenClasses } from '@hero/styles/spacings';

import { useLocale } from '@hero/tfs/src/shared/locale';
import {
  formatNumber,
  NumberFormatContext,
} from '@hero/tfs/src/shared/FormattedNumber';

export default function StatsWidget({
  lineStyle = 'none',
  text,
  value,
  format = true,
  prefix,
}) {
  const { type: formatType, unit } = useContext(NumberFormatContext);
  const [locale] = useLocale();

  return (
    <Row alignItems="center">
      <ScreenClassRender
        render={screenClass => (
          <Col
            minWidth={screenClasses.xl === screenClass ? 'initial' : '110px'}
          >
            <Text
              size="overline"
              textTransform="uppercase"
              marginBottom={lineStyle === 'none' ? 0 : 10}
              maxWidth="56px"
              minWidth="56px"
            >
              {text}
            </Text>
            {lineStyle === 'line' && (
              <Box height="1px" width="56px" backgroundColor={bayOfMany} />
            )}
            {lineStyle === 'dashed' && <PlannedLine />}
            {lineStyle === 'solid' && (
              <Box height="3px" width="56px" backgroundColor={bayOfMany} />
            )}
            {lineStyle === 'projected' && <PlannedLine color={neonCarrot} />}
          </Col>
        )}
      />
      <Text size="h2" marginLeft={16}>
        {format
          ? formatNumber({
              value: value || 0,
              type: formatType,
              unit,
              locale,
              defaultPrefix: prefix,
            })
          : value}
      </Text>
    </Row>
  );
}

export function PlannedLine({ height = '1px', width = 56, color = bayOfMany }) {
  return (
    <svg height={height} width={width}>
      <line
        x1={0}
        y1={0}
        x2={width}
        y2={0}
        stroke={color}
        strokeWidth={2}
        strokeDasharray="4,2"
      />
    </svg>
  );
}

export function ProjectedLine({
  height = '4px',
  width = 56,
  color = neonCarrot,
}) {
  return (
    <svg height={height} width={width}>
      <line
        x1={0}
        y1={0}
        x2={width}
        y2={0}
        stroke={color}
        strokeWidth="3"
        strokeDasharray="1,4"
        strokeLinecap="round"
      />
    </svg>
  );
}
