import React from 'react';
import { Box } from 'jsxstyle';

import Progress from '@hero/core/Progress';
import { Text } from '@hero/styles/typography-v5';
import { romanRed, whiteLilac } from '@hero/styles/colors-v4';

// overall in seconds
export default function BlockerTime({
  Icon,
  title,
  overall,
  min = 0,
  max = 0,
  average = 0,
  latestTime = 0,
  ...props
}) {
  return (
    <Box {...props}>
      <Box
        display="flex"
        alignItems="center"
        color={romanRed}
        paddingBottom="12px"
      >
        <Icon style={{ opacity: '0.8', fontSize: '28px' }} />
        <Text size="h6" as="h6" marginLeft="8px">
          {title}
        </Text>
      </Box>
      <Progress>
        <Progress.Title>Overall</Progress.Title>
        <Progress.Total>{formatDuration(overall)}</Progress.Total>
        <Progress.BgLine background={whiteLilac} />
        <Progress.MainLine background="rgba(49,66,96,0.5)" progress={1} />
      </Progress>
      <Progress>
        <Progress.Title>Average</Progress.Title>
        <Progress.Total>{formatDuration(average)}</Progress.Total>
        <Progress.BgLine background={whiteLilac} />
        <Progress.MainLine
          background="rgba(49,66,96,0.5)"
          progress={overall ? average / overall : 0}
        />
      </Progress>
      <Progress>
        <Progress.Title>Max</Progress.Title>
        <Progress.Total>{formatDuration(max)}</Progress.Total>
        <Progress.BgLine background={whiteLilac} />
        <Progress.MainLine
          background="rgba(49,66,96,0.5)"
          progress={overall ? max / overall : 0}
        />
      </Progress>
      <Progress>
        <Progress.Title>Min</Progress.Title>
        <Progress.Total>{formatDuration(min)}</Progress.Total>
        <Progress.BgLine background={whiteLilac} />
        <Progress.MainLine
          background="rgba(49,66,96,0.5)"
          progress={overall ? min / overall : 0}
        />
      </Progress>
      <Progress marginTop="8px">
        <Progress.Title>Last 30 days</Progress.Title>
        <Progress.Total>{formatDuration(latestTime)}</Progress.Total>
        <Progress.BgLine background={whiteLilac} />
        <Progress.MainLine
          background="rgba(49,66,96,0.5)"
          progress={overall ? latestTime / overall : 0}
        />
      </Progress>
    </Box>
  );
}

export function formatDuration(overall) {
  const days = Math.round(overall / (24 * 60 * 60));
  if (days === 1) {
    return `${days} day`;
  }

  return `${days} days`;
}
