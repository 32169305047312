import React from 'react';
import { Box } from 'jsxstyle';
import { Text } from '@hero/styles/typography-v5';
import Label from '@hero/core/Label';
import Input from '@hero/core/Input';
import { Container, Row, Col } from 'react-grid-system';

export default function ResourceSection({
  initiative,
  resourceTypes,
  onChange,
}) {
  return (
    <Box background="white">
      <Box
        display="flex"
        padding="16px 32px 0 32px"
        justifyContent="space-between"
      >
        <Text size="h4">Resources</Text>
      </Box>

      <Container fluid style={{ padding: '0 32px 24px 32px' }}>
        {resourceTypes.map(({ name, _id }) => (
          <Row key={_id}>
            <Col xs={12} md={6} lg={4}>
              <Label htmlFor={`init-resource-name-${_id}`}>Resource type</Label>
              <div>
                <Input
                  name={name}
                  readOnly
                  id={`init-resource-name-${_id}`}
                  placeholder="Enter value…"
                  defaultValue={name}
                  width="100%"
                  form="initiative-form"
                />
              </div>
            </Col>

            <Col xs={12} md={6} lg={4}>
              <Label htmlFor={`required-capacity-${_id}`} optional>
                Required capacity
              </Label>
              <div>
                <Input
                  id={`required-capacity-${_id}`}
                  type="number"
                  name="require_capacity"
                  placeholder="Enter value…"
                  value={
                    initiative.resources[_id]
                      ? initiative.resources[_id].require_capacity
                      : ''
                  }
                  onChange={e =>
                    onChange({
                      resources: {
                        ...initiative.resources,
                        [_id]: {
                          ...initiative.resources[_id],
                          require_capacity: Number(e.target.value),
                        },
                      },
                    })
                  }
                  width="100%"
                  form="initiative-form"
                />
              </div>
            </Col>

            <Col xs={12} md={6} lg={4}>
              <Label htmlFor={`available-capacity-${_id}`} optional>
                Available capacity
              </Label>
              <div>
                <Input
                  id={`available-capacity-${_id}`}
                  type="number"
                  name="available_capacity"
                  placeholder="Enter value…"
                  value={
                    initiative.resources[_id]
                      ? initiative.resources[_id].available_capacity
                      : ''
                  }
                  onChange={e =>
                    onChange({
                      resources: {
                        ...initiative.resources,
                        [_id]: {
                          ...initiative.resources[_id],
                          available_capacity: Number(e.target.value),
                        },
                      },
                    })
                  }
                  width="100%"
                  form="initiative-form"
                />
              </div>
            </Col>
          </Row>
        ))}
      </Container>
    </Box>
  );
}
