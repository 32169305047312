import styled, { css } from 'styled-components/macro';
import moment from 'moment';
import { bayOfMany, pattensBlue } from '@hero/styles/colors-v4';
import { startOf } from '@hero/tfs/src/09-initiatives-editor/shared';

//TODO: Need to sortout all the z-index positions used in the gantt so that we have list in once place
export const ZINDEXS = {
  budgetBar: 2,
  outcomeLine: 3,
  sideBar: 4,
  axis: 5,
};

export const SCALE_SIZE_BY_VIEW = {
  all: 60,
  months: 100,
  weeks: 60,
};

export const ROW_PADDING_OFFSET = 24; //top and bottom padding of UL & ULRow
export const ROW_TOP_PADDING = 5;

export const GroupWrapper = styled.div``;

export const UL = styled.ul`
  position: relative;
  min-height: ${props => props.height}px;
  margin: 0;
  list-style: none;
  padding: ${ROW_TOP_PADDING}px 8px 15px 16px;
`;

export const ULRow = styled(UL)`
  padding: ${ROW_TOP_PADDING}px 0 24px 0;
`;

export const GroupTitle = styled.div`
  padding: 8px 16px;
  width: 100%;
  margin-bottom: 8px;
  padding-bottom: 6px;
  font-size: 15px;
  font-weight: bold;
  color: ${bayOfMany};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
  text-transform: uppercase;
  background-color: ${pattensBlue};
`;

export const GroupTitleRow = styled(GroupTitle)`
  min-height: 34px;
  max-width: 100%;
`;

export const StripeLabel = styled.div`
  font-size: 15px;
  line-height: 16px;
  vertical-align: top;
  color: ${bayOfMany};
  position: relative;
  left: -404px;
  width: 400px;
  text-align: right;
  cursor: default;
  user-select: none;
  display: ${props => (props.hidden ? 'none' : 'block')};
`;

export const HoverCircleCSS = css`
  &::after {
    visibility: hidden;
    content: '';
    position: absolute;
    top: -5px;
    box-shadow: 0 0 0 1px ${bayOfMany};
    background-color: white;
    border-radius: 50%;
    width: 9px;
    height: 9px;
    transition: visibility 0.1s;
    z-index: ${ZINDEXS.outcomeLine + 1};
  }
`;

export const ShowHoverCrcleCss = css`
  &::after {
    visibility: visible;
  }
`;

export const DAYS_IN_VIEW = {
  months: 30,
  weeks: 7,
  days: 1,
};
export const HOURS_IN_DAY = 24;

export function ganttRowDimensions({
  view,
  startDate = new Date(),
  endDate = new Date(),
  leftMargin,
  zeroDate,
  scaleSize,
  periodMultiplier,
  animated,
}) {
  const posFix = view === 'months' ? 0.033 : 0;
  const amountOfPeriodsInAxis =
    Math.abs(zeroDate.diff(moment(startDate).startOf('day'), view, true)) +
    posFix;

  const leftOffset =
    leftMargin + (amountOfPeriodsInAxis * scaleSize) / periodMultiplier;
  const duration =
    Math.abs(
      moment(startDate)
        .startOf('day')
        .diff(moment(endDate).endOf('day'), view, true)
    ) - posFix;
  let width = animated ? (duration * scaleSize) / periodMultiplier : 10;
  // in some cases duration is very small and the width becomes less than 10px
  width = width < 10 ? 10 : width;
  return {
    leftOffset,
    width,
  };
}

export function formatDate(date, format = 'MMM DD, YYYY') {
  return moment(date).format(format);
}

export function calculateMovedDates({
  date1,
  date2,
  prevX,
  x,
  view,
  scaleSize,
  periodMultiplier,
  scrollAmount,
}) {
  const dragDelta = x - prevX;
  const scrollDelta = x > window.innerWidth / 2 ? scrollAmount : -scrollAmount;
  const dist = dragDelta + scrollDelta;
  const dateDiff =
    (dist / scaleSize) * periodMultiplier * DAYS_IN_VIEW[view] * HOURS_IN_DAY;

  const newDates = {};
  if (date1) {
    newDates.newDate1 = moment(date1)
      .clone()
      .add(dateDiff, 'hours');
  }
  if (date2) {
    newDates.newDate2 = moment(date2)
      .clone()
      .add(dateDiff, 'hours');
  }
  return newDates;
}

const periodsForMultiplier = {
  1: 'months',
  3: 'quarters',
  6: 'halfyears',
  12: 'years',
};

export function getGanttAxisData({ minDate, maxDate, periodMultiplier, view }) {
  const axisStartDate = startOf(
    moment(minDate),
    view === 'all' ? 'yearly' : view
  );

  let currentDate = axisStartDate.clone();

  const result = [];

  const period = view === 'all' ? periodsForMultiplier[periodMultiplier] : view;

  while (currentDate.isBefore(maxDate)) {
    result.push(currentDate.format());

    switch (period) {
      case 'weeks':
        currentDate = currentDate.add(1, 'week');
        break;
      case 'months':
        currentDate = currentDate.add(1, 'month');
        break;
      case 'quarters':
        currentDate = currentDate.add(1, 'Q');
        break;
      case 'halfyears':
        currentDate = currentDate.add(6, 'month');
        break;
      case 'years':
        currentDate = currentDate.add(1, 'year');
        break;
      default:
        currentDate = currentDate.add(1, 'month');
    }
  }

  return {
    axisData: result.map((date, index) => ({
      date,
      ...axisInfo(moment(date), period),
      yearLabel: moment(date)
        .add(1, 'year')
        .startOf('year')
        .format('YYYY'),
      count: index,
    })),
    axisStartDate,
    period,
  };
}

function axisInfo(date, period) {
  switch (period) {
    case 'weeks':
      return {
        endOfYear: date.week() === moment(date).weeksInYear(),
        label: `W${date.week()}`,
      };

    case 'months':
      return {
        endOfYear: date.month() === 11,
        label: date.format('MMM'),
      };

    case 'quarters':
      return {
        endOfYear: date.quarter() === 4,
        label: `Q${date.quarter()}`,
      };
    case 'halfyears':
      return {
        endOfYear: date.month() === 6,
        label: `H${date.month() === 0 ? 1 : 2}`,
      };
    case 'years':
      return {
        endOfYear: false,
        label: date.format('YYYY'),
      };
    default:
      return date.month() === 11;
  }
}
