import React from 'react';
import omit from 'lodash/omit';

import SearchParams from '@hero/tfs/src/shared/SearchParams';
import SearchInput from '@hero/tfs/src/shared/SearchInput';

export default function ListSearchInput() {
  return (
    <SearchParams>
      {({ params, setParams }) => (
        <SearchInput
          name="search"
          defaultValue={params.filter}
          placeholder="Search by title, lead, etc.,"
          type="text"
          style={{ width: '280px' }}
          containerProps={{
            marginRight: 32,
          }}
          onChange={event => {
            const filter = event.target.value;

            if (!filter) {
              return setParams(p => omit(p, 'filter'));
            }

            setParams(p => ({ ...p, filter }));
          }}
        />
      )}
    </SearchParams>
  );
}
