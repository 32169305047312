import { defineMessages } from 'react-intl';

export default defineMessages({
  pageTitle: {
    id: 'Login.title',
    description: 'Page title for Login Page',
    defaultMessage: 'hero - login',
  },
  Login: {
    id: 'Login.login',
    description: 'Login tab name',
    defaultMessage: 'hero- login',
  },
  Join: {
    id: 'Login.join',
    description: 'Login tab name',
    defaultMessage: 'hero - join',
  },
  Forgot: {
    id: 'Login.forgot',
    description: 'Login tab name',
    defaultMessage: 'hero - forgot Password',
  },
});
