import React, { Fragment, useContext, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Input from '@hero/core/Input';
import CurrencyInput from '@hero/tfs/src/shared/CurrencyInput';
import { llato } from '@hero/styles/typography';
import {
  TIME_TARGET_TYPE,
  FINANCE_TARGET_TYPE,
} from '@hero/tfs/src/shared/targetTypes';
import { useLocale } from '@hero/tfs/src/shared/locale';

const TimeUnitText = styled.label`
  ${llato};
  color: inherit;
  font-weight: bold;
  line-height: 30px;
  display: inline-block;
  padding-left: 6px;
  font-weight: normal;
`;

const PREFIX = {
  K: 1000,
  M: 1000000,
  B: 1000000000,
};

const DAYS = 'Days';
const WEEKS = 'Weeks';
const MONTHS = 'Months';
const YEARS = 'Years';
const TIME_UNIT_LABELS = {
  [DAYS]: 'Day',
  [WEEKS]: 'Week',
  [MONTHS]: 'Month',
  [YEARS]: 'Year',
};

function pluralize(number, label) {
  return number > 1 || number === 0 ? label + 's' : label;
}

export function getPrefix(value) {
  if (value < PREFIX.K) {
    return '';
  } else if (value < PREFIX.M) {
    return 'K';
  } else if (value < PREFIX.B) {
    return 'M';
  } else {
    return 'B';
  }
}

export function formatNumber({ value, type, defaultPrefix, unit, locale }) {
  let prefix;

  if (defaultPrefix) {
    prefix = defaultPrefix;
  } else {
    prefix = getPrefix(value);
  }

  const displayValue = value / (PREFIX[prefix] || 1);

  if (type === 'time') {
    const formatter = new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: prefix !== '' ? 2 : 0,
    });

    return `${formatter.format(displayValue)}${prefix} ${pluralize(
      value,
      TIME_UNIT_LABELS[unit]
    )}`;
  }

  if (type === 'currency') {
    let formatConfig = {
      style: 'currency',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      currency: unit,
    };
    if (prefix === 'M' || prefix === 'B') {
      formatConfig = {
        ...formatConfig,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      };
    }
    const formatter = new Intl.NumberFormat(locale, formatConfig);

    const parts = formatter.formatToParts(displayValue);

    if (parts[0].type === 'currency') {
      parts.push({
        type: 'prefix',
        value: prefix,
      });
    } else {
      parts.splice(parts.findIndex(x => x.type === 'currency') - 1, 0, {
        type: 'prefix',
        value: prefix,
      });
    }

    return parts.map(x => x.value).join('');
  }

  if (type === 'percent') {
    const formatter = new Intl.NumberFormat(locale, {
      style: 'percent',
      minimumFractionDigits: 1,
    });

    return `${formatter.format(displayValue)}${prefix}`;
  }

  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return `${formatter.format(displayValue)}${prefix} ${unit}`;
}

export const NumberFormatContext = React.createContext({
  type: TIME_TARGET_TYPE,
  unit: 'Weeks',
});

export default function FormattedNumber({
  as: Component,
  value,
  defaultPrefix,
  format,
  splitUnit,
  ...props
}) {
  const { type, unit, locale } = {
    locale: useLocale()[0],
    ...useContext(NumberFormatContext),
    ...format,
  };

  if (typeof props.children === 'function') {
    return props.children(
      formatNumber({ value, type, unit, locale, defaultPrefix })
    );
  } else {
    return (
      <Component {...props}>
        {splitUnit ? (
          <CompoundNumber
            text={formatNumber({ value, type, unit, locale, defaultPrefix })}
          />
        ) : (
          formatNumber({ value, type, unit, locale, defaultPrefix })
        )}
      </Component>
    );
  }
}

FormattedNumber.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  format: PropTypes.shape({
    type: PropTypes.string,
    unit: PropTypes.string,
    locale: PropTypes.string,
  }),
  splitUnit: PropTypes.bool,
  defaultPrefix: PropTypes.string,
};

FormattedNumber.defaultProps = {
  as: 'span',
  splitUnit: false,
};

function CompoundNumber({ text }) {
  const [number, unit] = text.split(' ');

  return (
    <span data-compound-number="">
      <span data-compound-value="">{number}</span>{' '}
      <span data-compound-unit="">{unit}</span>
    </span>
  );
}

FormattedNumber.Input = function FormattedNumberInput({
  format,
  autoResize,
  onClick,
  ...props
}) {
  const { type, unit } = {
    ...useContext(NumberFormatContext),
    ...format,
  };

  function handleClick(e) {
    document.execCommand('selectall', null, false);
    onClick && onClick(e);
  }

  if (type === 'currency') {
    return <CurrencyInput currency={unit} onClick={handleClick} {...props} />;
  }

  return (
    <UnitInput
      unit={unit}
      autoResize={autoResize}
      onClick={handleClick}
      {...props}
    />
  );
};

FormattedNumber.Input.propTypes = {
  showUnit: PropTypes.bool,
  format: PropTypes.shape({
    type: PropTypes.oneOf(['currency', 'time', 'percent', 'custom']),
    unit: PropTypes.string,
  }),
  autoResize: PropTypes.bool,
};

FormattedNumber.Input.defaultProps = {
  showUnit: true,
  autoResize: false,
};

function UnitInput({ unit, autoResize, onChange, ...props }) {
  // resize input element to content width
  const inputRef = useRef(null);

  useLayoutEffect(() => {
    if (autoResize) {
      inputRef.current.style.width =
        getTextWidth(props.value, window.getComputedStyle(inputRef.current)) +
        8 +
        'px';
    }
  });

  function handleChange(e) {
    const value = e.target.value.trim();
    const number = value ? parseInt(value, 10) : 0;

    if (!isNaN(number)) {
      onChange({
        ...e,
        target: {
          ...e.target,
          value: number,
        },
      });
    }
  }

  return (
    <Fragment>
      <Input ref={inputRef} type="text" onChange={handleChange} {...props} />
      {props.showUnit && (
        <TimeUnitText>{String(unit).toLowerCase()}</TimeUnitText>
      )}
    </Fragment>
  );
}

FormattedNumber.TfsProvider = function FormattedNumberProvider({
  tfs,
  children,
}) {
  return (
    <NumberFormatContext.Provider
      value={FormattedNumber.getNumberFormat(tfs)}
      children={children}
    />
  );
};

FormattedNumber.CurrencyProvider = function({ unit, children }) {
  return (
    <NumberFormatContext.Provider
      value={{ type: 'currency', unit }}
      children={children}
    />
  );
};

FormattedNumber.getNumberFormat = function(tfs) {
  let type = 'custom';
  let unit = '';

  switch (tfs.target_type) {
    case TIME_TARGET_TYPE:
      type = 'time';
      unit = tfs.time_unit;
      break;
    case FINANCE_TARGET_TYPE:
      type = 'currency';
      unit = tfs.currency_unit;
      break;
    default:
      const targetTypes = tfs.configuration.custom_target_types || [];
      const custom = targetTypes.find(({ _id }) => tfs.target_type === _id);
      type = 'custom';
      unit = custom ? custom.name : '';
  }

  return { type, unit };
};

export function getFormatForMetric(metric, currencyType) {
  if (metric === 'currency') {
    return {
      type: 'currency',
      unit: currencyType,
    };
  }

  return null;
}

export function getFormatForSize(size, currencyType) {
  if (size === 'cost') {
    return {
      type: 'currency',
      unit: currencyType,
    };
  }

  return null;
}

let canvas = null;

function getTextWidth(text, { font }) {
  if (!canvas) {
    canvas = document.createElement('canvas');
  }

  const context = canvas.getContext('2d');
  context.font = font;
  canvas.style.letterSpacing = '1px';
  const metrics = context.measureText(text);
  return metrics.width;
}
