import React from 'react';
import { Box } from 'jsxstyle';

import { Container, Row, Col, Visible } from 'react-grid-system';

import { Divider } from './shared';

import {
  PlaceholderLoader,
  DropdownLoader,
  UserLoader,
} from '@hero/tfs/src/shared/Loaders';

export function UserSelectLoader() {
  return (
    <Box height="156px" backgroundColor="white" padding="24px 32px">
      <PlaceholderLoader type="header" width="340px" />
      <Box display="flex">
        <PlaceholderLoader type="input" marginRight="20px" />
        <PlaceholderLoader type="input" marginRight="20px" />
        <PlaceholderLoader type="input" marginRight="20px" />
      </Box>
    </Box>
  );
}

export default function InitiativeLoader() {
  return (
    <Container fluid style={{ padding: 0, width: '100%' }}>
      <Row style={{ marginBottom: 50 }}>
        <Col sm={12} lg={4}>
          <Box height="700px" backgroundColor="white" padding="24px 32px">
            <PlaceholderLoader type="title" marginBottom="24px" />
            <PlaceholderLoader type="description" marginBottom="32px" />
            <DropdownLoader width="55%" />
            <DropdownLoader width="70%" />
            <DropdownLoader width="35%" />
            <DropdownLoader width="45%" marginBottom="32px" />
            <UserLoader width="45%" />
            <UserLoader width="55%" />
            <UserLoader width="40%" />
          </Box>
        </Col>
        <Col sm={12} lg={8}>
          <Visible xs sm md>
            <Divider />
          </Visible>
          <Box height="196px" backgroundColor="white" padding="24px 32px">
            <PlaceholderLoader type="header" />
            <Box display="flex">
              <Box marginRight="20px" width="40%">
                <PlaceholderLoader
                  type="input"
                  width="90%"
                  marginBottom="9px"
                />
                <PlaceholderLoader type="input" width="85%" />
              </Box>
              <PlaceholderLoader type="chart" />
            </Box>
          </Box>
          <Divider />
          <Box height="196px" backgroundColor="white" padding="24px 32px">
            <PlaceholderLoader type="header" />
            <Box display="flex">
              <Box marginRight="20px" width="40%">
                <PlaceholderLoader
                  type="input"
                  width="45%"
                  marginBottom="9px"
                />
                <PlaceholderLoader type="input" width="65%" />
              </Box>
              <PlaceholderLoader type="chart" />
            </Box>
          </Box>
          <Divider />
          <Box height="760px" backgroundColor="white" padding="24px 32px">
            <PlaceholderLoader type="header" width="500px" />
            <Box display="flex">
              <PlaceholderLoader type="input" marginRight="20px" />
              <PlaceholderLoader type="button" />
            </Box>
            <PlaceholderLoader type="riskRow" />
            <PlaceholderLoader type="blockerRow" />
            <PlaceholderLoader type="riskRow" />
            <PlaceholderLoader type="blockerRow" />
          </Box>
          <Divider />

          <Box height="156px" backgroundColor="white" padding="24px 32px">
            <PlaceholderLoader type="header" width="340px" />
            <Box display="flex">
              <PlaceholderLoader type="input" marginRight="20px" />
              <PlaceholderLoader type="input" marginRight="20px" />
              <PlaceholderLoader type="input" marginRight="20px" />
            </Box>
          </Box>
        </Col>
      </Row>
    </Container>
  );
}
