import React, { Component } from 'react';
import moment from 'moment';
import { Box } from 'jsxstyle';
import { chain } from 'lodash';
import styled from 'styled-components/macro';

import { silverChalice } from '@hero/styles/colors-v4';
import QuickInitPanel from '@hero/tfs/src/shared/QuickInitPanel';

import Axis from './Axis';
import Grid from './Grid';
import GanttChartSidebar from './GanttChartSidebar';
import GanttChartStripe from './GanttChartStripe';
import {
  SCALE_SIZE_BY_VIEW,
  ROW_PADDING_OFFSET,
  GroupWrapper,
  GroupTitleRow,
  ULRow,
  getGanttAxisData,
} from './shared';
import OutcomeLine from './OutcomeLine';

const EXTRA_CHUNKS_COUNT = 2;
const DEFAULT_CHART_HEIGHT = 330; // height of the top chart
const MONTHS_IN_YEAR = 12;

const LEFT_MARGIN = 180;
const ROW_HEIGHT = 25;
const SIDEBAR_WIDTH = 180;
const AXIS_HEIGHT = 48;
const MENU_WIDTH = 200;
const MENU_MARGIN = 32;
const SCROLL_STEP = 15;
const RIGHT_SCROLL_START = window.innerWidth - 90;
const LEFT_SCROLL_START = MENU_WIDTH + MENU_MARGIN + LEFT_MARGIN + 30;

export const VIEWS_ENUM = {
  months: 'months',
  weeks: 'weeks',
  all: 'all',
};

const getChartMinWidth = view => {
  return SCALE_SIZE_BY_VIEW[view] * (EXTRA_CHUNKS_COUNT + 1);
};

const TimeLine = styled.div`
  background-color: red;
  height: 100%;
  left: ${props => props.left || 0};
  position: absolute;
  width: 1px;
  opacity: 0.5;
`;

const ArrowBlock = styled.span`
  position: absolute;
  left: -15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 14px 6px 0;
  border-color: transparent rgba(224, 224, 224, 1); transparent transparent;
`;

const DateHintBlock = styled.div`
  align-items: center;
  background-color: rgba(224, 224, 224, 0.4);
  border: 1px solid ${silverChalice};
  border-radius: 5px;
  bottom: 1px;
  display: flex;
  font-family: Lato;
  font-size: 12px;
  height: 20px;
  left: ${props => props.left || 0};
  opacity: 0.5;
  position: absolute;
  width: 135px;
  z-index: 2;
  bottom: -5px;
`;

let currentPosition = 0;

export default class GanttChart extends Component {
  state = {
    size: DEFAULT_CHART_HEIGHT,
    data: this.props.data,
    isDragging: false,
    scrollAmount: 0,
    quickInit: null,
    stripId: null,
  };

  scrollContainer = React.createRef();

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({ data: this.props.data });
    }
  }

  componentDidMount() {
    const container = this.scrollContainer.current;
    container.scrollLeft = currentPosition - container.clientWidth / 3;
  }

  handleSetQuickInit = quickInit => {
    this.setState(prevState => ({
      ...prevState,
      quickInit,
    }));
  };

  render() {
    const {
      view: originalView,
      width: screenWidth,
      tfs,
      blockers,
    } = this.props;

    const { ref_id: tfsId, configuration } = tfs;
    const { status } = configuration;

    const { scrollLeft, quickInit } = this.state;

    const originalData = this.state.data || [];
    const {
      minDate,
      maxDate,
      axisMinDate,
      axisWidth,
      periodMultiplier,
      amountOfPeriodsInAxis,
      view,
    } = this.getDatePeriod(originalData, originalView, screenWidth);

    const data = this.getDataWithFixesForUnassignedDates(
      originalData,
      minDate,
      maxDate
    );

    const { axisData, axisStartDate, period } = getGanttAxisData({
      minDate: axisMinDate,
      maxDate,
      periodMultiplier,
      view: originalView,
    });

    const today = new Date();
    // As current day is not considered we need to add 0.033 (1/30 of the month).
    const posFix = view === 'months' ? 0.033 : 0;
    const periodsPassed =
      Math.abs(axisStartDate.diff(moment(today).startOf('day'), view, true)) +
      posFix;

    currentPosition =
      (periodsPassed * SCALE_SIZE_BY_VIEW[view]) / periodMultiplier;

    return (
      <Box
        position="relative"
        overflowY="auto"
        width="100%"
        height="100%"
        padding="10px 24px"
      >
        <Box
          overflowX="auto"
          width="100%"
          height="100%"
          props={{
            onScroll: this.onScroll,
            onMouseMove: this.onMouseMove,
            ref: this.scrollContainer,
          }}
        >
          <Box
            position="relative"
            fontSize="14px"
            lineHeight="20px"
            width={axisWidth + SIDEBAR_WIDTH}
            display="flex"
            flexWrap="wrap"
          >
            <Axis
              axisData={axisData}
              width={axisWidth}
              view={view}
              period={period}
              periodMultiplier={periodMultiplier}
              zeroDate={axisMinDate}
              scaleSize={SCALE_SIZE_BY_VIEW[view]}
              amountOfPeriodsInAxix={amountOfPeriodsInAxis}
              sidebarWidth={SIDEBAR_WIDTH}
              axisHeight={AXIS_HEIGHT}
              scrollLeft={scrollLeft}
            />

            <Grid
              axisData={axisData}
              width={axisWidth}
              scaleSize={SCALE_SIZE_BY_VIEW[view]}
              amountOfPeriodsInAxis={amountOfPeriodsInAxis}
              sidebarWidth={SIDEBAR_WIDTH}
              axisHeight={AXIS_HEIGHT}
            />

            <GanttChartSidebar
              tfsId={tfsId}
              data={data}
              view={view}
              periodMultiplier={periodMultiplier}
              zeroDate={axisMinDate}
              scaleSize={SCALE_SIZE_BY_VIEW[view]}
              scrollLeft={scrollLeft}
              leftMargin={LEFT_MARGIN}
              rowHeight={ROW_HEIGHT}
              width={SIDEBAR_WIDTH}
              stripId={this.state.stripId}
              onInitiativeClick={this.handleSetQuickInit}
              onMouseOver={id => this.setHoverStrip(id)}
              onMouseOut={() => {
                this.setHoverStrip(null);
              }}
            />

            <Box width={`calc(100% - ${SIDEBAR_WIDTH}px)`} position="relative">
              <TimeLine left={currentPosition + 'px'} />
              <DateHintBlock
                className="date-box"
                left={currentPosition + 20 + 'px'}
              >
                <ArrowBlock />
                <Box margin="0 auto">
                  {today.toLocaleString(undefined, {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric',
                  })}
                </Box>
              </DateHintBlock>
              {data.map(dataGroup => (
                <GroupWrapper key={dataGroup.label}>
                  <GroupTitleRow />

                  <ULRow
                    height={
                      (dataGroup.data.length * ROW_HEIGHT || 0) +
                      ROW_PADDING_OFFSET
                    }
                  >
                    {dataGroup.data.map((item, index) => (
                      <React.Fragment key={item.ref_id}>
                        <GanttChartStripe
                          orderIndex={index}
                          data={item}
                          tfs={tfs}
                          zeroDate={axisStartDate}
                          view={view}
                          scaleSize={SCALE_SIZE_BY_VIEW[view]}
                          periodMultiplier={periodMultiplier}
                          leftMargin={0}
                          onInitiativeClick={this.handleSetQuickInit}
                          onInitiaveUpdate={this.props.onInitiaveUpdate}
                          liveUpdate={this.liveUpdate}
                          scrollLeft={scrollLeft}
                          sidebarWidth={SIDEBAR_WIDTH}
                          statusList={status}
                          onDragStart={this.onDragStart}
                          onDragEnd={this.onDragEnd}
                          scrollAmount={this.state.scrollAmount}
                          blockers={blockers}
                          stripId={this.state.stripId}
                          onMouseOver={() => {
                            this.setHoverStrip(`bar-${item.ref_id}`);
                          }}
                          onMouseOut={() => {
                            this.setHoverStrip(null);
                          }}
                        />
                        <OutcomeLine
                          key={`outcome-${item.ref_id}`}
                          orderIndex={index}
                          data={item}
                          tfs={tfs}
                          zeroDate={axisStartDate}
                          view={view}
                          scaleSize={SCALE_SIZE_BY_VIEW[view]}
                          periodMultiplier={periodMultiplier}
                          leftMargin={0}
                          onInitiativeClick={this.handleSetQuickInit}
                          onInitiaveUpdate={this.props.onInitiaveUpdate}
                          liveUpdate={this.liveUpdate}
                          scrollLeft={scrollLeft}
                          sidebarWidth={SIDEBAR_WIDTH}
                          onDragStart={this.onDragStart}
                          onDragEnd={this.onDragEnd}
                          scrollAmount={this.state.scrollAmount}
                          onMouseOver={() => {
                            this.setHoverStrip(`line-${item.ref_id}`);
                          }}
                          onMouseOut={() => {
                            this.setHoverStrip(null);
                          }}
                        />
                      </React.Fragment>
                    ))}
                  </ULRow>
                </GroupWrapper>
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          position="absolute"
          top="10px"
          left="10px"
          width={180}
          height={48}
          backgroundColor="white"
          zIndex={2}
        />
        <QuickInitPanel
          init={quickInit}
          tfs={tfs}
          onClose={() => this.handleSetQuickInit(null)}
        />
      </Box>
    );
  }

  scrollTimeout = null;
  onScroll = e => {
    this.storedScrollPosition = {
      scrollLeft: e.target.scrollLeft,
    };

    this.setState(this.storedScrollPosition);

    // if (this.scrollTimeout) {
    //   return;
    // }

    // this.scrollTimeout = setTimeout(() => {

    //   this.scrollTimeout = null;
    // }, 1);
  };

  onDragStart = () => {
    this.setState({ isDragging: true });
  };

  onDragEnd = () => {
    this.setState({ isDragging: false, scrollAmount: 0 });
  };

  setHoverStrip = stripId => {
    this.setState(state => ({ ...state, stripId }));
  };

  onMouseMove = e => {
    if (!this.state.isDragging) {
      return;
    }
    if (e.clientX > RIGHT_SCROLL_START) {
      e.currentTarget.scrollLeft += SCROLL_STEP;
      this.setState({ scrollAmount: SCROLL_STEP });
    } else if (e.clientX < LEFT_SCROLL_START) {
      e.currentTarget.scrollLeft -= SCROLL_STEP;
      this.setState({ scrollAmount: SCROLL_STEP });
    } else {
      this.setState({ scrollAmount: 0 });
    }
  };

  getDataWithFixesForUnassignedDates(originalData) {
    return originalData.map(dataGroup => {
      const data = dataGroup.data.map(item => {
        const fixes = {
          budget_duration: item.budget_duration,
          outcome_duration: item.outcome_duration,
        };

        if (!item.budget_duration[0]) {
          fixes.budget_duration[0] = moment().format();
          fixes.allPeriodItem = true;
        }

        if (!item.budget_duration[1]) {
          fixes.budget_duration[1] = moment().format();
          fixes.allPeriodItem = true;
        }

        if (!item.outcome_duration[0]) {
          fixes.outcome_duration[0] = moment().format();
          fixes.allPeriodItem = true;
        }

        if (!item.outcome_duration[1]) {
          fixes.outcome_duration[1] = moment().format();
          fixes.allPeriodItem = true;
        }

        return { ...item, ...fixes };
      });

      return {
        ...dataGroup,
        data,
      };
    });
  }

  liveUpdate = diffItem => {
    const originalData = this.state.data || [];

    const data = originalData.map(dataGroup => {
      const data = dataGroup.data.map(item => {
        if (item.ref_id === diffItem.ref_id) {
          return { ...item, ...diffItem };
        }

        return item;
      });

      return {
        ...dataGroup,
        data,
      };
    });

    this.setState({ data });
  };

  getMinMaxDates(data) {
    const allDates = chain(data)
      .map('data')
      .flatten()
      .reduce((result, value, key) => {
        result = [
          ...result,
          ...value.outcome_duration,
          ...value.budget_duration,
        ];
        return result;
      }, [])
      .map(d => (d ? moment(d) : moment()))
      .value();

    return { min: moment.min(allDates), max: moment.max(allDates) };
  }

  getDatePeriod(data, originalView, screenWidth) {
    // TODO: rename view -> timeUnitPlural???
    const view =
      originalView === VIEWS_ENUM.all ? VIEWS_ENUM.months : originalView;

    const { min, max } = this.getMinMaxDates(data);

    const timeUnit = this.trimLastChar(view);
    const minDate = moment(min);
    const maxDate = moment(max);
    let axisMinDate = moment(min)
      .subtract(1, view)
      .startOf(timeUnit);
    let axisMaxDate = moment(max)
      .add(1, view)
      .startOf(timeUnit);

    const availableScreenWidth = Math.max(
      screenWidth - SIDEBAR_WIDTH,
      getChartMinWidth(view)
    );

    let periodMultiplier = 1;
    if (originalView === VIEWS_ENUM.all) {
      periodMultiplier = this.getPeriodMultiplier(
        availableScreenWidth,
        axisMinDate,
        axisMaxDate,
        view
      );
    }

    const minAmountOfPeriodsInAxisBasedOnScreenWidth = Math.floor(
      availableScreenWidth / SCALE_SIZE_BY_VIEW[view]
    );

    const amountOfPeriodsInAxisBasedOnTimePeriod = this.getAmountOfPeriodsInAxis(
      axisMinDate,
      axisMaxDate,
      view,
      periodMultiplier
    );

    let amountOfPeriodsInAxis = amountOfPeriodsInAxisBasedOnTimePeriod;
    if (
      minAmountOfPeriodsInAxisBasedOnScreenWidth >
      amountOfPeriodsInAxisBasedOnTimePeriod
    ) {
      amountOfPeriodsInAxis = minAmountOfPeriodsInAxisBasedOnScreenWidth;
      // recalculate axisMaxDate
      axisMaxDate = axisMinDate
        .clone()
        .add(amountOfPeriodsInAxis * periodMultiplier, view);
    } else if (originalView !== VIEWS_ENUM.all) {
      // add few more months/weeks to the end of the scale to make drag to expand easier
      amountOfPeriodsInAxis =
        amountOfPeriodsInAxisBasedOnTimePeriod + EXTRA_CHUNKS_COUNT;
      // recalculate axisMaxDate
      axisMaxDate = axisMinDate
        .clone()
        .add(amountOfPeriodsInAxis * periodMultiplier, view);
    }

    const chartAreaWidth = amountOfPeriodsInAxis * SCALE_SIZE_BY_VIEW[view];

    return {
      minDate,
      maxDate,
      axisMinDate,
      axisMaxDate,
      axisWidth: chartAreaWidth,
      amountOfPeriodsInAxis,
      periodMultiplier,
      view,
    };
  }

  scales = [1, 3, 6, 12];
  getPeriodMultiplier(availableScreenWidth, zeroDate, endDate, view) {
    const scales = this.scales;

    let scale = scales[0];
    let i = 0;
    while (
      availableScreenWidth <
      this.getAmountOfPeriodsInAxis(zeroDate, endDate, view, scale) *
        SCALE_SIZE_BY_VIEW[view]
    ) {
      i += 1;
      scale = scales[i];

      if (!scale) {
        const years = i - scales.length + 2;
        scale = years * MONTHS_IN_YEAR;
      }
    }

    return scale;
  }

  getAmountOfPeriodsInAxis(axisMinDate, axisMaxDate, view, periodMultiplier) {
    return Math.abs(
      Math.ceil(axisMaxDate.diff(axisMinDate, view, true) / periodMultiplier)
    );
  }

  trimLastChar(str) {
    return str && str.length ? str.slice(0, -1) : '';
  }
}
