import React from 'react';
import PropTypes from 'prop-types';
import { Motion, spring } from 'react-motion';
import { Point } from '@vx/point';
import { Line } from '@vx/shape';
import { lineColor } from '@hero/styles/colors';

export default function TargetLine({ width, target, y }) {
  if (!target) {
    return null;
  }

  return (
    <Motion
      defaultStyle={{ x: 0, y: 0 }}
      style={{
        x: spring(width, {
          stiffness: 80,
          damping: 17,
        }),
        y: spring(y - 2, {
          stiffness: 80,
          damping: 17,
        }),
      }}
    >
      {style => (
        <Line
          stroke={lineColor}
          strokeWidth={1}
          strokeDasharray="4 3"
          from={new Point({ x: 0, y: style.y })}
          to={new Point({ x: style.x, y: style.y })}
        />
      )}
    </Motion>
  );
}

TargetLine.propTypes = {
  width: PropTypes.number.isRequired,
  target: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
};
