import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'jsxstyle';
import Fuse from 'fuse.js';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import groupBy from 'lodash/groupBy';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';

import { getInitiativeColor } from '@hero/styles/colors';
import { greenCarribean, santasGrey } from '@hero/styles/colors-v4';

import sort from '@hero/tfs/src/shared/sorting';
import Fetch from '@hero/tfs/src/shared/Fetch';
import { useLocale } from '@hero/tfs/src/shared/locale';
import useInitiativeRouting from '@hero/tfs/src/shared/hooks/useInitiativeRouting';
import useInitiativesByTfs from '@hero/tfs/src/shared/hooks/useInitiativesByTfs';
import {
  TableScrollWrapper,
  TableHeadRow,
  TableRow,
  TableCell,
  TableMessageCell,
  styles,
} from '@hero/tfs/src/shared/table';
import { getInitiativeStatus } from '@hero/tfs/src/09-initiatives-editor/shared';
import { InitiativeContext } from '@hero/tfs/src/12-initiative/InitiativeEditorHost';
import { getActualValue, setActualValue } from '@hero/tfs/src/shared/budget';
import { NONE_OPTN_ID } from '@hero/tfs/src/shared/ChartConfiguration';

import ListViewRow from './ListViewRow';
import { ListViewContext } from './ListViewHost';
import { ListViewLoader } from './ListViewLoader';
import ListGroupRow from './ListGroupRow';
import { orderedGroupKeys } from './shared';

const listViewStyles = theme => ({
  ...styles(theme),
  root: {
    ...styles(theme).root,
    overflowX: 'initial',
  },
  headCell: {
    zIndex: 2,
    '&.titleHead': {
      minWidth: '280px',
      paddingLeft: '32px',
      position: 'sticky',
      left: 0,
      backgroundColor: 'white',
      zIndex: 3,
      borderRight: `1px solid ${santasGrey}`,
    },
    '&.centered': {
      textAlign: 'center',
    },
  },
  table: {
    ...styles(theme).table,
    marginBottom: '15px',
  },
  colorSwitchBase: {
    color: greenCarribean[200],
    '&$colorChecked': {
      color: greenCarribean,
      '& + $colorBar': {
        backgroundColor: greenCarribean[500],
      },
    },
  },
  colorBar: {},
  colorChecked: {},
});

const HEADERS = [
  { label: 'title', value: 'name', className: 'titleHead' },
  { label: 'stage Gate', value: 'stageGate' },
  {
    label: 'planned outcome',
    value: 'outcome_value',
    className: 'outcomeValue',
  },
  {
    label: 'actual outcome',
    value: 'actualOutcome',
    className: 'outcomeValue',
  },
  {
    label: 'outcome start date',
    value: 'outcomeStartDate',
    className: 'outcomeDate',
  },
  {
    label: 'outcome end date',
    value: 'outcomeEndDate',
    className: 'outcomeDate',
  },
  { label: 'Planned budget', value: 'cost', className: 'initiativeBudget' },
  { label: 'Actual cost', value: 'actualBudget', className: 'cost' },
  {
    label: 'budget start date',
    value: 'budgetStartDate',
    className: 'outcomeDate',
  },
  {
    label: 'budget end date',
    value: 'budgetEndDate',
    className: 'outcomeDate',
  },
  { label: 'workstream', value: 'workstreamName' },
  {
    label: 'business Unit',
    value: 'businessUnitName',
    className: 'businessUnit',
  },
  { label: 'region', value: 'regionName' },
  { label: 'sponsor', value: 'sponsorName' },
  { label: 'approver', value: 'approverName' },
  { label: 'lead', value: 'leadName' },
  { label: 'difficulty', value: 'difficultyName', className: 'difficulty' },
  {
    label: 'required Capacity',
    value: 'require_capacity',
    className: 'capacityField',
  },
  {
    label: 'available Capacity',
    value: 'available_capacity',
    className: 'capacityField',
  },
  { label: 'resource Type', value: 'resourceTypeName' },
];

const ALL_KEYS = [
  'name',
  'sponsorName',
  'approverName',
  'leadName',
  'difficultyName',
  'require_capacity',
  'available_capacity',
  'regionName',
  'businessUnitName',
  'resourceTypeName',
  'stageGate',
  'cost',
  'outcomeValue',
  'outcomeStartDateText',
  'outcomeEndDateText',
  'budgetStartDateText',
  'budgetEndDateText',
  'workstreamName',
];

const groupByKey = {
  workstream: 'workstreamName',
  stage_gate: 'stageGate',
  status: 'statusName',
  business_unit: 'businessUnitName',
  region: 'regionName',
  difficulty: 'difficultyName',
  lead: 'leadName',
};

const defaultEditedField = {
  fieldName: '',
  originalValue: null,
  value: null,
  editing: false,
};

export const OUTCOME_TYPE_CONFIG = [
  {
    _id: 'recurring',
    name: 'Recurring',
  },
  {
    _id: 'lumpsum',
    name: 'Lump Sum',
  },
];

function ListView({ classes, filter, group, isEdit, tfs, blockersKey }) {
  const [orderBy, setOrderBy] = React.useState('name');
  const [order, setOrder] = React.useState('asc');
  const [editedField, setEditedField] = React.useState(defaultEditedField);
  const [locale] = useLocale();
  const dateFormatter = new Intl.DateTimeFormat(locale, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const workstream = params.get('workstream');
  const { startEditing } = useInitiativeRouting();
  const { refreshedOn } = useContext(ListViewContext);
  const { refreshedOn: initsRefreshedOn, refreshInitiatives } = useContext(
    InitiativeContext
  );

  const initiatives = useInitiativesByTfs({
    tfsId: tfs.ref_id,
    workstream,
    key: refreshedOn + initsRefreshedOn + blockersKey,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [today] = React.useState(new Date());

  function startEditingField(init, fieldName) {
    setEditedField({
      value: init,
      originalValue: init,
      fieldName,
      editing: true,
    });
  }

  function stopEditingField() {
    setEditedField(editedField => ({
      ...editedField,
      editing: false,
    }));
  }

  function resetEdited() {
    setEditedField(defaultEditedField);
  }

  const configuration = tfs.configuration;
  const {
    business_unit: businessUnitList,
    region: regionList,
    resource_type: resourceTypeList,
    stage_gate: stageGateList,
    status: statusList,
    difficulty: difficultyList,
    workstream: workstreamList,
  } = configuration;

  return (
    <Box width="100%">
      {!initiatives ? (
        <ListViewLoader />
      ) : (
        <Paper className={classes.root}>
          <TableScrollWrapper>
            <Table className={classes.table}>
              <ListViewTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onSort={orderBy => {
                  setOrderBy(orderBy);
                  setOrder(order === 'asc' ? 'desc' : 'asc');
                }}
              />
              <Fetch
                key={blockersKey}
                url={`/api/blocker/${tfs.ref_id}/read-by-transformation-id`}
              >
                {({ data: blockersData }) => (
                  <Fetch.POST
                    url="/api/user/get-users"
                    render={usersListRsp => {
                      if (initiatives.length === 0) {
                        return (
                          <TableBody>
                            <TableRow>
                              <TableMessageCell>
                                <h2>No results found</h2>
                              </TableMessageCell>
                            </TableRow>
                          </TableBody>
                        );
                      }

                      const blockers = blockersData ? blockersData.data : [];

                      const initiativesForDisplay = initiatives
                        .map(init => {
                          if (
                            editedField.fieldName &&
                            init.ref_id === editedField.value.ref_id
                          ) {
                            return {
                              ...init,
                              ...editedField.changes,
                            };
                          }

                          return init;
                        })
                        .map(init => {
                          const initiativeStaus = getInitiativeStatus(
                            init,
                            blockers
                          );

                          return {
                            ...init,
                            businessUnitName: getNameById(
                              businessUnitList,
                              init.business_unit
                            ),
                            regionName: getNameById(regionList, init.region),
                            resourceTypeName: getNameById(
                              resourceTypeList,
                              init.resource_type
                            ),
                            stageGate: getNameById(
                              stageGateList,
                              init.stage_gate
                            ),
                            difficultyName:
                              getNameById(difficultyList, init.difficulty) ||
                              'No difficulty selected',
                            workstreamName: getNameById(
                              workstreamList,
                              init.workstream
                            ),
                            approverName: getUserNameFromList(
                              init.approver,
                              'Approver'
                            ),
                            leadName: getUserNameFromList(init.lead, 'Lead'),
                            sponsorName: getUserNameFromList(
                              init.sponsor,
                              'Sponsor'
                            ),
                            approverUser: getUserFromList(init.approver),
                            leadUser: getUserFromList(init.lead),
                            sponsorUser: getUserFromList(init.sponsor),

                            outcomeStartDate: moment(init.outcome_duration[0]),
                            outcomeEndDate: moment(init.outcome_duration[1]),
                            outcomeStartDateText:
                              init.outcome_duration && init.outcome_duration[0]
                                ? dateFormatter.format(
                                    new Date(init.outcome_duration[0])
                                  )
                                : 'No Outcome start date Selected',
                            outcomeEndDateText:
                              init.outcome_duration && init.outcome_duration[1]
                                ? dateFormatter.format(
                                    new Date(init.outcome_duration[1])
                                  )
                                : 'No Outcome end date Selected',
                            budgetStartDate: moment(init.budget_duration[0]),
                            budgetEndDate: moment(init.budget_duration[1]),
                            budgetStartDateText:
                              init.budget_duration && init.budget_duration[0]
                                ? dateFormatter.format(
                                    new Date(init.budget_duration[0])
                                  )
                                : 'No Budget start date Selected',
                            budgetEndDateText:
                              init.budget_duration && init.budget_duration[1]
                                ? dateFormatter.format(
                                    new Date(init.budget_duration[1])
                                  )
                                : 'No Budget end date Selected',

                            status: initiativeStaus,
                            statusName: getNameById(
                              statusList,
                              initiativeStaus
                            ),
                            actualOutcome:
                              init.actualOutcome ||
                              init.outcome_tracking.reduce(
                                (actualSum, tracking_row) =>
                                  actualSum + tracking_row.actual,
                                0
                              ),
                            actualBudget:
                              init.actualBudget ||
                              getActualValue(init.budget_tracking, today),
                            badgeColor: getInitiativeColor({
                              is_blocked: init.is_blocked,
                              status: initiativeStaus,
                              statusList,
                              stage_gate: init.stage_gate,
                            }),
                          };
                        });

                      const searchParts = (filter || '')
                        .split('=')
                        .map(x => x.trim());

                      const fuse = new Fuse(initiativesForDisplay, {
                        shouldSort: false,
                        threshold: 0.2,
                        location: 0,
                        distance: 100,
                        maxPatternLength: 32,
                        minMatchCharLength: 1,
                        keys:
                          searchParts.length > 1 ? [searchParts[0]] : ALL_KEYS,
                      });

                      const filteredInitiatives = filter
                        ? fuse.search(
                            searchParts.length > 1
                              ? searchParts[1]
                              : searchParts[0]
                          )
                        : initiativesForDisplay;

                      const sortedInitiatives = sort({
                        list: filteredInitiatives,
                        orderBy,
                        order,
                        type: getSortType(orderBy),
                      });

                      const groupedInitiatives =
                        !group || group === NONE_OPTN_ID
                          ? {
                              none: sortedInitiatives,
                            }
                          : groupBy(sortedInitiatives, groupByKey[group]);
                      const listGroupKeys =
                        !group || group === NONE_OPTN_ID
                          ? [NONE_OPTN_ID]
                          : orderedGroupKeys({ configuration, group });
                      const finalGroupKeys =
                        listGroupKeys || Object.keys(groupedInitiatives);

                      return (
                        <TableBody>
                          {finalGroupKeys.map(key => {
                            const groupInitiatives = groupedInitiatives[key];

                            return (
                              <React.Fragment key={key}>
                                {key !== NONE_OPTN_ID && groupInitiatives && (
                                  <ListGroupRow
                                    cols={HEADERS.length}
                                    title={key}
                                    count={
                                      groupInitiatives
                                        ? groupInitiatives.length
                                        : ''
                                    }
                                  />
                                )}
                                {groupInitiatives &&
                                  groupInitiatives.map(params => {
                                    const init = initiatives.find(
                                      init => init.ref_id === params.ref_id
                                    );

                                    return (
                                      <UpdateInitiative
                                        key={params.ref_id}
                                        today={today}
                                        editedField={editedField}
                                        onReset={stopEditingField}
                                        onFetch={() =>
                                          refreshInitiatives(Date.now())
                                        }
                                        onError={() => {
                                          enqueueSnackbar(
                                            'Failed to fetch users list',
                                            {
                                              variant: 'error',
                                            }
                                          );

                                          resetEdited();
                                        }}
                                      >
                                        {updateInitiative => (
                                          <ListViewRow
                                            rowData={params}
                                            currencyUnit={tfs.currency_unit}
                                            onClick={initiativeId => {
                                              if (!isEdit) {
                                                startEditing(
                                                  tfs.ref_id,
                                                  initiativeId
                                                );
                                              }
                                            }}
                                            onEdit={fieldName =>
                                              startEditingField(init, fieldName)
                                            }
                                            onCommit={updateInitiative}
                                            onCancel={resetEdited}
                                            editedField={editedField}
                                            configuration={configuration}
                                            usersList={usersListRsp.users}
                                            isEditMode={isEdit}
                                            onFieldChange={changes => {
                                              setEditedField(editedField => ({
                                                ...editedField,
                                                changes,
                                              }));
                                            }}
                                          />
                                        )}
                                      </UpdateInitiative>
                                    );
                                  })}
                              </React.Fragment>
                            );
                          })}
                        </TableBody>
                      );
                    }}
                  />
                )}
              </Fetch>
            </Table>
          </TableScrollWrapper>
        </Paper>
      )}
    </Box>
  );
}

ListView.propTypes = {
  classes: PropTypes.object.isRequired,
  filter: PropTypes.string,
  tfs: PropTypes.shape({
    ref_id: PropTypes.string,
    currency_unit: PropTypes.string,
    configuration: PropTypes.shape({
      business_unit: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
      region: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
      resource_type: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
      stage_gate: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
      status: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
      difficulty: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
      workstream: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
  }),
};

export function getNameById(list, id) {
  const found = list.find(item => item._id === id);
  return found ? found.name : '';
}

function getUserNameFromList(list, name) {
  return list.length !== 0
    ? `${list[0].first_name} ${list[0].last_name}`
    : `No ${name} Selected`;
}

function getUserFromList(list) {
  return (list && list[0]) || {};
}

function getSortType(orderBy) {
  if (
    ['startDate', 'endDate', 'outcomeStartDate', 'outcomeEndDate'].includes(
      orderBy
    )
  ) {
    return 'date';
  }

  if (
    [
      'require_capacity',
      'available_capacity',
      'actualOutcome',
      'actualBudget',
    ].includes(orderBy)
  ) {
    return 'number';
  }

  return '';
}

function ListViewTableHead({ classes, order, orderBy, onSort }) {
  return (
    <TableHead>
      <TableHeadRow>
        {HEADERS.map(header => {
          return (
            <TableCell
              key={`header-${header.value}`}
              className={`${classes.headCell} ${
                header.className ? header.className : ''
              } ${header.centered ? 'centered' : ''}`}
            >
              <Tooltip title={`Sort ${header.label}`}>
                <TableSortLabel
                  active={orderBy === header.value}
                  direction={order}
                  onClick={() => onSort(header.value)}
                >
                  {header.label}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          );
        })}
      </TableHeadRow>
    </TableHead>
  );
}

function UpdateInitiative({
  today,
  editedField,
  onFetch,
  onError,
  onReset,
  children,
}) {
  return (
    <Fetch.POST
      manual
      url="/api/initiative/update"
      onFetch={onFetch}
      onError={onError}
      children={({ doRequest }) =>
        children(function handleFieldSave() {
          const { value, changes } = editedField;

          if (!isEmpty(changes)) {
            const changesReadyToSave = omit(changes, [
              'actualOutcome',
              'actualBudget',
            ]);

            if (changes.actualOutcome) {
              changesReadyToSave.outcome_tracking = [
                {
                  ...value.outcome_tracking[0],
                  actual: changes.actualOutcome,
                },
              ];
            }

            if (changes.actualBudget) {
              changesReadyToSave.budget_tracking = setActualValue(
                value.budget_tracking,
                today,
                value.cost,
                changes.actualBudget
              );
            }

            doRequest({
              body: {
                ...value,
                ...changesReadyToSave,
              },
            });
          }

          onReset();
        })
      }
    />
  );
}

export default withStyles(listViewStyles)(ListView);
