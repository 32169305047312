import React, {
  useMemo,
  useCallback,
  useRef,
  useEffect,
  useState,
} from 'react';
import { Editor, Transforms, Range } from 'slate';
import { ReactEditor, useSelected, useFocused } from 'slate-react';
import { Box } from 'jsxstyle';
import styled from 'styled-components/macro';

import Avatar from '@hero/tfs/src/shared/UserAvatar';
import { sizes } from '@hero/styles/typography';
import { inputBorder, darkBlack, grey50 } from '@hero/styles/colors';
import { bayOfMany } from '@hero/styles/colors-v4';

const MentionsList = styled.div`
  max-height: 200px;
  position: absolute;
  min-width: 200px;
  // left: 0;
  // top: 0;
  border-radius: 1px;
  border: 1px solid ${inputBorder};
  background: white;
  z-index: 10;
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
`;

const MentionsListItem = styled.div`
  display: flex;
  align-items: center;
  color: ${darkBlack};
  font-size: ${sizes.s2};
  line-height: 38px;
  height: 38px;
  padding: 5px;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    background: ${grey50};
  }
`;

export const selectedMentionCss = `
  padding: 3px;
  margin: 0 1px;
  vertical-align: baseline;
  display: inline-block;
  border-radius: 1px;
  background-color: rgba(49, 66, 96, 0.7);
  font-size: ${sizes.s};
  color: white;
  box-shadow: ${props =>
    props.selected && props.focused ? `0 0 0 2px ${bayOfMany}` : 'none'};
  text-decoration: none;`;

const SelectedMention = styled.a`
  ${selectedMentionCss}
`;

const Mentions = React.forwardRef(({ list, editor, children }, parentRef) => {
  const ref = useRef();
  const [target, setTarget] = useState();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState('');
  const editorWithMention = useMemo(() => withMentions(editor), []);

  const mentions = list
    .filter(c => {
      return c.display.toLowerCase().search(search.toLowerCase()) > -1;
    })
    .slice(0, 10);

  const onKeyDown = useCallback(
    event => {
      if (target) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            const prevIndex = index >= mentions.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);
            break;
          case 'ArrowUp':
            event.preventDefault();
            const nextIndex = index <= 0 ? mentions.length - 1 : index - 1;
            setIndex(nextIndex);
            break;
          case 'Tab':
          case 'Enter':
            event.preventDefault();
            handleSelectMention(mentions[index]);
            break;
          case 'Escape':
            event.preventDefault();
            event.stopPropagation();
            setTarget(null);
            break;
          default:
            break;
        }
      }
    },
    [index, search, target]
  );

  const handleMention = () => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection);
      const wordBefore = Editor.before(editor, start, { unit: 'word' });
      const before = wordBefore && Editor.before(editor, wordBefore);
      const beforeRange = before && Editor.range(editor, before, start);
      const beforeText = beforeRange && Editor.string(editor, beforeRange);
      const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);
      const after = Editor.after(editor, start);
      const afterRange = Editor.range(editor, start, after);
      const afterText = Editor.string(editor, afterRange);
      const afterMatch = afterText.match(/^(\s|$)/);

      if (beforeMatch && afterMatch) {
        setTarget(beforeRange);
        setSearch(beforeMatch[1]);
        setIndex(0);
        return;
      }
    }

    setTarget(null);
  };

  function handleSelectMention(mention) {
    if (mention) {
      Transforms.select(editor, target);
      insertMention(editor, mention);
      setTarget(null);
    }
  }

  useEffect(() => {
    if (ref.current && target && mentions.length > 0) {
      const el = ref.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      const parentPos = parentRef
        ? parentRef.current.getBoundingClientRect()
        : { left: 0, top: 0 };
      el.style.left = `${rect.left -
        parentPos.left +
        window.pageXOffset +
        10}px`;
      el.style.top = `${rect.top - parentPos.top + window.pageYOffset + 30}px`;
    }
  }, [mentions.length, editor, index, search, target, parentRef]);

  return (
    <>
      <MentionsList ref={ref} show={target && mentions.length > 0}>
        {mentions.map((mention, i) => (
          <MentionsListItem
            as="a"
            key={mention.id}
            onMouseDown={() => {
              handleSelectMention(mention);
            }}
            style={{
              background: i === index ? grey50 : 'inherit',
            }}
          >
            <Avatar user={mention} />
            <Box as="span" marginLeft="5px">
              {mention.display}
            </Box>
          </MentionsListItem>
        ))}
      </MentionsList>
      {children({
        editorWithMention,
        mentionEvents: onKeyDown,
        mentionTrigger: handleMention,
      })}
    </>
  );
});

export default Mentions;

const withMentions = editor => {
  const { isInline, isVoid } = editor;

  editor.isInline = element => {
    return element.type === 'mention' ? true : isInline(element);
  };

  editor.isVoid = element => {
    return element.type === 'mention' ? true : isVoid(element);
  };

  return editor;
};

const insertMention = (editor, { id, display }) => {
  const mention = {
    type: 'mention',
    mention: { id, display },
    children: [{ text: display }],
  };
  Transforms.insertNodes(editor, mention);
  Transforms.move(editor);
};

export const MentionElement = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();
  return (
    <SelectedMention
      {...attributes}
      contentEditable={false}
      selected={selected}
      focused={focused}
      href={`mailto:${element.mention.id}`}
    >
      @{element.mention.display}
      {children}
    </SelectedMention>
  );
};
