import React, { useState } from 'react';
import { Box } from 'jsxstyle';
import { Container, Row, Col } from 'react-grid-system';
import min from 'date-fns/min';

import { Text } from '@hero/styles/typography-v5';
import { bayOfMany } from '@hero/styles/colors-v4';

import { ActionBtn } from '@hero/core/Dialog';
import FormattedNumber from '@hero/tfs/src/shared/FormattedNumber';
import TrackingDurationField from '../09-initiatives-editor/TrackingDurationField';
import LineChart from './LineChart';
import { BigNumber, getBudgetValues } from './shared';
import DistributeTracking from './DistributeTracking';

import { TR, TH } from './OutcomeSection';

const DEAFULT_INDEX_START = 10000; // This value should be based on the outcome tracking,
//But it can be above or below in the DOM order. So to avoid dependency of the data & assumed there will
//not be many outcome tracking input cells

export default React.memo(function BudgetSection({
  initiative,
  currencyUnit,
  onChange,
  onRestribute,
}) {
  const [showDetails, setShowDetails] = useState(false);
  const [editTracking, setEdittracking] = useState(false);

  const { planned, actual } = getBudgetValues(initiative);

  const minRecurringDate = min(
    initiative.budget_tracking
      .filter(x => x.type !== 'lumpsum')
      .map(x => new Date(x.date))
  );

  return (
    <Box background="white">
      <Box padding="16px 32px">
        <Container fluid style={{ margin: 0, padding: 0 }}>
          <Row justify="between" align="center" nogutter>
            <Col sm={12} md={4} lg={4}>
              <Text size="h4">Budget</Text>
            </Col>
            <Col sm={8} md={5} lg={4.5}>
              <TrackingDurationField
                id="budget-duration"
                value={initiative.budget_duration}
                onChange={budget_duration => onChange({ budget_duration })}
                marginLeft="auto"
                display="flex"
                justifyContent="flex-end"
              />
            </Col>
            <Col sm={3} md={2} lg={3} xl={2.5}>
              <ActionBtn
                onClick={() => setShowDetails(!showDetails)}
                style={{
                  marginBottom: 0,
                  maxWidth: '112px',
                  marginLeft: 'auto',
                }}
              >
                {showDetails ? 'Hide details' : 'Edit'}
              </ActionBtn>
            </Col>
          </Row>
        </Container>
      </Box>

      <Box position="relative" padding="16px 26px 16px 32px" display="flex">
        <FormattedNumber.CurrencyProvider unit={currencyUnit}>
          <Box minWidth="290px" paddingRight="5px">
            <table>
              <tbody>
                <tr style={{ paddingBottom: 20 }}>
                  <td
                    style={{
                      paddingRight: 20,
                      paddingBottom: 16,
                      width: 60,
                    }}
                  >
                    <Text size="overline" textTransform="uppercase">
                      Planned
                    </Text>
                    <Box
                      height="1px"
                      width="54px"
                      borderBottom={`1px dashed ${bayOfMany}`}
                      marginTop="4px"
                    />
                  </td>
                  <td
                    style={{
                      paddingBottom: 8,
                    }}
                  >
                    <Text size="h3" color={bayOfMany}>
                      <BigNumber value={planned} />
                    </Text>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Text size="overline" textTransform="uppercase">
                      Actual
                    </Text>
                    <Box
                      height="2px"
                      width="54px"
                      borderBottom={`2px solid ${bayOfMany}`}
                      marginTop="4px"
                    />
                  </td>
                  <td>
                    <Text size="h3" color={bayOfMany} lineHeight="38px">
                      <BigNumber value={actual} />
                    </Text>
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
          <LineChart
            type="budget"
            planned={initiative.cost}
            initiative={initiative}
            marginLeft={20}
            width="100%"
            height={100}
            asOf={new Date(initiative.budget_duration[1])}
          />
        </FormattedNumber.CurrencyProvider>
      </Box>
      <DistributeTracking
        editTracking={editTracking}
        type="Budget"
        value={initiative.cost}
        duration={initiative.budget_duration}
        tracking={initiative.budget_tracking}
        period={initiative.budget_period}
        unit={currencyUnit}
        onDistribute={({ value, tracking, duration, period }) => {
          onRestribute({
            cost: value,
            budget_tracking: tracking,
            budget_duration: duration,
            budget_period: period,
          });
        }}
      />
      {showDetails && (
        <>
          <Box
            height="8px"
            background="linear-gradient(180deg, rgba(49,66,96,0.1) 0%, rgba(49,66,96,0) 100%)"
            width="100%"
          />
          <Box padding="16px 26px 32px 32px">
            <table width="100%">
              <thead>
                <tr>
                  <TH textAlign="left">Quarter</TH>
                  <TH textAlign="left">Month</TH>
                  <TH textAlign="right" paddingRight="16px">
                    Planned Spend
                  </TH>
                  <TH textAlign="right" paddingRight="16px">
                    Actual Spend
                  </TH>
                </tr>
              </thead>

              <tbody>
                <FormattedNumber.CurrencyProvider unit={currencyUnit}>
                  {initiative.budget_tracking.map(
                    ({ date, planned, actual, type }, index) => {
                      const leftIndex = DEAFULT_INDEX_START + index + 1;
                      const rightIndex = initiative.budget_tracking
                        ? initiative.budget_tracking.length + leftIndex
                        : leftIndex + 1;

                      return (
                        <TR
                          tabIndex1={leftIndex}
                          tabIndex2={rightIndex}
                          key={date + index}
                          minRecurringDate={minRecurringDate}
                          date={new Date(date)}
                          planned={planned}
                          actual={actual}
                          onActualChange={changes => {
                            setEdittracking(true);
                            onChange({
                              budget_tracking: initiative.budget_tracking.map(
                                (item, i) => {
                                  if (index === i) {
                                    return {
                                      ...item,
                                      ...changes,
                                    };
                                  }

                                  return item;
                                }
                              ),
                            });
                          }}
                          onChange={changes => {
                            setEdittracking(true);
                            onChange({
                              ...getBudgetTrackingDetails({
                                index,
                                initiative,
                                changes,
                              }),
                            });
                          }}
                        />
                      );
                    }
                  )}
                </FormattedNumber.CurrencyProvider>
              </tbody>
            </table>
          </Box>
        </>
      )}
    </Box>
  );
});

function getBudgetTrackingDetails({ index, changes, initiative }) {
  const budget_tracking = initiative.budget_tracking.map((item, i) => {
    if (index === i) {
      return {
        ...item,
        ...changes,
      };
    }

    return item;
  });

  const cost = budget_tracking.reduce(
    (acc, tracking) => acc + tracking.planned,
    0
  );

  return {
    budget_tracking,
    cost,
  };
}
