import React from 'react';
import moment from 'moment';
import { Col, InlineRow } from 'jsxstyle';
import styled from 'styled-components/macro';
import { santasGrey, ghostGrey } from '@hero/styles/colors-v4';

import { ZINDEXS, SCALE_SIZE_BY_VIEW } from './shared';

const XAxis = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  padding-bottom: 24px;
  padding-top: 24px;
`;

const AxisPin = styled.li`
  position: absolute;
  width: 0;
  display: flex;
  justify-content: center;
`;

const AxisLabel = styled.span`
  display: inline-block;
  margin-bottom: 3px;
  white-space: nowrap;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  color: ${santasGrey};
  text-transform: uppercase;
`;

const AxisPeriodLabelWrapper = styled.span`
  position: absolute;
  left: 0px;
`;

const AxisPeriodLabel = styled.span`
  display: inline-block;
  background: ${santasGrey};
  color: #fff;
  border-radius: 1px;
  height: 17px;
  min-width: 38px;
  height: 17px;
  line-height: 17px;
  text-align: center;
  padding: 0 4px;
  font-size: 12px;
  position: relative;
  & & {
    background: #7481a2;
    margin-right: -5px;
    margin-left: 5px;
  }
  ${props =>
    props.showLine &&
    `
  :after {
    content: '';
    position: absolute;
    top: 17px;
    height: 30px;
    width: 1px;
    left: 50%;
    background-color: ${ghostGrey};
  }
  `};
`;

export default function Axis({
  axisData,
  period,
  view, // This view can be months when all mode selected
  scaleSize,
  sidebarWidth,
  axisHeight,
  scrollLeft,
}) {
  const firstPoint = axisData && axisData.length > 1 && axisData[0];

  let years = axisData.filter(a => a.endOfYear);
  if (firstPoint) {
    const firstYear = {
      ...firstPoint,
      yearLabel: moment(firstPoint.date).format('YYYY'),
    };
    years = [firstYear].concat(years);
  }

  return (
    <InlineRow
      width="100%"
      position="sticky"
      top="0"
      height={axisHeight}
      backgroundColor="white"
      zIndex={ZINDEXS.axis}
    >
      <InlineRow
        height="48px"
        width="180px"
        backgroundColor="white"
        position="sticky"
        left="0"
        zIndex={ZINDEXS.axis + 1}
      />
      <Col>
        {period !== 'years' &&
          years.map((year, index) => {
            let left = getLeftPosition({
              count: year.count,
              view,
              sidebarWidth,
              scaleSize,
            });
            const leftOffset = scrollLeft + sidebarWidth;
            const stickyYearLabel = left < leftOffset;
            left = left < leftOffset ? leftOffset : left;

            const nextYear = years[index + 1];
            let showCurrent = true;
            if (nextYear) {
              let nextLeft = getLeftPosition({
                count: nextYear.count,
                view,
                sidebarWidth,
                scaleSize,
              });
              showCurrent = nextLeft - 20 >= left;
            }

            if (!showCurrent) {
              return null;
            }

            return (
              <AxisPeriodLabelWrapper
                key={year.date}
                style={{
                  textAlign:
                    year.count && !stickyYearLabel ? 'center' : 'initial',
                  transform: `translateX(${left}px)`,
                  width: `${SCALE_SIZE_BY_VIEW[view]}px`,
                }}
              >
                <AxisPeriodLabel showLine={year.count && !stickyYearLabel}>
                  {year.yearLabel}
                </AxisPeriodLabel>
              </AxisPeriodLabelWrapper>
            );
          })}
        <XAxis>
          {axisData.map(({ date, label }, index) => (
            <AxisPin
              key={date}
              style={{
                left: index * scaleSize + sidebarWidth,
                width: `${SCALE_SIZE_BY_VIEW[view]}px`,
              }}
            >
              <AxisLabel>{label}</AxisLabel>
            </AxisPin>
          ))}
        </XAxis>
      </Col>
    </InlineRow>
  );
}

function getLeftPosition({ count, view, sidebarWidth, scaleSize }) {
  return (
    count * scaleSize +
    sidebarWidth +
    (count ? SCALE_SIZE_BY_VIEW[view] - SCALE_SIZE_BY_VIEW[view] / 2 : 0)
  );
}
