import React, { useMemo } from 'react';
import ProgressChart, { Canvas, makeData } from '../02-overview/ProgressChart';
import { TYPES } from '../02-overview/shared';
import DataTooltip from '@hero/tfs/src/02-overview/DataTooltip';
import { bayOfMany, quartz, puertoRico } from '@hero/styles/colors-v4';

export default React.memo(function Area({
  planned,
  type,
  initiative,
  color = bayOfMany,
  ...props
}) {
  const data = useMemo(
    () => makeData([initiative], { type, includeTfs: false }),
    [initiative, type]
  );

  return (
    <ProgressChart data={data} planned={planned} {...props}>
      <Canvas showAxis fill={quartz}>
        <Canvas.Threshold reverse={type === TYPES.budget} />
        <Canvas.PlannedArea color={color} />
        <Canvas.ActualArea color={color} />
        <Canvas.TodayLine color={puertoRico} />
        <Canvas.FocusLine showPlanned />
      </Canvas>
      <DataTooltip showPlanned />
    </ProgressChart>
  );
});
