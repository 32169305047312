import React from 'react';
import { Box } from 'jsxstyle';
import { useSpring, animated } from 'react-spring';
import { LXxsRegular, LLato } from '@hero/styles/typography';
import { blueRhino, quartz, bayOfMany } from '@hero/styles/colors-v4';
import FormattedNumber from '@hero/tfs/src/shared/FormattedNumber';

import { getPTValuesDiff } from './shared';
import { PlannedLine } from './StatsWidget';

const BRIDGE_LEFT_OFFSET = 16;

export const getBridgeBgStyles = ({ bgColor, height }) => ({
  position: 'relative',
  margin: '0 1px',
  borderRadius: '1px',
  backgroundColor: bgColor,
  height,
});

export const getESLinearGradientStyles = ({ bgColor }) => ({
  backgroundSize: '5px 5px',
  backgroundImage: `linear-gradient(135deg,  ${quartz} 25%, ${bgColor} 25%, ${bgColor} 50%,  ${quartz} 50%,  ${quartz} 75%, ${bgColor} 75%, ${bgColor} 100%)`,
});

export default function DashBridge({
  targetOutcome,
  height,
  isSurplus,
  deltaOutcome,
  children,
}) {
  const labels = React.Children.map(
    children,
    child =>
      child && (
        <Box
          component={LXxsRegular}
          flex="1 1 0"
          textAlign="center"
          textTransform="uppercase"
          margin="8px 0"
        >
          {child.props.label}{' '}
          {Number.isInteger(child.props.count) && `(${child.props.count})`}
        </Box>
      )
  );

  const totalOutcome = React.Children.map(children, child =>
    child ? child.props.outcome : 0
  ).reduce((a, b) => a + b, 0);

  const plannedOutcome = React.Children.map(children, child =>
    child ? child.props.id !== 'shortfall' && child.props.outcome : 0
  ).reduce((a, b) => a + b, 0);

  const yMax = Math.max(totalOutcome, targetOutcome, plannedOutcome);

  let voffset = height;

  const items = React.Children.map(children, child => {
    if (child) {
      const itemHeight = totalOutcome
        ? (child.props.outcome / yMax) * height
        : 0;
      const outcome = child.props.outcome;
      voffset -= itemHeight;
      const element = React.cloneElement(child, {
        height: outcome && itemHeight < 1 ? 1 : itemHeight,
        voffset,
      });
      return element;
    }
  });

  return (
    <Box display="flex">
      <Box position="relative" width="100%">
        <PlannedOutcomeLine
          isSurplus={isSurplus}
          deltaOutcome={deltaOutcome}
          yMax={yMax}
          plannedOutcome={plannedOutcome}
          targetOutcome={targetOutcome}
          height={height}
        />
        <TargetLine
          yMax={yMax}
          plannedOutcome={plannedOutcome}
          targetOutcome={targetOutcome}
          height={height}
        />
        <Box
          display="flex"
          paddingTop={8}
          paddingLeft={BRIDGE_LEFT_OFFSET}
          paddingRight={BRIDGE_LEFT_OFFSET}
          height={height + 9}
          borderWidth="0 0 1px 0"
          borderStyle="solid"
          borderColor="#9799AA"
          background={quartz}
        >
          {items}
        </Box>
        <Box display="flex">{labels}</Box>
      </Box>
    </Box>
  );
}

DashBridge.Item = function({ id, outcome, bgColor, height, voffset }) {
  const props = useSpring({
    height,
    voffset,
  });

  const textPositionStyle = {
    position: 'absolute',
    top: 0,
    transform: 'translate(0, -26px)',
    color: id === 'shortfall' ? bgColor : blueRhino,
  };

  let itemStyles = {
    ...getBridgeBgStyles({
      bgColor,
      height: props.height,
    }),
    flex: '1 1 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: props.voffset.interpolate(y => `translate(0, ${y}px)`),
  };

  if (id === 'shortfall') {
    itemStyles = {
      ...itemStyles,
      ...getESLinearGradientStyles({
        bgColor,
      }),
    };
  }

  return (
    <animated.div style={itemStyles}>
      <Box {...textPositionStyle}>
        <LLato bold>
          <FormattedNumber value={outcome} />
        </LLato>
      </Box>
    </animated.div>
  );
};

function PlannedOutcomeLine({
  isSurplus,
  deltaOutcome,
  yMax,
  plannedOutcome,
  targetOutcome,
  height,
}) {
  const { py } = getPTValuesDiff({
    yMax,
    plannedOutcome,
    targetOutcome,
    height,
  });

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      position="absolute"
      height="18px"
      transform={`translate(0, ${py - 2}px)`}
      width="100%"
    >
      <PlannedLine width="100%" />
    </Box>
  );
}

function TargetLine({ yMax, plannedOutcome, targetOutcome, height }) {
  const { ty, diff } = getPTValuesDiff({
    yMax,
    plannedOutcome,
    targetOutcome,
    height,
  });

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      position="absolute"
      height="18px"
      transform={`translate(0, ${diff === 0 ? ty - 2 : ty}px)`}
      width="100%"
    >
      <Box height="1px" background={bayOfMany} flexGrow="1" />
    </Box>
  );
}
