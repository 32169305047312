import React from 'react';
import Portal from '@material-ui/core/Portal';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import { redMandy } from '@hero/styles/colors-v4';

const styles = theme => ({
  content: {
    backgroundColor: redMandy,
  },

  message: {
    display: 'flex',
    alignItems: 'center',
  },

  icon: {
    marginRight: theme.spacing(1),
  },

  retryBtn: {
    color: 'white',
  },
});

function ErrorNotification({ classes, message, onClose, onRetry }) {
  return (
    <Portal>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={true}
        onClose={onClose}
      >
        <SnackbarContent
          className={classes.content}
          aria-describedby="client-snackbar"
          message={
            <span className={classes.message}>
              <ErrorIcon className={classes.icon} />
              {message}
            </span>
          }
          action={[
            <Button
              key="retry"
              className={classes.retryBtn}
              size="small"
              onClick={onRetry}
            >
              RETRY
            </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
    </Portal>
  );
}

export default withStyles(styles)(ErrorNotification);
