import React from 'react';
import styled, { css } from 'styled-components/macro';
import { rgba } from 'polished';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Input from '@hero/core/Input';

const InputWrapper = styled.div`
  position: relative;
`;

const ShowHideIconWrapper = styled.span`
  position: absolute;
  right: 10px;
  top: 8px;
  cursor: pointer;
`;

const VisibilityIconCss = css`
  color: ${rgba('#333', 0.3)};
  width: 20px;
  height: 20px;
`;

//The below &&{} is done to control priority over the material icon css.
// In some other places !important is being used
const StyledVisibilityOff = styled(VisibilityOff)`
  && {
    ${VisibilityIconCss};
  }
`;

const StyledVisibility = styled(Visibility)`
  && {
    ${VisibilityIconCss};
  }
`;

const ErrorLabel = styled.span`
  text-align: left;
  color: #e95959;
  transition: opacity 0.5s linear;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 19px;
`;

export default function Password({ error, errorMessage, ...props }) {
  const [passwordType, setPasswordType] = React.useState('password');
  const showPassword = passwordType === 'text';

  function handleShowHide(event) {
    event.preventDefault();
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  }

  return (
    <InputWrapper>
      <Input width="100%" type={passwordType} error={error} {...props} />
      {error && <ErrorLabel>{errorMessage}</ErrorLabel>}
      <ShowHideIconWrapper onClick={handleShowHide}>
        {showPassword ? <StyledVisibilityOff /> : <StyledVisibility />}
      </ShowHideIconWrapper>
    </InputWrapper>
  );
}
