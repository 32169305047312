import styled from 'styled-components/macro';

import {
  redMandy,
  greyManatee,
  ghostGrey,
  bayOfMany,
  santasGrey,
} from '@hero/styles/colors-v4';
import { xl } from '@hero/styles/typography';

//TODO : Move some of the css styles into common object
//So that it can be used in another places like
//React select control styling
export default styled.input`
  ${xl};
  box-sizing: border-box;
  padding: 7.5px 16px;
  display: inline-block;
  outline: 0px none;
  color: ${bayOfMany};
  min-width: ${props => props.width || '300px'};
  width: 100%;
  height: ${props => props.height || '38px'};
  border-width: 1px;
  border-style: solid;
  border-color: ${props => (props.error ? redMandy : ghostGrey)};
  border-radius: 1px;
  &[placeholder] {
    padding-left: 17px;
    outline: none;
  }
  &::placeholder {
    color: ${santasGrey};
  }
  &:focus {
    border-width: 1px;
    border-color: ${bayOfMany};
  }
  &:focus::placeholder {
    color: ${greyManatee};
  }
  &:invalid {
    border-color: ${redMandy};
  }
  &:disabled {
    color: ${santasGrey};
    cursor: not-allowed !important;
    box-shadow: none !important;
  }
  &:-moz-read-only {
    border-color: ${ghostGrey};
  }
  &:read-only {
    border-color: ${ghostGrey};
  }
`;
