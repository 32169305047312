import React from 'react';
import PropTypes from 'prop-types';
import { rgba } from 'polished';
import styled from 'styled-components';
import Add from '@material-ui/icons/Add';

import { Button } from '@hero/core/Button';
import { bayOfMany, puertoRico } from '@hero/styles/colors-v4';

const AddIcon = styled(Add)`
  vertical-align: middle;
  margin-right: 4px;
  color: inherit;
`;

const focusStyles = {
  border: `1px solid ${puertoRico}`,
};

const darkFocusStyles = {
  color: 'white',
};

const btnStyles = {
  light: {
    background: 'transparent !important',
    color: puertoRico,
    border: '1px solid transparent',
    ':hover': focusStyles,
    ':focus ': focusStyles,
  },
  dark: {
    justifyContent: 'center',
    height: '40px',
    width: '100%',
    border: '1px solid transparent',
    color: bayOfMany,
    backgroundColor: rgba(bayOfMany, 0.15),
    ':hover': darkFocusStyles,
    ':focus ': darkFocusStyles,
  },
};

const commonStyles = {
  display: 'inline-flex',
  alignItems: 'center',
};

const AddBtnBase = styled(Button)(({ variant }) => ({
  ...commonStyles,
  ...btnStyles[variant],
}));

export default function AddButton({ children, ...props }) {
  return (
    <AddBtnBase {...props}>
      <AddIcon style={{ fontSize: 17 }} />
      <span>{children}</span>
    </AddBtnBase>
  );
}

AddButton.propTypes = {
  variant: PropTypes.oneOf(['light', 'dark']),
};

AddButton.defaultProps = {
  variant: 'light',
};
