import React from 'react';
import styled from 'styled-components/macro';
import { Box } from 'jsxstyle';
import { useSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import Label from '@hero/core/Label';
import PasswordInput from '@hero/core/PasswordInput';
import PasswordStrength from '@hero/core/PasswordStrength';
import PasswordHint from '@hero/core/PasswordHint';
import { PrimaryButton, TextButton } from '@hero/core/Button';
import { T3, LLato } from '@hero/styles/typography';
import { blueRhino, redMandy } from '@hero/styles/colors-v4';
import Fetch from '@hero/tfs/src/shared/Fetch';

const Lb = styled(Label)`
  display: block;
`;

export default function PasswordChangeDialog({ isOpen, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const formEl = React.useRef();
  const [newPassword, setNewPassword] = React.useState('');
  const [passwordsDontMatch, setPasswordsDontMatch] = React.useState(false);

  React.useEffect(() => setNewPassword(''), [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onClose} scroll="body" maxWidth="xs">
      <Box padding="30px 20px">
        <Fetch.POST
          url="/api/user/manage-account"
          manual
          onFetch={() =>
            enqueueSnackbar('Password updated successfully', {
              variant: 'success',
            })
          }
        >
          {({ error, doRequest }) => (
            <form
              ref={formEl}
              onSubmit={function(e) {
                e.preventDefault();

                const currentPassword =
                  formEl.current['current-password'].value;
                const newPassword = formEl.current['new-password'].value;
                const newPasswordConfirmation =
                  formEl.current['new-password-confirmation'].value;

                if (newPassword !== newPasswordConfirmation) {
                  setPasswordsDontMatch(true);
                } else {
                  setPasswordsDontMatch(false);
                  doRequest({
                    body: {
                      currentPassword,
                      newPassword,
                    },
                  }).then(onClose);
                }
              }}
            >
              <header style={{ textAlign: 'center', marginBottom: '30px' }}>
                <T3 color={blueRhino}>Change password</T3>
              </header>

              <Box>
                <Lb htmlFor="current-password">Old password</Lb>
                <PasswordInput name="current-password" defaultValue="" />
              </Box>

              <Box>
                <Lb htmlFor="new-password">New password</Lb>
                <PasswordInput
                  name="new-password"
                  value={newPassword}
                  onChange={e => setNewPassword(e.target.value)}
                />

                <PasswordStrength password={newPassword} />
                <PasswordHint />
              </Box>

              <Box>
                <Lb htmlFor="new-password-confirmation">
                  New password (again)
                </Lb>
                <PasswordInput
                  name="new-password-confirmation"
                  defaultValue=""
                />
              </Box>

              {error && (
                <Box marginTop={15}>
                  <LLato color={redMandy}>{error.message}</LLato>
                </Box>
              )}

              {passwordsDontMatch && (
                <Box marginTop={15}>
                  <LLato color={redMandy}>Passwords must match</LLato>
                </Box>
              )}

              <Box display="flex" justifyContent="flex-end" marginTop={20}>
                <TextButton type="button" onClick={onClose}>
                  Cancel
                </TextButton>
                <PrimaryButton type="submit">Submit</PrimaryButton>
              </Box>
            </form>
          )}
        </Fetch.POST>
      </Box>
    </Dialog>
  );
}
