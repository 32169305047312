import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import useInitiativesByTfs from '@hero/tfs/src/shared/hooks/useInitiativesByTfs';
import useBlockersByTfs from '@hero/tfs/src/shared/hooks/useBlockersByTfs';

import TfsKanban from '@hero/tfs/src/08-kanban/TfsKanban';
import { InitiativeContext } from '@hero/tfs/src/12-initiative/InitiativeEditorHost';
import { KanbanLoader } from './KanbanLoader';

export default function TfsKanbanContainer({ tfs }) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const workstream = params.get('workstream');

  const { refreshedOn } = useContext(InitiativeContext);

  const initiatives = useInitiativesByTfs({
    tfsId: tfs.ref_id,
    key: refreshedOn,
  });

  const blockers = useBlockersByTfs(tfs.ref_id, workstream);
  const loading = !initiatives || !blockers;
  if (loading) {
    return (
      <KanbanLoader noOfColumns={tfs.configuration.stage_gate.length - 1} />
    );
  }

  return (
    <TfsKanban
      initiatives={initiatives}
      tfs={tfs}
      selectedWorkstream={workstream}
      blockers={blockers}
    />
  );
}
