import React from 'react';
import styled from 'styled-components/macro';
import { Row as JSXRow } from 'jsxstyle';
import {
  Container,
  Row,
  Col,
  Hidden,
  Visible,
  useScreenClass,
} from 'react-grid-system';

import Label from '@hero/core/Label';
import { Text } from '@hero/styles/typography-v5';
import { scaffolding, screenClasses } from '@hero/styles/spacings';

import ConfigEditor, {
  List,
  Entry,
  MainInput,
  LeadInput,
} from '@hero/tfs/src/shared/ConfigEditor';
import AddButton from '@hero/tfs/src/shared/AddButton';
import { BACKLOG } from '@hero/tfs/src/shared/StatusLegend';
import useUsers from '@hero/tfs/src/shared/hooks/useUsers';
import FormattedNumber from '@hero/tfs/src/shared/FormattedNumber';

import StatusInput from './StatusInput';
import { InputBlock } from './TfsBaseEditor';
import Input from '@hero/core/Input';

const Header = styled(JSXRow)`
  justify-content: space-between;
  width: 100%;
  padding: 32px 16px 16px 16px;
`;

const StyledNumberInput = styled(FormattedNumber.Input)`
  min-width: 120px;
  width: 100%;
  height: 40px;
`;

function NumberInput({ id, label, ...props }) {
  const inputId = `workstream-${label}-${id}-outcome-input`;

  return (
    <InputBlock>
      <Label noMargin htmlFor={inputId}>
        {label}
      </Label>
      <StyledNumberInput {...props} id={inputId} />
    </InputBlock>
  );
}

const PermanentStageGates = [BACKLOG, 'done', 'inProgress'];

export default function TfsConfigEditor({
  currencyUnit,
  showWorkStreamTarget,
  config,
  onChange,
}) {
  const users = useUsers();
  const screenClass = useScreenClass();
  return (
    <>
      <ConfigEditor config={config} section="workstream" onChange={onChange}>
        {({
          entries,
          reorder,
          deleteAt,
          getEntryAt,
          mergeEntryAt,
          addNewEntry,
          isInvalid,
        }) => (
          <>
            <Header>
              <Text size="h4">Workstream</Text>
              <AddButton onClick={() => addNewEntry({ name: '' })}>
                Add another Workstream
              </AddButton>
            </Header>

            <List onSortEnd={reorder}>
              {entries.map((entry, index) => (
                <Entry
                  key={entry._id}
                  index={index}
                  invalid={isInvalid(index)}
                  onDelete={() => deleteAt(index)}
                >
                  <Container
                    style={{ margin: 0, padding: 0, width: '100%' }}
                    fluid
                  >
                    <Row align="center" gutterWidth={8}>
                      <Col xs={12} lg={6} xl={3.25}>
                        <Label noMargin htmlFor={entry._id}>
                          Name
                        </Label>
                        <Input
                          id={entry._id}
                          placeholder="Enter workstream name"
                          value={getEntryAt(index).name}
                          onChange={e =>
                            mergeEntryAt(index, { name: e.target.value })
                          }
                          style={{
                            minWidth: '100%',
                          }}
                        />
                      </Col>
                      <Col
                        xs={12}
                        lg={6}
                        xl={3.75}
                        style={{
                          marginTop: [
                            screenClasses.xs,
                            screenClasses.sm,
                            screenClasses.md,
                          ].includes(screenClass)
                            ? scaffolding.xs
                            : 0,
                        }}
                      >
                        <LeadInput
                          value={getEntryAt(index).lead || 0}
                          onChange={lead => mergeEntryAt(index, { lead })}
                          users={users}
                        />
                      </Col>
                      <Hidden xs sm md lg>
                        {showWorkStreamTarget && (
                          <>
                            <Col xl={2.5}>
                              <NumberInput
                                id={entry._id}
                                label="Outcome"
                                showUnit={false}
                                placeholder="Outcome"
                                value={getEntryAt(index).outcome}
                                onChange={e =>
                                  mergeEntryAt(index, {
                                    outcome: e.target.value,
                                  })
                                }
                              />
                            </Col>
                            <Col xl={2.5}>
                              <FormattedNumber.CurrencyProvider
                                unit={currencyUnit}
                              >
                                <NumberInput
                                  id={entry._id}
                                  label="Budget"
                                  showUnit={false}
                                  placeholder="Budget"
                                  value={getEntryAt(index).cost}
                                  onChange={e =>
                                    mergeEntryAt(index, {
                                      cost: e.target.value,
                                    })
                                  }
                                />
                              </FormattedNumber.CurrencyProvider>
                            </Col>
                          </>
                        )}
                      </Hidden>
                    </Row>
                    <Visible xs sm md lg>
                      {showWorkStreamTarget && (
                        <Row
                          style={{ marginTop: scaffolding.xs }}
                          gutterWidth={8}
                        >
                          <Col xs={12} sm={12} md={6}>
                            <NumberInput
                              id={entry._id}
                              label="Outcome"
                              showUnit={false}
                              placeholder="Target"
                              value={getEntryAt(index).outcome}
                              onChange={e =>
                                mergeEntryAt(index, { outcome: e.target.value })
                              }
                            />
                          </Col>
                          <Col
                            xs={12}
                            sm={12}
                            md={6}
                            style={{
                              marginTop: [
                                screenClasses.xs,
                                screenClasses.sm,
                              ].includes(screenClass)
                                ? scaffolding.xs
                                : 0,
                            }}
                          >
                            <FormattedNumber.CurrencyProvider
                              unit={currencyUnit}
                            >
                              <NumberInput
                                id={entry._id}
                                label="Budget"
                                showUnit={false}
                                placeholder="Budget"
                                value={getEntryAt(index).cost}
                                onChange={e =>
                                  mergeEntryAt(index, {
                                    cost: e.target.value,
                                  })
                                }
                              />
                            </FormattedNumber.CurrencyProvider>
                          </Col>
                        </Row>
                      )}
                    </Visible>
                  </Container>
                </Entry>
              ))}

              <AddButton
                variant="dark"
                key="new-entry"
                onClick={() => addNewEntry({ name: '' })}
              >
                Add another Workstream
              </AddButton>
            </List>
          </>
        )}
      </ConfigEditor>

      <ConfigEditor config={config} section="stage_gate" onChange={onChange}>
        {({
          entries,
          reorder,
          deleteAt,
          getEntryAt,
          mergeEntryAt,
          addNewEntry,
          isInvalid,
        }) => (
          <>
            <Header>
              <Text size="h4">Stage Gates</Text>
              <AddButton
                onClick={() => addNewEntry({ name: '', description: '' })}
              >
                Add another Stage Gate
              </AddButton>
            </Header>

            <List onSortEnd={reorder}>
              {entries.map((entry, index) => (
                <Entry
                  key={entry._id}
                  index={index}
                  invalid={isInvalid(index)}
                  onDelete={
                    PermanentStageGates.includes(entry._id)
                      ? null
                      : () => deleteAt(index)
                  }
                >
                  <>
                    <MainInput
                      value={getEntryAt(index).name}
                      onChange={e =>
                        mergeEntryAt(index, {
                          name: e.target.value,
                        })
                      }
                      placeholder="Enter stage gate name"
                    />
                  </>
                </Entry>
              ))}

              <AddButton
                variant="dark"
                key="new-entry"
                onClick={() => addNewEntry({ name: '' })}
              >
                Add another Stage Gate
              </AddButton>
            </List>
          </>
        )}
      </ConfigEditor>

      <ConfigEditor config={config} section="region" onChange={onChange}>
        {({
          entries,
          reorder,
          deleteAt,
          getEntryAt,
          mergeEntryAt,
          addNewEntry,
          isInvalid,
        }) => (
          <>
            <Header>
              <Text size="h4">Region</Text>
              <AddButton onClick={() => addNewEntry({ name: '' })}>
                Add another Region
              </AddButton>
            </Header>
            <List onSortEnd={reorder}>
              {entries.map((entry, index) => (
                <Entry
                  key={entry._id}
                  index={index}
                  invalid={isInvalid(index)}
                  onDelete={() => deleteAt(index)}
                >
                  <MainInput
                    value={getEntryAt(index).name}
                    onChange={e =>
                      mergeEntryAt(index, { name: e.target.value })
                    }
                    placeholder="Enter region name"
                  />
                </Entry>
              ))}

              <AddButton
                variant="dark"
                key="new-entry"
                onClick={() => addNewEntry({ name: '' })}
              >
                Add another Region
              </AddButton>
            </List>
          </>
        )}
      </ConfigEditor>

      <ConfigEditor config={config} section="business_unit" onChange={onChange}>
        {({
          entries,
          reorder,
          deleteAt,
          getEntryAt,
          mergeEntryAt,
          addNewEntry,
          isInvalid,
        }) => (
          <>
            <Header>
              <Text size="h4">Business Unit</Text>
              <AddButton onClick={() => addNewEntry({ name: '' })}>
                Add another Business Unit
              </AddButton>
            </Header>
            <List onSortEnd={reorder}>
              {entries.map((entry, index) => (
                <Entry
                  key={entry._id}
                  index={index}
                  invalid={isInvalid(index)}
                  onDelete={() => deleteAt(index)}
                >
                  <MainInput
                    value={getEntryAt(index).name}
                    onChange={e =>
                      mergeEntryAt(index, { name: e.target.value })
                    }
                    placeholder="Enter business unit name"
                  />
                </Entry>
              ))}

              <AddButton
                variant="dark"
                key="new-entry"
                onClick={() => addNewEntry({ name: '' })}
              >
                Add another Business Unit
              </AddButton>
            </List>
          </>
        )}
      </ConfigEditor>

      <ConfigEditor config={config} section="resource_type" onChange={onChange}>
        {({
          entries,
          reorder,
          deleteAt,
          getEntryAt,
          mergeEntryAt,
          addNewEntry,
          isInvalid,
        }) => (
          <>
            <Header>
              <Text size="h4">Resource Type</Text>
              <AddButton onClick={() => addNewEntry({ name: '' })}>
                Add another Resource Type
              </AddButton>
            </Header>
            <List onSortEnd={reorder}>
              {entries.map((entry, index) => (
                <Entry
                  key={entry._id}
                  index={index}
                  invalid={isInvalid(index)}
                  onDelete={() => deleteAt(index)}
                >
                  <MainInput
                    value={getEntryAt(index).name}
                    onChange={e =>
                      mergeEntryAt(index, { name: e.target.value })
                    }
                    placeholder="Enter resource type name"
                  />
                </Entry>
              ))}

              <AddButton
                variant="dark"
                key="new-entry"
                onClick={() => addNewEntry({ name: '' })}
              >
                Add another Resource Type
              </AddButton>
            </List>
          </>
        )}
      </ConfigEditor>

      <ConfigEditor config={config} section="status" onChange={onChange}>
        {({ entries, reorder, getEntryAt, mergeEntryAt, isInvalid }) => (
          <>
            <Header>
              <Text size="h4">Status</Text>
            </Header>
            <List onSortEnd={reorder}>
              {entries.map((entry, index) => (
                <Entry key={entry._id} index={index} invalid={isInvalid(index)}>
                  <StatusInput
                    statusId={entry._id}
                    statusList={entries}
                    value={getEntryAt(index).name}
                    onChange={e =>
                      mergeEntryAt(index, { name: e.target.value })
                    }
                    placeholder="Enter status name"
                  />
                </Entry>
              ))}
            </List>
          </>
        )}
      </ConfigEditor>
    </>
  );
}
