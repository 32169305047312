import { darken, rgba } from 'polished';
import {
  santasGrey,
  romanRed,
  puertoRico,
  whiteLilac,
  mustard,
  jordyBlue,
} from './colors-v4';

function greyShade(darkPercentage = 1) {
  return darken(darkPercentage, '#FFFFFF');
}

export const white = '#FFFFFF';
export const black = '#333333';
export const darkBlack = '#000000';
export const cyan = '#33C6BD';
export const cyanLight = '#9FE5E0';
export const manatee = '#8b93a6';
export const catskillWhite = '#dfe7f0';
export const navLinkColor = '#565656';

export const grey20 = greyShade(0.02); // #FAFAFA
export const grey50 = greyShade(0.066); // #EEEEEE
export const grey150 = greyShade(0.15); // #d9d9d9
export const grey200 = greyShade(0.2); // #CCCCCC
export const grey300 = greyShade(0.3); // #b3b3b3
export const grey400 = greyShade(0.4); // #999999
export const grey500 = greyShade(0.537); // #767676
export const grey700 = greyShade(0.705); // #FBFBFB
export const grey900 = greyShade(0.38); //#9E9E9E
export const grey250 = greyShade(0.38); // D8D8D8
export const greyLight = '#E7E7E7';

export const primary = '#30415F';
export const greenLightest = '#F6FBF5';
export const primaryHover = '#4EB5AC';
export const primaryHoverLight = '#9FE5E0';
export const secondaryTextColor = '#282828';
export const buttonPrimary = '#4EB5AC';
export const inputBorder = '#E4E6EB';

export const colorError = '#E95959';
export const errorBackground = '#fbeaec';
export const colorIsExplicit = '#F26022'; // TODO: use `orange` instead
export const red = '#FF1744';
export const orange = '#FFA940';
export const green = '#27B30F';
export const yellow = '#FFEA00';
export const blue = '#2979ff';
export const darkBlue = '#19233c';
export const remove = '#DA5D5A';
export const lynch = '#7481A2';

export const toolbarBorder = '#E9EBF0';
export const toolbarActiveLink = '#30415F';
export const toolbarLink = '#A6A6A6';
export const toolbarShadow = '#e5e6eb';

export const navigationItem = '#2B4262';
export const navigationBar = '#F5F6FA';

export const progressBarGray = '#F4F4F4';
export const progressBarCyan = '#00B8AC';
export const progressBarCyanTransparent = rgba(progressBarCyan, 0.8);
export const progressBarCyanLight = '#98DED9';
export const progressBarKeppel = '#46B2AB';
export const progressBarTurquoise = '#33C6BD';

export const resizerSplitBorder = '#CCD2DB';

export const strengthColors = {
  default: grey50,
  red,
  orange,
  yellow,
  blue: cyan,
  green,
};

export const lineColor = '#314260';
export const bridgeTextColor = '#314260';
export const axisLineColor = '#9799AA';

export const breadCrumbArrowColor = '#8894A9';
export const legendTextColor = '#565656';
export const valueBarFill = '#15bcff';

export const initStatusColors = {
  default: whiteLilac,
  backlog: santasGrey,
  done: jordyBlue,
  blocked: romanRed,
  '0': romanRed,
  '1': mustard,
  '2': puertoRico,
};

export function getStatusColor(statusId, statusList, isShortfall = false) {
  let background = initStatusColors.default;
  let border = initStatusColors.default;
  let name = 'Default';

  if (!isShortfall) {
    let status = statusList.find(x => String(x._id) === String(statusId));
    name = status ? status.name : 'default';

    switch (statusId) {
      case -1:
        border = border;
        background = background;
        break;
      case '0':
        border = initStatusColors[statusId];
        background = initStatusColors[statusId]; // red
        break;
      case '1':
        border = initStatusColors[statusId];
        background = initStatusColors[statusId]; // amber
        break;
      case '2':
        border = initStatusColors[statusId];
        background = initStatusColors[statusId]; // green
        break;
      default:
        border = border;
        background = background;
        break;
    }
  } else {
    border = '#CDD2DA';
    background = '#E9EBF0';
    name = 'Shortfall';
  }
  return { background, border, name };
}

export function getInitiativeColor({
  status,
  stage_gate,
  is_blocked,
  is_shortfall,
  statusList,
}) {
  if (is_blocked) {
    return initStatusColors.blocked;
  }

  if (stage_gate === 'backlog') {
    return initStatusColors.backlog;
  }

  if (stage_gate === 'done') {
    return initStatusColors.done;
  }

  return getStatusColor(status, statusList, is_shortfall).background;
}
