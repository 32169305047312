import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';

import { M3Bold } from '@hero/styles/typography';
import ConfirmModal from '@hero/tfs/src/shared/ConfirmModal';
import Fetch from '@hero/tfs/src/shared/Fetch';

import sort from '@hero/tfs/src/shared/sorting';
import { TableHeadRow, TableCell, styles } from '@hero/tfs/src/shared/table';
import { Box } from '../shared';

import Row from './Row';

const HEADERS = [
  {
    label: 'Archived date',
    value: 'archived_date',
  },
  {
    label: 'Archived by',
    value: 'archived_by',
  },
  {
    label: 'Name',
    value: 'name',
  },
];

export class ArchivedBlockers extends Component {
  state = {
    confirmationType: null,
    deleteBlocker: null,
    restoreBlocker: null,
    order: 'asc',
    orderBy: 'archived_date',
  };

  render() {
    const { classes, enqueueSnackbar } = this.props;
    const { orderBy, order, deleteBlocker, restoreBlocker } = this.state;

    return (
      <Box>
        <Paper className={classes.root} style={{ marginLeft: -15 }}>
          <Table className={classes.table}>
            <TableHead>
              <TableHeadRow>
                {HEADERS.map(header => (
                  <TableCell
                    key={`archived-${header.value}`}
                    className={classes.headCell}
                  >
                    <Tooltip title={`Sort by ${header.label}`}>
                      <TableSortLabel
                        active={orderBy === header.value}
                        direction={order}
                        onClick={this.onSortClick(header.value)}
                      >
                        {header.label}
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                ))}
                <TableCell className={classes.headCell}>Restore</TableCell>
                <TableCell className={classes.headCell}>Delete</TableCell>
              </TableHeadRow>
            </TableHead>

            <Fetch.POST
              url="/api/blocker/archive-list"
              render={(
                { data: archived },
                { doRequest: fetchArchivedList }
              ) => (
                <Fetch.PUT
                  manual
                  url="/api/blocker/unarchive"
                  body={{ ref_id: restoreBlocker && restoreBlocker.ref_id }}
                  onFetch={({ data }) => {
                    this.closeConfirmation();
                    const message = data.ok
                      ? `Blocker "${data.data.name}" restored successfully`
                      : 'Sorry, Unable to restore';
                    enqueueSnackbar(message, {
                      variant: data.ok ? 'success' : 'error',
                    });
                    fetchArchivedList();
                  }}
                >
                  {({ doRequest: restoreTfs }) => (
                    <Fetch.DELETE
                      manual
                      url={`/api/blocker/${deleteBlocker &&
                        deleteBlocker.ref_id}`}
                      onFetch={({ data }) => {
                        this.closeConfirmation();
                        const message = data.ok
                          ? `Blocker "${data.data.name}" deleted successfully`
                          : 'Sorry, Unable to delete';
                        enqueueSnackbar(message, {
                          variant: data.ok ? 'success' : 'error',
                        });

                        fetchArchivedList();
                      }}
                    >
                      {({ doRequest: deleteTfs }) => (
                        <Fragment>
                          <Fetch.POST
                            url="/api/user/get-users"
                            render={({ users }) => (
                              <TableBody>
                                {sort({
                                  list: archived,
                                  orderBy,
                                  order,
                                  type:
                                    orderBy === 'archived_date' ? 'date' : '',
                                }).map((transformation, idx) => (
                                  <Row
                                    onDeleteClick={this.handleOnDeleteClick}
                                    onRestoreClick={this.handleOnRestoreClick}
                                    getUsername={id =>
                                      this.getUsername(id, users)
                                    }
                                    key={`archived-${
                                      transformation.name
                                    }-${idx}`}
                                    {...transformation}
                                  />
                                ))}
                              </TableBody>
                            )}
                          />
                          {restoreBlocker && (
                            <ConfirmModal
                              closeModal={this.closeConfirmation}
                              isOpen={this.state.confirmationType === 'restore'}
                              onConfirm={() => restoreTfs()}
                              title="Restore Blocker"
                            >
                              <p>
                                Please confirm you want to restore&nbsp;
                                <M3Bold style={{ fontWeight: 'bold' }}>
                                  {restoreBlocker.name}
                                </M3Bold>
                                ?
                              </p>
                            </ConfirmModal>
                          )}
                          {deleteBlocker && (
                            <ConfirmModal
                              closeModal={this.closeConfirmation}
                              isOpen={this.state.confirmationType === 'delete'}
                              isDanger
                              onConfirm={() => deleteTfs()}
                              title="Delete Blocker"
                            >
                              <p>
                                Please confirm you want to delete&nbsp;
                                <M3Bold>{deleteBlocker.name}</M3Bold>?
                              </p>
                            </ConfirmModal>
                          )}
                        </Fragment>
                      )}
                    </Fetch.DELETE>
                  )}
                </Fetch.PUT>
              )}
            />
          </Table>
        </Paper>
      </Box>
    );
  }

  handleOnDeleteClick = deleteBlocker => {
    this.setState({
      confirmationType: 'delete',
      deleteBlocker,
    });
  };

  handleOnRestoreClick = restoreBlocker => {
    this.setState({
      confirmationType: 'restore',
      restoreBlocker,
    });
  };

  closeConfirmation = () => {
    this.setState({
      confirmationType: null,
    });
  };

  onSortClick = prop => () => {
    this.setState(state => ({
      ...state,
      orderBy: prop,
      order: state.order === 'asc' ? 'desc' : 'asc',
    }));
  };

  getUsername = (id, users) => {
    const user = users.find(user => user.user_id === id);
    return user ? `${user.first_name} ${user.last_name}` : '';
  };
}

ArchivedBlockers.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(ArchivedBlockers));
