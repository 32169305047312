import { useHistory } from 'react-router-dom';

export default function useInitiativeRouting() {
  const history = useHistory();
  return {
    startEditing: (tfsId, initId) => {
      history.push(`/transformations/${tfsId}/initiative/${initId}`);
    },
  };
}
