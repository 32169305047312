import isSameDay from 'date-fns/isSameDay';

// Budget actual value is stored in initiative.budget_tracking
export function getActualValue(tracking, today) {
  const todayItemIndex = tracking.findIndex(({ date }) =>
    isSameDay(new Date(date), today)
  );

  if (todayItemIndex === -1) {
    return 0;
  }

  return tracking[todayItemIndex].actual;
}

// We need to update initiative.budget_tracking array with the new actual budget value
export function setActualValue(tracking, today, planned, actual) {
  const todayItemIndex = tracking.findIndex(({ date }) =>
    isSameDay(new Date(date), today)
  );

  if (todayItemIndex === -1) {
    return tracking.concat({
      date: today.toISOString(),
      planned,
      actual,
    });
  }

  return tracking.map((item, index) => {
    if (index === todayItemIndex) {
      return {
        ...item,
        actual,
      };
    }

    return item;
  });
}
