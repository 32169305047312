import React from 'react';
import { Box } from 'jsxstyle';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { Bar } from '@vx/shape';
import { Group } from '@vx/group';
import { GridColumns } from '@vx/grid';
import { AxisBottom } from '@vx/axis';
import { localPoint } from '@vx/event';
import { PatternLines } from '@vx/pattern';
import { scaleTime, scaleLinear } from 'd3-scale';
import { extent, max, bisector } from 'd3-array';
import { line, curveStepAfter } from 'd3-shape';
import { rgba } from 'polished';
import {
  eachDayOfInterval,
  format,
  isSameDay,
  compareAsc,
  isBefore,
  subDays,
  getMonth,
  differenceInMonths,
  isFirstDayOfMonth,
} from 'date-fns';
import moment from 'moment';

import { useSpring, animated } from 'react-spring';
import memoizeOne from 'memoize-one';
import { motion } from 'framer-motion';

import {
  bayOfMany,
  blueRhino,
  quartz,
  santasGrey,
  puertoRico,
  romanRed,
  neonCarrot,
} from '@hero/styles/colors-v4';
import { Text } from '@hero/styles/typography-v5';
import useElementWidth from '@hero/tfs/src/shared/hooks/useElementWidth';
import { getTfsTimeInterval } from './shared';
import { addDays } from 'date-fns/esm';

function getMostRecentData(data, asOf) {
  return data.find(d => isSameDay(d.date, asOf));
}

export default function ProgressChart({
  tfsId,
  data,
  planned = 0,
  target = 0,
  children,
  height = 149,
  marginLeft = 0,
  asOf = new Date(),
  containerHeight = '135px',
  ...props
}) {
  const containerEl = React.useRef();
  const width = useElementWidth(containerEl, marginLeft, {
    forceLayout: 100,
  });

  const xScale = scaleTime()
    .range([0, width])
    .domain(extent(data, d => d.date));

  const yScale = scaleLinear()
    .range([height, 0])
    .domain([
      0,
      Math.max(max(data, d => Math.max(d.actual, d.planned)), target, planned) *
        1.1,
    ]);

  // Tooltip on top
  const [hoverData, setHoverData] = React.useState(null);

  React.useEffect(() => setHoverData(null), [tfsId]);

  const asOfData = getMostRecentData(data, asOf);

  return (
    <Box
      position="relative"
      props={{ ref: containerEl }}
      height={containerHeight}
      {...props}
    >
      {React.Children.map(children, x =>
        React.cloneElement(x, {
          width,
          height,
          marginLeft,
          xScale,
          yScale,
          onHoverDataChange: d => setHoverData(d),
          asOf,
          currentData: hoverData,
          data,
          asOfData,
        })
      )}
    </Box>
  );
}

export function Canvas({
  width,
  height,
  marginLeft,
  data,
  asOf,
  xScale,
  yScale,
  currentData,
  asOfData,
  onHoverDataChange,
  fill = quartz,
  showAxis,
  children,
}) {
  const noOfTicks = getNoOfTicks(data);
  return (
    <svg width={width} height={height} style={{ overflow: 'visible' }}>
      <rect
        x={marginLeft - 5}
        y={0}
        width={width + 5}
        height={height}
        fill={fill}
      />
      <Group top={0} left={marginLeft}>
        <GridColumns
          lineStyle={{ pointerEvents: 'none' }}
          scale={xScale}
          height={height}
          strokeWidth="1"
          stroke={rgba(bayOfMany, 0.2)}
          numTicks={noOfTicks}
        />
        {React.Children.map(children, x =>
          React.cloneElement(x, {
            data,
            width,
            height,
            marginLeft,
            xScale,
            yScale,
            asOf,
            asOfData,
            currentData,
            onHoverDataChange,
          })
        )}
        {showAxis && (
          <AxisBottom top={height} left={0} scale={xScale} numTicks={noOfTicks}>
            {axis => {
              return (
                <g>
                  <line
                    x1={5}
                    y1={0}
                    x2={width}
                    y2={0}
                    stroke={santasGrey}
                    strokeWidth={1}
                  />
                  {axis.ticks.map((tick, i) => {
                    const tickX = tick.to.x;
                    const tickY = tick.to.y + 5;
                    const isFirstMonth = getMonth(new Date(tick.value)) === 0;
                    const formatter = isFirstMonth ? 'MMM YY' : 'MMM';
                    return (
                      <Group
                        key={`vx-tick-${tick.value}-${i}`}
                        transform={`translate(${tickX}, ${tickY})`}
                      >
                        <Text
                          as="text"
                          size="overline"
                          textTransform="uppercase"
                          textAnchor="middle"
                          fontWeight={isFirstMonth ? 700 : 400}
                          fill={bayOfMany}
                        >
                          {format(new Date(tick.value), formatter, {
                            awareOfUnicodeTokens: true,
                          })}
                        </Text>
                      </Group>
                    );
                  })}
                </g>
              );
            }}
          </AxisBottom>
        )}
      </Group>
    </svg>
  );
}

const PATTERNS = ['url(#dLines-excess)', 'url(#dLines-shortfall)'];

Canvas.Threshold = function({ reverse, asOf, data, xScale, yScale, width }) {
  const dataAsOfDate = data.filter(x => isBefore(x.date, asOf));
  const thresholdData = dataAsOfDate.map(d => {
    const x0 = xScale(d.date);
    const y0 = yScale(d.planned);
    const y1 = yScale(d.actual);
    return {
      date: d.date,
      x0,
      y0,
      y1,
    };
  });
  const dates = thresholdData
    .map(d => d.date)
    .filter(date => isFirstDayOfMonth(date));
  const groupedData = thresholdData.reduce((g, d) => {
    g[d.date] = d;
    return g;
  }, {});

  return (
    <g>
      <PatternLines
        id="dLines-excess"
        height={6}
        width={6}
        background={rgba(puertoRico, 0.1)}
        stroke={puertoRico}
        strokeWidth={2}
        orientation={['diagonal']}
        transform="rotateX(180deg)"
      />

      <PatternLines
        id="dLines-shortfall"
        height={6}
        width={6}
        background={rgba(romanRed, 0.1)}
        stroke={romanRed}
        strokeWidth={2}
        orientation={['diagonal']}
      />

      {dates.map((tick, index) => {
        const tickData = groupedData[tick];
        const { x0, y0, y1 } = tickData || { x0: 0, y0: 0, y1: 0 };
        const nextTick = dates[index + 1];

        const x1 = nextTick ? xScale(nextTick) : xScale(asOf);

        const rectWidth = x0 > x1 ? x0 - x1 : x1 - x0;
        const rectHeight = y0 > y1 ? y0 - y1 : y1 - y0;
        const isExcess = y0 > y1; //yScale produces less number when big number passed as argument
        const patterns = reverse ? [...PATTERNS].reverse() : PATTERNS;

        return (
          <Bar
            key={tick + index}
            fill={isExcess ? patterns[0] : patterns[1]}
            height={rectHeight}
            width={rectWidth + x0 > width ? width - x0 : rectWidth}
            x={x0}
            y={isExcess ? y1 : y0}
          />
        );
      })}
    </g>
  );
};

const areaVariant = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    strokeDasharray: '0,0',
  },
  visible: {
    opacity: 1,
    pathLength: 1000,
    strokeDasharray: '4,2',
  },
};

Canvas.ActualArea = function({
  asOf,
  data,
  xScale,
  yScale,
  color = bayOfMany,
}) {
  const createLine = line()
    .x(d => xScale(d.date))
    .y(d => yScale(d.actual))
    .curve(curveStepAfter);
  return (
    <g>
      <motion.path
        variants={areaVariant}
        initial="hidden"
        animate="visible"
        d={createLine(data.filter(x => isBefore(x.date, asOf)))}
        stroke={color}
        strokeWidth={3}
        fill="none"
      />
    </g>
  );
};

Canvas.PlannedArea = function({ data, xScale, yScale, color = bayOfMany }) {
  const createLine = line()
    .x(d => xScale(d.date))
    .y(d => yScale(d.planned))
    .curve(curveStepAfter);

  return (
    <g>
      <motion.path
        initial="hidden"
        animate="visible"
        d={createLine(data)}
        stroke={color}
        strokeDasharray="4,2"
        strokeWidth={1}
        fill="none"
      />
    </g>
  );
};

Canvas.TargetLine = function({
  width,
  yScale,
  value,
  color = bayOfMany,
  height,
}) {
  const position = useSpring({ y1: value === 0 ? height : yScale(value) });

  return (
    <g>
      <animated.line
        x1={-5}
        y1={position.y1}
        x2={width}
        y2={position.y1}
        stroke={color}
        strokeWidth={1}
      />
    </g>
  );
};

Canvas.ProjectedLine = function({
  data,
  asOfData,
  xScale,
  yScale,
  color = neonCarrot,
}) {
  if (!asOfData) {
    return null;
  }

  const projectedData = data.filter(x => !isBefore(x.date, asOfData.date));

  const createLine = line()
    .x(d => xScale(d.date))
    .y(d =>
      yScale(
        getProjectedValue({
          planned: d.planned,
          asOfPlanned: asOfData.planned,
          asOfActual: asOfData.actual,
        })
      )
    )
    .curve(curveStepAfter);

  return (
    <g>
      <motion.path
        initial="hidden"
        animate="visible"
        d={createLine(projectedData)}
        stroke={color}
        strokeWidth="1"
        strokeDasharray="4,2"
        fill="none"
      />
    </g>
  );
};

Canvas.TodayLine = function({ data, xScale, height, color }) {
  const todayData = getMostRecentData(data, new Date());

  if (!todayData) {
    return null;
  }

  const position = useSpring({ x1: xScale(todayData.date) });

  return (
    <g>
      <animated.line
        x1={position.x1}
        y1={0}
        x2={position.x1}
        y2={height}
        stroke={color}
        strokeWidth={2}
        style={{ pointerEvents: 'none' }}
      />
    </g>
  );
};

Canvas.FocusLine = function({
  data,
  asOfData,
  currentData,
  marginLeft,
  xScale,
  yScale,
  width,
  height,
  showPlanned = false,
  onHoverDataChange,
}) {
  const bisectDate = bisector(d => new Date(d.date)).left;
  const left = currentData ? xScale(currentData.date) : 0;
  const topActual = currentData ? yScale(currentData.actual) : 0;
  const topPlanned = currentData ? yScale(currentData.planned) : 0;
  const showProjected =
    currentData && asOfData && !isBefore(currentData.date, asOfData.date);
  const topProjected =
    currentData && asOfData
      ? yScale(
          getProjectedValue({
            planned: currentData.planned,
            asOfPlanned: asOfData.planned,
            asOfActual: asOfData.actual,
          })
        )
      : 0;

  function handleMove(event) {
    const xPoint = localPoint(event).x - marginLeft;
    const x0 = xScale.invert(xPoint);
    const index = bisectDate(data, x0, 1);
    const d0 = data[index - 1];
    const d1 = data[index];
    let d = d0;
    if (d1 && d1.date) {
      d = x0 - d0.date.getTime() > d1.date.getTime() - x0 ? d1 : d0;
    }
    // if ((d0 && isBefore(d0.date, asOf)) || (d1 && isBefore(d1.date, asOf))) {
    //   onHoverDataChange(d);
    // }
    if (d0 || d1) {
      onHoverDataChange(d);
    }
  }

  return (
    <FocusArea
      currentData={currentData}
      width={width}
      height={height}
      left={left}
      handleMove={handleMove}
      onHoverDataChange={onHoverDataChange}
      top1={showProjected ? topProjected : topActual}
      top2={topPlanned}
      showTop2={showPlanned}
    />
  );
};

function FocusArea({
  currentData,
  left,
  top1,
  top2,
  showTop2,
  width,
  height,
  handleMove,
  onHoverDataChange,
}) {
  return (
    <g>
      <rect
        y={0}
        width={width}
        height={height}
        fill="transparent"
        onMouseMove={handleMove}
        onMouseOut={() => {
          onHoverDataChange(null);
        }}
      />
      {currentData && (
        <>
          <line
            x1={left}
            y1={0}
            x2={left}
            y2={height}
            stroke={blueRhino}
            strokeWidth={1}
            style={{ pointerEvents: 'none' }}
          />
          {showTop2 && (
            <circle
              cx={left}
              cy={top2}
              r={5.5}
              fill="white"
              stroke={blueRhino}
              strokeWidth={3}
              style={{ pointerEvents: 'none' }}
            />
          )}
          <circle
            cx={left}
            cy={top1}
            r={5.5}
            fill="white"
            stroke={blueRhino}
            strokeWidth={3}
            style={{ pointerEvents: 'none' }}
          />
        </>
      )}
    </g>
  );
}

/**
 * `makeData` use tracking information (planned/actual) from all initiatives to
 * get single continuous data array for plotting.
 *
 * Every initiative produces a specific timeline depending on type (outcome/budget).
 * The timeline is an array of all recored planned/actual values with corresponding dates.
 *
 * We then combine all initiative timelines into a single timeline and select only data for
 * transformation time interval.
 *
 * Transformation time interval is:
 *  start - min start date across all initiatives
 *  end - max end date across all initiatives
 *
 * In the end we make accumulated timeline for plotting. And fill gaps in data.
 */

export function makeData(initiatives, { type, includeTfs = true }) {
  if (!makeData.validDataTypes.includes(type)) {
    throw new Error(
      `Invalid data type — ${type}. ` +
        `Should be one of [${makeData.validDataTypes.join(', ')}]`
    );
  }

  // we don't take into account lumpsum tracking info at the time
  function hasValidDuration(initiative) {
    if (type === 'budget') {
      return initiative.budget_duration.length > 0;
    }

    if (type === 'outcome') {
      return initiative.outcome_duration.length > 0;
    }

    return false;
  }

  const timelines = initiatives
    .filter(initiative => hasValidDuration(initiative))
    .map(initiative => makeTimeline(initiative, type));

  const masterTimeline = combineTimelines(timelines);

  // Let's get rid of all data that are outside of transformation time interval
  const finalTimeline = includeTfs
    ? memoizedSelectTimeline(masterTimeline, getTfsTimeInterval(initiatives))
    : masterTimeline;

  /**
   * So far planned/actual numbers were set just for a specific period:
   *  [
   *    { date: 'jan 01`, planned: 1, actual: 10 },
   *    { date: 'feb 01`, planned: 2, actual: 20 },
   *    { date: 'mar 01`, planned: 3, actual: 30 }
   *  ]
   *
   * At the last step we need to find accumulated value of planned/actual for plotting:
   *  [
   *    { date: 'jan 01`, planned: 1, actual: 10 },  // 0 + 1 = 1, 0 + 10 = 10
   *    { date: 'feb 01`, planned: 3, actual: 30 },  // 1 + 2 = 3, 10 + 20 = 30
   *    { date: 'mar 01`, planned: 6, actual: 60 }   // 3 + 3 = 6, 30 + 30 = 60
   *  ]
   */
  let actual = 0;
  let planned = 0;
  const accumulatedTimeline = finalTimeline.map(value => {
    planned += value.planned;
    actual += value.actual;

    return {
      ...value,
      planned,
      actual,
    };
  });

  // there is possiblity that for given date there is no data,
  // so we fill it with previous item planned/actual numbers
  const result = memoizedFillGapsInTimeline(
    accumulatedTimeline,
    (date, previous) => ({
      date,
      actual: previous.actual,
      planned: previous.planned,
    })
  );

  // checking duplicates to verify original data and algorithm,
  // if there is no duplicates, everyting is fine
  // if there are duplicates better to check original data and algorithm correctness
  const duplicates = memoizedFindDuplicates(result);

  if (duplicates.length > 0) {
    console.warn('Unexpected duplicates in ProgressChart');
    console.table(duplicates);
  }
  return result;
}

makeData.validDataTypes = ['budget', 'outcome'];

function fillGapsInTimeline(timeline, fillWith) {
  return timeline.reduce((result, item, index, source) => {
    if (index < source.length) {
      const start = item.date;
      const end = source[index + 1]
        ? subDays(source[index + 1].date, 1)
        : addDays(start, 15);
      const items = eachDayOfInterval({
        start,
        end: isBefore(end, start) ? start : end,
      }).map(date => fillWith(date, item));

      return result.concat(items);
    }

    return result;
  }, []);
}

const memoizedFillGapsInTimeline = memoizeOne(fillGapsInTimeline);

function combineTimelines(timelines) {
  const items = timelines.reduce(
    (result, timeline) => result.concat(timeline),
    []
  );
  const byDate = groupBy(
    items,
    x =>
      moment(x.date)
        .format()
        .split('T')[0]
  );
  const result = map(byDate, (value, key) => ({
    date: new Date(key),
    actual: value.reduce((sum, x) => sum + x.actual, 0),
    planned: value.reduce((sum, x) => sum + x.planned, 0),
  }));

  result.sort((a, b) => compareAsc(a.date, b.date));

  return result;
}

function selectTimeline(timeline, { start, end }) {
  const result = [...timeline];

  if (!timeline.includes(x => x.getTime() === start.getTime())) {
    result.unshift({
      date: start,
      actual: 0,
      planned: 0,
    });
  }

  if (!timeline.includes(x => x.getTime() === end.getTime())) {
    result.push({
      date: end,
      actual: 0,
      planned: 0,
    });
  }

  return result;
}

const memoizedSelectTimeline = memoizeOne(selectTimeline);

// Timeline here is an array of { date, actual, planned } objects.
// Ensure this array is monotonic in chronological order to work correctly
// with other procedures.
function makeTimeline(initiative, type) {
  function getTrackingData(initiative) {
    if (type === 'budget') {
      return initiative.budget_tracking;
    }

    if (type === 'outcome') {
      return initiative.outcome_tracking;
    }

    return [];
  }

  // function getStartDate(initiative) {
  //   if (type === 'budget') {
  //     return initiative.budget_duration[0];
  //   }

  //   if (type === 'outcome') {
  //     return initiative.outcome_duration[0];
  //   }

  //   return new Date();
  // }

  // function getInitialPlannedValue(initiative) {
  //   if (type === 'budget') {
  //     return initiative.cost;
  //   }

  //   if (type === 'outcome') {
  //     return initiative.outcome_value;
  //   }

  //   return 0;
  // }

  return getTrackingData(initiative).map(({ date, actual, planned }) => ({
    date,
    actual,
    planned,
    initiative: initiative.ref_id,
  }));
}

function findDuplicates(data) {
  return filter(
    map(groupBy(data, x => x.date.toLocaleString('uk-UA')), (v, k) => [
      k,
      ...v.map(x => x.planned),
    ]),
    value => value.length > 2
  );
}

const memoizedFindDuplicates = memoizeOne(findDuplicates);

function getNoOfTicks(data) {
  if (!data || data.length === 0) return 10;

  const rightDate = data[0].date;
  const leftDate = data[data.length - 1].date;
  const noOfMonths = differenceInMonths(leftDate, rightDate);

  return noOfMonths <= 6 ? noOfMonths : 10;
}

export function getProjectedValue({ planned, asOfPlanned, asOfActual }) {
  return planned - asOfPlanned + asOfActual;
}
