import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'jsxstyle';
import pluralize from 'pluralize';
import uniq from 'lodash/uniq';
import { useSnackbar } from 'notistack';

import TagInput from '@hero/core/TagInput';
import { PrimaryButton } from '@hero/core/Button';
import Fetch from '@hero/tfs/src/shared/Fetch';
import Editor from '@hero/tfs/src/shared/Editor';

export default function InviteMembers({ onInvited }) {
  const [defaultEmails, setDefaultEmails] = useState([]);
  const [key, setKey] = useState(Date.now());

  const { enqueueSnackbar } = useSnackbar();

  return (
    <Fetch.POST
      manual
      url="/api/user/sent-user-invitation"
      onError={({ error }) => {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      }}
      onFetch={({ data }) => {
        if (data.sendstats && data.sendstats.Failed.length > 0) {
          setDefaultEmails(data.sendstats.Failed);

          enqueueSnackbar(
            `Failed to invite members ${data.sendstats.Failed.join(', ')}`,
            {
              variant: 'error',
            }
          );
        } else {
          setDefaultEmails(() => []);
        }
        setKey(Date.now());
        onInvited(data);
      }}
    >
      {({ loading, data, doRequest, reset }) => (
        <Editor
          key={key}
          id="invite-newmember-form"
          initialValue={defaultEmails}
          onSubmit={emails => {
            if (emails.length === 0) {
              enqueueSnackbar('Please enter at least one email', {
                variant: 'error',
              });
            } else {
              doRequest({ body: { invite_to: emails } });
            }
          }}
        >
          {({ value, onChange }) => {
            const validEmails = value.filter(v => isEmail(v));
            return (
              <Box>
                <TagInput
                  id="invitation-email"
                  placeholder={
                    value.length === 0
                      ? 'Enter email(s) to invite new members'
                      : 'Add another email'
                  }
                  type="email"
                  width="100%"
                  value={value}
                  onChange={e => {
                    onChange(uniq(e.target.value));
                    reset();
                  }}
                  separator={/[\s,]/}
                  renderTag={props => (
                    <TagInput.Tag
                      key={props.index}
                      {...props}
                      invalid={!isEmail(props.text)}
                    />
                  )}
                  right={() => (
                    <PrimaryButton
                      disabled={
                        value.length === 0 ||
                        loading ||
                        validEmails.length === 0
                      }
                      type="submit"
                      form="invite-newmember-form"
                      minWidth="180px"
                      style={{ maxHeight: '41px' }}
                    >
                      {data && data.sendstats.Failed.length > 0
                        ? 'Invite again'
                        : `Invite ${pluralize(
                            'member',
                            validEmails.length,
                            validEmails.length > 0
                          )}`}
                    </PrimaryButton>
                  )}
                />
              </Box>
            );
          }}
        </Editor>
      )}
    </Fetch.POST>
  );
}

InviteMembers.propTypes = {
  onInvited: PropTypes.func.isRequired,
};

const emailInput = document.createElement('input');
emailInput.type = 'email';
function isEmail(text) {
  emailInput.value = text;
  return emailInput.checkValidity();
}
