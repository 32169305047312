import React from 'react';
import Nav from '@hero/core/Nav';

import { FormattedMessageWithHelmet } from '@hero/tfs/src/shared/FormattedMessage';
import SignInTabs from './SignInTabs';
import { Wrapper } from './styles';
import messages from './messages';

const Login = props => (
  <React.Fragment>
    <FormattedMessageWithHelmet {...messages['pageTitle']} />
    <Wrapper>
      <Nav fixed />
      <SignInTabs {...props} />
    </Wrapper>
  </React.Fragment>
);

Login.defaultProps = {
  title: 'hero',
  signup: false,
};

export default Login;
