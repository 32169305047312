import React from 'react';

export default function useElementWidth(
  ref,
  marginLeft,
  { forceLayout = 0 } = {}
) {
  const [width, setWidth] = React.useState(0);

  function resize() {
    if (ref.current) {
      setWidth(ref.current.getBoundingClientRect().width - marginLeft);
    }
  }

  React.useLayoutEffect(() => {
    resize();

    if (forceLayout) {
      setTimeout(() => {
        resize();
      }, forceLayout);
    }

    window.addEventListener('resize', resize, false);

    return () => {
      window.removeEventListener('resize', resize, false);
    };
  }, []);

  return width;
}
