import React, { Component } from 'react';
import moment from 'moment';

import { TableCell, TableRow } from '@hero/tfs/src/shared/table';
import { ActionButton, DeleteLogo, EllipsisText } from '../shared';

class Row extends Component {
  render() {
    const {
      name,
      archived_date: archivedDate,
      archived_by: archivedBy,
      getUsername,
      cellClassName,
    } = this.props;
    return (
      <TableRow>
        <TableCell className={cellClassName}>
          {moment(archivedDate)
            .toDate()
            .toLocaleDateString()}
        </TableCell>
        <TableCell className={cellClassName}>
          {getUsername(archivedBy)}
        </TableCell>
        <TableCell className={cellClassName}>
          <EllipsisText>{name}</EllipsisText>
        </TableCell>
        <TableCell className={cellClassName}>
          <ActionButton onClick={this.onRestoreClick}>Restore</ActionButton>
        </TableCell>
        <TableCell className={cellClassName}>
          <ActionButton onClick={this.onDeleteClick}>
            <DeleteLogo />
          </ActionButton>
        </TableCell>
      </TableRow>
    );
  }

  onDeleteClick = () => {
    const { ref_id, name, onDeleteClick } = this.props;
    onDeleteClick({ ref_id, name });
  };

  onRestoreClick = () => {
    const { ref_id, name, onRestoreClick } = this.props;
    onRestoreClick({ ref_id, name });
  };
}

export default Row;
