import React from 'react';
import styled from 'styled-components/macro';
import { Box } from 'jsxstyle';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Divider from '@material-ui/core/Divider';
import Avatar from '@hero/tfs/src/shared/UserAvatar';
import { MD, T3 } from '@hero/styles/typography';
import { blueRhino, greyScorpion } from '@hero/styles/colors-v4';
import NotificationIcon from '@hero/core/icons/Notification';
import useUsers from '@hero/tfs/src/shared/hooks/useUsers';
import useNotifications from './useNotifications';
import useLastEventTime from './useLastEventTime';

const Icon = styled.div`
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  color: rgba(255, 255, 255, 0.6);

  svg {
    width: 24px;
    height: 24px;
  }
`;

const Container = styled.div`
  position: relative;
  max-height: 80vh;
  width: 350px;
`;

const Header = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  width: 100%;
  padding: 1em;
  text-align: center;
`;

const Row = styled.div`
  width: 100%;
  padding: 1em;
`;

export default function NotificationTarget() {
  const anchor = React.useRef();
  const [today] = React.useState(Date.now());
  const [open, setOpen] = React.useState(false);
  const lastEventTime = useLastEventTime();
  const notifications = useNotifications(lastEventTime);
  const users = useUsers(today);

  React.useEffect(() => {
    anchor.current = document.getElementById('notification-target');
  }, []);

  return (
    <>
      <IconButton
        id="notification-target"
        size="small"
        onClick={() => setOpen(true)}
      >
        <Icon>
          <NotificationIcon />
        </Icon>
      </IconButton>

      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Container>
          <Header>
            <T3 color={blueRhino}>Notifications</T3>
          </Header>
          <Divider variant="middle" />

          <Row>
            {notifications.map(action => (
              <Action value={action} users={users} />
            ))}
          </Row>
        </Container>
      </Popover>
    </>
  );
}

function Action({ value, users }) {
  const user = users.find(user => user.user_id === value.author_id);

  if (!user) {
    return null;
  }

  let text = '';

  switch (value.type) {
    case 'BLOCKER::NEW_ASSIGNEE':
      text = `Added you to blocker ${value.payload.blocker}`;
      break;

    case 'INITIATIVE::BUDGET_CHANGE':
      text = `Changed budget to ${value.payload.newBudget}`;
      break;

    case 'INITIATIVE::OUTCOME_CHANGE':
      text = `Changed outcome to ${value.payload.newOutcome}`;
      break;

    default:
      text = '';
  }

  return (
    <Box display="flex" margin="4px 0 14px 0">
      <Box width="33px" flex="0 1 auto">
        <Avatar user={user} />
      </Box>
      <Box paddingLeft="16px" width="100%">
        <MD color={blueRhino} bold>
          {user.first_name} {user.last_name}
        </MD>{' '}
        <MD color={greyScorpion}>{text}</MD>
      </Box>
    </Box>
  );
}
