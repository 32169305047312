import React, { useState } from 'react';
import styled from 'styled-components/macro';
import differenceBy from 'lodash/differenceBy';
import { Visible, Hidden } from 'react-grid-system';

import { PrimaryButton, SecondaryButton } from '@hero/core/Button';

import { ToolbarLinks } from '@hero/tfs/src/shared/FullScreenModal';
import ConfirmModal from '@hero/tfs/src/shared/ConfirmModal';
import ErrorIcon from '@hero/core/icons/Error';
import FormattedNumber from '@hero/tfs/src/shared/FormattedNumber';

import TfsBaseEditor from './TfsBaseEditor';
import TfsConfigEditor from './TfsConfigEditor';
import { Row } from 'jsxstyle';
import { Text } from '@hero/styles/typography-v5';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  opacity: 1;
  width: 80vw;
  max-width: 960px;
  height: 85vh;
  overflow: auto;
`;

const ConfigBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Error = styled(({ children, ...props }) => (
  <div {...props}>
    <ErrorIcon
      width={'1.2rem'}
      height={'1.2rem'}
      styles={{ marginRight: '5px' }}
    />
    {children}
  </div>
))`
  svg {
    flex-grow: 0;
    margin-bottom: -3px;
  }

  span {
    padding-left: 0.6em;
    flex-grow: 2;
    align-self: center;
  }
`;

export default function TfsEditor({
  transformation,
  users,
  onChange,
  onCancel,
  isTfsValid,
  error,
}) {
  const [deletedTargetType, setDeletedTargetType] = useState(null);
  const [lastConfig, setLastConfig] = useState(transformation.configuration);
  const isFormValid = isTfsValid(transformation);

  return (
    <Container>
      <ToolbarLinks>
        <Visible xs sm>
          <Text size="h5" fontWeight="700">
            Transformation Settings
          </Text>
        </Visible>
        <Hidden xs sm>
          <Text size="h2" fontWeight="700">
            Transformation Settings
          </Text>
        </Hidden>
        <Row alignItems="center">
          <SecondaryButton type="button" onClick={onCancel}>
            Cancel
          </SecondaryButton>
          <PrimaryButton type="submit" disabled={!isFormValid}>
            Save
          </PrimaryButton>
        </Row>
      </ToolbarLinks>
      {error && (
        <Error>
          <span>{error.message || 'Please fill all required fields'}</span>
        </Error>
      )}
      <TfsBaseEditor
        transformation={transformation}
        usersList={users}
        onChange={onChange}
      />

      <ConfigBox>
        <FormattedNumber.TfsProvider tfs={transformation}>
          <TfsConfigEditor
            currencyUnit={transformation.currency_unit}
            config={transformation.configuration}
            showWorkStreamTarget
            onChange={config => {
              const deletedCustomTargetTypes = differenceBy(
                transformation.configuration.custom_target_types,
                config.custom_target_types,
                '_id'
              );

              const usedEntry = deletedCustomTargetTypes.find(
                entry => transformation.target_type === entry._id
              );

              if (usedEntry) {
                setDeletedTargetType(usedEntry);
                setLastConfig(config);
              } else {
                onChange({
                  ...transformation,
                  configuration: config,
                });
              }
            }}
          />
        </FormattedNumber.TfsProvider>

        <ConfirmModal
          closeModal={() => setDeletedTargetType(null)}
          isOpen={Boolean(deletedTargetType)}
          onConfirm={() => {
            setDeletedTargetType(null);
            onChange({
              ...transformation,
              configuration: lastConfig,
            });
          }}
          title="Please confirm"
          message={
            <span>
              {deletedTargetType && deletedTargetType.name} is currently used as
              transformation's target type. If you delete it, target type would
              be reset to Time (Weeks).
              <p style={{ marginTop: '1em' }}>
                Do you want to delete{' '}
                {deletedTargetType && deletedTargetType.name}?
              </p>
            </span>
          }
          confirmBtnTitle="Delete"
        />
      </ConfigBox>
    </Container>
  );
}
