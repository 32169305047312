export const api_url = process.env.REACT_APP_API_URL;

export const BASE_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const getToken = () => {
  return localStorage.getItem('id_token');
};

const getBody = ({ method }, data) => {
  return method === 'POST' || method === 'PUT'
    ? JSON.stringify(data)
    : undefined;
};

export const fetchApi = (url, data, opts = { method: 'POST' }) => {
  let body = null;

  if (data instanceof File) {
    body = new FormData();
    body.append('image', data);
  } else {
    body = getBody(opts, data);
  }

  const options = {
    ...opts,
    headers:
      data instanceof File
        ? { Authorization: getToken() }
        : { ...BASE_HEADERS, Authorization: getToken() },
    body,
  };

  return fetch(`${api_url}${url}`, options).then(res => {
    if (res.ok) {
      return res.json();
    }
    return res.text().then(text => {
      throw new Error(text);
    });
  });
};
