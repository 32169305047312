import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FormattedMessageComponent } from 'react-intl';
import Helmet from 'react-helmet';

const FormattedMessage = ({ id, fallback = '', ...props }) => {
  if (id) return <FormattedMessageComponent id={id} {...props} />;
  console.warn(`Missing translation for "${fallback}"`);
  return fallback;
};

FormattedMessage.propTypes = {
  id: PropTypes.string,
  fallback: PropTypes.string,
};

export const FormattedMessageWithHelmet = ({ id, fallback = '', ...props }) => {
  if (id)
    return (
      <FormattedMessageComponent id={id} {...props}>
        {message => (
          <Helmet>
            <title>{message}</title>
          </Helmet>
        )}
      </FormattedMessageComponent>
    );
  console.warn(`Missing translation for "${fallback}"`);
  return fallback;
};

FormattedMessage.propTypes = {
  id: PropTypes.string,
  fallback: PropTypes.string,
};

export default FormattedMessage;
