import { keyframes } from 'styled-components/macro';

export const fadeIn = keyframes`
	from { opacity: 0; }
	to { opacity: 1; }
`;

export const fadeInScale = keyframes`
	from {
		opacity: 0;
		transform: scale(0.9);
	}
`;

export const fadeInSlideUp = keyframes`
	from {
		opacity: 0;
		transform: translateY(10%);
	}
`;

export const fadeInSlideRight = keyframes`
	0% {
		opacity: 0;
		transform: translateX(-15%);
	}
`;

export const slideIn = keyframes`
	from { opacity: 0; transform: translateX(-100px); }
`;

export const appear = keyframes`
	from {opacity: 0;}
	to {opacity: 1;}
`;

export const fadeDownOutAnim = keyframes`
	0% {
		opacity: 1;
		transform: translate(0px, 0px);
	}
	10% {
		opacity: 0.6777369479537847;
		transform: translate(0px, 0px);
	}
	20% {
		opacity: 0.6;
		transform: translate(0px, 7px);
	}
	100% {
		opacity: 0;
		transform: translate(0px, 60px);
	}
`;

export const fadeUpAnim = keyframes`
	0% {
		opacity: 0;
		transform: translate(0px, 100px);
	}
	100% {
		opacity: 1;
		transform: translate(0px, 0px);
		}
`;

export const fadeAnim = keyframes`
	0% {
		opacity: 0;
	}
	20% {
		opacity: 0.52;

	}
	100% {
		opacity: 1;

	}
`;

export const fadeOutAnim = keyframes`
	0% {
		opacity: 1;
	}
	20% {
		opacity: 0.52;
	}
	100% {
		opacity: 0;
	}
`;
