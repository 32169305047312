import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'jsxstyle';
import { greenCarribean, bayOfMany } from './colors-v4';

const sizeToStyle = {
  oswaldH1: {
    fontFamily: 'Oswald, sans-serif',
    fontSize: '60px',
    fontWeight: 600,
  },
  oswaldH2: {
    fontFamily: 'Oswald, sans-serif',
    fontSize: '36px',
    fontWeight: 500,
  },
  oswaldH3: {
    fontFamily: 'Oswald, sans-serif',
    fontSize: '28px',
    fontWeight: 500,
  },
  oswaldH6: {
    fontFamily: 'Oswald, sans-serif',
    fontSize: '18px',
    fontWeight: 400,
  },
  h1: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '43px',
    fontWeight: 600,
  },

  h2: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '36px',
    fontWeight: 600,
  },

  h3: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '30px',
    fontWeight: 600,
  },

  h4: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '25px',
    fontWeight: 600,
  },

  h5: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '21px',
  },

  h6: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '18px',
  },

  link: {
    fontFamily: 'Lato, sans-serif',
    fontSize: '18px',
    color: greenCarribean,
    textDecoration: 'underline',
  },

  p1: {
    fontFamily: 'Lato, sans-serif',
    fontSize: '15px',
  },

  p2: {
    fontFamily: 'Lato, sans-serif',
    fontSize: '12px',
  },

  overline: {
    fontFamily: 'Lato, sans-serif',
    fontSize: '10px',
  },
};

export const Text = React.forwardRef(function(
  { size, as, component, ...props },
  ref
) {
  return (
    <Box
      props={{ ref }}
      component={as || component || 'span'}
      color={bayOfMany}
      fontWeight={400}
      lineHeight="1.2em"
      {...sizeToStyle[size]}
      {...props}
    />
  );
});

Text.propTypes = {
  size: PropTypes.oneOf(Object.keys(sizeToStyle)),
};

Text.defaultProps = {
  size: 'p1',
};
