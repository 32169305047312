import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box } from 'jsxstyle';
import styled from 'styled-components/macro';

import { ActionBtn } from '@hero/core/Dialog';
import Input from '@hero/core/Input';

import Fetch from '@hero/tfs/src/shared/Fetch';
import { createDefaultBlocker } from '@hero/tfs/src/03-blockers/shared';
import Editor from '@hero/tfs/src/shared/Editor';

const RiskInput = styled(Input)`
  height: 41px;
  padding: 8px 140px;
  position: relative;
`;

export default function AddRisk({ tfs, initiative, refreshRisks }) {
  const { enqueueSnackbar } = useSnackbar();
  const [reset, setReset] = useState(Date.now());

  return (
    <Fetch.POST
      key={reset}
      manual
      url="/api/blocker/create"
      onError={() =>
        enqueueSnackbar('Failed to create the issue', {
          variant: 'error',
        })
      }
      onFetch={() => {
        setReset(Date.now());
        refreshRisks();
      }}
    >
      {({ loading, doRequest: createBlocker }) => (
        <Editor
          id="create-risk"
          initialValue={{
            ...createDefaultBlocker({
              configData: tfs.configuration,
              initiatives: [initiative],
            }),
            type: 'risk',
          }}
          onSubmit={(blocker, event) => {
            event.stopPropagation();
            if (blocker && blocker.description) {
              createBlocker({ body: blocker });
            }
          }}
        >
          {({ value, onChange }) => (
            <Box position="relative">
              <RiskInput
                name="risk-subject"
                type="text"
                placeholder="Risk subject..."
                value={value.description}
                onChange={e => {
                  const description = e.target.value;
                  onChange(latestValue => ({
                    ...latestValue,
                    description,
                  }));
                }}
              />
              <Box width="120px" position="absolute" right="0" top="0">
                <ActionBtn
                  disabled={!value.description}
                  style={{
                    minWidth: '120px',
                    height: '100%',
                    marginBottom: 0,
                    padding: '10px 16px 12px 16px',
                  }}
                  form="create-risk"
                  type="submit"
                >
                  {loading ? 'Adding risk' : 'Add risk'}
                </ActionBtn>
              </Box>
            </Box>
          )}
        </Editor>
      )}
    </Fetch.POST>
  );
}
