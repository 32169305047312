import React from 'react';
import styled from 'styled-components/macro';
import { rgba } from 'polished';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Box, Row } from 'jsxstyle';

import { bayOfMany, ghostGrey } from '@hero/styles/colors-v4';

import Fetch from '@hero/tfs/src/shared/Fetch';
import FormattedNumber from '@hero/tfs/src/shared/FormattedNumber';
import SearchParams from '@hero/tfs/src/shared/SearchParams';
import Page from '@hero/tfs/src/shared/Page';
import ProtectedRoute from '@hero/tfs/src/shared/ProtectedRoute';
import LoadingIndicator from '@hero/tfs/src/shared/LoadingIndicator';

import ChartConfiguration, {
  ToolbarOptions,
  GroupSelect,
  SizeSelect,
  ShowZeroItemsToggle,
  ViewSelect,
  ViewBySelect,
  CHART_TYPE_LIST,
} from '@hero/tfs/src/shared/ChartConfiguration';
import StatusLegend from '@hero/tfs/src/shared/StatusLegend';
import { fadeIn } from '@hero/tfs/src/shared/styles/animation';

import TfsDashboard from './02-overview/TfsDashboard';
import TfsBlockers from './03-blockers/TfsBlockers';
import BridgeChartContainer from './04-bridge/BridgeChartContainer';
import GanttChartContainer from './05-gantt/GanttChartContainer';
import EffortValueChartContainer from './06-effort-outcome/EffortValueChartContainer';
import ListView from './07-list/ListView';
import ListSearchInput from './07-list/ListSearchInput';
import ListEditInput from './07-list/ListEditInput';
import KanbanContainer from './08-kanban/TfsKanbanContainer';
import InitiativeBox from '@hero/tfs/src/12-initiative/InitiativeBox';
import Settings from '@hero/tfs/src/00-settings/Settings';
import { TfsEditorContext } from '@hero/tfs/src/10-transformations-editor/TfsHost';
import { BarLegend } from '@hero/tfs/src/05-gantt/GanttChartStripe';
import { OutcomeLegend } from '@hero/tfs/src/05-gantt/OutcomeLine';

const Toolbar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 74px;
  animation: ${fadeIn} ease 0.3s;
  padding: 0 32px;
`;

export default React.memo(function HeroApp({ tfsId, baseUrl }) {
  const lastUpdatedTfsAt = React.useContext(TfsEditorContext);

  return (
    <Fetch.POST
      key={lastUpdatedTfsAt}
      url="/api/transformation/read"
      body={{ query: { ref_id: tfsId } }}
    >
      {({ data, loading }) => {
        return (
          <Page>
            <Page.Header>
              <Page.Nav tfsId={tfsId} baseUrl={baseUrl} />
            </Page.Header>
            {!data || loading ? (
              <LoadingIndicator
                loading
                label={'Please wait, Transformation is loading...'}
                delay={0}
              />
            ) : (
              <PageContent tfs={data.data} baseUrl={baseUrl} />
            )}
          </Page>
        );
      }}
    </Fetch.POST>
  );
});

function PageContent({ tfs, baseUrl }) {
  const [blockersUpdatedAt, setBlockersUpdatedAt] = React.useState(Date.now());
  const location = useLocation();
  const shouldShowSectionNav = !/transformations\/(.+)\/(initiative)|(settings)\/(.+)/.test(
    location.pathname
  );

  const shouldShowLegend = /transformations\/(.+)\/kanban/.test(
    location.pathname
  );

  const {
    workstream: workstreams,
    status: statusList,
    stage_gate: stageGates,
  } = tfs.configuration;

  return (
    <>
      {shouldShowSectionNav && (
        <Page.SectionNav
          workstreams={workstreams}
          tfsId={tfs.ref_id}
          onBlockerSubmit={() => setBlockersUpdatedAt(Date.now())}
          shouldShowLegend={shouldShowLegend}
          statusList={statusList}
          stageGates={stageGates}
        />
      )}
      <Switch>
        <Redirect exact from={baseUrl} to={`${baseUrl}/overview`} />

        <Route
          path={`${baseUrl}/overview`}
          render={() => (
            <Page.Main>
              <TfsDashboard tfs={tfs} />
            </Page.Main>
          )}
        />

        <Route
          path={`${baseUrl}/blockers`}
          render={() => (
            <Page.Main>
              <TfsBlockers tfs={tfs} blockersUpdatedAt={blockersUpdatedAt} />
            </Page.Main>
          )}
        />

        <Route
          path={`${baseUrl}/list`}
          render={() => (
            <Page.Main>
              <ChartConfiguration chartType={CHART_TYPE_LIST}>
                {({ group, setChartOption }) => (
                  <Container tfs={tfs}>
                    <Toolbar>
                      <ToolbarOptions>
                        <GroupSelect
                          value={group}
                          chartType={CHART_TYPE_LIST}
                          onChange={setChartOption}
                        />
                        <Row alignItems="center">
                          <StatusLegend
                            marginRight={32}
                            statusList={statusList}
                            stageGates={stageGates}
                          />
                          <ListSearchInput />
                          <ListEditInput />
                        </Row>
                      </ToolbarOptions>
                    </Toolbar>

                    <Content>
                      <SearchParams>
                        {({ params }) => (
                          <ListView
                            blockersKey={blockersUpdatedAt}
                            isEdit={params.isEdit === '1'}
                            filter={params.filter}
                            group={group}
                            tfs={tfs}
                          />
                        )}
                      </SearchParams>
                    </Content>
                  </Container>
                )}
              </ChartConfiguration>
            </Page.Main>
          )}
        />

        <Route
          path={`${baseUrl}/kanban`}
          render={() => (
            <Page.Main>
              <Container tfs={tfs} backgroundColor="transparent">
                <KanbanContainer tfs={tfs} />
              </Container>
            </Page.Main>
          )}
        />

        <Route
          path={`${baseUrl}/bridge`}
          render={() => (
            <Page.Main>
              <ChartConfiguration chartType="bridge">
                {({
                  chartType,
                  group,
                  size,
                  showZeroItems,
                  setChartOption,
                }) => (
                  <Container tfs={tfs}>
                    <Toolbar>
                      <ToolbarOptions>
                        <Row alignItems="center">
                          <GroupSelect
                            key={chartType}
                            value={group}
                            onChange={setChartOption}
                          />
                          <SizeSelect value={size} onChange={setChartOption} />
                          <ShowZeroItemsToggle
                            group={group}
                            value={showZeroItems}
                            onChange={setChartOption}
                          />
                        </Row>
                        <StatusLegend
                          statusList={statusList}
                          stageGates={stageGates}
                        />
                      </ToolbarOptions>
                    </Toolbar>

                    <Content>
                      <BridgeChartContainer
                        tfs={tfs}
                        groupBy={group}
                        sizeBy={size}
                        showZeroItems={showZeroItems}
                      />
                    </Content>
                  </Container>
                )}
              </ChartConfiguration>
            </Page.Main>
          )}
        />

        <Route
          path={`${baseUrl}/gantt`}
          render={() => (
            <Page.Main>
              <ChartConfiguration chartType="gantt">
                {({ chartType, group, view, setChartOption }) => (
                  <Container tfs={tfs}>
                    <Toolbar>
                      <ToolbarOptions>
                        <Row alignItems="center">
                          <GroupSelect
                            key={chartType}
                            value={group}
                            onChange={setChartOption}
                          />
                          <ViewSelect value={view} onChange={setChartOption} />
                        </Row>

                        <StatusLegend
                          left={() => (
                            <Row alignItems="center">
                              <BarLegend marginRight="16px" />
                              <OutcomeLegend />
                              <Box
                                height="24px"
                                width="1px"
                                marginLeft={16}
                                backgroundColor={ghostGrey}
                              />
                            </Row>
                          )}
                          statusList={statusList}
                          stageGates={stageGates}
                        />
                      </ToolbarOptions>
                    </Toolbar>

                    <Content>
                      <GanttChartContainer
                        tfs={tfs}
                        groupBy={group}
                        view={view}
                      />
                    </Content>
                  </Container>
                )}
              </ChartConfiguration>
            </Page.Main>
          )}
        />

        <Route
          path={`${baseUrl}/effort-outcome`}
          render={() => (
            <Page.Main>
              <ChartConfiguration chartType="effort-outcome">
                {({ chartType, group, viewBy, setChartOption }) => (
                  <Container tfs={tfs}>
                    <Toolbar>
                      <ToolbarOptions>
                        <Row alignItems="center">
                          <GroupSelect
                            key={chartType}
                            value={group}
                            onChange={setChartOption}
                          />
                          <ViewBySelect
                            value={viewBy}
                            onChange={setChartOption}
                          />
                        </Row>
                        <StatusLegend
                          statusList={statusList}
                          stageGates={stageGates}
                        />
                      </ToolbarOptions>
                    </Toolbar>

                    <Content>
                      <EffortValueChartContainer
                        tfs={tfs}
                        groupBy={group}
                        viewBy={viewBy}
                      />
                    </Content>
                  </Container>
                )}
              </ChartConfiguration>
            </Page.Main>
          )}
        />
        <Route
          path={`${baseUrl}/initiative/:initId`}
          render={({ match }) => (
            <InitiativeBox
              id={match.params.initId}
              tfsId={tfs.ref_id}
              baseUrl={baseUrl}
            />
          )}
        />

        <Route
          path={`${baseUrl}/settings`}
          render={({ match, history, location }) => (
            <ProtectedRoute>
              <Settings match={match} history={history} location={location} />
            </ProtectedRoute>
          )}
        />
      </Switch>
    </>
  );
}

function Container({ backgroundColor = 'white', tfs, children }) {
  return (
    <Box
      boxShadow={`0 1px 2px 0 ${rgba(bayOfMany, 0.1)}`}
      border-radius="1px"
      backgroundColor={backgroundColor}
      width="100%"
      overflow="scroll"
      height="calc(100% - 16px)"
      position="relative"
    >
      <FormattedNumber.TfsProvider tfs={tfs}>
        {children}
      </FormattedNumber.TfsProvider>
    </Box>
  );
}

function Content({ children }) {
  return (
    <Box transform="none" overflow="scroll" height="calc(100% - 74px)">
      {children}
    </Box>
  );
}
