import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { WebPrimaryBtn } from '@hero/core/Button';
import PasswordInput from '@hero/core/PasswordInput';
import { LoginContext } from '@hero/tfs/src/shared/LoginContextProvider';

import {
  TabContentWrapper,
  InputWrapper,
  Input,
  ButtonWrapper,
  ErrorSection,
  ErrorLabel,
  SuccessLabel,
} from '../styles';

import { ForgotPasswordLink } from './styles';

const USERNAME_REGEX = /^([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})$/i;
const EMPTY_STRING = '';

class SignIn extends Component {
  static propTypes = {
    index: PropTypes.number,
    loginError: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
    registrationSuccess: PropTypes.bool.isRequired,
    login: PropTypes.func.isRequired,
    clearLoginMessage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    errorMessage: EMPTY_STRING,
  };

  state = {
    username: EMPTY_STRING,
    password: EMPTY_STRING,
    usernameError: false,
    passwordError: false,
    isInvited: new URLSearchParams(window.location.search).has('invite'),
    showVerificationBtn: false,
    successMessage: '',
  };

  render() {
    const {
      username,
      password,
      usernameError,
      passwordError,
      isInvited,
      showVerificationBtn,
      successMessage,
    } = this.state;
    const { loginError, errorMessage, registrationSuccess } = this.props;

    return (
      <form onSubmit={this.handleSubmit}>
        <TabContentWrapper>
          <InputWrapper>
            <Input
              value={username}
              error={usernameError}
              placeholder={'Email'}
              name={'username'}
              onChange={this.handleChange}
            />
            {usernameError && (
              <ErrorLabel error={usernameError}>
                Please enter valid Email.
              </ErrorLabel>
            )}
          </InputWrapper>
          <PasswordInput
            error={passwordError}
            errorMessage="Please enter password."
            placeholder={'Password'}
            name={'password'}
            value={password}
            onChange={this.handleChange}
          />
          <ForgotPasswordLink to="/reset-password">
            Forgot Password ?
          </ForgotPasswordLink>
          {(loginError || errorMessage) && (
            <ErrorSection>
              <ErrorLabel error={loginError}>{errorMessage}</ErrorLabel>
            </ErrorSection>
          )}
          {registrationSuccess && !isInvited && (
            <SuccessLabel>
              We have sent you an email, please confirm your email address to
              before you try to login.
            </SuccessLabel>
          )}
          {successMessage && <SuccessLabel>{successMessage}</SuccessLabel>}
          <ButtonWrapper>
            {showVerificationBtn ? (
              <WebPrimaryBtn
                type="button"
                onClick={this.resendVerificationEmail}
              >
                Re-send verification email
              </WebPrimaryBtn>
            ) : (
              <WebPrimaryBtn type="submit">Login</WebPrimaryBtn>
            )}
          </ButtonWrapper>
        </TabContentWrapper>
      </form>
    );
  }

  componentDidUpdate(prevProps) {
    const { clearLoginMessage, index } = this.props;
    const { index: prevIndex } = prevProps;

    prevIndex !== index &&
      clearLoginMessage({
        errorMessage: null,
      });

    if (this.props.loginError && !prevProps.loginError) {
      this.setState({ successMessage: '' });
    }
  }

  handleChange = e => {
    const { clearLoginMessage, errorMessage, registrationSuccess } = this.props;
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'Error']: false,
    });
    if (errorMessage || registrationSuccess) {
      clearLoginMessage({
        errorMessage: null,
        registrationSuccess: false,
      });
    }
  };

  handleSubmit = event => {
    event.preventDefault();

    const { history, login } = this.props;
    this.validateDetails(() => {
      const { username, password, usernameError, passwordError } = this.state;
      if (usernameError || passwordError) {
        return;
      }
      login({ username, password }).then(response => {
        if (response.ok) {
          history.push('/');
        } else if (JSON.parse(response.message).hasOwnProperty('is_verified')) {
          this.setState({
            showVerificationBtn: !JSON.parse(response.message).is_verified,
          });
        }
      });
    });
  };

  validateDetails = cb => {
    const { username, password } = this.state;
    const usernameError =
      !username ||
      username.trim().length === 0 ||
      !USERNAME_REGEX.test(username);
    const passwordError = !password || password.trim().length === 0;
    this.setState(
      {
        usernameError,
        passwordError,
      },
      () => {
        cb();
      }
    );
  };

  resendVerificationEmail = () => {
    this.props.resendVerificationEmail({ email: this.state.username }).then(
      rsp =>
        console.log(rsp) ||
        this.setState({
          showVerificationBtn: false,
          successMessage: rsp.message,
        })
    );
  };
}

const SignInWithProps = withRouter(routeProps => (
  <LoginContext.Consumer>
    {({
      loginError,
      registrationSuccess,
      errorMessage,
      login,
      clearLoginMessage,
      resendVerificationEmail,
    }) => (
      <SignIn
        {...routeProps}
        loginError={loginError}
        registrationSuccess={registrationSuccess}
        errorMessage={errorMessage}
        login={login}
        clearLoginMessage={clearLoginMessage}
        resendVerificationEmail={resendVerificationEmail}
      />
    )}
  </LoginContext.Consumer>
));

export default props => <SignInWithProps {...props} />;
