import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { LoginContext } from '@hero/tfs/src/shared/LoginContextProvider';
import LoadingIndicator from '@hero/tfs/src/shared/LoadingIndicator';
import { getToken } from '@hero/tfs/src/shared/fetch-api';

class ProtectedComponent extends React.Component {
  static propTypes = {
    verifying: PropTypes.bool,
    authVerify: PropTypes.func.isRequired,
  };

  state = {
    verifying: true,
  };

  render() {
    const { isAuthenticated, render } = this.props;
    const { verifying } = this.state;

    return (
      <LoadingIndicator
        loading={verifying}
        label={'Please wait while authenticating'}
        render={() => render(isAuthenticated)}
      />
    );
  }

  componentDidMount() {
    const { authVerify, isAuthenticated } = this.props;
    const token = getToken();
    if (!isAuthenticated) {
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        authVerify({ token }).then(() => {
          this.setState({
            verifying: false,
          });
        });
      });
    } else {
      this.setState({
        verifying: false,
      });
    }
  }
}

export default function ProtectedRoute({ children }) {
  return (
    <LoginContext.Consumer>
      {({ isAuthenticated, authVerify }) => (
        <ProtectedComponent
          isAuthenticated={isAuthenticated}
          authVerify={authVerify}
          render={isAuthenticated =>
            isAuthenticated ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                }}
              />
            )
          }
        />
      )}
    </LoginContext.Consumer>
  );
}
