import React from 'react';
import styled from 'styled-components/macro';

const Label = styled.p`
  color: #ffa940;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 19px;
  margin: 14px 0;
`;

export default function Hint() {
  return (
    <Label>
      <b>Hint: </b>
      Passwords must be at least 6 characters long, and can't be things like
      "password", "123456" or "abcdef".
    </Label>
  );
}
