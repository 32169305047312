import styled from 'styled-components/macro';

import { s2Regular, sBold } from '@hero/styles/typography';
import { darkBlack, bridgeTextColor, axisLineColor } from '@hero/styles/colors';

export const CHART_AXIS_LABEL_WIDTH = 70;
export const CHART_AXIS_WIDTH = 92;
export const DEFAULT_WIDTH = 1100;
export const CHART_SECTION_PADDING_TOP = 20;
export const CHART_SECTION_PADDING_BOTTOM = 0;
export const CHART_SECTION_PADDING_LEFT = 24;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: ${CHART_SECTION_PADDING_TOP}px;
  padding-bottom: ${CHART_SECTION_PADDING_BOTTOM}px;
  padding-left: ${CHART_SECTION_PADDING_LEFT}px;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export const ChartWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 30px;
  width: ${({ width = DEFAULT_WIDTH }) => width}px;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const AxisTypes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AxisType = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: ${CHART_AXIS_LABEL_WIDTH}px;
  height: ${props => props.height}px;
  color: ${darkBlack};
  ${s2Regular};
`;

export const AxisSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const SeparateLine = styled.div`
  position: absolute;
  width: 1px;
  background-color: #dee2ed;
  left: ${props => props.left}px;
  height: ${props => props.height + 30}px;
`;

export const DefaultSeparateLine = styled.div`
  position: absolute;
  width: 1px;
  background-color: ${axisLineColor};
  left: ${CHART_AXIS_LABEL_WIDTH +
    CHART_AXIS_WIDTH +
    CHART_SECTION_PADDING_LEFT}px;
  height: ${props => props.height + 30}px;
`;

export const GroupText = styled.div`
  position: absolute;
  top: calc(100% - 30px);
  width: 145px;
  left: ${props => props.left}px;
  padding: 0 5px;
  color: ${bridgeTextColor};
  ${sBold};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;

export const Rect = styled.rect`
  cursor: pointer;
`;
