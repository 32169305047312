import jwtDecode from 'jwt-decode';

export default function getCurrentUser() {
  const token = localStorage.getItem('id_token');

  let user = {};
  try {
    user = jwtDecode(token);
  } catch (eror) {
    console.warn('Invalide user token');
  }
  return user;
}
