import React from 'react';

export default function Editor({ initialValue, onSubmit, children, ...props }) {
  const [value, onChange] = React.useState(initialValue);

  function handleSubmit(event) {
    event && event.preventDefault();
    event && event.stopPropagation();
    onSubmit(value, event);
  }

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%' }} {...props}>
      {children({
        value,
        onChange,
        initialValue,
        submit: handleSubmit,
      })}
    </form>
  );
}
