import React from 'react';

import { romanRed } from '@hero/styles/colors-v4';
import { Text } from '@hero/styles/typography-v5';

import { DD } from '@hero/tfs/src/shared/UserMenu';
import SelectMenu from '@hero/core/SelectMenu';

export default function Menu({
  items,
  selected,
  readOnly,
  buttonStyles,
  iconSize = '19px',
  onSelect,
  showIcon = true,
  ...props
}) {
  const ref = React.useRef();
  const currentItem = items.find(x => String(x._id) === selected);

  return (
    <DD {...props}>
      <SelectMenu.TargetButton
        ref={ref}
        color={selected === '-1' && romanRed}
        readOnly={readOnly}
        iconSize={iconSize}
        showIcon={showIcon}
        style={{ ...buttonStyles }}
      >
        {currentItem ? (
          currentItem.name
        ) : (
          <Text size="p1" color={romanRed}>
            Not selected
          </Text>
        )}
      </SelectMenu.TargetButton>

      <SelectMenu
        selected={selected}
        onChange={selected => onSelect(selected)}
        target={ref}
      >
        <SelectMenu.List
          options={items
            .filter(x => String(x._id) !== '-1')
            .map(item => ({ id: item._id, text: item.name }))}
        />
      </SelectMenu>
    </DD>
  );
}
