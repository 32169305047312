import React from 'react';
import { Box } from 'jsxstyle';
import { Container, Row, Col } from 'react-grid-system';

import { solitude } from '@hero/styles/colors-v4';

import {
  PlaceholderLoader,
  StatsLoader,
  ColumnLoader,
} from '@hero/tfs/src/shared/Loaders';

export default function BlockersLoader() {
  return (
    <Container fluid style={{ margin: '0 0 0 0', width: '100%' }}>
      <Row
        align="center"
        justify="space-between"
        style={{ minHeight: 64, marginBottom: 18, backgroundColor: 'white' }}
      >
        <Col sm={12} lg={4}>
          <PlaceholderLoader type="title" />
        </Col>
        <Col sm={9} lg={6.5}>
          <Box display="flex" alignItems="center">
            <StatsLoader
              marginLeft="auto"
              marginRight={56}
              alignItems="center"
            />
            <StatsLoader marginRight={56} alignItems="center" />
            <StatsLoader marginRight={56} alignItems="center" />
          </Box>
        </Col>
        <Col sm={3} lg={1.5}>
          <PlaceholderLoader type="button" />
        </Col>
      </Row>
    </Container>
  );
}

export function BlockerKanbanLoader({ noOfColumns }) {
  const columns = new Array(noOfColumns).fill(0).map((c, index) => index);
  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="space-between"
      overflowY="hidden"
      overflowX="auto"
    >
      {columns.map(c => (
        <ColumnLoader
          key={c}
          minWidth="357px"
          width="33%"
          marginRight={c === noOfColumns - 1 ? '0' : '32px'}
          columnColor={solitude}
          columnContentStyles={{ padding: '16px 8px', minHeight: 200 }}
        />
      ))}
    </Box>
  );
}
