import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'jsxstyle';
import groupBy from 'lodash/groupBy';

import { MainActionBtn } from '@hero/core/Dialog';
import SlidingPanel from '@hero/core/SlidingPanel';
import { Text } from '@hero/styles/typography-v5';
import { whiteLilac, santasGrey, bayOfMany } from '@hero/styles/colors-v4';
import { scaffolding } from '@hero/styles/spacings';
import UserAvatar from '@hero/tfs/src/shared/UserAvatar';

import UserMenu, { DL, DT } from '@hero/tfs/src/shared/UserMenu';
import Menu from '@hero/tfs/src/shared/Menu';
import useUsers from '@hero/tfs/src/shared/hooks/useUsers';
import useEscKeydown from '@hero/tfs/src/shared/hooks/useEscKeydown';
import useRiskIssueBlockers from '@hero/tfs/src/shared/hooks/useRiskIssueBlockers';
import useInitiativeRouting from '@hero/tfs/src/shared/hooks/useInitiativeRouting';
import FormattedNumber from '@hero/tfs/src/shared/FormattedNumber';
import { UserLoader, BlockerLoader } from '@hero/tfs/src/shared/Loaders';
import {
  getOutcomeValues,
  getBudgetValues,
} from '@hero/tfs/src/12-initiative/shared';
import { deserialize } from '@hero/tfs/src/09-initiatives-editor/shared';
import { isBlockerDone } from '@hero/tfs/src/03-blockers/shared';

export default function QuickInitPanel({ init, onClose, tfs }) {
  const users = useUsers();
  const usersLoading = !users || users.length === 0;
  const normalizedInit = init ? deserialize(init, users) : {};
  const { planned: outcomePlanned, actual: outcomeActual } = getOutcomeValues(
    normalizedInit
  );
  const { planned: budgetPlanned, actual: budgetActual } = getBudgetValues(
    normalizedInit
  );
  const { configuration: config } = tfs;
  const { startEditing } = useInitiativeRouting();
  useEscKeydown(onClose);

  return (
    <>
      <SlidingPanel
        type="right"
        isOpen={Boolean(init)}
        size={22}
        header={
          <Text size="h3" lineHeight="37px">
            {normalizedInit.name}
          </Text>
        }
        footer={() => (
          <Box>
            <MainActionBtn
              onClick={() => startEditing(tfs.ref_id, normalizedInit.ref_id)}
            >
              Go to initiative page
            </MainActionBtn>
          </Box>
        )}
        onClose={onClose}
      >
        {Boolean(init) && (
          <Box backgroundColor="white">
            <Box
              backgroundColor="white"
              borderBottom={`1px solid ${whiteLilac}`}
              padding={`${scaffolding.sm} ${scaffolding.lg}`}
            >
              <FormattedNumber.TfsProvider tfs={tfs}>
                <Text
                  as="div"
                  size="h6"
                  marginBottom={scaffolding.sm}
                  fontWeight="600"
                >
                  Outcome
                </Text>
                <PlannedActualLabels
                  planned={outcomePlanned}
                  actual={outcomeActual}
                />
              </FormattedNumber.TfsProvider>
            </Box>
            <Box
              borderBottom={`1px solid ${whiteLilac}`}
              padding={`${scaffolding.sm} ${scaffolding.lg}`}
            >
              <FormattedNumber.CurrencyProvider unit={tfs.currency_unit}>
                <Text
                  as="div"
                  size="h6"
                  marginBottom={scaffolding.sm}
                  fontWeight="600"
                >
                  Budget
                </Text>
                <PlannedActualLabels
                  planned={budgetPlanned}
                  actual={budgetActual}
                />
              </FormattedNumber.CurrencyProvider>
            </Box>
            <Box
              borderBottom={`1px solid ${whiteLilac}`}
              padding={`${scaffolding.sm} ${scaffolding.lg}`}
            >
              <Text
                as="div"
                size="h6"
                marginBottom={scaffolding.sm}
                fontWeight="600"
              >
                Issues & Blockers
              </Text>
              <IssuesBlockers init={normalizedInit} />
            </Box>
            <Box
              padding={`${scaffolding.md} ${scaffolding.lg} 0 ${
                scaffolding.lg
              }`}
            >
              <Text
                marginBottom={scaffolding.lg}
                opacity={normalizedInit.description ? 1 : 0.3}
              >
                {normalizedInit.description || 'Description not available'}
              </Text>
            </Box>
            <Box
              padding={`0px ${scaffolding.lg} ${scaffolding.xs} ${
                scaffolding.lg
              }`}
            >
              <DL>
                <DT>Workstream</DT>
                <Menu
                  items={config.workstream}
                  selected={normalizedInit.workstream}
                  readOnly
                  showIcon={false}
                />
              </DL>
              <DL>
                <DT>Stage</DT>
                <Menu
                  items={config.stage_gate}
                  selected={normalizedInit.stage_gate_id}
                  readOnly
                  showIcon={false}
                />
              </DL>

              <DL>
                <DT>Business Unit</DT>
                <Menu
                  items={config.business_unit}
                  selected={normalizedInit.business_unit}
                  readOnly
                  showIcon={false}
                />
              </DL>

              <DL>
                <DT>Difficulty</DT>
                <Menu
                  items={config.difficulty}
                  selected={normalizedInit.difficulty_id}
                  readOnly
                  showIcon={false}
                />
              </DL>

              <DL>
                <DT>Region</DT>
                <Menu
                  items={config.region}
                  selected={normalizedInit.region}
                  readOnly
                  showIcon={false}
                />
              </DL>
              {usersLoading ? (
                <>
                  <UserLoader />
                  <UserLoader />
                  <UserLoader />
                </>
              ) : (
                <>
                  <DL>
                    <Box float="left" marginRight={10}>
                      <UserAvatar user={normalizedInit.lead || {}} />
                    </Box>

                    <DT>Lead</DT>
                    <UserMenu
                      users={users}
                      selected={
                        normalizedInit.lead && normalizedInit.lead.user_id
                      }
                      readOnly
                      showIcon={false}
                    />
                  </DL>

                  <DL>
                    <Box float="left" marginRight={10}>
                      <UserAvatar user={normalizedInit.sponsor || {}} />
                    </Box>

                    <DT>Sponsor</DT>
                    <UserMenu
                      users={users}
                      selected={
                        normalizedInit.sponsor && normalizedInit.sponsor.user_id
                      }
                      readOnly
                      showIcon={false}
                    />
                  </DL>

                  <DL>
                    <Box float="left" marginRight={10}>
                      <UserAvatar user={normalizedInit.approver || {}} />
                    </Box>
                    <DT>Approver</DT>
                    <UserMenu
                      users={users}
                      selected={
                        normalizedInit.approver &&
                        normalizedInit.approver.user_id
                      }
                      readOnly
                      showIcon={false}
                    />
                  </DL>
                </>
              )}
            </Box>
          </Box>
        )}
      </SlidingPanel>
    </>
  );
}

QuickInitPanel.propTypes = {
  init: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  tfs: PropTypes.shape({
    configuration: PropTypes.shape({
      workstream: PropTypes.array.isRequired,
      stage_gate: PropTypes.array.isRequired,
      business_unit: PropTypes.array.isRequired,
      difficulty: PropTypes.array.isRequired,
      region: PropTypes.array.isRequired,
    }).isRequired,
    currency_unit: PropTypes.string.isRequired,
  }).isRequired,
};

function PlannedActualLabels({ planned, actual, color = bayOfMany }) {
  return (
    <Box display="flex" flexWrap="wrap" justifyContent="space-between">
      <Box display="flex" flexDirection="column">
        <Text as="div" size="overline" color={santasGrey}>
          Planned
        </Text>
        <Text size="h3" color={color}>
          <FormattedNumber value={planned} />
        </Text>
      </Box>
      <Box display="flex" flexDirection="column" minWidth="112px">
        <Text as="div" size="overline" color={santasGrey}>
          Actual
        </Text>
        <Text size="h3" color={color}>
          <FormattedNumber value={actual} />
        </Text>
      </Box>
    </Box>
  );
}

function IssuesBlockers({ init }) {
  const keyRisks = useRiskIssueBlockers(init.ref_id);
  const loadingRisks = !keyRisks;
  if (loadingRisks) {
    return <BlockerLoader />;
  }
  const filteredKeyRisks = keyRisks.filter(
    r => !r.resolved && (r.type === 'blocker' && !isBlockerDone(r))
  );
  const groupedRisks = groupBy(filteredKeyRisks, r => r.type);

  return (
    <Box display="flex" flexWrap="wrap" justifyContent="space-between">
      <Box display="flex" flexDirection="column">
        <Text as="div" size="overline" color={santasGrey}>
          Risks
        </Text>
        <Text size="h3" color={bayOfMany}>
          {groupedRisks.risk ? groupedRisks.risk.length : 0}
        </Text>
      </Box>
      <Box display="flex" flexDirection="column">
        <Text as="div" size="overline" color={santasGrey}>
          Issues
        </Text>
        <Text size="h3" color={bayOfMany}>
          {groupedRisks.issue ? groupedRisks.issue.length : 0}
        </Text>
      </Box>
      <Box display="flex" flexDirection="column">
        <Text as="div" size="overline" color={santasGrey}>
          Blockers
        </Text>
        <Text size="h3" color={bayOfMany}>
          {groupedRisks.blocker ? groupedRisks.blocker.length : 0}
        </Text>
      </Box>
    </Box>
  );
}
