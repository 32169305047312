import React from 'react';

import SearchParams from '@hero/tfs/src/shared/SearchParams';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MaterialSwitch from '@hero/core/MaterialSwitch';
import { Text } from '@hero/styles/typography-v5';

export default function ListEditInput() {
  return (
    <SearchParams>
      {({ params, setParams }) => (
        <FormControlLabel
          labelPlacement="end"
          control={
            <MaterialSwitch
              checked={params.isEdit && params.isEdit !== '0'}
              onChange={event => {
                setParams(p => ({
                  ...p,
                  isEdit: event.target.checked ? 1 : 0,
                }));
              }}
              value="edit"
            />
          }
          label={
            <Text size="p2" fontWeight="bold" marginLeft="12px">
              Edit
            </Text>
          }
        />
      )}
    </SearchParams>
  );
}
