import React from 'react';
import { Box } from 'jsxstyle';
import styled from 'styled-components/macro';
import { Container, Row, Col } from 'react-grid-system';
import { rgba } from 'polished';

import { romanRed, bayOfMany } from '@hero/styles/colors-v4';

import { Text } from '@hero/styles/typography-v5';

import EditorAutosave from '@hero/core/EditorAutosave';
import FormattedNumber from '@hero/tfs/src/shared/FormattedNumber';
import EditText from './EditText';
import {
  getBlockerStats,
  getTotalBlockedOutcome,
} from '@hero/tfs/src/03-blockers/shared';
import BlockerStatsRow from '@hero/tfs/src/03-blockers/BlockerStatsRow';
import EditBlockerContainer from '@hero/tfs/src/03-blockers/BlockerForm';

const BlockerText = styled.div`
  cursor: pointer;
  &:hover {
    span {
      text-decoration: underline;
    }
  }
`;

export default function BlockerRow({
  tfs,
  blocker,
  onEditUpdate,
  onTextUpdate,
  onDelete,
}) {
  const [open, setOpen] = React.useState(false);

  const { leadTime, responseTime, cycleTime } = getBlockerStats(blocker);
  const { name, description } = blocker;

  return (
    <>
      <Box
        padding="24px 16px"
        backgroundColor="white"
        boxShadow={`0 2px 4px 0 ${rgba(bayOfMany, 0.1)}`}
        marginBottom="8px"
      >
        <Text
          size="overline"
          as="div"
          color={romanRed}
          marginBottom="12px"
          textTransform="uppercase"
        >
          Blocker
        </Text>
        <Box marginTop="16px" marginBottom="8px">
          <BlockerText onClick={() => setOpen(true)}>
            <Text size="h5" fontWeight="600">
              {name}
            </Text>
          </BlockerText>
        </Box>
        <Box marginBottom="16px">
          <Text>
            <EditorAutosave
              initialValue={description}
              onSubmit={t => onTextUpdate(t)}
            >
              {({ value, onChange }) => (
                <EditText
                  value={value}
                  onChange={onChange}
                  placeholder="Enter blocker description..."
                />
              )}
            </EditorAutosave>
          </Text>
        </Box>
        <Container fluid style={{ margin: 0, padding: 0 }}>
          <Row justify="between" align="center" nogutter>
            <Col sm={12} xl={4}>
              <Text size="h2" fontWeight="bold" color={romanRed}>
                <FormattedNumber value={getTotalBlockedOutcome(blocker)} />
              </Text>
            </Col>
            <Col sm={12} xl={8}>
              <BlockerStatsRow
                width="auto"
                leadTime={leadTime}
                reactionTime={responseTime}
                cycleTime={cycleTime}
                statRowProps={{
                  valueColor: romanRed,
                  marginRight: '24px',
                }}
                naLabel="Not started"
              />
            </Col>
          </Row>
        </Container>
      </Box>
      {open && (
        <EditBlockerContainer
          tfsId={tfs.ref_id}
          isOpen={open}
          configData={tfs.configuration}
          closeModal={blocker => {
            blocker && onEditUpdate(blocker);
            setOpen(false);
          }}
          blockerValue={blocker}
          onDelete={onDelete}
        />
      )}
    </>
  );
}
