import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'jsxstyle';
import { rgba } from 'polished';
import { SingleDatePicker as DatePicker } from 'react-dates';
import moment from 'moment';
import ReactSelect from 'react-select';
import styled from 'styled-components/macro';
import { useSnackbar } from 'notistack';
import uuid from 'uuid/v4';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';

import Close from '@material-ui/icons/Close';
import TimerIcon from '@material-ui/icons/Timer';
import UpdateIcon from '@material-ui/icons/Update';
import BuildIcon from '@material-ui/icons/Build';

import Label from '@hero/core/Label';
import Progress from '@hero/core/Progress';
import { IconButton, UndoButton } from '@hero/core/Button';
import Dialog, { Actions, MainActionBtn } from '@hero/core/Dialog';
import UserAutocompleteInput from '@hero/core/UserAutocompleteInput';
import ObjectInput from '@hero/tfs/src/shared/ObjectInput';
import Fetch from '@hero/tfs/src/shared/Fetch';
import Input from '@hero/core/Input';
import { Switch, TagSegment } from '@hero/core/Switch';
import {
  greyManatee,
  appleBlossom,
  romanRed,
  bayOfMany,
  waikawaGrey,
  chambray,
} from '@hero/styles/colors-v4';
import { TM3, MXXLBold } from '@hero/styles/typography';
import { scaffolding } from '@hero/styles/spacings';

import FormattedNumber from '@hero/tfs/src/shared/FormattedNumber';
import Editor from '@hero/tfs/src/shared/Editor';
import EditorAutosave from '@hero/core/EditorAutosave';
import useInitiativesByTfs from '@hero/tfs/src/shared/hooks/useInitiativesByTfs';
import {
  createDefaultBlocker,
  isValidBlocker,
  getValidBlocker,
  getBlockerStats,
  getTotalBlockedOutcome,
  isBlockerDone,
} from './shared';
import { BlockerStat } from './BlockerStatsRow';
import ActivityBox from './ActivityBox';
import ArchiveBlocker from './ArchiveBlocker';

const MultiValueContainer = props => {
  return null;
};

export const Crumb = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 64px;
  padding: 10px 10px 10px 32px;
  margin-left: -32px;
  background-color: ${props => props.color};
  min-width: ${props => props.width || '224px'};
  &::after {
    content: '';
    position: absolute;
    right: -20px;
    border-top: 32px solid transparent;
    border-bottom: 32px solid transparent;
    border-left: 20px solid ${props => props.color};
    transition: all 0.2s ease-in;
  }
  transition: all 0.2s ease-in;
`;

export const Border = styled.div`
  height: 33px;
  width: 1px;
  border-right: 1px solid #ffffff;
  opacity: 0.2;
  margin-left: auto;
  margin-right: 21px;
  padding-left: 12px;
`;

export function BlockerHeader({ blocker, done }) {
  const { leadTime, responseTime, cycleTime } = getBlockerStats(blocker);
  return (
    <Box display="flex" alignItems="center" flex="1">
      <Crumb color={done ? waikawaGrey : romanRed}>
        {blocker.ref_id ? (
          blocker.initiatives && blocker.initiatives.length > 0 ? (
            <FormattedNumber
              as={MXXLBold}
              value={getTotalBlockedOutcome(blocker)}
            />
          ) : (
            <MXXLBold>Blocker</MXXLBold>
          )
        ) : (
          <MXXLBold>Blocker</MXXLBold>
        )}
      </Crumb>
      {blocker.ref_id && (
        <>
          <BlockerStat
            marginLeft="34px"
            icon={<TimerIcon />}
            title="Lead time:"
            value={leadTime}
          />
          <BlockerStat
            marginLeft="24px"
            icon={<UpdateIcon />}
            title="Response time:"
            value={responseTime}
          />
          <BlockerStat
            marginLeft="24px"
            icon={<BuildIcon />}
            title="Cycle time:"
            value={cycleTime}
          />
        </>
      )}

      <Border />
    </Box>
  );
}

export function BlockerDialog({
  form,
  isOpen,
  value,
  loading,
  configData,
  initiatives,
  onChange,
  onClose,
  onDelete,
}) {
  const done = isBlockerDone(value);

  return (
    <Dialog
      zIndex={10}
      isOpen={isOpen}
      width="840px"
      isDismissable
      onClose={onClose}
      position="flex-start"
      headerColor={done ? chambray : appleBlossom}
      header={<BlockerHeader blocker={value} done={done} />}
    >
      <BlockerForm
        form={form}
        value={value}
        onChange={onChange}
        onStatusChange={changes => {
          changes['timestamp'] = Date.now();
          onChange({
            ...value,
            state: value.state.concat(changes),
          });
        }}
        loading={loading}
        configData={configData}
        initiatives={initiatives}
        onDelete={onDelete}
      />
    </Dialog>
  );
}

const InputBlock = styled.div`
  width: 100%;
  min-width: 230px;
  display: inline-block;
  input:disabled {
    background-color: rgba(139, 147, 166, 0.2);
  }
`;

export function BlockerForm({
  form,
  value,
  loading,
  onChange,
  onStatusChange,
  configData,
  initiatives,
  onDelete,
}) {
  const [isDateFocused, setIsDateFocused] = React.useState(false);

  return (
    <Box display="flex">
      <Box minWidth="588px" maxWidth="588px">
        <InputBlock>
          <Label htmlFor="blocker-title">Blocker title</Label>
          <ObjectInput
            as={Input}
            autoFocus
            id="blocker-title"
            name="name"
            value={value}
            width="100%"
            onChange={e => onChange(e.target.value)}
          />
        </InputBlock>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <InputBlock>
            <Label htmlFor="blocker-due-date" optional>
              Due date
            </Label>
            <Box width="100%" paddingRight={scaffolding.sm}>
              <DatePicker
                date={value.deadline && moment(value.deadline)}
                onDateChange={date =>
                  onChange({
                    ...value,
                    deadline: date ? date.format() : null,
                  })
                }
                focused={isDateFocused}
                onFocusChange={({ focused }) => setIsDateFocused(focused)}
                isOutsideRange={() => false}
                numberOfMonths={1}
                id="blocker-due-date"
                openDirection="down"
                displayFormat="MMM D, YYYY"
                block
              />
            </Box>
          </InputBlock>

          <InputBlock>
            <Label htmlFor="assigned-to" optional>
              Assigned to
            </Label>
            <Box>
              <Fetch.POST url="/api/user/get-users">
                {({ data }) => (
                  <UserAutocompleteInput
                    value={value.assigned_to && value.assigned_to.user_id}
                    users={data ? data.users : []}
                    placeholder="Choose assigned person"
                    onChange={id =>
                      onChange({
                        ...value,
                        assigned_to: {
                          user_id: id,
                          is_active: 1,
                        },
                      })
                    }
                    id="assigned-to"
                    name="assignedTo"
                    autocomplete="off"
                  />
                )}
              </Fetch.POST>
            </Box>
          </InputBlock>
        </Box>
        <InputBlock>
          <Label htmlFor="blocker-state">State</Label>
          <div>
            <ObjectInput
              as={Switch}
              id="state-id"
              name="state_id"
              value={value.state[value.state.length - 1]}
              fullWidth
              onChange={e => onStatusChange(e.target.value)}
            >
              {configData.blocker_state
                .filter(x => String(x._id) !== '-1') // remove not selected options
                .map(({ name, _id }) => (
                  <TagSegment key={_id} value={_id}>
                    {name}
                  </TagSegment>
                ))}
            </ObjectInput>
          </div>
        </InputBlock>
        <InputBlock>
          <Label htmlFor="blocker-description" optional>
            Description
          </Label>
          <Input
            as="textarea"
            id="blocker-description"
            name="description"
            value={value.description}
            width="100%"
            height="60px"
            onChange={e => onChange({ ...value, description: e.target.value })}
            style={{ resize: 'vertical' }}
          />
        </InputBlock>

        <InputBlock>
          <Label htmlFor="proposed-solution" optional>
            Proposed solution
          </Label>
          <Input
            as="textarea"
            id="proposed-solution"
            name="proposed_solution"
            value={value.proposed_solution}
            onChange={e =>
              onChange({ ...value, proposed_solution: e.target.value })
            }
            width="100%"
            height="60px"
            style={{ resize: 'vertical' }}
          />
        </InputBlock>
        <Box marginTop="24px">
          <TM3 style={{ color: bayOfMany, fontWeight: 600 }}>
            Blocked Initiatives
          </TM3>
          <InputBlock>
            <Label htmlFor="proposed-solution" />
            <ReactSelect
              placeholder="Add initiative(s) to the blocker"
              value={value.initiatives.map(x =>
                toInitiativeOption(x, configData, initiatives)
              )}
              components={{ MultiValueContainer }}
              onChange={newValues =>
                onChange({
                  ...value,
                  initiatives: newValues.map(x => fromInitiativeOption(x)),
                })
              }
              isMulti
              name="colors"
              options={initiatives.map(x =>
                toInitiativeOption(x, configData, initiatives)
              )}
              className="basic-multi-select"
              classNamePrefix="blocker-init"
              styles={{
                control: provided => ({
                  ...provided,
                  borderColor: rgba(greyManatee, 0.75),
                  borderRadius: '1px',
                }),
              }}
            />
          </InputBlock>
          {value.initiatives && value.initiatives.length > 0 && (
            <BlockedOutcomes
              blocker={value}
              onDelete={ref_id => {
                onChange({
                  ...value,
                  initiatives: value.initiatives.filter(
                    x => x.ref_id !== ref_id
                  ),
                });
              }}
            />
          )}
        </Box>

        {value.ref_id && <ActivityBox blocker={value} />}
      </Box>
      <Box flex="1">
        <Actions>
          {!value.ref_id && (
            <MainActionBtn
              form={form}
              disabled={loading || !isValidBlocker(value)}
              type="submit"
            >
              Create
            </MainActionBtn>
          )}
          {value.ref_id && <ArchiveBlocker onDelete={onDelete} />}
        </Actions>
      </Box>
    </Box>
  );
}

function BlockedOutcomes({ blocker, onDelete }) {
  const totalBlocked = getTotalBlockedOutcome(blocker);
  const isNew = !blocker.ref_id;
  return (
    <Box marginTop={scaffolding.sm}>
      {blocker.initiatives.length > 1 && (
        <Progress paddingLeft="32px">
          <Progress.Header>
            <Progress.Current>Total outcome blocked</Progress.Current>
            <Progress.Total color={bayOfMany}>
              <FormattedNumber value={totalBlocked} />
            </Progress.Total>
          </Progress.Header>

          <Progress.Line progress={1} background={bayOfMany} />
          <Progress.AtTheTime progress={1} />
        </Progress>
      )}
      {blocker.initiatives
        .slice(0)
        .sort((a, b) => b.outcome_value - a.outcome_value)
        .map((x, i) => {
          const progress =
            totalBlocked > 0 ? x.outcome_value / totalBlocked : 0;
          return (
            <Box display="flex" key={x.ref_id}>
              <IconButton
                disabled={!isNew && blocker.initiatives.length === 1}
                onClick={() => {
                  onDelete(x.ref_id);
                }}
                style={{ marginRight: 4 }}
              >
                <Close />
              </IconButton>
              <Progress width="100%">
                <Progress.Header>
                  <Progress.Current>{x.name}</Progress.Current>
                  <Progress.Total color={romanRed}>
                    <FormattedNumber value={x.outcome_value} />
                  </Progress.Total>
                </Progress.Header>

                <Progress.Line progress={progress} background={romanRed} />
                <Progress.AtTheTime progress={progress} />
              </Progress>
            </Box>
          );
        })}
    </Box>
  );
}

export function CreateBlockerContainer({
  tfsId,
  isOpen,
  closeModal,
  onBlockerSubmit,
}) {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Fetch.POST
      url="/api/transformation/read"
      body={{ query: { ref_id: tfsId } }}
      render={({ data: tfs }) => (
        <Fetch.POST
          url="/api/initiative/read-by-transformation-id"
          body={{ query: { ref_id: tfsId } }}
          render={({ data: initiatives }) => (
            <Fetch.POST
              manual
              url="/api/blocker/create"
              onFetch={() =>
                enqueueSnackbar('Blocker created successfully', {
                  variant: 'success',
                })
              }
            >
              {({ loading, doRequest: createBlocker }) => (
                <FormattedNumber.TfsProvider tfs={tfs}>
                  <Editor
                    id="create-blocker"
                    initialValue={createDefaultBlocker({
                      configData: tfs.configuration,
                      initiatives: [],
                    })}
                    onSubmit={blocker => {
                      if (isValidBlocker(blocker)) {
                        createBlocker({
                          body: getValidBlocker(blocker),
                        }).then(() => {
                          onBlockerSubmit();
                        });
                        closeModal();
                      }
                    }}
                  >
                    {({ value, onChange }) => (
                      <BlockerDialog
                        form="create-blocker"
                        isOpen={isOpen}
                        loading={loading}
                        value={value}
                        configData={tfs.configuration}
                        initiatives={initiatives}
                        onChange={onChange}
                        onClose={() => {
                          onChange(
                            createDefaultBlocker({
                              configData: tfs.configuration,
                              initiatives: [],
                            })
                          );
                          closeModal();
                        }}
                      />
                    )}
                  </Editor>
                </FormattedNumber.TfsProvider>
              )}
            </Fetch.POST>
          )}
        />
      )}
    />
  );
}

export default function EditBlockerContainer({
  tfsId,
  isOpen,
  closeModal,
  onBlockerSubmit,
  configData,
  blockerValue,
  onDelete,
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const initiatives = useInitiativesByTfs({ tfsId });

  return (
    <Fetch.POST manual url="/api/blocker/update">
      {({ loading, doRequest: updateBlocker }) => (
        <Fetch.PUT manual url={`/api/blocker/archive`}>
          {({ doRequest: archiveBlocker }) => (
            <Fetch.PUT manual url={`/api/blocker/unarchive`}>
              {({ doRequest: unarchiveBlocker }) => (
                <EditorAutosave
                  initialValue={blockerValue}
                  onSubmit={blocker => {
                    if (isValidBlocker(blocker)) {
                      return updateBlocker({
                        body: blocker,
                      }).then(
                        () => onBlockerSubmit && onBlockerSubmit(blocker)
                      );
                    }
                  }}
                  style={{ height: 0 }}
                >
                  {({ value, onChange }) => (
                    <BlockerDialog
                      form="update-blocker"
                      value={value}
                      isOpen={isOpen}
                      onClose={() => {
                        closeModal(value);
                      }}
                      onChange={onChange}
                      loading={loading}
                      configData={configData}
                      initiatives={initiatives || []}
                      onDelete={() =>
                        handleArchive({
                          blockerId: value.ref_id,
                          archiveBlocker,
                          undoBlocker: unarchiveBlocker,
                          enqueueSnackbar,
                          closeSnackbar,
                          restoreBlocker,
                          closeModal,
                          onBlockerSubmit,
                          onDelete,
                        })
                      }
                    />
                  )}
                </EditorAutosave>
              )}
            </Fetch.PUT>
          )}
        </Fetch.PUT>
      )}
    </Fetch.POST>
  );
}

EditBlockerContainer.propTypes = {
  tfsId: PropTypes.string.isRequired,
};

function handleArchive({
  blockerId,
  archiveBlocker,
  undoBlocker,
  enqueueSnackbar,
  closeSnackbar,
  closeModal,
  onBlockerSubmit,
  onDelete,
}) {
  const snackBarId = uuid();

  archiveBlocker({
    body: { ref_id: blockerId },
  }).then(() => {
    onBlockerSubmit && onBlockerSubmit();
    onDelete && onDelete();
    const key = enqueueSnackbar('Blocker archived', {
      variant: 'success',
      action: (
        <UndoButton color="primary" size="small">
          Undo
        </UndoButton>
      ),
      onClickAction: () =>
        restoreBlocker({
          blockerId,
          undoBlocker,
          onBlockerSubmit,
          closeSnackbar,
          snackBarId,
        }),
      onExited: () => {
        const snackbarKeys = {
          ...JSON.parse(sessionStorage.getItem('snackbarKeys')),
          [snackBarId]: key,
        };
        const updatedKeys = omit(snackbarKeys, snackBarId);

        if (isEmpty(updatedKeys)) {
          sessionStorage.removeItem('snackbarKeys');
        } else {
          sessionStorage.setItem(
            'snackbarKeys',
            JSON.stringify(omit(snackbarKeys, snackBarId))
          );
        }
      },
    });

    const snackbarKeys = {
      ...JSON.parse(sessionStorage.getItem('snackbarKeys')),
      [snackBarId]: key,
    };

    sessionStorage.setItem('snackbarKeys', JSON.stringify(snackbarKeys));
  });

  closeModal();
}

function restoreBlocker({
  blockerId,
  undoBlocker,
  onBlockerSubmit,
  closeSnackbar,
  snackBarId,
}) {
  undoBlocker({
    body: {
      ref_id: blockerId,
    },
  }).then(() => {
    if (typeof onBlockerSubmit === 'function') {
      onBlockerSubmit && onBlockerSubmit();
    }
  });

  const snackbarKey = JSON.parse(sessionStorage.getItem('snackbarKeys'))[
    snackBarId
  ];
  closeSnackbar(snackbarKey);
}

function toInitiativeOption(initiative, configData, initiativeList) {
  const { name, ref_id, outcome_value } = initiative;
  const initiativeInstance = initiativeList.find(x => x.ref_id == ref_id); // eslint-disable-line eqeqeq
  const workstream = initiativeInstance ? initiativeInstance.workstream : null;
  const ws = configData.workstream.find(x => x._id == workstream); // eslint-disable-line eqeqeq

  if (!ws) {
    console.warn(`Can not find workstream ${workstream}`);
    console.table(configData.workstream);
  }

  return {
    name,
    outcome_value,
    value: ref_id,
    label: ws ? `${name} - ${ws.name}` : name,
  };
}

function fromInitiativeOption({ name, value, outcome_value }) {
  return {
    name,
    ref_id: value,
    outcome_value,
  };
}
