import React from 'react';
import styled from 'styled-components/macro';
import { createEditor, Node } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { greenCarribean, ghostGrey } from '@hero/styles/colors-v4';

function serialize(nodes) {
  return nodes.map(n => Node.string(n)).join('\n');
}

function deserialize(text) {
  return text.split('\n').map(line => {
    return {
      children: [{ text: line }],
    };
  });
}

const StyledEditable = styled(Editable)`
  padding: 8px 16px;
  width: 100%;
  line-height: 1.4em;
  min-height: 38px;
  box-shadow: 0 0 0 1px
    ${props => (props.showBorder ? ghostGrey : 'transparent')};
  &:hover {
    cursor: pointer;
    background-color: trasparent;
    box-shadow: 0 0 0 2px ${ghostGrey};
  }
  &:focus {
    cursor: initial;
    background-color: white;
    box-shadow: 0 0 0 2px ${greenCarribean};
  }
`;

export default React.memo(function EditText({ value, onChange, placeholder }) {
  const editor = React.useMemo(
    () => withHistory(withReact(createEditor())),
    []
  );
  return (
    <Slate
      editor={editor}
      value={deserialize(value)}
      onChange={value => onChange(serialize(value))}
    >
      <StyledEditable placeholder={placeholder} showBorder={!value} />
    </Slate>
  );
});
