import React from 'react';
import { Box } from 'jsxstyle';
import { rgba } from 'polished';

import { DangerButton } from '@hero/core/Button';
import { Text } from '@hero/styles/typography-v5';
import ButtonBase from '@material-ui/core/ButtonBase';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditorAutosave from '@hero/core/EditorAutosave';
import ClickOutside from '@hero/tfs/src/shared/ClickOutside';
import { puertoRico, bayOfMany } from '@hero/styles/colors-v4';

import KeyRiskStatus from './KeyRiskStatus';
import EditText from './EditText';

export default function KeyRisk({
  tfs,
  initiative,
  text,
  type,
  resolved,
  onDelete,
  onResolve,
  onTextUpdate,
  onTypeChange,
  onAddBlocker,
  deletingInProgress,
}) {
  return (
    <Box
      padding="24px 16px"
      backgroundColor="white"
      boxShadow={`0 2px 4px 0 ${rgba(bayOfMany, 0.1)}`}
      marginBottom="8px"
    >
      {resolved && (
        <Text
          size="h5"
          as="div"
          color={puertoRico}
          marginBottom="12px"
          fontWeight="500"
        >
          Resolved
        </Text>
      )}
      <Box paddingBottom="24px">
        <Text>
          <EditorAutosave initialValue={text} onSubmit={t => onTextUpdate(t)}>
            {({ value, onChange }) => (
              <EditText
                value={value}
                onChange={onChange}
                placeholder="Enter risk description"
              />
            )}
          </EditorAutosave>
        </Text>
      </Box>

      <Box display="flex" alignItems="center">
        {!resolved && (
          <KeyRiskStatus
            tfs={tfs}
            initiative={initiative}
            type={type}
            text={text}
            onChange={onTypeChange}
            marginRight="auto"
            onAddBlocker={onAddBlocker}
          />
        )}
        <Box display="flex" marginLeft="auto">
          <ConfirmButton inProgress={deletingInProgress} onConfirm={onDelete}>
            Delete
          </ConfirmButton>

          <ButtonBase type="button" onClick={() => onResolve(!resolved)}>
            <Text as="a" size="link" marginLeft={20}>
              {resolved ? 'Mark as unresolved' : 'Resolve'}
            </Text>
          </ButtonBase>
        </Box>
      </Box>
    </Box>
  );
}

function ConfirmButton({ inProgress, onConfirm, children }) {
  const [shouldConfirm, setConfirm] = React.useState(false);

  if (shouldConfirm) {
    return (
      <ClickOutside onClickOutside={() => setConfirm(false)}>
        <DangerButton type="button" disabled={inProgress} onClick={onConfirm}>
          {inProgress ? (
            <span>
              <CircularProgress size={15} /> Deleting...
            </span>
          ) : (
            'Confirm Deletion'
          )}
        </DangerButton>
      </ClickOutside>
    );
  }

  return (
    <ButtonBase type="button" onClick={() => setConfirm(true)}>
      <Text as="a" size="link">
        {children}
      </Text>
    </ButtonBase>
  );
}
