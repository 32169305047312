import styled, { keyframes } from 'styled-components/macro';
import DefaultButton from '@hero/core/Button';
import { toolbarLink, remove } from '@hero/styles/colors';
import { IconCSS, sizes } from '@hero/styles/typography';
import deleteIcon from '@material-ui/icons/Delete';

const slideUpAndFadeIn = keyframes`
  from {
    opacity: 0;
		transform: translate(0px, 50px);
  }

  to {
    opacity: 1;
		transform: translate(0px, 0);
  }
`;

export const Box = styled.div`
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  animation: ${slideUpAndFadeIn} ease 0.3s;
`;

export const Header = styled.header`
  padding: 0 15px 20px 0;
`;

export const Button = styled(DefaultButton)`
  width: 160px;
  height: 38px;
  min-height: 38px;
  margin-left: auto;
  border-radius: 1px;
`;

export const ActionButton = styled.a`
  font-size: ${sizes.s};
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: ${toolbarLink};
  margin-right: 70px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const DeleteLogo = styled(deleteIcon)`
  ${IconCSS};
  &:hover {
    color: ${remove};
  }
`;

export const EllipsisText = styled.div`
  max-width: 30vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
