import React from 'react';
import { Box } from 'jsxstyle';
import { useSnackbar } from 'notistack';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import pick from 'lodash/pick';

import EditorAutosave from '@hero/core/EditorAutosave';

import Page from '@hero/tfs/src/shared/Page';
import SetupSection from './SetupSection';
import OutcomeSection from './OutcomeSection';
import BudgetSection from './BudgetSection';
import ResourceSection from './ResourceSection';
import KeyRisksSection from './KeyRisksSection';
import Fetch from '../shared/Fetch';
import AddNewButton from '../shared/AddNewButton';

import useInitiative from '../shared/hooks/useInitiative';
import useTransformation from '../shared/hooks/useTransformation';
import useUsers from '../shared/hooks/useUsers';
import FormattedNumber from '../shared/FormattedNumber';
import {
  allocate,
  copyActualOutcome,
  copyPlannedOutcome,
  copyPlannedBudget,
  copyActualBudget,
  deserialize,
  serialize,
  RED,
} from '../09-initiatives-editor/shared';
import ArchiveInitiative from './ArchiveInitiative';
import { Divider } from './shared';
import InitiativeLoader from './InitiativeLoader';
import LinksSection from './LinksSection';
import InitActivityBox from './InitActivityBox';

const SETUP_SECTION = 'setup_section';

export default function InitiativeBox({ id, tfsId, baseUrl }) {
  const [tfs] = useTransformation(tfsId);
  const [initiative] = useInitiative(id);
  const users = useUsers();

  const showLoader = !tfs || !initiative || !users || users.length === 0;

  if (!showLoader && tfs._id !== initiative.parent_container_id) {
    return <Redirect to={`${baseUrl}/overview`} />;
  }

  return (
    <Page.Main expand>
      <Container fluid style={{ padding: 0, width: '100%' }}>
        <Row align="center" nogutter>
          <Col sm={6} lg={8}>
            <Page.ArrowBack />
          </Col>
          <Col sm={6} lg={4}>
            <Box
              minHeight="40px"
              maxHeight="40px"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              margin="16px 0 20px"
            >
              {!showLoader && (
                <>
                  <ArchiveInitiative init={initiative} tfsId={tfs.ref_id} />
                  <AddNewButton tfsId={tfs.ref_id} marginLeft="16px" />
                </>
              )}
            </Box>
          </Col>
        </Row>

        {showLoader ? (
          <InitiativeLoader />
        ) : (
          <Row style={{ marginBottom: 50 }}>
            <Col sm={12} lg={4}>
              <InitiativeEditor
                type={SETUP_SECTION}
                initiative={deserialize(
                  pick(initiative, [
                    'parent_container_id',
                    'ref_id',
                    'name',
                    'description',
                    'workstream',
                    'stage_gate',
                    'business_unit',
                    'difficulty',
                    'region',
                    'lead',
                    'sponsor',
                    'approver',
                  ]),
                  users
                )}
                users={users}
              >
                {({ value, onChange }) => (
                  <SetupSection
                    initiative={value}
                    onChange={changes => onChange(v => ({ ...v, ...changes }))}
                    config={tfs.configuration}
                    users={users}
                  />
                )}
              </InitiativeEditor>
              <Divider />
              <InitiativeEditor
                initiative={pick(initiative, [
                  'links',
                  'parent_container_id',
                  'ref_id',
                ])}
              >
                {({ value, onChange }) => (
                  <LinksSection
                    links={value.links}
                    onChange={changes => onChange(v => ({ ...v, ...changes }))}
                  />
                )}
              </InitiativeEditor>
              <Divider />
              <Hidden xs sm md>
                <InitActivityBox refId={initiative.ref_id} />
              </Hidden>
            </Col>
            <Col sm={12} lg={8}>
              <InitiativeEditor
                initiative={pick(initiative, [
                  'parent_container_id',
                  'ref_id',
                  'outcome_value',
                  'outcome_duration',
                  'outcome_tracking',
                  'outcome_period',
                ])}
                users={users}
              >
                {({ value, onChange }) => (
                  <FormattedNumber.TfsProvider tfs={tfs}>
                    <OutcomeSection
                      initiative={value}
                      onRestribute={changes =>
                        onChange(v =>
                          handleOutcomeOnDistribute(v, {
                            ...v,
                            ...changes,
                          })
                        )
                      }
                      onChange={changes =>
                        onChange(v =>
                          handleOutcomeChange(
                            v,
                            { ...v, ...changes },
                            { merge: true }
                          )
                        )
                      }
                    />
                  </FormattedNumber.TfsProvider>
                )}
              </InitiativeEditor>

              <Divider />
              <InitiativeEditor
                initiative={pick(initiative, [
                  'parent_container_id',
                  'ref_id',
                  'cost',
                  'budget_duration',
                  'budget_tracking',
                  'budget_period',
                ])}
                users={users}
              >
                {({ value, onChange }) => (
                  <BudgetSection
                    initiative={value}
                    currencyUnit={tfs.currency_unit}
                    onRestribute={changes =>
                      onChange(v =>
                        handleBudgetOnDistribute(v, {
                          ...v,
                          ...changes,
                        })
                      )
                    }
                    onChange={changes =>
                      onChange(v =>
                        handleBudgetChange(
                          v,
                          { ...v, ...changes },
                          { merge: true }
                        )
                      )
                    }
                  />
                )}
              </InitiativeEditor>

              <Divider />
              <InitiativeEditor initiative={initiative} users={users}>
                {({ value, onChange }) => (
                  <KeyRisksSection
                    initiative={initiative}
                    tfs={tfs}
                    onAddBlocker={() =>
                      value.status !== RED &&
                      onChange(v => ({ ...v, status: RED }))
                    }
                  />
                )}
              </InitiativeEditor>
              <Divider />
              <InitiativeEditor
                initiative={pick(initiative, [
                  'parent_container_id',
                  'ref_id',
                  'resources',
                ])}
                users={users}
              >
                {({ value, onChange }) => (
                  <ResourceSection
                    initiative={value}
                    resourceTypes={tfs.configuration.resource_type.filter(
                      r => r._id !== '-1'
                    )}
                    onChange={changes => onChange(v => ({ ...v, ...changes }))}
                  />
                )}
              </InitiativeEditor>
              <Visible xs sm md>
                <Divider />
                <InitActivityBox refId={initiative.ref_id} />
              </Visible>
            </Col>
          </Row>
        )}
      </Container>
    </Page.Main>
  );
}

function InitiativeEditor({ type, initiative, children, ...props }) {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Fetch.POST
      manual
      url="/api/initiative/update"
      onError={() =>
        enqueueSnackbar('Failed to update the initiative', {
          variant: 'error',
        })
      }
    >
      {({ doRequest: doUpdate }) => (
        <EditorAutosave
          initialValue={initiative}
          onSubmit={newInitiative => {
            if (
              type !== SETUP_SECTION ||
              (type === SETUP_SECTION && isValid(newInitiative))
            ) {
              return doUpdate({
                body:
                  type === SETUP_SECTION
                    ? serialize(newInitiative)
                    : newInitiative,
              });
            }
          }}
          children={children}
          {...props}
        />
      )}
    </Fetch.POST>
  );
}

function isValid(initiative) {
  return initiative.name && initiative.name.length > 0;
}

function handleOutcomeChange(value, changes, { merge = false } = {}) {
  if (
    value.outcome_duration[0] !== changes.outcome_duration[0] ||
    value.outcome_duration[1] !== changes.outcome_duration[1]
  ) {
    const newValue = {
      ...(merge && value),
      ...changes,
      outcome_updated_date: Date.now(),
    };

    return copyPlannedOutcome({
      from: value,
      to: copyActualOutcome({
        from: newValue,
        to: {
          ...newValue,
          outcome_tracking: allocate(
            newValue.outcome_value,
            'monthly',
            newValue.outcome_duration,
            { shouldDistribute: false }
          ),
        },
      }),
    });
  }

  return {
    ...(merge && value),
    ...changes,
    outcome_updated_date: Date.now(),
  };
}

function handleOutcomeOnDistribute(value, changes) {
  const newValue = {
    ...changes,
    outcome_updated_date: Date.now(),
  };

  return copyActualOutcome({
    from: value,
    to: {
      ...newValue,
    },
  });
}

function handleBudgetChange(value, changes, { merge = false } = {}) {
  if (
    value.budget_duration[0] !== changes.budget_duration[0] ||
    value.budget_duration[1] !== changes.budget_duration[1]
  ) {
    const newValue = {
      ...(merge && value),
      ...changes,
      budget_updated_date: Date.now(),
    };

    return copyPlannedBudget({
      from: value,
      to: copyActualBudget({
        from: newValue,
        to: {
          ...newValue,
          budget_tracking: allocate(
            newValue.cost,
            'monthly',
            newValue.budget_duration,
            { shouldDistribute: false }
          ),
        },
      }),
    });
  }
  return {
    ...(merge && value),
    ...changes,
    budget_updated_date: Date.now(),
  };
}

function handleBudgetOnDistribute(value, changes) {
  const newValue = {
    ...changes,
    budget_updated_date: Date.now(),
  };

  return copyActualBudget({
    from: value,
    to: {
      ...newValue,
    },
  });
}
