import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Nav from '@hero/core/Nav';
import { WebPrimaryBtn } from '@hero/core/Button';
import PasswordInput from '@hero/core/PasswordInput';
import PasswordHint from '@hero/core/PasswordHint';
import PasswordStrength from '@hero/core/PasswordStrength';
import { getQueryParams } from '@hero/tfs/src/shared/query-string';
import { LoginContext } from '@hero/tfs/src/shared/LoginContextProvider';

import { ButtonWrapper, ErrorSection, ErrorLabel } from '../styles';
import { Wrapper, LoadingIndicator, Section, PageTitle } from './styles';

const DEFAULT_STATE = {
  password: '',
  confirmPassword: '',
  passwordError: false,
  confirmPasswordError: false,
  notMatchError: false,
  showLoading: false,
  email: '',
};

class ChangePassword extends Component {
  state = DEFAULT_STATE;

  render() {
    const {
      password,
      confirmPassword,
      passwordError,
      confirmPasswordError,
      notMatchError,
      showLoading,
    } = this.state;

    return (
      <Wrapper>
        <Nav fixed />
        <form onSubmit={this.handleSubmit}>
          <Section>
            <PageTitle>Change password</PageTitle>
            <PasswordInput
              width="100%"
              error={passwordError || notMatchError}
              placeholder="New password"
              name={'password'}
              value={password}
              onChange={this.handleChange}
            />
            <PasswordStrength password={password} />
            <PasswordInput
              error={confirmPasswordError || notMatchError}
              placeholder="Confirm new password"
              name={'confirmPassword'}
              value={confirmPassword}
              onChange={this.handleChange}
            />
            <PasswordHint />
            {notMatchError && (
              <ErrorSection>
                <ErrorLabel>Passwords do not match</ErrorLabel>
              </ErrorSection>
            )}
            <ButtonWrapper>
              <WebPrimaryBtn type="submit">
                {!password || notMatchError || passwordError
                  ? 'Waiting for password'
                  : 'Submit'}
              </WebPrimaryBtn>
            </ButtonWrapper>
            <LoadingIndicator loading={showLoading} label={'Please wait....'} />
          </Section>
        </form>
      </Wrapper>
    );
  }

  componentDidMount() {
    const { history, location, forgotPasswordVerify } = this.props;
    const { token } = getQueryParams(location);

    forgotPasswordVerify({ token }).then(response => {
      const { ok, email } = response;
      if (ok) {
        let userEmailAddress = email;
        this.setState({ email: userEmailAddress });
      } else {
        history.push('/unauthorized');
      }
    });
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'Error']: false,
      notMatchError: false,
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { changePassword, location } = this.props;
    const { token } = getQueryParams(location);

    this.validateDetails(() => {
      const {
        password,
        passwordError,
        confirmPasswordError,
        notMatchError,
        email,
      } = this.state;
      const { history } = this.props;
      if (confirmPasswordError || passwordError || notMatchError) {
        return;
      }
      this.setState({
        showLoading: true,
      });

      let newpassword = password;

      changePassword({ token, newpassword, email }).then(response => {
        const { ok, message } = response;
        if (ok) {
          this.setState({
            ...DEFAULT_STATE,
          });
          history.push('/login');
        } else {
          alert(message);
          this.setState({
            showLoading: false,
          });
        }
      });
    });
  };

  validateDetails = cb => {
    const { password, confirmPassword } = this.state;
    const passwordError = !password || password.trim().length === 0;
    const confirmPasswordError =
      !confirmPassword || confirmPassword.trim().length === 0;
    const notMatchError =
      !passwordError && !confirmPasswordError && password !== confirmPassword;

    this.setState(
      {
        confirmPasswordError,
        passwordError,
        notMatchError,
      },
      cb
    );
  };
}

const ChangePasswordWithRouter = withRouter(routeProps => (
  <LoginContext.Consumer>
    {({ token, changePassword, password, forgotPasswordVerify, email }) => (
      <ChangePassword
        {...routeProps}
        newpassword={password}
        changePassword={changePassword}
        token={token}
        forgotPasswordVerify={forgotPasswordVerify}
        email={email}
      />
    )}
  </LoginContext.Consumer>
));

export default ChangePasswordWithRouter;
