import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'jsxstyle';

import Dialog from '@hero/core/Dialog';
import {
  DangerButton,
  SecondaryButton,
  PrimaryButton,
} from '@hero/core/Button';

export default function ConfirmModal({
  zIndex,
  isOpen,
  isDanger,
  closeModal,
  onConfirm,
  title,
  message,
  confirmBtnTitle,
  children,
}) {
  if (!isOpen) {
    return null;
  }

  return (
    <Dialog
      zIndex={zIndex}
      isOpen={isOpen}
      isDismissable
      width="593px"
      header={title}
      footer={() => (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
          {isDanger ? (
            <DangerButton type="submit" onClick={onConfirm} autoFocus>
              {confirmBtnTitle}
            </DangerButton>
          ) : (
            <PrimaryButton type="submit" onClick={onConfirm} autoFocus>
              {confirmBtnTitle}
            </PrimaryButton>
          )}
        </Box>
      )}
      onClose={closeModal}
    >
      {message}
      {children}
    </Dialog>
  );
}

ConfirmModal.propTypes = {
  zIndex: PropTypes.number,
  title: PropTypes.string,
  message: PropTypes.node,
  isOpen: PropTypes.bool,
  isDanger: PropTypes.bool,
  closeModal: PropTypes.func,
  confirmBtnTitle: PropTypes.string,
};

ConfirmModal.defaultProps = {
  usersList: [],
  title: 'Confirmation needed',
  confirmBtnTitle: 'Confirm',
  closeModal: () => {},
};
