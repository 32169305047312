import React from 'react';

const SvgInitiative = props => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" {...props}>
    <g fillRule="nonzero" fill="currentColor">
      <path d="M6.833 21.833a.917.917 0 0 1-.916-.916v-2.25a.917.917 0 0 1 1.833 0v2.25c0 .506-.41.916-.917.916zM10.5 21.833a.917.917 0 0 1-.917-.916v-7.001a.917.917 0 0 1 1.834 0v7c0 .507-.411.917-.917.917zM14.167 21.833a.917.917 0 0 1-.917-.916v-5.5a.917.917 0 0 1 1.833 0v5.5c0 .506-.41.916-.916.916zM17.833 21.833a.917.917 0 0 1-.916-.916v-2.75a.917.917 0 0 1 1.833 0v2.75c0 .506-.41.916-.917.916zM21.5 21.833a.917.917 0 0 1-.917-.916v-8.25a.917.917 0 0 1 1.834 0v8.25c0 .506-.411.916-.917.916zM25.167 21.833a.917.917 0 0 1-.917-.916v-5.251a.917.917 0 0 1 1.833 0v5.25c0 .507-.41.917-.916.917z" />
    </g>
  </svg>
);

export default SvgInitiative;
