import React, { useState } from 'react';
import { Box, Row } from 'jsxstyle';
import Close from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

import { Text } from '@hero/styles/typography-v5';
import { scaffolding } from '@hero/styles/spacings';
import Label from '@hero/core/Label';
import Input from '@hero/core/Input';
import { ActionBtn } from '@hero/core/Dialog';
import { IconButton } from '@hero/core/Button';
import Dialog from '@hero/core/Dialog';

import { romanRed, bayOfMany } from '@hero/styles/colors-v4';

const DEFAULT_LINK = {
  title: '',
  url: '',
};

function isValidUrl(string) {
  try {
    new URL(string);
  } catch (_) {
    return false;
  }

  return true;
}

export default function LinksSection({ links, onChange }) {
  const [editIndex, setEditIndex] = useState(null);

  function addLink(newLink) {
    const newLinks = [...links, newLink];
    onChange({ links: newLinks });
  }

  function editLink(newLink) {
    const newLinks = [...links];
    newLinks.splice(editIndex, 1, newLink);
    setEditIndex(null);
    onChange({ links: newLinks });
  }

  return (
    <Box background="white" width="100%" padding="16px 32px 24px 32px">
      <Box display="flex" justifyContent="space-between">
        <Text size="h4">Links</Text>
      </Box>
      <AddEditLink link={DEFAULT_LINK} onSave={addLink} buttonText="Add" />
      <Box marginTop={scaffolding.sm}>
        {links.map((link, linkIndex) => (
          <Row
            key={link._id || link.url}
            alignItems="center"
            marginBottom={scaffolding.xs}
          >
            <IconButton
              style={{
                marginRight: scaffolding.xxs,
                minHeight: scaffolding.md,
              }}
              onClick={() =>
                onChange({ links: links.filter((_, i) => i !== linkIndex) })
              }
            >
              <Close />
            </IconButton>
            <a
              href={link.url}
              rel="noreferrer noopener"
              target="_blank"
              style={{ flex: 1 }}
            >
              <Text as="div" size="link" width="100%">
                {link.title}
              </Text>
            </a>
            <IconButton
              style={{
                marginLeft: scaffolding.xxs,
                minHeight: scaffolding.md,
              }}
              onClick={() => setEditIndex(linkIndex)}
            >
              <EditIcon />
            </IconButton>
          </Row>
        ))}
      </Box>
      {Number.isInteger(editIndex) && (
        <Dialog
          isOpen
          isDismissable
          header="Edit link"
          headerColor={bayOfMany}
          onClose={() => setEditIndex(null)}
        >
          <AddEditLink
            link={links[editIndex]}
            onSave={editLink}
            buttonText="Update"
          />
        </Dialog>
      )}
    </Box>
  );
}

function AddEditLink({ link, buttonText, onSave }) {
  const [newLink, setNewLink] = useState(link);
  const [errors, setErrors] = useState(DEFAULT_LINK);

  function onClick() {
    const title = !newLink.title ? 'Enter valid title' : '';
    const url =
      !newLink.url || !isValidUrl(newLink.url) ? 'Enter valid url' : '';
    if (title || url) {
      setErrors({
        title,
        url,
      });
    } else {
      setErrors(DEFAULT_LINK);
      setNewLink(DEFAULT_LINK);
      onSave(newLink);
    }
  }

  return (
    <Box>
      <Label htmlFor="link-name">
        Title&nbsp;
        {errors.title && <Text color={romanRed} />}
      </Label>
      <div>
        <Input
          id="link-name"
          name="link"
          placeholder="Link title"
          value={newLink.title}
          onChange={e => {
            const title = e.target.value;
            setNewLink(link => ({ ...link, title }));
          }}
          width="100%"
        />
      </div>
      <Label htmlFor="link-url">
        URL&nbsp;
        {errors.url && <Text color={romanRed}>({errors.url})</Text>}
      </Label>
      <div>
        <Input
          id="link-url"
          name="url"
          placeholder="Link URL"
          value={newLink.url}
          onChange={e => {
            const url = e.target.value;
            setNewLink(link => ({ ...link, url }));
          }}
          width="100%"
        />
      </div>
      <ActionBtn
        disabled={!newLink.title || !newLink.url}
        style={{
          marginLeft: 'auto',
          width: 'auto',
          marginTop: scaffolding.sm,
        }}
        onClick={onClick}
      >
        {buttonText}
      </ActionBtn>
    </Box>
  );
}
