import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'jsxstyle';
import { rgba } from 'polished';
import styled, { css } from 'styled-components/macro';
import mapValues from 'lodash/mapValues';
import uuid from 'uuid/v4';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from 'react-sortable-hoc';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import DragIcon from '@material-ui/icons/DragIndicator';
import {
  greyManatee,
  blueRhino,
  silverChalice,
  redMandy,
  bayOfMany,
} from '@hero/styles/colors-v4';
import { xl, md } from '@hero/styles/typography';
import UserAutocompleteInput from '@hero/core/UserAutocompleteInput';
import Label from '@hero/core/Label';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 56px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => (props.invalid ? redMandy : rgba(bayOfMany, 0.1))};
  transition: border-color 0.2s ease;
  transition-delay: 0.5s;
  border-radius: 1px;
  box-shadow: 0 2px 4px ${rgba(bayOfMany, 0.1)};
  background-color: #ffffff;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Body = styled.div`
  display: flex;
`;

const Msg = styled.div`
  ${md};
  width: 100%;
  text-align: center;
  background-color: #555;
  color: white;
  border-radius: 0 0 4px 4px;
`;

const InvalidMsg = styled.div`
  ${md};
  position: absolute;
  width: 100%;
  box-sizing: content-box;
  background-color: ${redMandy};
  border: 1px solid ${redMandy};
  left: -1px;
  bottom: 0;
  bottom: -15px;
  color: white;
  opacity: 0;
  text-align: center;
  border-radius: 0 0 4px 4px;

  transition: background-color 0.2s ease;
  transition: opacity 0.2s ease;
  transition-delay: 0.5s;

  ${props =>
    props.visible &&
    css`
      background-color: ${redMandy};
      opacity: 1;
    `};
`;

const DragArea = SortableHandle(styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  cursor: move;
`);

const RemoveArea = styled.div`
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 100%;
  min-height: 56px;
  padding: 8px 25px 8px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledMainInput = styled.input`
  ${xl};
  height: 40px;
  width: 100%;
  color: ${blueRhino};
  border: none;
  outline: none;
  padding: 5px;

  &::placeholder {
    color: ${silverChalice};
  }

  &:focus {
    background-color: #f2f2f2;
  }
`;

export function MainInput(props) {
  const inputRef = React.useRef();

  function onKeyPress(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      inputRef.current.blur();
    }
  }

  React.useEffect(() => {
    if (!props.value) {
      inputRef.current.focus();
    }
  }, []);

  return <StyledMainInput ref={inputRef} onKeyPress={onKeyPress} {...props} />;
}

export function LeadInput({ value, users, onChange }) {
  return (
    <Box minWidth="208px" width="100%">
      <Label noMargin>Lead</Label>
      <UserAutocompleteInput value={value} onChange={onChange} users={users} />
    </Box>
  );
}

export default function ConfigEditor({ config, section, onChange, children }) {
  const entries = config[section];

  return children({
    entries,

    reorder: ({ oldIndex, newIndex }) =>
      onChange({
        ...config,
        [section]: arrayMove(entries, oldIndex, newIndex),
      }),

    deleteAt: index =>
      onChange({
        ...config,
        [section]: entries.filter((_, i) => i !== index),
      }),

    getEntryAt: index => entries[index],

    mergeEntryAt: (index, changes) =>
      onChange({
        ...config,
        [section]: entries.map((entry, i) => {
          if (i === index) {
            return {
              ...entry,
              ...changes,
            };
          }

          return entry;
        }),
      }),

    addNewEntry: changes =>
      onChange({
        ...config,
        [section]: entries.concat({
          _id: uuid(),
          ...changes,
        }),
      }),

    isInvalid: index => {
      const { name } = entries[index];
      return entries.some((x, i) => i !== index && x.name === name);
    },
  });
}

export const Container = SortableContainer(({ children }) => (
  <Box padding="0 16px" width="100%">
    {children}
  </Box>
));

export function List({ className = 'config-sort-list', children, ...props }) {
  return (
    <Container
      lockAxis="y"
      useDragHandle={true}
      helperClass={className}
      {...props}
    >
      {children}
    </Container>
  );
}

export const Entry = SortableElement(
  ({ invalid, onDelete, message, zIndex, children }) => (
    <Wrapper invalid={invalid}>
      <Body>
        <DragArea>
          <DragIcon style={{ color: greyManatee }} />
        </DragArea>

        <Content>{children}</Content>

        {onDelete && (
          <RemoveArea>
            <IconButton aria-label="Delete" onClick={onDelete}>
              <DeleteOutlinedIcon
                style={{ fontSize: '24px', color: greyManatee }}
              />
            </IconButton>
          </RemoveArea>
        )}
      </Body>

      <InvalidMsg visible={invalid}>Please remove one of duplicates</InvalidMsg>
      {message && <Msg>{message}</Msg>}
    </Wrapper>
  )
);

Entry.propTypes = {
  children: PropTypes.element,
  onDelete: PropTypes.func,
  invalid: PropTypes.bool,
  message: PropTypes.string,
};

const DEFAULT_ENTRIES = {
  stage_gate: 'No stage gate selected',
  region: 'No region selected',
  resource_type: 'No resource type selected',
  business_unit: 'No business unit selected',
  workstream: 'No workstream selected',
};

export function addDefaultEntries(config) {
  return mapValues(config, (value, key) => {
    if (DEFAULT_ENTRIES[key]) {
      return [
        {
          _id: -1,
          name: DEFAULT_ENTRIES[key],
        },
        ...value,
      ];
    }

    return value;
  });
}

export function removeDefaultEntries(config) {
  return mapValues(config, value => {
    if (Array.isArray(value)) {
      return value.filter(entry => String(entry._id) !== '-1');
    }

    return value;
  });
}
