import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Box } from 'jsxstyle';
import { Container, Row, Col } from 'react-grid-system';

import { ActionBtn } from '@hero/core/Dialog';
import { Text } from '@hero/styles/typography-v5';
import '@hero/styles/tabs.css';
import { santasGrey, blueRhino, bayOfMany } from '@hero/styles/colors-v4';
import FormattedNumber from '../shared/FormattedNumber';
import TrackingDurationField from '../09-initiatives-editor/TrackingDurationField';
import format from 'date-fns/format';
import min from 'date-fns/min';
import LineChart from './LineChart';
import DistributeTracking from './DistributeTracking';
import { BigNumber, getOutcomeValues } from './shared';

export const Input = styled(FormattedNumber.Input)`
  border: 1px solid transparent;
  text-align: right;
  width: 150px;

  &:hover {
    box-shadow: 0 0 0 1px ${santasGrey}, 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  }

  &:focus {
    box-shadow: 0 0 0 1px ${santasGrey}, 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  }
`;

export default React.memo(function OutcomeSection({
  initiative,
  onChange,
  onRestribute,
}) {
  const [showDetails, setShowDetails] = useState(true);
  const [editTracking, setEdittracking] = useState(false);

  const { planned, actual } = getOutcomeValues(initiative);

  const minRecurringDate = min(
    initiative.outcome_tracking
      .filter(x => x.type !== 'lumpsum')
      .map(x => new Date(x.date))
  );

  return (
    <Box background="white">
      <Box padding="16px 32px">
        <Container fluid style={{ margin: 0, padding: 0 }}>
          <Row justify="between" align="center" nogutter>
            <Col sm={12} md={4} lg={4}>
              <Text size="h4">Outcome</Text>
            </Col>
            <Col sm={8} md={5} lg={4.5}>
              <TrackingDurationField
                value={initiative.outcome_duration}
                onChange={outcome_duration => onChange({ outcome_duration })}
                marginLeft="auto"
                display="flex"
                justifyContent="flex-end"
              />
            </Col>
            <Col sm={3} md={2} lg={3} xl={2.5}>
              <ActionBtn
                onClick={() => setShowDetails(!showDetails)}
                style={{
                  marginBottom: 0,
                  maxWidth: '112px',
                  marginLeft: 'auto',
                }}
              >
                {showDetails ? 'Hide details' : 'Edit'}
              </ActionBtn>
            </Col>
          </Row>
        </Container>
      </Box>

      <Box position="relative" padding="16px 26px 16px 32px" display="flex">
        <Box minWidth="290px" paddingRight="5px">
          <table>
            <tbody>
              <tr>
                <td
                  style={{
                    paddingRight: 20,
                    paddingBottom: 16,
                    width: 60,
                  }}
                >
                  <Text size="overline" textTransform="uppercase">
                    Planned
                  </Text>
                  <Box
                    height="1px"
                    width="54px"
                    borderBottom={`1px dashed ${bayOfMany}`}
                    marginTop="4px"
                  />
                </td>
                <td
                  style={{
                    paddingBottom: 8,
                  }}
                >
                  <Text size="h3" color={bayOfMany}>
                    <BigNumber value={planned} />
                  </Text>
                </td>
              </tr>

              <tr>
                <td>
                  <Text size="overline" textTransform="uppercase">
                    Actual
                  </Text>
                  <Box
                    height="2px"
                    width="54px"
                    borderBottom={`2px solid ${bayOfMany}`}
                    marginTop="4px"
                  />
                </td>
                <td>
                  <Text size="h3" color={bayOfMany} lineHeight="38px">
                    <BigNumber value={actual} />
                  </Text>
                </td>
              </tr>
            </tbody>
          </table>
        </Box>

        <LineChart
          type="outcome"
          planned={initiative.outcome_value}
          initiative={initiative}
          marginLeft={20}
          width="100%"
          height={100}
          asOf={new Date(initiative.outcome_duration[1])}
        />
      </Box>
      <DistributeTracking
        editTracking={editTracking}
        type="Outcome"
        value={initiative.outcome_value}
        duration={initiative.outcome_duration}
        tracking={initiative.outcome_tracking}
        period={initiative.outcome_period}
        onDistribute={({ value, tracking, duration, period }) => {
          onRestribute({
            outcome_value: value,
            outcome_tracking: tracking,
            outcome_duration: duration,
            outcome_period: period,
          });
        }}
      />
      {showDetails && (
        <>
          <Box
            height="8px"
            background="linear-gradient(180deg, rgba(49,66,96,0.1) 0%, rgba(49,66,96,0) 100%)"
            width="100%"
          />
          <Box padding="16px 26px 32px 32px">
            <table width="100%">
              <thead>
                <tr>
                  <TH textAlign="left">Quarter</TH>
                  <TH textAlign="left">Month</TH>
                  <TH textAlign="right" paddingRight="16px">
                    Planned
                  </TH>
                  <TH textAlign="right" paddingRight="16px">
                    Actual
                  </TH>
                </tr>
              </thead>

              <tbody>
                {initiative.outcome_tracking.map(
                  ({ date, planned, actual, type }, index) => {
                    return (
                      <TR
                        tabIndex1={index + 1}
                        tabIndex2={
                          initiative.outcome_tracking.length + 1 + index
                        }
                        key={date + index}
                        minRecurringDate={minRecurringDate}
                        date={new Date(date)}
                        planned={planned}
                        actual={actual}
                        onActualChange={changes => {
                          setEdittracking(true);
                          onChange({
                            outcome_tracking: initiative.outcome_tracking.map(
                              (item, i) => {
                                if (index === i) {
                                  return {
                                    ...item,
                                    ...changes,
                                  };
                                }

                                return item;
                              }
                            ),
                          });
                        }}
                        onChange={changes => {
                          setEdittracking(true);
                          onChange({
                            ...getOutcomeTrackingDetails({
                              index,
                              initiative,
                              changes,
                            }),
                          });
                        }}
                      />
                    );
                  }
                )}
              </tbody>
            </table>
          </Box>
        </>
      )}
    </Box>
  );
});

export function TH({ children, ...props }) {
  return (
    <Box component="th" color={santasGrey} textTransform="uppercase" {...props}>
      <Text size="overline" color="inherit">
        {children}
      </Text>
    </Box>
  );
}

export function TR({
  tabIndex1,
  tabIndex2,
  minRecurringDate,
  date,
  actual,
  planned,
  onChange,
  onActualChange,
}) {
  const isFirstMonthOfQuarter = [0, 3, 6, 9].includes(date.getMonth());
  const isLastMonthOfQuarter = [2, 5, 8, 11].includes(date.getMonth());

  return (
    <tr
      style={{
        borderTop:
          isFirstMonthOfQuarter || minRecurringDate.getTime() === date.getTime()
            ? `1px solid ${santasGrey}`
            : 'none',
      }}
    >
      <td>
        {(isFirstMonthOfQuarter ||
          minRecurringDate.getTime() === date.getTime()) && (
          <Text size="p1" fontWeight="bold">
            {format(date, 'QQQ, yyyy')}
          </Text>
        )}
      </td>
      <td
        style={{
          padding: '5px 0',
          borderBottom: isLastMonthOfQuarter
            ? `1px solid ${santasGrey}`
            : `1px solid #E2E1E6`,
          borderRight: `1px solid ${santasGrey}`,
        }}
      >
        <Text size="p1" fontWeight="bold">
          {format(date, 'MMMM')}
        </Text>
      </td>

      <td
        style={{
          textAlign: 'right',
          borderBottom: `1px solid ${santasGrey}`,
          borderRight: `1px solid ${santasGrey}`,

          color: blueRhino,
          maxWidth: 150,
        }}
      >
        <Input
          tabIndex={tabIndex2}
          value={planned}
          showUnit={false}
          onChange={e => onChange({ planned: e.target.value })}
          width="100%"
        />
      </td>
      <td
        style={{
          textAlign: 'right',
          borderBottom: `1px solid ${santasGrey}`,
          borderRight: `1px solid ${santasGrey}`,

          maxWidth: 150,
        }}
      >
        <Input
          tabIndex={tabIndex1}
          value={actual}
          showUnit={false}
          onChange={e => onActualChange({ actual: e.target.value })}
          width="100%"
        />
      </td>
    </tr>
  );
}

function getOutcomeTrackingDetails({ index, changes, initiative }) {
  const outcome_tracking = initiative.outcome_tracking.map((item, i) => {
    if (index === i) {
      return {
        ...item,
        ...changes,
      };
    }

    return item;
  });

  const outcome_value = outcome_tracking.reduce(
    (acc, tracking) => acc + tracking.planned,
    0
  );

  return {
    outcome_tracking,
    outcome_value,
  };
}
