import { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';
import { FetchContext } from './Fetch';
import { addDefaultEntries, removeDefaultEntries } from './ConfigEditor';

export default class ConfigSync extends Component {
  state = {
    value: null,
    lastSyncValue: null,
  };

  onChange = value => {
    this.setState({ value }, () => {
      this.enqueuSync();
    });
  };

  componentDidMount() {
    this.read().catch(err => this.props.onError(err));

    window.addEventListener('beforeunload', event => {
      const changedKeys = findChangedSections(
        this.state.value,
        this.state.lastSyncValue,
        this.props.fieldsToSync
      );

      if (changedKeys.length > 0) {
        event.preventDefault();
        event.returnValue = '';
      }
    });
  }

  read = () => {
    return this.context
      .fetch('/api/configuration/read', {
        method: 'POST',
      })
      .then(data => {
        const value = removeDefaultEntries(data.data);
        this.setState({
          value,
          lastSyncValue: value,
        });
      });
  };

  sync = () => {
    const currentValue = this.state.value;
    const changedKeys = findChangedSections(
      this.state.value,
      this.state.lastSyncValue,
      this.props.fieldsToSync
    );

    if (changedKeys.length > 0) {
      Promise.all(
        changedKeys
          .filter(sectionKey => isValidSection(currentValue[sectionKey]))
          .map(sectionKey =>
            this.context.fetch('/api/configuration/reorder', {
              method: 'POST',
              body: {
                key: sectionKey,
                value: addDefaultEntries(currentValue)[sectionKey],
              },
            })
          )
      )
        .then(() => {
          this.setState({ lastSyncValue: currentValue }, () => {
            this.props.onSync();
          });
        })
        .catch(err => this.props.onError(err));
    }
  };

  enqueuSync = debounce(this.sync, 1000);

  render() {
    if (!this.state.value) {
      return null;
    }

    return this.props.children({
      value: this.state.value,
      onChange: this.onChange,
      sync: this.sync,
    });
  }
}

ConfigSync.propTypes = {
  onSync: PropTypes.func,
  onError: PropTypes.func,
};

ConfigSync.defaultProps = {
  onSync: () => {},
  onError: () => {},
};

function findChangedSections(currentValue, prevValue, sectionKeys) {
  const changedKeys = [];

  sectionKeys.forEach(key => {
    const currentValueWithoutEmptyEntries = currentValue[key].filter(
      ({ name }) => Boolean(name)
    );

    if (!isEqual(currentValueWithoutEmptyEntries, prevValue[key])) {
      changedKeys.push(key);
    }
  });

  return changedKeys;
}

function isValidSection(entries) {
  return uniqBy(entries, 'name').length === entries.length;
}

ConfigSync.contextType = FetchContext;
