import { createGlobalStyle } from 'styled-components/macro';
import { normalize } from 'polished';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { ghostGrey } from '@hero/styles/colors-v4';
import { fonts } from '@hero/styles/fonts';

export default createGlobalStyle`
	${normalize()};
	${fonts};
	*,
	*:before,
	*:after {
		box-sizing: border-box;
	}

	html {
		font-size: 16px;
		font-family: 'Lato', Helvetica, Arial, sans-serif;
		font-weight: normal;
		line-height: 1.3;
		-webkit-font-smoothing: antialiased;
		background-color: #fff;
		color: #333;
	}

	body {
		overflow-x: hidden;
	}

	#root {
		width: 100vw;
		height: 100vh;
		overflow-x: hidden;
		overflow-y: hidden;
		background-color: #f5f7fa;
	}

	#app {
		width: 100vw;
		height: 100vh;
		overflow-y: scroll;
		overflow-x: hidden;
	}

	#header {
		position: absolute;
		width: 100%;
		min-height: 56px;
		background-color:#314260;
		z-index: 1;
	}

	a {
		color: inherit;
		cursor: pointer;
		text-decoration: none;
	}

	h1, h2, h3, h4, h5, h6 {
		margin: 0;
		font-weight: inherit;
	}

	table {
		border-collapse: collapse;
	}

	button {
		font: inherit;
		color: inherit;
		border-radius: 0;
	}

	input, textarea {
		font: inherit;
	}

	input[type="number"] {
		appearance: textfield;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			appearance: none;
			margin: 0;
		}
	}

	#adalIdTokenFrame {
		top: 0;
	}
	.scrollBar,scrollWrap,scrollRail {
		-webkit-transition: opacity .4s cubic-bezier(.25, .46, .45, .94);
		-moz-transition: opacity .4s cubic-bezier(.25, .46, .45, .94);
		-o-transition: opacity .4s cubic-bezier(.25, .46, .45, .94);
		transition: opacity .4s cubic-bezier(.25, .46, .45, .94)
	}

	::-webkit-scrollbar-button,::-webkit-scrollbar-track-piece,::-webkit-scrollbar-corner,::-webkit-resizer {
		display: none
	}

	::-webkit-scrollbar {
		width: 6px;
		height: 8px
	}

	::-webkit-scrollbar-track {
		background: transparent
	}

	::-webkit-scrollbar-thumb {
		background: #c7c7c7
	}

	.sortableHelper {
		z-index: 2;
	}

	.SingleDatePicker .SingleDatePickerInput__withBorder,
	.DateRangePicker .DateRangePickerInput__withBorder {
		border: 1px solid ${ghostGrey};
    border-radius: 1px;
	}

  .SingleDatePicker_picker {
    z-index: 3;
	}
	.SingleDatePickerInput .DateInput_input {
		padding-left: 17px;
	}
  .DateRangePicker .DateInput,
  .SingleDatePicker .DateInput {
		border-radius: 1px;
  }
  .DateInput .DateInput_input {
    height: 38px;
    outline: none;
		border-radius: 1px;
		font-size: 14px;
	}
	.DateRangePicker .DateRangePickerInput_arrow_svg {
		width: 16px;
		height: 16px;
		margin: 4px 0;
	}
	.DateRangePicker .DateInput {
		min-width: 110px;
		width: 110px;
	}

	/* hide @vx/text helper element to fix layout issue */
	body > svg {
		position: absolute;
	}
	/*
		Material Dialog z-index causing issues for
		react sortable hoc drag and drop list items inside Modal dialog
	*/
	.config-sort-list {
		z-index: 1301;
	}
`;
