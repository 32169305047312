import React, { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components/macro';
import { Box } from 'jsxstyle';
import { Text } from '@hero/styles/typography-v5';
import { chambray } from '@hero/styles/colors-v4';

import LightTooltip from '@hero/tfs/src/shared/LightTooltip';
import ChartTooltip from '@hero/tfs/src/shared/ChartTooltip';

import { copyTrackingOverDuration } from '@hero/tfs/src/09-initiatives-editor/shared';

import {
  ZINDEXS,
  ROW_TOP_PADDING,
  HoverCircleCSS,
  ShowHoverCrcleCss,
  ganttRowDimensions,
  formatDate,
  calculateMovedDates,
} from './shared';

const STRIPE_MIN_WIDTH = 10;
const STRIPE_ROW_HEIGHT = 25;
const TOP_OFFSET = 7;

const LineWrapper = styled.li`
  display: block;
  position: ${props => props.position || 'absolute'};
  height: 5px;
  border-radius: 1px;
  will-change: box-shadow, width;
  cursor: ${props => (props.dragging ? 'move' : 'pointer')};
  transition: ${props => (props.highlight ? 'box-shadow 0.2s' : 'none')};
  width: ${props => Math.max(props.width, STRIPE_MIN_WIDTH)}px;
  left: ${props => props.leftOffset}px;
  top: ${props =>
    props.orderIndex * STRIPE_ROW_HEIGHT + TOP_OFFSET + ROW_TOP_PADDING}px;
  background: ${chambray};
  z-index: ${ZINDEXS.outcomeLine};
`;

const LeftEndDragGrip = styled.div`
  position: absolute;
  left: 0;
  width: 2px;
  height: 12px;
  top: -4px;
  cursor: ew-resize;
  background-color: ${chambray};
  ${HoverCircleCSS};
  &::after {
    left: -4px;
    top: 4px;
  }
  ${LineWrapper}:hover & {
    ${ShowHoverCrcleCss};
  }
`;

const RightEndDragGrip = styled.div`
  position: absolute;
  right: 0;
  width: 2px;
  height: 12px;
  top: -4px;
  cursor: ew-resize;
  background-color: ${chambray};
  ${HoverCircleCSS};
  &::after {
    right: -4px;
    top: 4px;
  }
  ${LineWrapper}:hover & {
    &::after {
      visibility: visible;
    }
  }
`;

const CenterWrapper = styled.div`
  position: absolute;
  left: 5px;
  top: 0;
  height: 100%;
  width: calc(100% - 10px);
`;

export const OutcomeLegend = props => {
  return (
    <Box display="flex" alignItems="center" pointerEvents="none" {...props}>
      <LineWrapper width={32} position="relative">
        <LeftEndDragGrip />
        <RightEndDragGrip />
      </LineWrapper>
      <Text size="overline" textTransform="uppercase" marginLeft={8}>
        Outcome
      </Text>
    </Box>
  );
};

export default class OutcomeLine extends Component {
  state = { animated: false };

  componentWillUnmount() {
    this.unmounted = true;
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
  }

  componentDidMount() {
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
  }

  render() {
    const { animated } = this.state;
    const {
      data,
      tfs,
      zeroDate,
      view,
      scaleSize,
      periodMultiplier,
      orderIndex,
      leftMargin,
      onMouseOver,
      onMouseOut,
    } = this.props;

    // As current day is not considered we need to add 0.033 (1/30 of the month).
    const { leftOffset, width } = ganttRowDimensions({
      view,
      startDate: data.outcome_duration[0],
      endDate: data.outcome_duration[1],
      zeroDate,
      leftMargin,
      scaleSize,
      animated,
      periodMultiplier,
    });

    const dragging =
      this.state.isRightDragging ||
      this.state.isLeftDragging ||
      this.state.isCenterDragging;

    if (!animated) {
      this.animateShow();
    }

    return (
      <LineWrapper
        width={width}
        allPeriodItem={data.allPeriodItem}
        orderIndex={orderIndex}
        leftOffset={Math.floor(leftOffset)}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        dragging={dragging}
        onMouseDown={this.onCenterMouseDown}
        onClick={this.onClick}
      >
        <LightTooltip
          placement="top"
          title={<Text size="p2">{formatDate(data.outcome_duration[0])}</Text>}
        >
          <LeftEndDragGrip
            stripeWidth={width}
            onDragStart={this.preventDnD}
            onMouseDown={this.onLeftEndMouseDown}
          />
        </LightTooltip>
        <LightTooltip
          placement="top"
          title={<Text size="p2">{formatDate(data.outcome_duration[1])}</Text>}
        >
          <RightEndDragGrip
            stripeWidth={width}
            onDragStart={this.preventDnD}
            onMouseDown={this.onRightEndMouseDown}
          />
        </LightTooltip>
        <ChartTooltip init={data} tfs={tfs}>
          <CenterWrapper />
        </ChartTooltip>
      </LineWrapper>
    );
  }

  preventDnD = () => false;

  onRightEndMouseDown = event => {
    event.stopPropagation();
    event.preventDefault();
    this.props.onDragStart();
    this.setState({
      isRightDragging: true,
      rightDraggingX: event.screenX,
      cancelClick: false,
    });
  };

  onLeftEndMouseDown = event => {
    event.stopPropagation();
    event.preventDefault();
    this.props.onDragStart();
    this.setState({
      isLeftDragging: true,
      leftDraggingX: event.screenX,
      cancelClick: false,
    });
  };

  onCenterMouseDown = event => {
    event.stopPropagation();
    event.preventDefault();
    this.props.onDragStart();
    this.setState({
      isCenterDragging: true,
      centerDraggingX: event.screenX,
      cancelClick: false,
    });
  };

  onMouseUp = () => {
    if (
      this.state.isRightDragging ||
      this.state.isLeftDragging ||
      this.state.isCenterDragging
    ) {
      if (this.state.initiativePatch) {
        this.props.onInitiaveUpdate(this.state.initiativePatch);
      }

      // fix for click event not triggered
      if (this.state.isCenterDragging && !this.state.cancelClick) {
        this.onClick();
      }
      this.props.onDragEnd();

      this.setState({
        initiativePatch: null,
        isRightDragging: false,
        rightDraggingX: null,
        isLeftDragging: false,
        leftDraggingX: null,
        isCenterDragging: false,
        centerDraggingX: null,
      });
    }
  };

  onMouseMove = event => {
    if (this.state.isRightDragging) {
      this.moveRightTo(event.screenX);
    }
    if (this.state.isLeftDragging) {
      this.moveLeftTo(event.screenX);
    }
    if (this.state.isCenterDragging) {
      this.moveBothTo(event.screenX);
    }
  };

  moveLeftTo(leftDraggingX) {
    const {
      data,
      scaleSize,
      periodMultiplier,
      view,
      liveUpdate,
      scrollAmount,
    } = this.props;

    const { newDate1: startDate } = calculateMovedDates({
      date1: data.outcome_duration[0],
      prevX: this.state.leftDraggingX,
      x: leftDraggingX,
      view,
      scaleSize,
      periodMultiplier,
      scrollAmount,
    });

    if (startDate.diff(moment(data.outcome_duration[1])) > 0) {
      return;
    }

    const initiativePatch = {
      ref_id: data.ref_id,
      parent_container_id: data.parent_container_id,
      outcome_duration: [
        startDate.format(),
        moment(data.outcome_duration[1]).format(),
      ],
    };
    this.setState({ leftDraggingX, initiativePatch, cancelClick: true });

    liveUpdate(initiativePatch);
  }

  moveRightTo(rightDraggingX) {
    const {
      data,
      scaleSize,
      periodMultiplier,
      view,
      liveUpdate,
      scrollAmount,
    } = this.props;

    const { newDate1: endDate } = calculateMovedDates({
      date1: data.outcome_duration[1],
      prevX: this.state.rightDraggingX,
      x: rightDraggingX,
      view,
      scaleSize,
      periodMultiplier,
      scrollAmount,
    });

    if (endDate.diff(moment(data.outcome_duration[0])) < 0) {
      return;
    }

    const initiativePatch = {
      ref_id: data.ref_id,
      parent_container_id: data.parent_container_id,
      outcome_duration: [
        moment(data.outcome_duration[0]).format(),
        endDate.format(),
      ],
    };
    this.setState({ rightDraggingX, initiativePatch, cancelClick: true });

    liveUpdate(initiativePatch);
  }

  moveBothTo(centerDraggingX) {
    const {
      data,
      scaleSize,
      periodMultiplier,
      view,
      liveUpdate,
      scrollAmount,
    } = this.props;

    const { newDate1: startDate, newDate2: endDate } = calculateMovedDates({
      date1: data.outcome_duration[0],
      date2: data.outcome_duration[1],
      prevX: this.state.centerDraggingX,
      x: centerDraggingX,
      view,
      scaleSize,
      periodMultiplier,
      scrollAmount,
    });

    const outcome_duration = [startDate.format(), endDate.format()];

    const outcome_tracking = copyTrackingOverDuration(
      data.outcome_tracking,
      outcome_duration,
      data.outcome_period
    );

    const initiativePatch = {
      ref_id: data.ref_id,
      parent_container_id: data.parent_container_id,
      outcome_duration,
      outcome_tracking,
    };
    this.setState({ centerDraggingX, initiativePatch, cancelClick: true });

    liveUpdate(initiativePatch);
  }

  onClick = () => {
    if (this.state.cancelClick) {
      return;
    }

    const { onInitiativeClick, data } = this.props;

    onInitiativeClick(data);
  };

  animateShowTimeoutRef = null;
  animateShow = () => {
    clearTimeout(this.animateShowTimeoutRef);

    this.animateShowTimeoutRef = setTimeout(() => {
      if (this.unmounted) {
        return;
      }

      this.setState({
        animated: true,
      });
    });
  };
}
