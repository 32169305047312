import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Downshift from 'downshift';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import Input from '@hero/core/Input';

import {
  grey400,
  grey150,
  darkBlack,
  inputBorder,
  white,
} from '@hero/styles/colors';
import { sizes } from '@hero/styles/typography';
import { greyManatee } from '@hero/styles/colors-v4';

const SelectedImageWrapper = styled.div`
  position: absolute;
  left: 1px;
  top: 38px;
`;

const AutocompleteInput = styled(Input)`
  width: 100%;
  min-width: 100%;
  height: 40px;
  &[placeholder] {
    font-size: ${sizes.s2};
    padding-left: 17px;
    outline: none;
  }
  &::placeholder {
    color: ${grey400};
  }
  ${SelectedImageWrapper} + & {
    padding-left: 43px;
  }
`;

const StyledDDIcon = styled(ArrowDropDownIcon)`
  position: absolute;
  right: 2px;
  top: 8px;
  color: ${greyManatee};
  transition: all 0.2s linear;
`;

const Wrapper = styled.div`
  user-select: none;
  outline: none;
  display: inline-block;
  width: ${props => props.width || '100%'};

  height: 40px;
  position: relative;
`;

const ListWrapper = styled.div`
  user-select: none;
  outline: none;
  display: inline-block;
  width: ${props => props.width || '100%'};
  position: absolute;
  max-height: 200px;
  overflow: auto;
  top: 36px;
  left: 0;
  background: ${white};
  border: 1px solid ${inputBorder};
  border-top: none;
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  z-index: 1;
`;

const UserName = styled.span`
  line-height: 28px;
  display: inline-block;
  height: 30px;
  position: absolute;
  bottom: 0;
  left: 45px;
`;

const AvatarIcon = styled.img`
  border-radius: 1px;
  display: inline-block;
  font-size: ${sizes.xxs};
  height: 33px;
  line-height: 33px;
  overflow: hidden;
  text-align: center;
  background-color: ${grey150};
  width: 33px;
  position: absolute;
  bottom: 2px;
  left: 3px;
`;

const AvatarInitials = styled.span`
  border-radius: 1px;
  display: inline-block;
  font-size: ${sizes.xxs};
  height: 33px;
  line-height: 33px;
  overflow: hidden;
  text-align: center;
  background-color: ${grey150};
  width: 33px;
  position: absolute;
  bottom: 2px;
  left: 3px;
  text-transform: uppercase;
`;

const ListItem = styled.div`
  color: ${darkBlack};
  font-size: ${sizes.s2};
  line-height: 38px;
  height: 38px;
  padding-left: 5px;
  border-top: 1px solid ${inputBorder};
  text-decoration: ${props => (props.disabled ? 'line-through' : '')};
  color: ${props => (props.disabled ? grey150 : '')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  cursor: pointer;
  position: relative;
  &:hover {
    background: #eee;
  }
  &[disabled] ${AvatarInitials} {
    color: ${grey400};
  }
`;

function UserAutocompleteInput({
  value,
  users,
  defaultValue,
  invalid,
  placeholder,
  onChange,
}) {
  const [text, setText] = React.useState(getText(users, value));
  const inputRef = React.useRef();

  // Update text value after users were changed
  React.useEffect(() => setText(getText(users, value)), [
    users.map(x => x.user_id).join(),
  ]);

  const items = users
    .filter(user => user.status === 'active')
    .map(user => toUserItem(user));

  const initialText = getText(users, value);
  const visibleItems =
    initialText === text
      ? items
      : items.filter(
          item =>
            item.displayName.toLowerCase().indexOf(text.toLowerCase()) !== -1
        );

  function overrideDefaultSelectAction(e) {
    e.preventDefault();
    e.stopPropagation();

    if (inputRef.current) {
      inputRef.current.blur();
    }
  }

  return (
    <Downshift
      onChange={newValue => {
        setText(getText(users, newValue));
        onChange(newValue);
      }}
      defaultSelectedItem={defaultValue}
      selectedItem={value}
    >
      {({
        selectedItem,
        getInputProps,
        getItemProps,
        getRootProps,
        highlightedIndex,
        isOpen,
        openMenu,
      }) => {
        const selectedListItem = items.find(
          item => String(selectedItem) === String(item.value)
        );

        return (
          <Wrapper {...getRootProps({ refKey: 'ref' })}>
            {selectedListItem && (
              <SelectedImageWrapper onClick={openMenu}>
                {getUserIcon(selectedListItem)}
              </SelectedImageWrapper>
            )}
            <AutocompleteInput
              {...getInputProps({
                placeholder,
                invalid,
                value: text,
                ref: inputRef,
                onChange: event => setText(event.target.value),
                onFocus: openMenu,
                onBlur: () => setText(getText(users, value)),
              })}
            />
            <StyledDDIcon
              onClick={openMenu}
              style={isOpen ? { transform: 'rotate(180deg)' } : {}}
            />
            {isOpen && (
              <ListWrapper>
                {visibleItems.map((item, index) => (
                  <ListItem
                    key={item.value}
                    {...getItemProps({
                      onMouseDown:
                        selectedItem === item.value
                          ? overrideDefaultSelectAction
                          : null,
                      index,
                      item: item.value,
                      disabled: item.disabled,
                      style: {
                        fontWeight:
                          selectedItem === item.value ? 'bold' : 'normal',
                        background:
                          highlightedIndex === index ? '#eee' : 'transparent',
                      },
                    })}
                  >
                    {getUserIcon(item)}
                    <UserName>{item.displayName}</UserName>
                  </ListItem>
                ))}
              </ListWrapper>
            )}
          </Wrapper>
        );
      }}
    </Downshift>
  );
}

UserAutocompleteInput.propTypes = {
  invalid: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      profile_picture: PropTypes.string,
    })
  ),
};

UserAutocompleteInput.defaultProps = {
  invalid: false,
  placeholder: '',
  defaultValue: '',
  value: '',
};

function getUserIcon(item) {
  return item.profilePicture ? (
    <AvatarIcon src={item.profilePicture} title={item.value} />
  ) : (
    <AvatarInitials>{item.initial}</AvatarInitials>
  );
}

function getText(users, value) {
  const selectedItem = users
    .map(user => toUserItem(user))
    .find(item => item.value === value);

  return selectedItem ? selectedItem.displayName : '';
}

function toUserItem(user) {
  return {
    value: user.user_id,
    displayName: `${user.first_name} ${user.last_name}`,
    disabled: user.status === 'disabled',
    profilePicture: user.profile_picture,
    initial: [user.first_name, user.last_name]
      .filter(x => Boolean(x))
      .map(x => x[0])
      .join(''),
  };
}

export default UserAutocompleteInput;
