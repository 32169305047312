import React from 'react';
import styled from 'styled-components/macro';
import { Box } from 'jsxstyle';
import { formatRelative, format, differenceInDays } from 'date-fns';

import MaterialSwitch from '@hero/core/MaterialSwitch';
import Fetch from '@hero/tfs/src/shared/Fetch';
import { MD, TM3 } from '@hero/styles/typography';
import Avatar from '@hero/tfs/src/shared/UserAvatar';
import { scaffolding } from '@hero/styles/spacings';
import { sizes, weights, XXSUpperCase } from '@hero/styles/typography';
import Editor, { TOOLBAR_OPTIONS } from '@hero/tfs/src/shared/slate/Editor';
import { bayOfMany, santasGrey, limeade } from '@hero/styles/colors-v4';
import { getDefaultValue } from '../shared/slate/utils';
import EditorPreview, {
  htmlSerialize,
} from '@hero/tfs/src/shared/slate/EditorPreview';

export const ActionButton = styled.button`
  text-decoration: underline;
  font-size: ${sizes.xs};
  font-weight: ${weights.medium};
  line-height: 19px;
  letter-spacings: 0.3px;
  text-align: center;
  color: ${limeade};
  margin-right: 10px;
  cursor: pointer;
`;

export default function ActivityBox({ blocker, width }) {
  const [commentsOnly, setCommentsOnly] = React.useState('all');
  return (
    <Box
      width={width}
      minHeight="200px"
      position="relative"
      marginTop={scaffolding.xs}
      paddingTop={scaffolding.sm}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <TM3 style={{ color: bayOfMany, fontWeight: 600 }}>Activity</TM3>
        <Box>
          <MaterialSwitch
            id="show-comments"
            checked={commentsOnly === 'comment'}
            onChange={ev =>
              setCommentsOnly(ev.target.checked ? 'comment' : 'all')
            }
          />
          <XXSUpperCase
            as="label"
            style={{ marginLeft: '4px' }}
            htmlFor="show-comments"
          >
            SHOW COMMENTS ONLY
          </XXSUpperCase>
        </Box>
      </Box>
      <Fetch url={`/api/blocker/${blocker.ref_id}/activity`}>
        {({ data, doRequest: reloadActivityFeed }) => (
          <>
            <Fetch.POST
              url={`/api/blocker/${blocker.ref_id}/activity`}
              manual
              onFetch={() => {
                reloadActivityFeed();
              }}
            >
              {({ doRequest: addComment }) => (
                <Box padding="12px 0 11px 0">
                  <Fetch.POST url="/api/user/get-users">
                    {({ data }) => (
                      <Editor
                        toolbarOptions={{
                          ...TOOLBAR_OPTIONS,
                          showStrikeThrough: false,
                          showTitles: false,
                        }}
                        placeholder="Enter comment..."
                        mentionList={
                          data
                            ? data.users
                                .filter(user => user.status === 'active')
                                .map(user => ({
                                  ...user,
                                  id: user.email,
                                  display: [
                                    user.first_name,
                                    user.last_name,
                                  ].join(' '),
                                }))
                            : []
                        }
                        onSubmit={message => {
                          addComment({
                            body: {
                              type: 'comment',
                              message,
                              messageText: htmlSerialize({ children: message }),
                            },
                          });
                        }}
                      />
                    )}
                  </Fetch.POST>
                </Box>
              )}
            </Fetch.POST>

            {data && (
              <Box padding="8px 0">
                {data.data
                  .filter(e =>
                    commentsOnly === 'all' ? true : e.type === commentsOnly
                  )
                  .map(event => {
                    switch (event.type) {
                      case 'comment':
                        return (
                          <Comment
                            key={event.event_id}
                            id={event.event_id}
                            url={`/api/blocker/${blocker.ref_id}/activity/${
                              event.event_id
                            }`}
                            author={event.created_by}
                            date={new Date(event.time)}
                            text={event.message}
                            reloadActivityFeed={reloadActivityFeed}
                          />
                        );
                      case 'create':
                        return (
                          <Comment
                            key={event.event_id}
                            author={event.created_by}
                            date={new Date(event.time)}
                            text={event.message}
                          />
                        );
                      case 'move':
                        return (
                          <StatusChangeEvent
                            key={event.event_id}
                            author={event.author}
                            date={event.date}
                            from={event.from}
                            to={event.to}
                          />
                        );
                      default:
                        return null;
                    }
                  })}
              </Box>
            )}
          </>
        )}
      </Fetch>
    </Box>
  );
}

export function Comment({ id, url, author, date, text, reloadActivityFeed }) {
  const [updateText, setUpdateText] = React.useState(getDefaultValue(text));
  const [showEdit, setShowEdit] = React.useState(false);

  return (
    <Fetch.PUT
      manual
      url={url}
      onFetch={() => {
        reloadActivityFeed();
      }}
    >
      {({ doRequest: updateComment, loading: saving }) => (
        <Box marginBottom={scaffolding.sm}>
          <UserBlock user={author}>
            <Box display="flex" justifyContent="space-between">
              <MD style={{ fontWeight: 'bold' }}>
                {author.first_name + ' ' + author.last_name}{' '}
              </MD>
              <MD color={santasGrey}>
                {formattedRelativeDate(date, new Date())}
              </MD>
            </Box>
            <Box component="p" margin="0" padding="4px 0">
              {showEdit ? (
                <Fetch.POST url="/api/user/get-users">
                  {({ data }) => (
                    <Editor
                      isEdit
                      defaultValue={updateText}
                      toolbarOptions={{
                        ...TOOLBAR_OPTIONS,
                        showStrikeThrough: false,
                        showTitles: false,
                      }}
                      placeholder="Enter comment..."
                      mentionList={
                        data
                          ? data.users
                              .filter(user => user.status === 'active')
                              .map(user => ({
                                ...user,
                                id: user.email,
                                display: [user.first_name, user.last_name].join(
                                  ' '
                                ),
                              }))
                          : []
                      }
                      onChange={setUpdateText}
                      onSubmit={message => {
                        updateComment({
                          body: {
                            type: 'comment',
                            message,
                            messageText: htmlSerialize({ children: message }),
                          },
                        }).then(() => {
                          setShowEdit(false);
                        });
                      }}
                    />
                  )}
                </Fetch.POST>
              ) : (
                <EditorPreview element={{ children: updateText }} />
              )}
            </Box>
            {id && (
              <Box display="flex">
                {showEdit ? (
                  <>
                    <ActionButton as="a" onClick={() => setShowEdit(false)}>
                      Cancel
                    </ActionButton>
                    <ActionButton
                      as="a"
                      onClick={() => {
                        updateComment({
                          body: {
                            type: 'comment',
                            message: updateText,
                            messageText: htmlSerialize({
                              children: updateText,
                            }),
                          },
                        }).then(() => {
                          setShowEdit(false);
                        });
                      }}
                    >
                      {saving ? 'Saving' : 'Save'}
                    </ActionButton>
                  </>
                ) : (
                  <>
                    <ActionButton as="a" onClick={() => setShowEdit(true)}>
                      Edit
                    </ActionButton>
                    <Fetch.DELETE
                      manual
                      url={url}
                      onFetch={() => {
                        reloadActivityFeed();
                      }}
                    >
                      {({ doRequest: deleteComment, loading: deleting }) => (
                        <ActionButton as="a" onClick={deleteComment}>
                          {deleting ? 'Deleting...' : 'Delete'}
                        </ActionButton>
                      )}
                    </Fetch.DELETE>
                  </>
                )}
              </Box>
            )}
          </UserBlock>
        </Box>
      )}
    </Fetch.PUT>
  );
}

export function StatusChangeEvent({ author, date, from, to }) {
  return (
    <UserBlock user={author}>
      <MD style={{ fontWeight: 'bold' }}>
        {author.first_name + ' ' + author.last_name}{' '}
      </MD>
      <MD>
        moved this blocker from {from} to {to}
      </MD>
      <p style={{ margin: 0 }}>
        <MD color={santasGrey}>{formattedRelativeDate(date, new Date())}</MD>
      </p>
    </UserBlock>
  );
}

export function UserBlock({ user, children }) {
  return (
    <Box display="flex" margin="4px 0">
      <Box width="33px" flex="0 1 auto">
        <Avatar user={user} />
      </Box>
      <Box paddingLeft="16px" width="100%">
        {children}
      </Box>
    </Box>
  );
}

export function formattedRelativeDate(d1, d2) {
  return differenceInDays(d2, d1) > 5
    ? format(d1, "MMM dd, yyyy 'at' HH:MM a")
    : formatRelative(d1, d2);
}
