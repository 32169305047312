import { createMuiTheme } from '@material-ui/core/styles';
import { rgba } from 'polished';
import {
  bayOfMany,
  greyManatee,
  puertoRico,
  redMandy,
  ghostGrey,
  quartz,
} from '@hero/styles/colors-v4';

export default createMuiTheme({
  palette: {
    primary: {
      main: bayOfMany,
    },
    secondary: {
      main: puertoRico,
    },
    error: {
      main: redMandy,
    },
    grey: {
      main: ghostGrey,
    },
  },

  typography: {
    useNextVariants: true,
    fontFamily: ['"Lato"', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
  },

  overrides: {
    MuiSelect: {
      root: {
        border: `1px solid ${rgba(greyManatee, 0.75)}`,
        backgroundColor: 'white',
        borderRadius: 1,
        width: '100%',
        fontFamily: 'Lato',
        fontSize: '15px',
        lineHeight: '18px',

        '&:hover': {
          border: `1px solid ${bayOfMany}`,
        },
      },

      select: {
        boxSizing: 'content-box',
        padding: '7.5px 16px',
      },

      selectMenu: {
        height: '21px',
      },
    },

    MuiInput: {
      root: {
        '&:before': {
          border: 'none !important',
        },

        '&:after': {
          display: 'none',
        },
      },
    },

    //TODO: Need to override small swtich styles
    MuiSwitch: {
      switchBase: {
        '&$checked': {
          transform: 'translateX(14px)',
        },
      },
    },

    // Trust me, it works!
    // Modal should not have zIndex. Instead it should be last element in body.
    // Related bug: on config editor we are able to reorder items,
    // but those items are cloned to body and aren't visible when in modal
    // that use zIndex to stay on top.
    MuiModal: {
      root: {
        zIndex: 5,
      },
    },

    MuiDialog: {
      paper: {
        borderRadius: 1,
        backgroundColor: quartz,
      },
    },

    MuiPopover: {
      paper: {
        borderRadius: 1,
      },
    },
    MuiTooltip: {
      popper: {
        opacity: 1,
      },
    },
    MuiSkeleton: {
      root: {
        backgroundColor: rgba(256, 256, 256, 0.4),
      },
    },
  },
});
