import React from 'react';
import styled from 'styled-components/macro';
import escapeHtml from 'escape-html';
import { Text } from 'slate';

import { weights } from '@hero/styles/typography';
import { bayOfMany } from '@hero/styles/colors-v4';
import { MentionElement } from './Mentions';

const blockquoteCss = `
position: relative;
padding-left: 16px;
&::before {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	display: block;
	width: 4px;
	border-radius: 8px;
	background: rgba(49, 66, 96, 0.4);
	content: '';
}`;

export const BlockQuote = styled.blockquote`
  ${blockquoteCss}
`;

const codeCss = `
white-space: pre-wrap;
word-wrap: break-word;
word-break: normal;
-webkit-tab-size: 4;
-moz-tab-size: 4;
tab-size: 4;
padding: 2px 3px 1px;
border: 1px solid rgba(49, 66, 96, 0.05);
border-radius: 3px;
background-color: rgba(49, 66, 96, 0.04);
color: ${bayOfMany};`;

export const Code = styled.code`
  ${codeCss}
`;

export const CodeBlock = styled.pre`
  ${codeCss}
`;

export const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong style={{ fontWeight: weights.bold }}>{children}</strong>;
  }

  if (leaf.code) {
    children = <Code>{children}</Code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.strikethrough) {
    children = <s>{children}</s>;
  }

  return <span {...attributes}>{children}</span>;
};

export const Node = ({ element, attributes, children }) => {
  switch (element.type) {
    case 'block-quote':
      return <BlockQuote {...attributes}>{children}</BlockQuote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'heading-three':
      return <h3 {...attributes}>{children}</h3>;
    case 'heading-four':
      return <h4 {...attributes}>{children}</h4>;
    case 'heading-five':
      return <h5 {...attributes}>{children}</h5>;
    case 'heading-six':
      return <h6 {...attributes}>{children}</h6>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'code-block':
      return <CodeBlock {...attributes}>{children}</CodeBlock>;
    case 'link':
      return <a href={`${escapeHtml(element.url)}`}>{children}</a>;
    case 'mention':
      return <MentionElement element={element} attributes={attributes} />;
    default:
      return (
        <p {...attributes} style={{ margin: 0, padding: 0 }}>
          {children}
        </p>
      );
  }
};

export default function EditorPreview(props) {
  const { element } = props;
  if (Text.isText(element)) {
    return <Leaf leaf={element}> {escapeHtml(element.text)} </Leaf>;
  }

  const children = element.children.map((n, index) => {
    return (
      <EditorPreview key={`${n.type ? n.type + index : index}`} element={n} />
    );
  });

  return <Node {...props}>{children}</Node>;
}

export function renderLeaf(children, leaf) {
  if (leaf.bold) {
    children = `<strong style={font-weight: bold}>${children}</strong>`;
  }

  if (leaf.code) {
    children = `<code style="white-space: pre-wrap;word-wrap: break-word;word-break: normal;-webkit-tab-size: 4;-moz-tab-size: 4;tab-size: 4;padding: 2px 3px 1px;border: 1px solid rgba(49, 66, 96, 0.05);border-radius: 3px;background-color: rgba(49, 66, 96, 0.04);color:#314260;">${children}</code>`;
  }

  if (leaf.italic) {
    children = `<em>${children}</em>`;
  }

  if (leaf.underline) {
    children = `<u>${children}</u>`;
  }

  if (leaf.strikethrough) {
    children = `<s>${children}</s>`;
  }

  return children;
}

export function htmlSerialize(node) {
  if (Text.isText(node)) {
    return renderLeaf(escapeHtml(node.text), node);
  }

  const children = node.children.map(n => htmlSerialize(n)).join('');
  switch (node.type) {
    case 'block-quote':
      return `<blockquote style="position: relative;margin-left: 16px; padding-left: 4px;border: 4px rgba(49, 66, 96, 0.4);border-radius: 8px;">${children}</blockquote>`;
    case 'paragraph':
      return `<p>${children}</p>`;
    case 'bulleted-list':
      return `<ul>${children}</ul>`;
    case 'heading-one':
      return `<h1>${children}</h1>`;
    case 'heading-two':
      return `<h2>${children}</h2>`;
    case 'heading-three':
      return `<h3>${children}</h3>`;
    case 'heading-four':
      return `<h4>${children}</h4>`;
    case 'heading-five':
      return `<h5>${children}</h5>`;
    case 'heading-six':
      return `<h6>${children}</h6>`;
    case 'list-item':
      return `<li>${children}</li>`;
    case 'numbered-list':
      return `<ol>${children}</ol>`;
    case 'code-block':
      return `<code style="white-space: pre-wrap;word-wrap: break-word;word-break: normal;-webkit-tab-size: 4;-moz-tab-size: 4;tab-size: 4;padding: 2px 3px 1px;border: 1px solid rgba(49, 66, 96, 0.05);border-radius: 3px;background-color: rgba(49, 66, 96, 0.04);color:#314260;">${children}</code>`;

    case 'mention':
      return `<a href="mailto:${
        node.mention.id
      }" style="padding: 3px;margin: 0 1px;vertical-align: baseline;display: inline-block;border-radius: 1px;background-color: rgba(49, 66, 96, 0.7);font-size: 16px;color: white;box-shadow:0 0 0 2px #314260;text-decoration:none">${
        node.mention.display
      }</a>`;
    case 'link':
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    default:
      return children;
  }
}
