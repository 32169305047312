import React from 'react';
import styled from 'styled-components/macro';

import { withStyles } from '@material-ui/core';

import { TableRow, TableCell, styles } from '@hero/tfs/src/shared/table';
import { pattensBlue } from '@hero/styles/colors-v4';
import { Text } from '@hero/styles/typography-v5';

const StyledTableRow = styled(TableRow)`
  height: 34px !important;
`;

export const TableRowCell = styled(TableCell)`
  background-color: ${pattensBlue};
  padding: 8px 16px !important;
`;

export const rowStyles = theme => ({
  ...styles(theme),
  title: {
    minWidth: '280px',
    maxWidth: '280px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'sticky',
    left: 0,
    borderRight: `1px solid ${pattensBlue}`,
    backgroundColor: pattensBlue,
  },
});

function ListGroupRow({ classes, cols, title, count }) {
  return (
    <StyledTableRow>
      <TableRowCell className={classes.title} colSpan={6}>
        <Text fontWeight="bold">
          {title} ({count})
        </Text>
      </TableRowCell>
      <TableRowCell colSpan={cols >= 6 ? cols - 6 : cols} />
    </StyledTableRow>
  );
}

export default withStyles(rowStyles)(ListGroupRow);
