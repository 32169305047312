import React from 'react';
import { Box } from 'jsxstyle';

import SelectMenu from '@hero/core/SelectMenu';
import { Text } from '@hero/styles/typography-v5';
import { greyManatee, romanRed } from '@hero/styles/colors-v4';

export default function UserMenu({
  users,
  selected,
  onSelect,
  readOnly,
  showIcon = true,
  maxWidth,
}) {
  const ref = React.useRef();
  const currentUser = users.find(x => String(x.user_id) === String(selected));

  return (
    <DD>
      <SelectMenu.TargetButton
        ref={ref}
        readOnly={readOnly}
        showIcon={showIcon}
        maxWidth={maxWidth}
      >
        {currentUser ? (
          currentUser.first_name + ' ' + currentUser.last_name
        ) : (
          <Text size="p1" color={romanRed}>
            Not selected
          </Text>
        )}
      </SelectMenu.TargetButton>

      <SelectMenu
        selected={selected}
        onChange={selected => onSelect(selected)}
        target={ref}
        width={300}
      >
        <SelectMenu.UserList users={users} />
      </SelectMenu>
    </DD>
  );
}

export function DT(props) {
  return (
    <Text
      as="dt"
      size="overline"
      textTransform="uppercase"
      color={greyManatee}
      {...props}
    />
  );
}

export function DD(props) {
  return <Text as="dd" size="p1" margin="5px 0 0 0" {...props} />;
}

export function DL(props) {
  return <Box component="dl" marginTop="16px" {...props} />;
}
