import React from 'react';

export const BlockersContext = React.createContext(null);

export default function BlockersHost({ children }) {
  const [refreshedOn, refreshBlockers] = React.useState(Date.now());

  return (
    <BlockersContext.Provider value={{ refreshedOn, refreshBlockers }}>
      {children}
    </BlockersContext.Provider>
  );
}
