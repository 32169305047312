import React from 'react';

const SvgClose = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      d="M19.5 20.25a.743.743 0 0 1-.53-.22L12 13.061l-6.97 6.97a.744.744 0 0 1-1.06 0 .752.752 0 0 1 0-1.061L10.94 12 3.97 5.03a.743.743 0 0 1-.22-.53c0-.2.078-.389.22-.53a.743.743 0 0 1 .53-.22c.2 0 .389.078.53.22L12 10.94l6.97-6.97a.744.744 0 0 1 1.06 0c.142.141.22.33.22.53s-.078.389-.22.53L13.061 12l6.97 6.97a.75.75 0 1 1-.531 1.28z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgClose;
