import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import Fetch from '@hero/tfs/src/shared/Fetch';

import { TfsEditorContext } from '@hero/tfs/src/10-transformations-editor/TfsHost';
import { InitiativeContext } from '@hero/tfs/src/12-initiative/InitiativeEditorHost';
import ChartContainer from '@hero/tfs/src/shared/ChartContainer';
import { useLocale } from '@hero/tfs/src/shared/locale';
import EffortValue from './EffortValue';

export default function EffortValueChartContainer({ tfs, groupBy, viewBy }) {
  const [locale] = useLocale();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const workstream = params.get('workstream');
  const tfsLastUpdatedAt = React.useContext(TfsEditorContext);
  const { refreshedOn } = useContext(InitiativeContext);

  return (
    <ChartContainer>
      {({ width, height }) => (
        <Fetch.POST
          key={tfsLastUpdatedAt + refreshedOn} // need this to force update bridge data after edit/create
          url="/api/chart/effort-outcome"
          body={{
            transformation_id: tfs.ref_id,
            group_by: groupBy,
            view_by: viewBy,
            workstream,
          }}
        >
          {({ data }) => (
            <Fetch url={`/api/blocker/${tfs.ref_id}/read-by-transformation-id`}>
              {({ data: blockersData }) =>
                data && (
                  <EffortValue
                    data={data.data.chartdata}
                    viewBy={viewBy}
                    groupBy={groupBy}
                    width={width}
                    height={height}
                    tfs={tfs}
                    locale={locale}
                    blockers={blockersData ? blockersData.data : []}
                  />
                )
              }
            </Fetch>
          )}
        </Fetch.POST>
      )}
    </ChartContainer>
  );
}
