//Good to have all different spacings here

export const scaffolding = {
  xxs: '4px',
  xs: '8px',
  xs1: '12px',
  sm: '16px',
  md: '24px',
  lg: '32px',
  xl: '64px',
};

export const screenClasses = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
};

const breakpoints = [576, 768, 992, 1200];

export const mediaQueries = {
  xs: `screen and (max-width: ${breakpoints[0]})`,
  sm: `screen and (min-width: ${breakpoints[0]})`,
  md: `screen and (min-width: ${breakpoints[1]})`,
  lg: `screen and (min-width: ${breakpoints[2]})`,
  xl: `screen and (min-width: ${breakpoints[3]})`,
};
