import styled from 'styled-components/macro';

import { sBold } from '@hero/styles/typography';

export const FORM_WIDTH = 320;

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  min-width: 300px;
  max-width: 400px;
  min-height: 100vh;
  width: ${FORM_WIDTH}px;
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TabLabel = styled.span`
  text-transform: initial;
  ${sBold};
`;
