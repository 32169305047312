import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Box } from 'jsxstyle';
import isNil from 'lodash/isNil';
import FormattedNumber from '@hero/tfs/src/shared/FormattedNumber';
import { santasGrey } from '@hero/styles/colors-v4';

const activeCss = css`
  box-shadow: 0 0 0 1px ${santasGrey}, 0 2px 6px 0 rgba(0, 0, 0, 0.2);
`;

export const BigInput = styled(FormattedNumber.Input)`
  color: inherit;
  font-size: 32px;
  border: none;
  outline: none;
  padding: 0;
  background-color: transparent;
  min-width: 192px;
  max-width: 192px;
  &:hover {
    ${activeCss};
    cursor: pointer;
  }
  &:focus {
    ${activeCss};
    cursor: initial;
  }
  &:active {
    ${activeCss};
    cursor: initial;
  }
`;

export const BigNumber = styled(FormattedNumber).attrs({
  splitUnit: true,
})`
  font-size: 32px;
  font-weight: 600;
`;

export function Divider(props) {
  return <Box height={24} {...props} />;
}

export function getOutcomeValues(initiative) {
  const planned = initiative.outcome_value;
  const actual = initiative.outcome_tracking
    ? initiative.outcome_tracking
        .filter(x => x.type === 'recurring' || isNil(x.type))
        .map(x => x.actual)
        .reduce((a, b) => a + b, 0)
    : 0;

  return {
    planned,
    actual,
  };
}

export function getBudgetValues(initiative) {
  const planned = initiative.cost;

  const actual = initiative.budget_tracking
    ? initiative.budget_tracking.map(x => x.actual).reduce((a, b) => a + b, 0)
    : 0;
  return {
    planned,
    actual,
  };
}
