import moment from 'moment';
import orderByFunc from 'lodash/orderBy';

const sort = ({ list, orderBy, order = 'asc', type = 'text' }) => {
  if (type === 'date') {
    return orderByDate(list, orderBy, order);
  }
  if (type === 'number') {
    return orderByNumber(list, orderBy, order);
  }
  return orderByFunc(list, [orderBy], [order]);
};

const orderByDate = (list, orderBy, order) =>
  list.sort((listPrev, listNext) =>
    order === 'asc'
      ? moment(listPrev[orderBy]).diff(moment(listNext[orderBy]))
      : moment(listNext[orderBy]).diff(moment(listPrev[orderBy]))
  );

const orderByNumber = (list, field, order) =>
  list.sort((a, b) =>
    order === 'asc' ? a[field] - b[field] : b[field] - a[field]
  );

export default sort;
