import qs from 'qs';

export function getQueryParams(location) {
  return qs.parse(location.search, {
    strictNullHandling: true,
    ignoreQueryPrefix: true,
  });
}

export function getNewQueryParams(location, newParams = {}) {
  const queryParams = getQueryParams(location);
  return qs.stringify({ ...queryParams, ...newParams });
}
