import React, { useState, useContext } from 'react';
import { Box } from 'jsxstyle';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import uuid from 'uuid/v4';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';

import { UndoButton } from '@hero/core/Button';
import { Text } from '@hero/styles/typography-v5';
import ConfirmModal from '@hero/tfs/src/shared/ConfirmModal';
import Fetch from '@hero/tfs/src/shared/Fetch';

import { ListViewContext } from '@hero/tfs/src/07-list/ListViewHost';

export default function ArchiveInitiative({ tfsId, init }) {
  const [openConfirm, setOpenConfirm] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const { refreshListView } = useContext(ListViewContext);

  const handleOnArchive = () => {
    history.push(`/transformations/${tfsId}/list`);
  };

  return (
    <Box>
      <IconButton
        area-label="Archive initiative"
        title="Archive initiative"
        onClick={() => setOpenConfirm(true)}
      >
        <DeleteIcon />
      </IconButton>
      <Fetch.PUT manual url={`/api/initiative/archive`}>
        {({ doRequest: archiveInit }) => (
          <Fetch.PUT manual url={`/api/initiative/unarchive`}>
            {({ doRequest: unarchiveInit }) => (
              <ConfirmModal
                closeModal={() => setOpenConfirm(false)}
                isOpen={openConfirm}
                isDanger
                onConfirm={() =>
                  handleArchive({
                    initId: init.ref_id,
                    enqueueSnackbar,
                    closeSnackbar,
                    archiveInit,
                    undoArchiveInit: unarchiveInit,
                    restoreInit,
                    onArchive: handleOnArchive,
                    refreshListView,
                  })
                }
                title="Archive initiative"
                confirmBtnTitle="Archive"
              >
                <p>
                  Are you sure you want to archive&nbsp;
                  <Text size="h6" fontWeight="bold">
                    {init.name}
                  </Text>
                  &nbsp;?
                </p>
              </ConfirmModal>
            )}
          </Fetch.PUT>
        )}
      </Fetch.PUT>
    </Box>
  );
}

ArchiveInitiative.propTypes = {
  init: PropTypes.object.isRequired,
  tfsId: PropTypes.string.isRequired,
};

function handleArchive({
  initId,
  enqueueSnackbar,
  closeSnackbar,
  archiveInit,
  undoArchiveInit,
  onArchive,
  refreshListView,
}) {
  const snackBarId = uuid();

  archiveInit({
    body: { ref_id: initId },
  }).then(() => {
    const key = enqueueSnackbar('Initiative archived', {
      variant: 'success',
      action: <UndoButton>Undo</UndoButton>,
      onClickAction: () =>
        restoreInit({
          initId,
          undoArchiveInit,
          closeSnackbar,
          snackBarId,
          refreshListView,
        }),
      onExited: () => {
        const snackbarKeys = {
          ...JSON.parse(sessionStorage.getItem('snackbarKeys')),
          [snackBarId]: key,
        };
        const updatedKeys = omit(snackbarKeys, snackBarId);

        if (isEmpty(updatedKeys)) {
          sessionStorage.removeItem('snackbarKeys');
        } else {
          sessionStorage.setItem(
            'snackbarKeys',
            JSON.stringify(omit(snackbarKeys, snackBarId))
          );
        }
      },
    });

    const snackbarKeys = {
      ...JSON.parse(sessionStorage.getItem('snackbarKeys')),
      [snackBarId]: key,
    };

    sessionStorage.setItem('snackbarKeys', JSON.stringify(snackbarKeys));
  });

  onArchive();
}

function restoreInit({
  initId,
  undoArchiveInit,
  closeSnackbar,
  snackBarId,
  refreshListView,
}) {
  undoArchiveInit({
    body: {
      ref_id: initId,
    },
  }).then(() => {
    refreshListView(Date.now());
  });

  const snackbarKey = JSON.parse(sessionStorage.getItem('snackbarKeys'))[
    snackBarId
  ];
  closeSnackbar(snackbarKey);
}
