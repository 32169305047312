import React from 'react';
import { Box } from 'jsxstyle';

import { ghostGrey, quartz } from '@hero/styles/colors-v4';

export default function Grid({
  axisData,
  width,
  scaleSize,
  amountOfPeriodsInAxis,
  sidebarWidth,
  axisHeight,
}) {
  return (
    <Box position="absolute" left={0} top={0} height="100%" width="100%">
      {axisData.map(({ date, endOfYear }, index) => (
        <React.Fragment key={date}>
          <Box
            position="absolute"
            height={`calc(100% - ${axisHeight}px)`}
            top={axisHeight}
            width={scaleSize}
            style={{
              left: index * scaleSize + sidebarWidth,
              backgroundColor: index % 2 ? quartz : 'white',
            }}
          />
          <Box
            position="absolute"
            height={`calc(100% - ${axisHeight}px)`}
            top={axisHeight}
            width={1}
            borderRightStyle={endOfYear ? 'solid' : 'dashed'}
            borderRightWidth="1px"
            borderRightColor={ghostGrey}
            style={{ left: (index + 1) * scaleSize + sidebarWidth }}
            zIndex="1"
          />
        </React.Fragment>
      ))}
    </Box>
  );
}
