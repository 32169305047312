import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { rgba } from 'polished';

import { bayOfMany, ghostGrey } from '@hero/styles/colors-v4';

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'white',
    padding: '8px 16px',
    boxShadow: `0 1px 2px 0 ${rgba(bayOfMany, 0.1)}`,
    border: `1px solid ${ghostGrey}`,
  },
}))(Tooltip);

export default LightTooltip;
