import React from 'react';

const SvgCheck = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <path
      d="M8.017 21.16a2.68 2.68 0 0 1-.516-.052 2.625 2.625 0 0 1-1.681-1.12l-3.58-5.079a1.102 1.102 0 0 1 1.8-1.267l3.594 5.1a.45.45 0 0 0 .383.217.431.431 0 0 0 .346-.174L19.881 4.21a1.099 1.099 0 1 1 1.724 1.364L10.1 20.132a2.613 2.613 0 0 1-.62.581 2.61 2.61 0 0 1-1.463.447z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgCheck;
