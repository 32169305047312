// /user/verify-secondary-email
import React from 'react';
import PropTypes from 'prop-types';

import LoadingIndicator from '@hero/tfs/src/shared/LoadingIndicator';
import { FormattedMessageWithHelmet } from '@hero/tfs/src/shared/FormattedMessage';

import { LoginContext } from '@hero/tfs/src/shared/LoginContextProvider';
import { getQueryParams } from '@hero/core/utils/query-string';

import { setToken } from '@hero/tfs/src/shared/jwt-token';

import { Wrapper } from './styles';
import messages from './messages';

class VerifyEmail extends React.Component {
  static propTypes = {
    verifying: PropTypes.bool,
    success: PropTypes.bool.isRequired,
    accountVerify: PropTypes.func.isRequired,
  };

  static defaultProps = {
    verifying: true,
  };

  render() {
    const { verifying } = this.props;

    return (
      <React.Fragment>
        <FormattedMessageWithHelmet {...messages['title']} />
        <LoadingIndicator
          loading={verifying}
          label={'Please wait, Your account is being verified'}
          render={() => <Wrapper>Redirecting to login page</Wrapper>}
        />
      </React.Fragment>
    );
  }

  componentDidMount() {
    const { history, location, verifyEmail } = this.props;
    const { token } = getQueryParams(location);
    const timeout = setTimeout(() => {
      clearTimeout(timeout);

      verifyEmail({ token }).then(res => {
        const { success } = this.props;
        const { token } = res.data;

        if (success) {
          //Save the token - no redirect to login
          setToken(token);

          history.push('/');
        } else {
          history.push('/unauthorized');
        }
      });
    }, 200);
  }
}

export default props => (
  <LoginContext.Consumer>
    {({ verifying, success, verifyEmail }) => (
      <VerifyEmail
        {...props}
        verifying={verifying}
        success={success}
        verifyEmail={verifyEmail}
      />
    )}
  </LoginContext.Consumer>
);
