import styled from 'styled-components/macro';
import { rgba } from 'polished';

import Button from '@hero/core/Button';
import LoadingIndicatorComponent from '@hero/tfs/src/shared/LoadingIndicator';

import { primary, white, grey150 } from '@hero/styles/colors';
import { mMedium, lMedium } from '@hero/styles/typography';

export const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Section = styled.section`
  width: 400px;
  border: 1px solid ${grey150};
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  padding: 40px 50px;
`;

export const ButtonWrapper = styled(Button).attrs({ type: 'submit' })`
  margin-top: 18px;
`;

export const ButtonText = styled.span`
  text-transform: uppercase;
  color: ${white};
  ${lMedium};
`;

export const LoadingIndicator = styled(LoadingIndicatorComponent)`
  position: absolute;
  right: 0px;
  top: 0;
  background-color: ${rgba(white, 0.8)};
  justify-content: center;
  ${mMedium};
`;

export const PageTitle = styled.span`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  color: ${primary};
  ${lMedium};
`;
