export default class ServerError extends Error {
  constructor(rsp, data) {
    super();

    this.status = rsp.status;
    this.statusText = rsp.statusText;
    this.headers = rsp.headers;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ServerError);
    }

    if (data.message) {
      if (typeof data.message === 'object' && data.message.message) {
        this.message = data.message.message;
      } else {
        this.message = data.message;
      }
    } else if (data.data) {
      this.message = data.data.message;
    } else {
      this.message = `Failed to fetch ${rsp.url}`;
    }
  }
}
