import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import styled from 'styled-components/macro';
import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';
import aVsAn from 'indefinite';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';

import Fetch from '@hero/tfs/src/shared/Fetch';
import ConfirmModal from '@hero/tfs/src/shared/ConfirmModal';
import { TableHeadRow, TableCell, styles } from '@hero/tfs/src/shared/table';
import { toolbarBorder } from '@hero/styles/colors';

import InvitateMembers from './InviteMembers';
import { Box } from './shared';
import UserItem from './UserItem';

export const Toolbar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 12px 24px;
  min-height: 69px;
  border-bottom: 1px solid ${toolbarBorder};
`;

const TableWrapper = styled(Box)`
  padding: 5px;
`;

const tableStyles = theme => ({
  ...styles(theme),
  root: {
    ...styles(theme).root,
    overflowX: 'initial',
  },
});

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'username', numeric: false, disablePadding: false, label: 'MEMBER' },
  { id: 'email', numeric: false, disablePadding: false, label: 'EMAIL' },
  { id: 'role', numeric: false, disablePadding: false, label: 'ROLE' },
  { id: 'status', numeric: false, disablePadding: false, label: 'STATUS' },
  { id: 'manage', numeric: false, disablePadding: false, label: 'MANAGE' },
];

const ACTIVATE = 'activate';
const DEACTIVATE = 'deactivate';
const DELETE = 'delete';
const CHANGE_ROLE = 'change-role';

const ACTION_DIALOG_TITLES = {
  activate: 'Activate',
  deactivate: 'Deactivate',
  delete: 'Delete',
  'change-role': 'Role Change',
};

function ManageMembersHead({
  order,
  orderBy,
  headCellClass,
  onRequestSort,
  can,
}) {
  return (
    <TableHead>
      <TableHeadRow>
        {rows
          .filter(row => (row.id === 'manage' ? can.length > 0 : true))
          .map(row => {
            return (
              <TableCell
                key={row.id}
                numeric={row.numeric}
                className={headCellClass}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={event => onRequestSort(event, row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
      </TableHeadRow>
    </TableHead>
  );
}

ManageMembersHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

class UserActions extends Component {
  state = {
    pendingAction: null,
    user: null,
    role: null,
  };

  render() {
    const { onActivate, onDeactivate, onDelete, onChangeRole } = this.props;
    const { pendingAction, user, role } = this.state;

    return (
      <Fetch.POST manual>
        {({ doRequest }) => (
          <Fragment>
            <ConfirmModal
              closeModal={() =>
                this.setState({ pendingAction: null, user: null })
              }
              isOpen={Boolean(pendingAction && user)}
              isDanger={pendingAction === DELETE}
              onConfirm={() => {
                switch (pendingAction) {
                  case ACTIVATE: {
                    doRequest({
                      url: '/api/user/activate',
                      body: { user_id: user.user_id },
                    }).then(() => {
                      onActivate(user);
                      this.setState({ pendingAction: null, user: null });
                    });

                    break;
                  }

                  case DEACTIVATE: {
                    doRequest({
                      url: '/api/user/disable',
                      body: { user_id: user.user_id },
                    }).then(() => {
                      onDeactivate(user);
                      this.setState({ pendingAction: null, user: null });
                    });

                    break;
                  }

                  case DELETE: {
                    doRequest({
                      url: '/api/user/delete',
                      body: { user_id: user.user_id },
                    }).then(() => {
                      onDelete(user);
                      this.setState({ pendingAction: null, user: null });
                    });

                    break;
                  }

                  case CHANGE_ROLE: {
                    doRequest({
                      url: '/api/user/manage-role',
                      body: { user_id: user.user_id, role: role },
                    }).then(() => {
                      onChangeRole(user, role);
                      this.setState({
                        pendingAction: null,
                        user: null,
                        role: null,
                      });
                    });

                    break;
                  }

                  default:
                    break;
                }
              }}
              title={ACTION_DIALOG_TITLES[pendingAction]}
              message={getConfirmationMessage(pendingAction, user, role)}
            />

            {this.props.children({
              activate: user =>
                this.setState({ pendingAction: ACTIVATE, user }),

              deactivate: user =>
                this.setState({ pendingAction: DEACTIVATE, user }),

              deleteUser: user =>
                this.setState({ pendingAction: DELETE, user }),

              changeRole: (user, role) =>
                this.setState({ pendingAction: CHANGE_ROLE, user, role }),
            })}
          </Fragment>
        )}
      </Fetch.POST>
    );
  }
}

class ManageMembers extends React.Component {
  state = {
    selectedCurrency: null,
    order: 'asc',
    orderBy: 'email',
    selected: [],
    latestUpdated: Date.now(),
  };

  render() {
    const { classes, enqueueSnackbar } = this.props;
    const { order, orderBy, latestUpdated } = this.state;

    return (
      <Fetch.POST
        url="/api/user/current"
        render={({ data: userData }) => (
          <>
            {getPermissions(userData).includes('invite') && (
              <Toolbar>
                <InvitateMembers
                  onInvited={data => {
                    if (data.sendstats.Failed.length === 0) {
                      this.setState({
                        latestUpdated: Date.now(),
                      });
                    }

                    const { Passed, Existing } = data.sendstats;
                    let msg = [];

                    if (Passed.length > 0) {
                      msg.push(`Sent invitation to ${Passed.join(', ')}.`);
                    }

                    if (Existing.length > 0) {
                      msg.push(
                        `Note: ${Existing.join(', ')} already exist${
                          Existing.length > 0 ? 's' : ''
                        }.`
                      );
                    }

                    if (msg.length > 0) {
                      enqueueSnackbar(msg.join(' '), {
                        variant: 'success',
                      });
                    }
                  }}
                />
              </Toolbar>
            )}

            <TableWrapper>
              <Fetch.POST manual url="/api/user/sent-user-invitation">
                {({ doRequest: sendInvitation }) => (
                  <Fetch.POST
                    key={latestUpdated}
                    url="/api/user/get-users"
                    render={({ users }, { doRequest: fetchUsers }) => (
                      <Paper className={classes.root}>
                        <div className={classes.tableWrapper}>
                          <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                          >
                            <ManageMembersHead
                              order={order}
                              orderBy={orderBy}
                              onRequestSort={(_, orderBy) => this.sort(orderBy)}
                              rowCount={users.length}
                              headCellClass={classes.headCell}
                              can={getPermissions(userData)}
                            />

                            <UserActions
                              onActivate={() =>
                                this.handleOnAction(
                                  'User activated successfully',
                                  fetchUsers
                                )
                              }
                              onDeactivate={() =>
                                this.handleOnAction(
                                  'User de-activated successfully',
                                  fetchUsers
                                )
                              }
                              onDelete={() =>
                                this.handleOnAction(
                                  'User deleted successfully',
                                  fetchUsers
                                )
                              }
                              onChangeRole={() =>
                                this.handleOnAction(
                                  'User role changed successfully',
                                  fetchUsers
                                )
                              }
                            >
                              {({
                                activate,
                                deactivate,
                                deleteUser,
                                changeRole,
                              }) => (
                                <TableBody>
                                  {users
                                    .map(user => ({
                                      ...user,
                                      ...(user.status !== 'invited' && {
                                        username: getUsername(user),
                                      }),
                                    }))
                                    .sort(getSorting(order, orderBy))
                                    .map((user, idx) => (
                                      <UserItem
                                        key={`user-${user.user_id}-${idx}`}
                                        rowCellClassName={classes.rowCell}
                                        role="checkbox"
                                        tabIndex={-1}
                                        user={user}
                                        can={getPermissions(userData, user)}
                                        onActivate={() => activate(user)}
                                        onDeactivate={() => deactivate(user)}
                                        onDelete={() => deleteUser(user)}
                                        onInvite={() =>
                                          sendInvitation({
                                            body: { invite_to: [user.email] },
                                          }).then(() =>
                                            this.handleOnAction(
                                              'Invite has been sent successfully',
                                              fetchUsers
                                            )
                                          )
                                        }
                                        onChangeRole={role =>
                                          changeRole(user, role)
                                        }
                                      />
                                    ))}
                                </TableBody>
                              )}
                            </UserActions>
                          </Table>
                        </div>
                      </Paper>
                    )}
                  />
                )}
              </Fetch.POST>
            </TableWrapper>
          </>
        )}
      />
    );
  }

  handleOnAction = (message, fetchUsers) => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(message, {
      variant: 'success',
    });
    fetchUsers();
  };

  sort = orderBy => {
    let order = 'desc';

    if (this.state.orderBy === orderBy && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };
}

function getUsername(user) {
  if (user.first_name && user.last_name) {
    return `${user.first_name} ${user.last_name}`;
  }

  return user.username;
}

const MEMBER = 'member';
const ADMIN = 'admin';
const OWNER = 'owner';
function getPermissions(currentUser, member) {
  if (member && currentUser.user_id === member.user_id) {
    return [];
  }

  if (currentUser.role === OWNER) {
    return [
      'activate',
      'deactivate',
      'delete',
      'invite',
      'make-admin',
      'make-member',
      'make-owner',
    ];
  }

  if (currentUser.role === ADMIN) {
    if (member && member.role === MEMBER) {
      return ['activate', 'deactivate', 'delete', 'invite'];
    } else {
      return ['invite'];
    }
  }

  return [];
}

function getConfirmationMessage(action, user, role) {
  if (!user) {
    return '';
  }
  if (action === 'change-role') {
    return `Please confirm you want to make user "${startCase(
      user.username
    )}" as ${aVsAn(role)}?`;
  }

  return `Please confirm you want to ${lowerCase(
    ACTION_DIALOG_TITLES[action]
  )} the user "${startCase(user.username)} (${user.email})"?`;
}

ManageMembers.propTypes = {
  classes: PropTypes.object.isRequired,
};

ManageMembers.defaultProps = {
  usersList: [],
};

export default withSnackbar(withStyles(tableStyles)(ManageMembers));
