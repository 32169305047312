import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from 'jsxstyle';

import { Text } from '@hero/styles/typography-v5';
import FormattedNumber from '@hero/tfs/src/shared/FormattedNumber';
import LightTooltip from '@hero/tfs/src/shared/LightTooltip';
import { santasGrey, whiteLilac } from '@hero/styles/colors-v4';
import {
  getOutcomeValues,
  getBudgetValues,
} from '@hero/tfs/src/12-initiative/shared';

const GridCell = ({ children, ...props }) => (
  <Text size="p2" as="div" fontWeight="bold" padding="4px 0" {...props}>
    {children}
  </Text>
);

export default function ChartTooltip({ init, tfs, children, ...props }) {
  const { planned: outcomePlanned, actual: outcomeActual } = getOutcomeValues(
    init
  );
  const { planned: budgedPlanned, actual: budgetActual } = getBudgetValues(
    init
  );

  return (
    <LightTooltip
      title={
        <Box as="p">
          <GridCell marginBottom="4px">{init.name}</GridCell>
          <Grid gridTemplateColumns="1fr 1fr 1fr" gridColumnGap="24px">
            <GridCell size="p2" as="div" gridColumnStart={2}>
              Outcome
            </GridCell>
            <GridCell size="p2" as="div">
              Budget
            </GridCell>
            <Box
              height="1px"
              marginBottom="4px"
              backgroundColor={whiteLilac}
              gridColumn="1 / span 3"
            />
            <GridCell color={santasGrey}>Planned</GridCell>
            <FormattedNumber.TfsProvider tfs={tfs}>
              <GridCell>
                <FormattedNumber value={outcomePlanned} />
              </GridCell>
              <GridCell>
                <FormattedNumber value={outcomeActual} />
              </GridCell>
            </FormattedNumber.TfsProvider>
            <GridCell color={santasGrey}>Actual</GridCell>
            <FormattedNumber.CurrencyProvider unit={tfs.currency_unit}>
              <GridCell>
                <FormattedNumber value={budgedPlanned} />
              </GridCell>
              <GridCell>
                <FormattedNumber value={budgetActual} />
              </GridCell>
            </FormattedNumber.CurrencyProvider>
          </Grid>
        </Box>
      }
      aria-label={init.name}
      placement="top"
      {...props}
    >
      {children}
    </LightTooltip>
  );
}

ChartTooltip.propTypes = {
  init: PropTypes.object.isRequired,
  tfs: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};
