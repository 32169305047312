import React from 'react';
import PropTypes from 'prop-types';

import { setToken } from '@hero/tfs/src/shared/jwt-token';
import { fetchApi } from '@hero/tfs/src/shared/fetch-api';

export const LoginContext = React.createContext({});
const DEFAULT_SERVER_ERROR = 'Oops! Something went wrong.';

const handleError = data => {
  //If preflight fails when no server then handle js error
  //If the message is not in JSON format then throw default message
  if (data.message === 'Failed to fetch') {
    return DEFAULT_SERVER_ERROR;
  } else {
    try {
      return JSON.parse(data.message).message;
    } catch (e) {
      return DEFAULT_SERVER_ERROR;
    }
  }
};

export default class LoginContextProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  state = {
    isAuthenticated: false,
    socketUrl: null,
    token: null,
    userId: null,
    loginError: false,
    errorMessage: null,
    registerError: false,
    registerErrorMsg: null,
    registrationSuccess: false,
    verifying: true,
    success: false,

    clearLoginMessage: state => {
      this.setState({
        loginError: false,
        ...state,
      });
    },
    clearRegisterMessage: () => {
      this.setState({
        registerError: false,
        registerErrorMsg: null,
      });
    },

    clearRegistationSuccessMessage: () => {
      this.setState({
        registrationSuccess: false,
      });
    },

    login: details => {
      const body = {
        ...details,
        // tenantId: 'biwas',
      };
      return fetchApi('/user/signin', body)
        .then(response => {
          const { ok, message, data } = response;
          const { token, socketUrl, userId } = data;
          if (ok) {
            setToken(token);
            this.setState({
              socketUrl,
              userId,
              token,
              loginError: false,
              errorMessage: null,
              isAuthenticated: true,
            });
          } else {
            this.setState({
              loginError: true,
              errorMessage: message ? message : DEFAULT_SERVER_ERROR,
            });
          }
          return response;
        })
        .catch(error => {
          const message = handleError(error);
          this.setState({
            loginError: true,
            errorMessage: message ? message : DEFAULT_SERVER_ERROR,
          });
          return error;
        });
    },

    logout: () => {
      const body = {
        token: localStorage.getItem('id_token'),
      };
      return fetchApi(`/user/logout`, body)
        .then(response => {
          this.removeToken();
          return response;
        })
        .catch(error => {
          this.removeToken();
          return error;
        });
    },

    register: details => {
      const body = {
        ...details,
      };
      return fetchApi('/user/register', body)
        .then(response => {
          const { ok, message } = response;
          if (ok) {
            this.setState({
              registrationSuccess: true,
              registerError: false,
              loginError: false,
            });
          } else {
            this.setState({
              registrationSuccess: false,
              registerError: true,
              loginError: false,
              registerErrorMsg: message ? message : DEFAULT_SERVER_ERROR,
            });
          }
          return response;
        })
        .catch(error => {
          const message = handleError(error);
          this.setState({
            success: false,
            registerError: true,
            registerErrorMsg: message ? message : DEFAULT_SERVER_ERROR,
          });
          return error;
        });
    },

    accountVerify: details => {
      const body = {
        ...details,
      };
      return fetchApi('/user/verify-email', body)
        .then(response => {
          const { ok } = response;
          this.setState({
            success: ok,
            verifying: false,
          });
          return response;
        })
        .catch(error => {
          this.setState({
            success: false,
            verifying: false,
          });
          return error;
        });
    },

    verifyEmail: details => {
      const body = {
        ...details,
      };
      return fetchApi('/user/verify-secondary-email', body)
        .then(response => {
          const { ok } = response;
          this.setState({
            success: ok,
            verifying: false,
          });
          return response;
        })
        .catch(error => {
          this.setState({
            success: false,
            verifying: false,
          });
          return error;
        });
    },

    authVerify: details => {
      return fetchApi('/user/tokenVerify', details)
        .then(response => {
          const { ok, locale } = response;
          this.setState({
            success: ok,
            isAuthenticated: ok,
            locale,
          });
          return response;
        })
        .catch(error => {
          this.setState({
            success: false,
            isAuthenticated: false,
          });
          return error;
        });
    },

    resendVerificationEmail: ({ email }) => {
      return fetchApi('/user/resend-verification-email', { email })
        .then(rsp => {
          this.state.clearLoginMessage({ errorMessage: '' });
          return rsp;
        })
        .catch(error => {
          this.setState({ loginError: true, errorMessage: error.message });
        });
    },

    forgotPassword: details => {
      const body = {
        ...details,
      };
      return fetchApi(`/user/forgot-password`, body)
        .then(response => response)
        .catch(error => {
          const message = handleError(error);
          return { ok: false, message };
        });
    },

    forgotPasswordVerify: details => {
      const body = {
        ...details,
      };
      return fetchApi(`/user/forgot-password-tokenVerify`, body)
        .then(response => {
          return response;
        })
        .catch(error => {
          const message = handleError(error);
          return { ok: false, message };
        });
    },

    changePassword: details => {
      const body = {
        ...details,
      };
      return fetchApi(`/user/set-forgot-password`, body)
        .then(response => {
          return response;
        })
        .catch(error => {
          const message = handleError(error);
          return { ok: false, message };
        });
    },
  };

  render() {
    return (
      <LoginContext.Provider value={this.state}>
        {this.props.children}
      </LoginContext.Provider>
    );
  }
}
