import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import Fetch from '@hero/tfs/src/shared/Fetch';
import { TfsEditorContext } from '@hero/tfs/src/10-transformations-editor/TfsHost';
import { InitiativeContext } from '@hero/tfs/src/12-initiative/InitiativeEditorHost';

import ChartContainer from '@hero/tfs/src/shared/ChartContainer';
import BridgeChart from './BridgeChart';

export default function BridgeChartContainer({
  tfs,
  sizeBy,
  groupBy,
  showZeroItems,
}) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const workstream = params.get('workstream');
  const tfsLastUpdatedAt = React.useContext(TfsEditorContext);
  const { refreshedOn } = useContext(InitiativeContext);

  return (
    <ChartContainer>
      {({ width, height }) => (
        <Fetch.POST
          key={tfsLastUpdatedAt + refreshedOn} // need this to force update bridge data after edit/create
          url="/api/chart/bridge"
          body={{
            group_by: groupBy,
            size_by: sizeBy,
            transformation_id: tfs.ref_id,
            workstream,
          }}
        >
          {({ data }) => (
            <Fetch url={`/api/blocker/${tfs.ref_id}/read-by-transformation-id`}>
              {({ data: blockersData }) =>
                data && (
                  <BridgeChart
                    data={removeZeroNotSelected(
                      String(showZeroItems) === '1'
                        ? data.data
                        : removeZeroItems(data.data)
                    )} // data from the server contains all items including non-contributing to bridge chart
                    blockers={blockersData ? blockersData.data : []}
                    sizeBy={sizeBy}
                    groupBy={groupBy}
                    tfs={tfs}
                    currencyType={tfs.currency_unit}
                    timeType={tfs.time_unit}
                    width={width}
                    height={height}
                    config={tfs.configuration}
                  />
                )
              }
            </Fetch>
          )}
        </Fetch.POST>
      )}
    </ChartContainer>
  );
}

function removeZeroItems({ chartdata, ...remaining }) {
  return {
    ...remaining,
    chartdata: chartdata.filter(x => Boolean(x.value)),
  };
}

function removeZeroNotSelected({ chartdata, ...remaining }) {
  return {
    ...remaining,
    chartdata: chartdata.filter(
      x => String(x.ref_id) !== '-1' || Boolean(x.value)
    ),
  };
}
