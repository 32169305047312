import React from 'react';
import { Box, Row, Col } from 'jsxstyle';

import { PlaceholderLoader } from '@hero/tfs/src/shared/Loaders';

import Card, { Header, Content } from './Card';

export default function TfsDashboardLoader() {
  return (
    <Box width="100%">
      <Card backgroundColor="white" marginBottom="32px">
        <Header>
          <PlaceholderLoader type="h3" width="20%" />
        </Header>
        <Content>
          <StatsLoader />
          <PlaceholderLoader
            type="dashBoardChart"
            marginBottom="32px"
            marginLeft="auto"
          />
          <PlaceholderLoader type="dashBoardChart" marginLeft="auto" />
        </Content>
      </Card>
      <Card backgroundColor="white" marginBottom="32px">
        <Header>
          <PlaceholderLoader type="h3" width="20%" />
        </Header>
        <Content>
          <StatsLoader />
          <PlaceholderLoader type="dashBoardChart" marginLeft="auto" />
        </Content>
      </Card>
      <Card backgroundColor="white">
        <Header>
          <PlaceholderLoader type="h3" width="20%" />
        </Header>
        <Content>
          <BlockerStasRow />
        </Content>
      </Card>
    </Box>
  );
}

function BlockerStasRow() {
  const cols = new Array(3).fill(0).map((c, index) => index);
  return (
    <Row width="100%" flexFlow="wrap">
      {cols.map(c => (
        <Row key={c} marginRight="56px" alignItems="center">
          <PlaceholderLoader type="user" height="48px" width="48px" />
          <Col alignItems="center" marginLeft="16px" width={150}>
            <PlaceholderLoader type="p" marginBottom={4} />
            <PlaceholderLoader type="h2" />
          </Col>
        </Row>
      ))}
    </Row>
  );
}

function StatsLoader() {
  const stats = new Array(2).fill(0).map((b, index) => index);
  return (
    <>
      <Row alignItems="center" justifyContent="space-between" marginBottom={24}>
        {stats.map(stat => (
          <Row key={stat} width="50%">
            <Col width="110px" justifyContent="center">
              <PlaceholderLoader type="p" marginBottom={4} />
              <PlaceholderLoader type="progressBar" />
            </Col>
            <PlaceholderLoader type="h2" marginLeft={16} width="80px" />
          </Row>
        ))}
      </Row>
      <Row alignItems="center" justifyContent="space-between" marginBottom={24}>
        {stats.map(stat => (
          <Row key={stat} width="50%">
            <Col width="110px">
              <PlaceholderLoader type="p" marginBottom={4} />
              <PlaceholderLoader type="progressBar" />
            </Col>
            <PlaceholderLoader type="h2" marginLeft={16} width="80px" />
          </Row>
        ))}
      </Row>
    </>
  );
}
