import { keyframes } from 'styled-components';

export const fading = keyframes`
	0% {
		opacity: .8;
	}

	50% {
		opacity: .4;
	}

	100% {
		opacity: 1;
	}
`;

export const rotate = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;
