import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import ConfigSync from '@hero/tfs/src/shared/ConfigSync';
import TfsConfigEditor from '@hero/tfs/src/10-transformations-editor/TfsConfigEditor';
import { Box } from './shared';
import ErrorNotification from './ErrorNotification';

class GlobalConfig extends Component {
  state = {
    error: null,
  };

  showError = error => {
    this.setState({ error });
  };

  hideError = () => {
    this.setState({ error: null });
  };

  render() {
    const { enqueueSnackbar } = this.props;
    const { error } = this.state;

    return (
      <Box style={{ paddingTop: 0 }}>
        <ConfigSync
          fieldsToSync={[
            'stage_gate',
            'region',
            'business_unit',
            'workstream',
            'resource_type',
            'custom_target_types',
            'status',
          ]}
          onError={error => this.showError(error)}
          onSync={() =>
            enqueueSnackbar('All changes saved', { variant: 'success' })
          }
        >
          {({ value, onChange, sync }) => (
            <Fragment>
              <TfsConfigEditor config={value} onChange={onChange} />

              {error && (
                <ErrorNotification
                  message={error.message}
                  onClose={this.hideError}
                  onRetry={() => {
                    this.hideError();
                    sync();
                  }}
                />
              )}
            </Fragment>
          )}
        </ConfigSync>
      </Box>
    );
  }
}

export default withSnackbar(GlobalConfig);
