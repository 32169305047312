import React from 'react';
import useAutosave from './hooks/useAutosave';

export default function EditorAutosave({
  initialValue,
  onSubmit,
  children,
  ...props
}) {
  const [value, setValue] = useAutosave(initialValue, onSubmit);

  function onChange(newValue) {
    setValue(newValue);
  }

  return (
    <form
      onSubmit={e => e.preventDefault()}
      style={{ width: '100%' }}
      {...props}
    >
      {children({
        value,
        onChange,
        initialValue,
      })}
    </form>
  );
}
