import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import SlidingPanelComp from 'react-sliding-side-panel';

import '@reach/dialog/styles.css';
import Close from '@material-ui/icons/Close';
import useControlledState from '@quid/react-use-controlled-state';

import { santasGrey, bayOfMany, whiteLilac } from '@hero/styles/colors-v4';
import { scaffolding } from '@hero/styles/spacings';
import useLockBodyScroll from '@hero/tfs/src/shared/hooks/useLockBodyScroll';
import ClickOutside from '@hero/tfs/src/shared/ClickOutside';

import './SlidingPanel.css';

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${scaffolding.xl};
  font-family: Montserrat, Lato, san-serif;
  font-size: 30px;
  line-height: 37px;
  font-weight: 600;
  color: ${bayOfMany};
  span {
    color: inherit;
  }
  z-index: ${props => (props.zIndex ? props.zIndex + 1 : 'initial')};
  flex: 0 0 auto;
  padding: ${scaffolding.md} ${scaffolding.lg};
  position: sticky;
  top: 0;
  background-color: white;
`;

const DismissIcon = styled(Close)`
  position: absolute;
  top: ${scaffolding.xs};
  right: ${scaffolding.xs};
  cursor: pointer;
  color: ${santasGrey};
  &&.dismiss-icon {
    height: 32px;
    width: 32px;
  }
`;

const Body = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  min-height: calc(100vh - 56px - 63px - 85px);
`;

const Footer = styled.div`
  min-height: 63px;
  flex: 0 0 63px;
  border-top: 1px solid ${whiteLilac};
  padding: ${scaffolding.xs1} ${scaffolding.lg};
  position: sticky;
  bottom: 0;
  background-color: white;
`;

const StyledSlidingPanelContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 0;
  padding: 0;
  width: 100%;
  border-radius: 1px;
  height: 100%;
`;

export default function SlidingPanel({
  isDefaultOpen,
  isOpen,
  size = 22,
  type = 'right',
  header,
  footer,
  onClose,
  children,
}) {
  const [showSlidingPanel, setShowSlidingPanel] = useControlledState(
    isDefaultOpen,
    isOpen,
    onClose
  );

  useLockBodyScroll();

  function handleDismiss() {
    setShowSlidingPanel(false);
  }

  return (
    <SlidingPanelComp
      type={type}
      size={size}
      isOpen={showSlidingPanel}
      backdropClicked={onClose}
    >
      <ClickOutside onClickOutside={handleDismiss}>
        <StyledSlidingPanelContent>
          {header && (
            <Header>
              {header}

              <DismissIcon
                className="dismiss-icon"
                onClick={() => handleDismiss()}
              />
            </Header>
          )}
          <Body className="slidingPanel-body">{children}</Body>
          {footer && (
            <Footer>
              {footer({
                handleClose: () => handleDismiss(),
              })}
            </Footer>
          )}
        </StyledSlidingPanelContent>
      </ClickOutside>
    </SlidingPanelComp>
  );
}

SlidingPanel.propTypes = {
  trigger: PropTypes.node,
  isDefaultOpen: PropTypes.bool,
  isOpen: PropTypes.bool,
  isDismissable: PropTypes.bool,
  isFluid: PropTypes.bool,
  zIndex: PropTypes.number,
  position: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
  width: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  footer: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.element,
  ]),
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
};
