import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import qs from 'qs';

function SearchParams({ children }) {
  return (
    <Route>
      {({ location, history }) => {
        const params = qs.parse(location.search.replace('?', ''));

        return children({
          params,
          setParams(p) {
            let newParams = null;
            if (typeof p === 'function') {
              newParams = p(params);
            } else {
              const search = qs.parse(location.search.replace('?', ''));
              newParams = {
                ...search,
                ...p,
              };
            }
            history.push({
              ...location,
              search: qs.stringify(newParams),
            });
          },
          clearParams() {
            history.push({ location });
          },
        });
      }}
    </Route>
  );
}

SearchParams.propTypes = {
  children: PropTypes.func.isRequired,
};

export default SearchParams;
