import React from 'react';
import styled from 'styled-components';
import { Box } from 'jsxstyle';
import { NavLink } from 'react-router-dom';
import qs from 'qs';

import { santasGrey, bayOfMany, blueRhino } from '@hero/styles/colors-v4';
import SearchParams from '@hero/tfs/src/shared/SearchParams';

export const TfsNavLink = styled(NavLink)`
  outline: none;
  padding: 0px 16px 10px 16px;
  border-bottom: 4px solid
    ${props => (props.active ? blueRhino : 'transparent')};
  transition: 200ms ease-in-out;
  font-family: Lato, san-serif;
  transition-property: opacity, color, background-color, border-color;
  margin-right: 3px;
  color: ${props => (props.active ? bayOfMany : santasGrey)};
  &:hover,
  &:focus,
  &.active {
    border-color: ${blueRhino};
    color: ${bayOfMany};
  }

  &:last-child {
    margin-right: 0;
  }
  font-size: 15px;
  letter-spacing: 0.3px;
  font-weight: 600;
  line-height: 18px;
  white-space: nowrap;
`;

export default function WorkstreamDropdown({ list }) {
  return (
    <SearchParams>
      {({ params }) => (
        <Box paddingRight="15px" marginRight="auto">
          {list.map(x => (
            <TfsNavLink
              key={x._id}
              to={{
                search: qs.stringify({
                  ...params,
                  workstream: x._id !== '-1' ? x._id : undefined,
                }),
              }}
              active={
                (!params.workstream && x._id === '-1') ||
                params.workstream === String(x._id)
              }
            >
              {x._id === '-1' ? 'All Workstreams' : x.name}
            </TfsNavLink>
          ))}
        </Box>
      )}
    </SearchParams>
  );
}
