import React, { useState, useContext } from 'react';
import { mountainMeadow, appleBlossom } from '@hero/styles/colors-v4';
import { Box } from 'jsxstyle';
import styled from 'styled-components/macro';
import InitiativeIcon from '@hero/core/icons/Initiative';
import BlockerIcon from '@hero/core/icons/Blocker';

import { CreateBlockerContainer } from '@hero/tfs/src/03-blockers/BlockerForm';
import { BlockersContext } from '@hero/tfs/src/03-blockers/BlockersHost';

import PlusPopover from './PlusPopover';
import AddLink from './AddLink';

import NewInitiative from '../12-initiative/NewInitiative';

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export default function AddNewButton({ tfsId, ...props }) {
  const [open, setOpen] = useState(false);
  const [showNewInit, setShowNewInit] = useState(false);

  const { refreshBlockers } = useContext(BlockersContext);

  return (
    <Box {...props}>
      <PlusPopover>
        {({ onClose }) => (
          <List>
            <AddLink
              icon={InitiativeIcon}
              color={mountainMeadow}
              text="New Initiative"
              description="Add a new Initiative/ Project to your transformation"
              onClick={e => {
                e.preventDefault();
                setShowNewInit(true);
                onClose();
              }}
            />
            <AddLink
              icon={BlockerIcon}
              iconWidth="fit-content"
              text="New Blocker"
              description="Add a new blocker/ impediment to the transformation delivery"
              color={appleBlossom}
              onClick={() => {
                setOpen(true);
                onClose();
              }}
            />
          </List>
        )}
      </PlusPopover>
      {showNewInit && (
        <NewInitiative
          isOpen={showNewInit}
          tfsId={tfsId}
          onCancel={() => setShowNewInit(false)}
        />
      )}
      {open && (
        <CreateBlockerContainer
          tfsId={tfsId}
          isOpen={open}
          closeModal={() => setOpen(false)}
          onBlockerSubmit={() => refreshBlockers(Date.now())}
        />
      )}
    </Box>
  );
}
