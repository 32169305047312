import moment from 'moment';
import { isSameDay, compareAsc } from 'date-fns';
import { isBlockerDone } from '@hero/tfs/src/03-blockers/shared';
import { BACKLOG } from '@hero/tfs/src/shared/StatusLegend';

import useCurrentUser from '../shared/hooks/useCurrentUser';

export const RED = '0';
export const AMBER = '1';
export const GREEN = '2';

export function createInitiative(transformation) {
  const currentUser = useCurrentUser();

  const initiative = {
    ...assignOutcomeTracking({
      transformation_id: transformation.ref_id,
      name: '',
      business_unit: '-1',
      workstream: '-1',
      resource_type: '-1',
      stage_gate: BACKLOG,
      region: '-1',

      available_capacity: 0,
      require_capacity: 0,

      status: '2',
      difficulty: '-1',
      lead: serializeUser(currentUser),
    }),
    ...assignBudgetTracking(),
  };

  const { start } = findTimeInterval(initiative);

  return {
    ...initiative,
    start_date: start,
  };
}

export function assignOutcomeTracking(initiative) {
  const outcome_value = initiative.outcome_value || 0;
  const outcome_period = 'monthly';
  const outcome_duration = getInitialDuration(outcome_period);

  return {
    ...initiative,

    outcome_type: 'recurring',
    outcome_value,
    outcome_period,
    outcome_duration,
    outcome_date: moment().format(),
    outcome_tracking: allocate(outcome_value, outcome_period, outcome_duration),
  };
}

export function assignBudgetTracking(initiative = {}) {
  const cost = initiative.cost || 0;
  const budget_period = 'monthly';
  const budget_duration = getInitialDuration(budget_period);

  return {
    ...initiative,

    budget_period,
    budget_duration,
    budget_type: 'recurring',
    budget_date: moment().format(),
    budget_tracking: allocate(cost, budget_period, budget_duration),
  };
}

export function findTimeInterval(initiative) {
  const dates = [];

  if (initiative.outcome_type === 'recurring') {
    dates.push(...initiative.outcome_duration);
  } else {
    dates.push(initiative.outcome_date);
  }

  if (initiative.budget_type === 'recurring') {
    dates.push(...initiative.budget_duration);
  } else {
    dates.push(initiative.budget_date);
  }

  dates.sort((a, b) => compareAsc(new Date(a), new Date(b)));

  return {
    start: dates[0],
    end: dates[dates.length - 1],
  };
}

export function allocate(
  value,
  period,
  duration,
  { shouldDistribute = true } = {}
) {
  let currentDate = startOf(moment(duration[0]), period);
  const endDate = moment(duration[1]);
  const result = [];

  while (currentDate.isBefore(endDate)) {
    result.push(currentDate.format());

    switch (period) {
      case 'weekly':
        currentDate = currentDate.add(1, 'week');
        break;
      case 'monthly':
        currentDate = currentDate.add(1, 'month');
        break;
      case 'quarterly':
        currentDate = currentDate.add(1, 'Q');
        break;
      case 'yearly':
        currentDate = currentDate.add(1, 'year');
        break;
      default:
        currentDate = currentDate.add(1, 'month');
    }
  }

  return result.map(date => ({
    date,
    planned: shouldDistribute ? Math.floor(value / result.length) : 0,
    actual: 0,
  }));
}

// Copy actual Outcome values from one initiative to another
// Don't copy if time interval is different
export function copyActualOutcome({ from, to }) {
  if (from.outcome_period !== to.outcome_period) {
    return to;
  }

  return {
    ...from,
    outcome_tracking: to.outcome_tracking.map(x => {
      const found = from.outcome_tracking.find(
        y => isSameDay(new Date(x.date), new Date(y.date)) && x.type === y.type
      );

      if (!found) {
        return x;
      }

      return {
        ...x,
        actual: found.actual,
      };
    }),
  };
}

export function copyPlannedOutcome({ from, to }) {
  return {
    ...to,
    outcome_tracking: to.outcome_tracking.map(x => {
      const found = from.outcome_tracking.find(
        y => isSameDay(new Date(x.date), new Date(y.date)) && x.type === y.type
      );

      if (!found) {
        return x;
      }

      return {
        ...x,
        planned: found.planned,
      };
    }),
  };
}

export function copyPlannedBudget({ from, to }) {
  return {
    ...to,
    budget_tracking: to.budget_tracking.map(x => {
      const found = from.budget_tracking.find(
        y => isSameDay(new Date(x.date), new Date(y.date)) && x.type === y.type
      );

      if (!found) {
        return x;
      }

      return {
        ...x,
        planned: found.planned,
      };
    }),
  };
}

// Copy actual budget values from one initiative to another
// Don't copy if time interval is different
export function copyActualBudget({ from, to }) {
  if (from.budget_period !== to.budget_period) {
    return to;
  }

  return {
    ...from,
    budget_tracking: to.budget_tracking.map(x => {
      const found = from.budget_tracking.find(y =>
        isSameDay(new Date(x.date), new Date(y.date))
      );

      if (!found) {
        return x;
      }

      return {
        ...x,
        actual: found.actual,
      };
    }),
  };
}

export function startOf(date, period) {
  switch (period) {
    case 'weekly':
      return date.startOf('week');
    case 'monthly':
      return date.startOf('month');
    case 'quarterly':
      return moment()
        .quarter(moment().quarter())
        .startOf('quarter');
    case 'yearly':
      return date.startOf('year');
    default:
      return date.startOf('month');
  }
}

function getInitialDuration(period) {
  switch (period) {
    case 'weekly':
    case 'monthly':
      return [
        moment().format(),
        moment()
          .add(1, 'years')
          .format(),
      ];

    case 'yearly':
      return [
        moment().format(),
        moment()
          .add(1, 'years')
          .format(),
      ];

    default:
      return [
        moment().format(),
        moment()
          .add(1, 'years')
          .format(),
      ];
  }
}

export function getInitiativeStatus(initiative, blockers) {
  const initiativesBlockers = blockers.filter(blocker =>
    blocker.initiatives.some(x => x.ref_id === initiative.ref_id)
  );

  if (initiativesBlockers.some(x => !isBlockerDone(x))) {
    return RED;
  }

  if (initiative.status === '1') {
    return AMBER;
  }

  if (
    initiativesBlockers.every(x => isBlockerDone(x)) &&
    initiative.status === RED
  ) {
    return GREEN;
  }

  return initiative.status;
}

export function deserialize(initiative, users) {
  return {
    ...initiative,
    sponsor:
      initiative.sponsor && initiative.sponsor.length > 0
        ? users.find(user => user.user_id === initiative.sponsor[0].user_id)
        : null,
    lead:
      initiative.sponsor && initiative.lead.length > 0
        ? users.find(user => user.user_id === initiative.lead[0].user_id)
        : null,
    approver:
      initiative.sponsor && initiative.approver.length > 0
        ? users.find(user => user.user_id === initiative.approver[0].user_id)
        : null,
  };
}

export function serialize(initiative) {
  return {
    ...initiative,
    sponsor: initiative.sponsor ? [serializeUser(initiative.sponsor)] : [],
    lead: initiative.lead ? [serializeUser(initiative.lead)] : [],
    approver: initiative.approver ? [serializeUser(initiative.approver)] : [],
  };
}

function serializeUser(user) {
  return {
    is_active: 1,
    user_id: user.user_id,
  };
}

export function copyTrackingOverDuration(tracking, newDuration, period) {
  const newTracking = allocate(0, period, newDuration, {
    shouldDistribute: false,
  });

  return newTracking.map((item, index) => {
    const oldItem = tracking[index];

    return {
      ...item,
      planned: oldItem ? oldItem.planned : 0,
      actual: oldItem ? oldItem.actual : 0,
    };
  });
}
