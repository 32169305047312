import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Box } from 'jsxstyle';
import isHotkey from 'is-hotkey';
import { Editable, withReact, useSlate, Slate } from 'slate-react';
import { Editor as SlateEditor, Transforms, createEditor } from 'slate';
import { withHistory } from 'slate-history';
import FormatBold from '@material-ui/icons/FormatBold';
import FormatQuote from '@material-ui/icons/FormatQuote';
import FormatListBullet from '@material-ui/icons/FormatListBulleted';
import FormatListNumbered from '@material-ui/icons/FormatListNumbered';
import FormatItalic from '@material-ui/icons/FormatItalic';
import FormatUnderlined from '@material-ui/icons/FormatUnderlined';
import Send from '@material-ui/icons/Send';
import CodeIcon from '@material-ui/icons/Code';
import Title from '@material-ui/icons/Title';
import StrikethroughS from '@material-ui/icons/StrikethroughS';
import Tooltip from '@material-ui/core/Tooltip';
import useControlledState from '@quid/react-use-controlled-state';

import {
  ghostGrey,
  bayOfMany,
  quartz,
  blueRhino,
  puertoRico,
} from '@hero/styles/colors-v4';
import { weights } from '@hero/styles/typography';
import CodeBlockIcon from '@hero/core/icons/CodeBlock';
import Mentions from './Mentions';
import { DEFAULT_VALUE } from './utils';
import { Node, Leaf } from './EditorPreview';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const Headings = [
  'heading-one',
  'heading-two',
  'heading-three',
  'heading-four',
  'heading-five',
  'heading-six',
];

const Menu = styled.div`
  display: flex;
`;

const Button = styled.button`
  height: 32px;
  width: 32px;
  outline: none;
  background-color: ${props =>
    props.active ? 'rgba(49,66,96,0.2)' : 'transparent'};
  color: ${props => (props.active ? bayOfMany : 'rgba(49,66,96, 0.7)')};
  font-weight: ${props => (props.active ? weights.bolder : weights.medium)};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s, background-color 0.2s, color 0.2s;
  pointer-events: all;
  opacity: 1;
  border: none;
  border-radius: 1px;
  cursor: pointer;
  &:hover {
    background-color: rgba(49, 66, 96, 0.3);
    color: '#1d1c1d';
  }
  &:not(:last-child) {
    margin-right: 1px;
  }
  :disabled {
    opacity: 0.2;
    pointer-events: none;
  }
`;

const MenuButton = styled(Button)`
  background-color: ${props => (props.active ? 'white' : 'transparent')};
  color: ${props => (props.active ? bayOfMany : 'white')};
  font-weight: ${props => (props.active ? weights.bolder : weights.medium)};
  &:hover {
    background-color: white;
    color: ${bayOfMany};
  }
`;

const SubmitButton = styled(Button)`
  margin-left: auto;
  color: ${props => (props.disabled ? ghostGrey : blueRhino)};
  &:hover {
    background-color: transparent;
    color: ${props => (props.disabled ? ghostGrey : puertoRico)};
  }
`;

const Toolbar = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 4px;
  height: 40px;
  background-color: ${quartz};
  opacity: ${props => (props.disable ? 0.5 : 1)};
  & ${Button} {
    pointer-events: ${props => (props.disable ? 'none' : 'all')};
  }
`;

const iconStyles = {
  height: '20px',
  width: '20px',
  fontSize: '16px',
  color: 'currentColor',
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

function isContentNotEmpty(content) {
  return content.some(c => {
    return c.children.some(ch => {
      return ch.text ? ch.text.trim() : ch.type === 'mention';
    });
  });
}

export const TOOLBAR_OPTIONS = {
  showBold: true,
  showItalic: true,
  showUndelined: true,
  showStrikeThrough: true,
  showCode: true,
  showBlockQuote: true,
  showTitles: true,
  showNumberList: true,
  showBulletList: true,
  showCodeBlock: true,
};

export default function Editor({
  isEdit,
  placeholder = 'Enter content...',
  defaultValue = DEFAULT_VALUE,
  value,
  mentionList = [],
  toolbarOptions = TOOLBAR_OPTIONS,
  bgColor = 'white',
  onChange = () => {},
  onSubmit,
}) {
  const [content, setContent] = useControlledState(
    defaultValue,
    value,
    onChange
  );
  //TODO: Need to have disable state for toolbar but
  //Using onBlur and onFocus creating problems for editor
  // const [focused, setFocused] = useState(false);

  const renderElement = useCallback(props => <Node {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const parentRef = React.useRef();

  return (
    <div ref={parentRef}>
      <Box
        position="relative"
        padding="7.5px 16px 47.5px 16px"
        borderColor={ghostGrey}
        borderRadius="1px"
        background={bgColor}
      >
        <Mentions ref={parentRef} editor={editor} list={mentionList}>
          {({ editorWithMention, mentionEvents, mentionTrigger }) => (
            <Slate
              editor={editorWithMention}
              value={content}
              onChange={value => {
                setContent(value);
                mentionTrigger();
              }}
            >
              <ToolbarOptions
                isEdit={isEdit}
                toolbarOptions={toolbarOptions}
                // setFocused={setFocused}
                // disable={!focused}
                content={content}
                onSubmit={onSubmit}
                setContent={setContent}
              />
              <Editable
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                placeholder={placeholder}
                spellCheck
                // onFocus={(event, editor, next) => {
                //   console.log(event, editor, next)
                //   setFocused(true);
                //   // next();
                // }}
                // onBlur={(event, editor, next) => {
                //   setFocused(false);
                //   // next();
                // }}
                onKeyDown={event => {
                  for (const hotkey in HOTKEYS) {
                    if (isHotkey(hotkey, event)) {
                      event.preventDefault();
                      const mark = HOTKEYS[hotkey];
                      toggleMark(editor, mark);
                    }
                  }
                  mentionEvents(event);
                }}
              />
            </Slate>
          )}
        </Mentions>
      </Box>
    </div>
  );
}
Editor.propTypes = {
  isEdit: PropTypes.bool,
  toolbarOptions: PropTypes.shape({
    showBold: PropTypes.bool,
    showItalic: PropTypes.bool,
    showUndelined: PropTypes.bool,
    showStrikeThrough: PropTypes.bool,
    showCode: PropTypes.bool,
    showBlockQuote: PropTypes.bool,
    showTitles: PropTypes.bool,
    showNumberList: PropTypes.bool,
    showBulletList: PropTypes.bool,
    showCodeBlock: PropTypes.bool,
  }),
  placeholder: PropTypes.string,
  defaultValue: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func,
};

function ToolbarOptions({
  isEdit,
  toolbarOptions,
  disable,
  content,
  setFocused,
  onSubmit,
  setContent,
}) {
  const {
    showBold,
    showItalic,
    showUndelined,
    showStrikeThrough,
    showCode,
    showBlockQuote,
    showTitles,
    showNumberList,
    showBulletList,
    showCodeBlock,
  } = toolbarOptions;
  return (
    <Toolbar
      disable={disable}
      onMouseDown={() => setFocused && setFocused(true)}
    >
      {showBold && (
        <MarkButton format="bold">
          <FormatBold style={iconStyles} />
        </MarkButton>
      )}

      {showItalic && (
        <MarkButton format="italic">
          <FormatItalic style={iconStyles} />
        </MarkButton>
      )}
      {showUndelined && (
        <MarkButton format="underline">
          <FormatUnderlined style={iconStyles} />
        </MarkButton>
      )}
      {showStrikeThrough && (
        <MarkButton format="strikethrough">
          <StrikethroughS style={iconStyles} />
        </MarkButton>
      )}
      {showCode && (
        <MarkButton format="code">
          <CodeIcon style={iconStyles} />
        </MarkButton>
      )}
      {showTitles && <MultiBlockButton />}
      {showBlockQuote && (
        <BlockButton format="block-quote">
          <FormatQuote style={iconStyles} />
        </BlockButton>
      )}
      {showNumberList && (
        <BlockButton format="numbered-list">
          <FormatListNumbered style={iconStyles} />
        </BlockButton>
      )}
      {showBulletList && (
        <BlockButton format="bulleted-list">
          <FormatListBullet style={iconStyles} />
        </BlockButton>
      )}
      {showCodeBlock && (
        <BlockButton format="code-block">
          <CodeBlockIcon style={iconStyles} />
        </BlockButton>
      )}
      {onSubmit && (
        <SubmitButton
          disabled={!isContentNotEmpty(content)}
          onClick={() => {
            onSubmit(content);
            setContent(isEdit ? content : DEFAULT_VALUE);
          }}
        >
          <Send style={iconStyles} />
        </SubmitButton>
      )}
    </Toolbar>
  );
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    SlateEditor.removeMark(editor, format);
  } else {
    SlateEditor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format) => {
  try {
    const [match] = SlateEditor.nodes(editor, {
      match: n => n.type === format,
    });

    return !!match;
  } catch (ex) {
    return false;
  }
};

const isMultiBlockActive = (editor, formats) => {
  const [match] = SlateEditor.nodes(editor, {
    match: n => formats.includes(n.type),
  });
  return !!match;
};

const isMarkActive = (editor, format) => {
  try {
    const marks = SlateEditor.marks(editor);
    return marks ? marks[format] === true : false;
  } catch (ex) {
    return false;
  }
};

const BlockButton = ({ isMenu, format, children }) => {
  const editor = useSlate();
  return isMenu ? (
    <MenuButton
      active={isBlockActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <span>{children}</span>
    </MenuButton>
  ) : (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <span>{children}</span>
    </Button>
  );
};

const MarkButton = ({ format, children }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {children}
    </Button>
  );
};

const MultiBlockButton = () => {
  const editor = useSlate();
  return (
    <Tooltip
      interactive
      placement="top"
      title={
        <Menu>
          <BlockButton isMenu format={Headings[0]}>
            H1
          </BlockButton>
          <BlockButton isMenu format={Headings[1]}>
            H2
          </BlockButton>
          <BlockButton isMenu format={Headings[2]}>
            H3
          </BlockButton>
          <BlockButton isMenu format={Headings[3]}>
            H4
          </BlockButton>
          <BlockButton isMenu format={Headings[4]}>
            H5
          </BlockButton>
          <BlockButton isMenu format={Headings[5]}>
            H6
          </BlockButton>
        </Menu>
      }
    >
      <div>
        <Button active={isMultiBlockActive(editor, Headings)}>
          <Title style={iconStyles} />
        </Button>
      </div>
    </Tooltip>
  );
};
