import React, { Children, cloneElement, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { lmont, llato } from '@hero/styles/typography';
import {
  greyManatee,
  blueRhino,
  greenCarribean,
  ghostGrey,
  bayOfMany,
} from '@hero/styles/colors-v4';
import { rgba } from 'polished';

const SwitchWrapper = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
`;

const Label = styled.label`
  display: inline-block;
  ${lmont};
  height: 38px;
  color: ${greyManatee};
  padding: 8px 24px;
  cursor: pointer;
  border: 1px solid ${greyManatee};
  border-right: 0;
  user-select: none;

  &:last-child {
    border-right: 1px solid ${greyManatee};
  }

  &:hover {
    color: ${blueRhino};
  }

  &:last-child {
    border-radius: 0 1px 1px 0;
  }
`;

const TagLabel = styled.label`
  display: inline-block;
  ${llato};
  padding: 5px 1.5em;
  border-radius: 1px;
  border: 1px solid #e6e6e6;
  margin-right: 7px;
  margin-bottom: 2px;
  cursor: pointer;
  color: white;
  background-color: ${ghostGrey};
  transition: border-color 0.2s;

  &:hover {
    border-color: ${blueRhino};
  }

  &:last-child {
    margin-right: 0;
  }
`;

const DarkTagLabel = styled(TagLabel)`
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border: none;

  &:hover {
    outline: 1px solid rgba(255, 255, 255, 0.5);
  }
`;

const Input = styled.input`
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
  padding: 0;
  margin: -1px;
  border: 0;
  clip: rect(0 0 0 0);

  &:checked + ${Label} {
    background: ${blueRhino};
    color: white;
  }

  &:checked + ${TagLabel} {
    background: ${bayOfMany};
    color: white;
    border-color: ${bayOfMany};
  }

  &:checked + ${DarkTagLabel} {
    background: rgba(255, 255, 255, 0.3);
    color: white;
    border-color: rgba(255, 255, 255, 0.3);
  }

  &:checked + ${DarkTagLabel}:hover {
    border-color: white;
  }

  &:checked + ${TagLabel}.green {
    background: #ccf1ee;
    color: #003734;
    border-color: #ccf1ee;
  }

  &:checked + ${TagLabel}.amber {
    background: #fff9d0;
    color: #4d4306;
    border-color: #fff9d0;
  }

  &:checked + ${TagLabel}.red {
    background: #fbdcdd;
    color: #47191a;
    border-color: #fbdcdd;
  }

  &:focus + ${Label} {
    outline: 2px solid ${rgba(greenCarribean, 0.7)};
  }

  &:active + ${TagLabel}, &:focus + ${TagLabel} {
    border-color: #1e2e45;
  }

  &:active + ${TagLabel}.green, &:focus + ${TagLabel}.green {
    border-color: #003734;
  }

  &:active + ${TagLabel}.amber, &:focus + ${TagLabel}.amber {
    border-color: #4d4306;
  }

  &:active + ${TagLabel}.red, &:focus + ${TagLabel}.red {
    border-color: #47191a;
  }

  &:first-child + ${Label} {
    border-radius: 1px 0 0 1px;
    border-right-width: 0;
  }
`;

function SwitchWithRef(
  { name, value, onChange, children, className, appearance, ...props },
  ref
) {
  return (
    <SwitchWrapper className={className} ref={ref} {...props}>
      {Children.map(children, (element, index) =>
        cloneElement(element, {
          name,
          onChange,
          id: name + index,
          checked: value === element.props.value,
          appearance,
        })
      )}
    </SwitchWrapper>
  );
}

SwitchWithRef.propTypes = {
  name: PropTypes.string.isRequired,
  appearance: PropTypes.oneOf(['light', 'dark']),
};

SwitchWithRef.defaultProps = {
  appearance: 'light',
};

const Switch = React.forwardRef(SwitchWithRef);

const Segment = React.forwardRef(function({ children, ...props }, ref) {
  return (
    <Fragment>
      <Input type="radio" ref={ref} {...props} />
      <Label htmlFor={props.id}>{children}</Label>
    </Fragment>
  );
});

const TagSegment = React.forwardRef(function(
  { color, appearance, children, ...props },
  ref
) {
  if (appearance === 'dark') {
    return (
      <Fragment>
        <Input type="radio" ref={ref} {...props} />
        <DarkTagLabel className={color} htmlFor={props.id}>
          {children}
        </DarkTagLabel>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Input type="radio" ref={ref} {...props} />
      <TagLabel className={color} htmlFor={props.id}>
        {children}
      </TagLabel>
    </Fragment>
  );
});

TagSegment.propTypes = {
  color: PropTypes.oneOf(['green', 'amber', 'red']),
};

Switch.Segment = Segment;
Switch.TagSegment = TagSegment;

export { Switch, Segment, TagSegment };
export default Switch;
