import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { progressBarCyan } from '@hero/styles/colors';
import { s2Regular } from '@hero/styles/typography';

export const ForgotPasswordLink = styled(Link)`
  display: inline-block;
  cursor: pointer;
  color: ${progressBarCyan};
  margin-top: 8px;
  ${s2Regular};
`;
