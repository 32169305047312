import React from 'react';
import styled from 'styled-components/macro';

import { rgba } from 'polished';
import { greyManatee, bayOfMany } from '@hero/styles/colors-v4';
import { currencies } from '@hero/tfs/src/shared/currencies';
import ReactSelect, { components } from 'react-select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const PRIORITY_LIST = ['USD', 'GBP', 'EUR'];

const StyledDDIcon = styled(ArrowDropDownIcon)`
  color: ${greyManatee};
  transition: all 0.2s linear;
`;

function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <StyledDDIcon />
    </components.DropdownIndicator>
  );
}

export default function CurrencySelect({ value, onChange, ...props }) {
  const options = Object.keys(currencies)
    .map(currencyCode => {
      const priorityIndex = PRIORITY_LIST.indexOf(currencyCode);
      const priorityPrefix =
        priorityIndex > -1
          ? Array.from({ length: PRIORITY_LIST.length - priorityIndex })
              .fill(' ')
              .join('')
          : '';

      return {
        text: priorityPrefix + currencies[currencyCode].name,
        value: currencyCode,
      };
    })
    .sort((x, y) => x.text.localeCompare(y.text))
    .map(x => ({ ...x, label: x.text.trim() }));

  return (
    <ReactSelect
      components={{ DropdownIndicator, IndicatorSeparator: () => null }}
      value={options.find(x => x.value === value)}
      onChange={x =>
        onChange({
          target: {
            value: x.value,
          },
        })
      }
      options={options}
      styles={{
        control: provided => ({
          ...provided,
          borderColor: rgba(greyManatee, 0.75),
          borderRadius: '1px',
          maxHeight: '38px',
          // This line disable the blue border
          boxShadow: '0 !important',
          '&:hover': {
            borderColor: bayOfMany,
          },
        }),
      }}
    />
  );
}
