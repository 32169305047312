import React from 'react';
import { Box } from 'jsxstyle';
import ReactSelect from 'react-select';
import { rgba } from 'polished';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import differenceBy from 'lodash/differenceBy';
import { useSnackbar } from 'notistack';

import { Actions, MainActionBtn } from '@hero/core/Dialog';
import Label from '@hero/core/Label';
import { greyManatee } from '@hero/styles/colors-v4';

import Editor from '@hero/tfs/src/shared/Editor';
import Fetch from '@hero/tfs/src/shared/Fetch';

import { getValidInitForBlocker } from '@hero/tfs/src/03-blockers/shared';

function getBlockerStatusByState(state, config) {
  const blockerState = config.blocker_state.find(
    blocker => blocker._id === state[state.length - 1].state_id
  );

  return blockerState ? blockerState : { _id: 'backlog', name: 'Todo' };
}

export default function LinkExistingBlockers({
  loading,
  tfsId,
  initiative,
  initBlockers = [],
  tfsConfig,
  onAddBlockers,
}) {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Fetch.POST
      url="/api/blocker/update"
      manual
      onError={() =>
        enqueueSnackbar('Failed to update the blocker', {
          variant: 'error',
        })
      }
    >
      {({ doRequest: updateBlocker }) => (
        <Editor
          id="link-existing-blockers"
          initialValue={initBlockers
            .filter(x => Boolean(x.ref_id))
            .map(x => toBlockerOption(x, tfsConfig))}
          onSubmit={(values, event) => {
            event.stopPropagation();
            const blockers = values.map(v => {
              if (v.blocker.initiatives) {
                v.blocker.initiatives = uniqBy(
                  [
                    ...v.blocker.initiatives,
                    getValidInitForBlocker(initiative),
                  ],
                  'ref_id'
                );
              } else {
                v.blocker.initiatives = [getValidInitForBlocker(initiative)];
              }
              return v.blocker;
            });
            Promise.all([
              Promise.all(
                differenceBy(
                  blockers,
                  initBlockers,
                  x => x.ref_id || x.clientId
                ).map(blocker => {
                  return updateBlocker({
                    body: blocker,
                  });
                })
              ),
              Promise.all(
                differenceBy(
                  initBlockers,
                  blockers,
                  x => x.ref_id || x.clientId
                ).map(blocker => {
                  if (
                    blocker.initiatives.some(
                      x => x.ref_id !== initiative.ref_id
                    )
                  ) {
                    return updateBlocker({
                      body: {
                        ...blocker,
                        initiatives: blocker.initiatives.filter(
                          x => x.ref_id !== initiative.ref_id
                        ),
                      },
                    });
                  } else {
                    return updateBlocker({
                      body: {
                        ...blocker,
                        state: blocker.state.concat({
                          state_id: 'done',
                          timestamp: new Date().toString(),
                        }),
                      },
                    });
                  }
                })
              ),
            ]).then(() => {
              onAddBlockers(blockers);
            });
          }}
        >
          {({ value, onChange }) => (
            <Box display="flex" minHeight="300px">
              <Box minWidth="588px" maxWidth="588px">
                <Label htmlFor="blocker-select-id">Select blockers</Label>
                <TfsBlockerSelect
                  tfsId={tfsId}
                  tfsConfig={tfsConfig}
                  blockers={value}
                  onSelectBlockers={newBlockers => {
                    onChange(newBlockers);
                  }}
                />
              </Box>
              <Box flex="1">
                <Actions>
                  <MainActionBtn
                    form="link-existing-blockers"
                    disabled={loading}
                    type="submit"
                  >
                    Create
                  </MainActionBtn>
                </Actions>
              </Box>
            </Box>
          )}
        </Editor>
      )}
    </Fetch.POST>
  );
}

export function TfsBlockerSelect({
  tfsId,
  blockers,
  tfsConfig,
  onSelectBlockers,
}) {
  return (
    <Fetch url={`/api/blocker/${tfsId}/read-by-transformation-id`}>
      {({ data: blockersData }) =>
        blockersData && (
          <ReactSelect
            value={blockers}
            onChange={onSelectBlockers}
            isMulti
            autoFocus
            name="colors"
            options={sortBy(
              blockersData.data.map(x => toBlockerOption(x, tfsConfig)),
              ['label']
            )}
            className="basic-multi-select"
            classNamePrefix="select"
            styles={{
              control: provided => ({
                ...provided,
                borderColor: rgba(greyManatee, 0.75),
                borderRadius: '1px',
              }),
            }}
          />
        )
      }
    </Fetch>
  );
}

function toBlockerOption(blocker, tfsConfig) {
  const { name, ref_id, state } = blocker;
  const label = `${getBlockerStatusByState(state, tfsConfig).name} - ${name}`;
  return {
    label,
    value: ref_id,
    blocker,
  };
}
