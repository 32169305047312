import React from 'react';
import { Box, Grid } from 'jsxstyle';

import { PlaceholderLoader, ColumnLoader } from '@hero/tfs/src/shared/Loaders';
import { pattensBlue, waikawaGrey } from '@hero/styles/colors-v4';

export function KanbanLoader({ noOfRows = 4, noOfColumns = 5 }) {
  const rows = new Array(noOfRows).fill(0).map((r, index) => index);
  const columns = new Array(noOfColumns).fill(0).map((c, index) => index);

  return (
    <Box display="flex" flexDirection="column">
      {rows.map(r => (
        <React.Fragment key={`row-${r}`}>
          <Box
            backgroundColor={waikawaGrey}
            padding="5px 32px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            marginBottom="16px"
            minHeight="48px"
            maxHeight="48px"
            width="100%"
          >
            <PlaceholderLoader
              type="title"
              width={`${Math.floor(Math.random() * 80 + 1)}%`}
            />
          </Box>
          <Grid
            display="flex"
            marginBottom="32px"
            gridTemplateColumns={[...Array(noOfColumns).keys()]
              .map(e => 'minmax(240px, 1fr)')
              .join(' ')}
            gridTemplateRows="auto"
            columnGap="16px"
          >
            {columns.map(c => (
              <ColumnLoader
                key={`${r}-col-${c}`}
                columnNumber={c}
                width="100%"
                columnColor={pattensBlue}
                noOfCards={3}
                isLast={noOfColumns - 1 === c}
              />
            ))}
          </Grid>
        </React.Fragment>
      ))}
    </Box>
  );
}
