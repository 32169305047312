import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import useApiUrl from './useApiUrl';
import useToken from './useToken';

export default function useTransformation(id, key) {
  const [tfs, setTfs] = useState();
  const token = useToken();
  const apiUrl = useApiUrl();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    fetch(new URL('/api/transformation/read', apiUrl), {
      body: JSON.stringify({
        query: {
          ref_id: id,
        },
      }),
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    })
      .then(rsp => rsp.json())
      .then(data => {
        if (data.ok) {
          setTfs(data.data);
        } else {
          console.log('Broken data');
        }
      })
      .catch(err => {
        console.log(err);
        enqueueSnackbar('Failed to fetch the transformation', {
          variant: 'error',
        });
      });
  }, [id, token, apiUrl, key]);

  return [tfs];
}
