import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { spring, TransitionMotion } from 'react-motion';
import { scaleLinear, scaleBand } from 'd3-scale';
import { max } from 'd3-array';
import { hierarchy, treemap } from 'd3-hierarchy';
import { AxisLeft, AxisBottom } from '@vx/axis';
import { Text } from '@vx/text';

import FormattedNumber, {
  getFormatForSize,
} from '@hero/tfs/src/shared/FormattedNumber';
import QuickInitPanel from '@hero/tfs/src/shared/QuickInitPanel';
import { axisLineColor, darkBlue, lineColor } from '@hero/styles/colors';
import { sBold } from '@hero/styles/typography';
import { romanRed, puertoRico, quartz } from '@hero/styles/colors-v4';

import TreeMap from './TreeMap';
import ProjectedLine from './ProjectedLine';
import TargetLine from './TargetLine';
import Legend from './Legend';

const MARGIN = { top: 40, right: 10, bottom: 55, left: 40 };
const CHART_LEFT_WIDE_SPACING = 120;

const BridgeText = styled(Text).attrs({
  fill: '#16233E',
  textAnchor: 'middle',
})`
  ${sBold};
`;

export default function BridgeChart({
  width: CHART_WIDTH,
  height: CHART_HEIGHT,
  data,
  sizeBy,
  tfs,
  blockers,
}) {
  const [quickInit, setQuickInit] = useState(null);

  const { currency_unit: currencyType, configuration: config } = tfs;
  const WIDTH =
    CHART_WIDTH - (MARGIN.left + MARGIN.right + CHART_LEFT_WIDE_SPACING);
  const HEIGHT = CHART_HEIGHT - (MARGIN.top + MARGIN.bottom);

  const { projected } = data;
  const target = Math.abs(data.target);

  const chartdata = data.chartdata.reduce((res, curr) => {
    const prev = res[res.length - 1];
    const prevEnd = prev ? prev.end : 0;

    const current = {
      ...curr,
      start: prevEnd,
      end: prevEnd + curr.value,
    };

    return [...res, current];
  }, []);

  const Y_AXIS_MAX_VALUE = target
    ? Math.max(max(chartdata, d => d.end), target)
    : max(chartdata, d => d.end);

  const xScale = scaleBand()
    .domain(chartdata.map(d => d.name))
    .rangeRound([0, WIDTH])
    .paddingOuter(0.015)
    .paddingInner(0.1);

  const yScale = scaleLinear()
    .domain([0, Y_AXIS_MAX_VALUE])
    .nice()
    .range([HEIGHT, 0]);

  const { defaultStyles, styles, bridgeTextStyles } = getTreeMapData(
    chartdata,
    xScale,
    yScale
  );

  const bandwidth = xScale.bandwidth();

  return (
    <>
      <svg width={CHART_WIDTH} height={CHART_HEIGHT}>
        <defs>
          <filter x="0" y="0" width="1" height="1" id="solid">
            <feFlood floodColor={quartz} />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <rect
          x={MARGIN.left + 100}
          y={MARGIN.top}
          height={HEIGHT}
          width={WIDTH}
          fill={quartz}
        />
        <g transform={`translate(${MARGIN.left + 100},${MARGIN.top})`}>
          <TransitionMotion defaultStyles={defaultStyles} styles={styles}>
            {treeMapStyles => (
              <TreeMap
                tfs={tfs}
                statusList={config.status}
                styles={treeMapStyles}
                blockers={blockers}
                sizeBy={sizeBy}
                projected={projected}
                target={target}
                onClick={(event, data) => {
                  event.preventDefault();
                  setQuickInit(data);
                }}
              />
            )}
          </TransitionMotion>
          <TargetLine width={WIDTH} target={target} y={yScale(target)} />
          <Legend
            width={WIDTH}
            value={target}
            y={getPositionWithoutCollision({ yScale, projected, target }).ty}
            title={sizeBy === 'cost' ? 'Budget' : 'Target'}
            currencyType={currencyType}
            sizeBy={sizeBy}
          />
          <ProjectedLine
            width={WIDTH}
            projected={projected}
            target={target}
            y={yScale(projected)}
            color={getProjectedColor({ projected, target, sizeBy })}
          />
          <Legend
            width={WIDTH}
            value={projected}
            y={getPositionWithoutCollision({ yScale, projected, target }).py}
            title="Planned"
            currencyType={currencyType}
            sizeBy={sizeBy}
            color={getProjectedColor({ projected, target, sizeBy })}
          />
          {bridgeTextStyles.map(
            ({ id, value, x, y }) =>
              bandwidth > 30 && (
                <FormattedNumber
                  key={id}
                  as={BridgeText}
                  value={value}
                  x={x}
                  y={y}
                  filter="url(#solid)"
                  format={getFormatForSize(sizeBy, currencyType)}
                  width={bandwidth}
                />
              )
          )}
          <AxisBottom
            scale={xScale}
            top={HEIGHT}
            stroke={axisLineColor}
            strokeWidth={1}
            tickStroke="none"
            tickComponent={({ formattedValue, ...tickProps }) => {
              const isSurplus =
                sizeBy === 'cost' ? projected < target : target < projected;

              const text =
                isSurplus && formattedValue === 'Shortfall'
                  ? 'Surplus'
                  : formattedValue;

              if (bandwidth < 20) {
                return null;
              }

              return (
                <Text width={bandwidth} verticalAnchor="middle" {...tickProps}>
                  {bandwidth > 50 ? text : toShortLabel(text)}
                </Text>
              );
            }}
            tickLabelProps={(value, index) => ({
              dy: '3',
              fill: darkBlue,
              fontSize: 15,
              fontWeight: 'bold',
              textAnchor: 'middle',
            })}
          />
          <AxisLeft
            hideTicks={true}
            hideZero={true}
            scale={yScale}
            top={0}
            stroke={axisLineColor}
            strokeWidth={1}
            tickLabelProps={(value, index) => ({
              fill: 'none',
            })}
          />
        </g>
      </svg>
      <QuickInitPanel
        init={quickInit}
        tfs={tfs}
        onClose={() => {
          setQuickInit(null);
        }}
      />
    </>
  );
}

BridgeChart.propTypes = {
  sizeBy: PropTypes.string.isRequired,
  groupBy: PropTypes.string.isRequired,
  currencyType: PropTypes.string.isRequired,
  tfs: PropTypes.shape({
    currency_unit: PropTypes.string.isRequired,
    time_unit: PropTypes.string.isRequired,
    configuration: PropTypes.object.isRequired,
  }).isRequired,
};

function toShortLabel(text) {
  if (text === 'Shortfall') {
    return 'Sf';
  }

  if (text.match(/No(.*)selected/)) {
    return 'N/A';
  }

  return text[0];
}

const LABEL_VERTICAL_SPACING = 14;

function getTreeMapData(chartdata, xScale, yScale) {
  const styles = [];
  const defaultStyles = [];
  const bridgeTextStyles = [];
  const barHeight = d => Math.abs(yScale(d.start) - yScale(d.end));
  const barWidth = xScale.bandwidth();
  const x = d => d.name;
  const y = d => Math.max(d.start, d.end);

  chartdata.forEach((data, i) => {
    let parentWidth = barWidth;
    let parentHeight = barHeight(data);
    let parentX = xScale(x(data));
    let parentY = yScale(y(data));

    const { name, ref_id, value, is_shortfall } = data;

    bridgeTextStyles.push({
      id: `${name}_${ref_id}`,
      value: value,
      x: xScale(x(data)) + barWidth / 2,
      y: yScale(y(data)) - LABEL_VERTICAL_SPACING,
    });

    const treeData = {
      name,
      ref_id,
      is_shortfall,
      size: value,
      children: data.is_shortfall
        ? [
            {
              name: data.name,
              ref_id: data.name + 1,
              value: data.value,
              status: -1,
              is_shortfall: true,
            },
          ]
        : data.initiatives,
    };

    const treemapCore = treemap()
      .size([parentWidth, parentHeight])
      .paddingInner(4);

    const root = hierarchy(treeData)
      .eachBefore(d => {
        d.data.id = (d.parent ? d.parent.data.id + '.' : '') + d.data.name;
      })
      .sum(d => d.value)
      .sort((a, b) => {
        return b.height - a.height || b.value - a.value;
      });

    treemapCore(root);
    //Map defaultStyles and newStyles from nodes
    //These are required for the animations
    root.leaves().forEach(node => {
      const key = `initiative-${node.data.ref_id}`;
      const height = node.y1 - node.y0;
      const width = node.x1 - node.x0;

      defaultStyles.push({
        key,
        style: {
          x: parentX,
          y: parentY + parentHeight,
          height: 0,
          width: 0,
          scale: 0,
        },
      });

      styles.push({
        key,
        style: {
          x: spring(parentX + node.x0),
          y: spring(node.y0 + parentY),
          height: spring(height),
          width: spring(width),
          scale: spring(1),
        },
        data: { node, parentX, parentY },
      });
    });
  });

  return {
    defaultStyles,
    styles,
    bridgeTextStyles,
  };
}

function getProjectedColor({ target, projected, sizeBy }) {
  if (sizeBy === 'outcome_value') {
    return target > projected ? romanRed : puertoRico;
  } else if (sizeBy === 'cost') {
    return target > projected ? puertoRico : romanRed;
  }

  return lineColor;
}

function getPositionWithoutCollision({ target, projected, yScale }) {
  const ty = yScale(target);
  const py = yScale(projected);
  const diff = ty - py;

  if (diff === 0) {
    return {
      ty: ty + 18,
      py: py - 16,
    };
  }

  if (Math.abs(diff) < 30 && diff > 0) {
    return {
      ty: ty + 18,
      py: py - 13,
    };
  }

  if (Math.abs(diff) < 30 && diff < 0) {
    return {
      ty: ty - 13,
      py: py + 18,
    };
  }

  return { ty, py };
}
