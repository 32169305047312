import React from 'react';

const SvgBlocker = props => (
  <svg width="1em" height="1em" viewBox="0 0 19 26" {...props}>
    <path
      d="M1.812 25.533a1.146 1.146 0 0 1-1.145-1.145V2.62c0-.08.007-.128.015-.163a1.137 1.137 0 0 1 .764-.947L3.745.732A7.589 7.589 0 0 1 6.18.333c1.544 0 3.03.454 4.297 1.314.886.6 1.924.918 3.007.918.584 0 1.157-.093 1.704-.278l2.29-.776a1.149 1.149 0 0 1 1.038.154c.3.214.477.562.477.93V15.25c0 .492-.313.927-.778 1.084l-2.291.778c-.782.264-1.601.399-2.435.399a7.616 7.616 0 0 1-4.297-1.314 5.342 5.342 0 0 0-3.01-.918c-.583 0-1.154.093-1.701.278l-1.523.513v8.318c0 .632-.513 1.145-1.146 1.145zm4.366-12.545c1.546 0 3.03.453 4.297 1.313a5.34 5.34 0 0 0 3.008.918c.583 0 1.156-.093 1.703-.278l1.512-.513V4.192l-.777.263a7.596 7.596 0 0 1-2.435.398A7.622 7.622 0 0 1 9.19 3.541a5.331 5.331 0 0 0-3.009-.92A5.29 5.29 0 0 0 4.48 2.9l-1.521.516v10.236l.787-.266c.78-.264 1.599-.398 2.433-.398z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default SvgBlocker;
