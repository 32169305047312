import React from 'react';
import { Box } from 'jsxstyle';
import styled from 'styled-components/macro';
import { rgba } from 'polished';
import { Switch, Route, Redirect } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import Page from '@hero/tfs/src/shared/Page';
import { TfsNavLink } from '@hero/tfs/src/shared/WorkstreamDropdown';
import ManageMembers from './ManageMembers';
import ManageMyAccount from './ManageMyAccount';
import GlobalConfig from './GlobalConfig';

import Tooltip from '@material-ui/core/Tooltip';
import { bayOfMany } from '@hero/styles/colors-v4';
import { Text } from '@hero/styles/typography-v5';
import ArchivedTransformation from './ArchivedTransformation';
import ArchivedBlocker from './ArchivedBlockers';
import ArchivedInitiatives from './ArchivedInitiatives';

const Circle = styled.div`
  display: flex;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  height: 40px;
  width: 40px;
  background-color: #637593;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-in;
  :hover {
    background-color: ${rgba('#637593', 0.8)};
  }
`;

const Icon = styled.div`
  display: inline-block;
  position: relative;
  margin: auto;

  svg {
    color: ${props => props.color};
    width: 24px;
    height: 24px;
    vertical-align: bottom;
  }
`;

export const ToolbarLinks = styled.div`
  display: flex;
  align-items: center;
`;

export default function Settings({ history, match, location }) {
  const tfsId = location.state ? location.state.tfsId : null;

  return (
    <Page>
      <Page.InnerRow
        marginTop={56}
        height={68}
        marginBottom={12}
        padding="24px 0"
        alignItems="center"
        justifyContent="space-between"
      >
        <ToolbarLinks>
          <div>
            <TfsNavLink
              replace={true}
              to={{ pathname: `${match.url}/manage-members`, state: { tfsId } }}
            >
              Manage members
            </TfsNavLink>
            <TfsNavLink
              replace={true}
              to={{
                pathname: `${match.url}/manage-my-account`,
                state: { tfsId },
              }}
            >
              Manage my account
            </TfsNavLink>
            <TfsNavLink
              replace={true}
              to={{
                pathname: `${match.url}/global-configuration`,
                state: { tfsId },
              }}
            >
              Global configuration
            </TfsNavLink>
            <TfsNavLink
              replace={true}
              to={{
                pathname: `${match.url}/archived-transformation`,
                state: { tfsId },
              }}
            >
              Archived transformations
            </TfsNavLink>
            <TfsNavLink
              replace={true}
              to={{
                pathname: `${match.url}/archived-initiatives`,
                state: { tfsId },
              }}
            >
              Archived initiatives
            </TfsNavLink>
            <TfsNavLink
              replace={true}
              to={{
                pathname: `${match.url}/archived-blockers`,
                state: { tfsId },
              }}
            >
              Archived blockers
            </TfsNavLink>
          </div>
        </ToolbarLinks>
        <Tooltip title="Logout" placement="bottom" enterDelay={200}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Text size="p2" marginRight="8px">
              Logout
            </Text>
            <Circle
              onClick={() => {
                localStorage.removeItem('id_token');
                history.push('/login');
              }}
            >
              <Icon color="white">
                <ExitToAppIcon />
              </Icon>
            </Circle>
          </Box>
        </Tooltip>
      </Page.InnerRow>

      <Page.Main display="flex" flexDirection="column">
        <Box
          margin="0 0 32px 0"
          flexGrow="1"
          overflow="auto"
          boxShadow={`0 1px 2px 0 ${rgba(bayOfMany, 0.1)}`}
          border-radius="1px"
        >
          <Box
            backgroundColor="white"
            width="100%"
            overflow="hidden"
            height="100%"
            display="flex"
            flexDirection="column"
          >
            <Switch>
              <Redirect
                exact
                from={`${match.url}/`}
                to={{
                  pathname: `${match.url}/manage-members`,
                  state: { tfsId },
                }}
              />
              <Route
                exact
                path={`${match.url}/manage-members`}
                component={ManageMembers}
              />
              <Route
                exact
                path={`${match.url}/manage-my-account`}
                component={ManageMyAccount}
              />
              <Route
                exact
                path={`${match.url}/global-configuration`}
                component={GlobalConfig}
              />
              <Route
                exact
                path={`${match.url}/archived-transformation`}
                component={ArchivedTransformation}
              />
              <Route
                exact
                path={`${match.url}/archived-blockers`}
                component={ArchivedBlocker}
              />
              <Route
                exact
                path={`${match.url}/archived-initiatives`}
                component={ArchivedInitiatives}
              />
            </Switch>
          </Box>
        </Box>
      </Page.Main>
    </Page>
  );
}
