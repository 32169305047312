import React, { useContext } from 'react';
import LogRocket from 'logrocket';
import Fetch from './Fetch';
import { LoginContext } from './LoginContextProvider';

if (process.env.NODE_ENV === 'production') {
  if (process.env.REACT_APP_LOGROCKET) {
    LogRocket.init(process.env.REACT_APP_LOGROCKET, {
      release: `${process.env.REACT_APP_BUILD_NUMBER} • ${
        process.env.REACT_APP_COMMIT
      }`,
      shouldCaptureIP: false,
    });
  } else {
    console.warn(
      `LogRocket is not initialized. Please set REACT_APP_LOGROCKET environment variable with LogRocket project id.`
    );
  }
}

export default function Logger({ children }) {
  const { isAuthenticated } = useContext(LoginContext);

  if (process.env.NODE_ENV !== 'production') {
    return children;
  }

  if (!process.env.REACT_APP_LOGROCKET) {
    return children;
  }

  if (!isAuthenticated) {
    return children;
  }

  return (
    <Fetch.POST url="/api/user/current">
      {({ data }) => {
        if (data) {
          const user = data.data;
          LogRocket.identify(user.email, {
            ...user,
            name: `${user.first_name} ${user.last_name}`,
          });
        }
        return children;
      }}
    </Fetch.POST>
  );
}
