import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ObjectInput extends Component {
  handleInput = event => {
    const { value, name, type, onChange } = this.props;
    let newValue = event.target.value;

    // remove empty prop
    if (newValue === '') {
      const { [name]: removedValue, ...remainingValue } = value;
      onChange({
        ...event,
        target: {
          ...event.target,
          value: remainingValue,
        },
      });
    } else {
      if (type === 'number') {
        newValue = Number(newValue);
      }

      onChange({
        ...event,
        target: {
          value: {
            ...value,
            [name]: newValue,
          },
        },
      });
    }
  };

  render() {
    const {
      as,
      value,
      name,
      type,
      decimalPrecision,
      onChange,
      children,
      ...restProps
    } = this.props;
    let newValue = value.hasOwnProperty(name) ? value[name] : '';
    if (type === 'number' && typeof newValue === 'number') {
      newValue = String(+newValue.toFixed(decimalPrecision));
    }

    return React.createElement(
      as,
      {
        ...restProps,
        name,
        type,
        value: newValue,
        onChange: this.handleInput,
      },
      children
    );
  }
}

ObjectInput.defaultProps = {
  as: 'input',
  decimalPrecision: 2,
};

ObjectInput.propTypes = {
  name: PropTypes.string.isRequired,
  decimalPrecision: PropTypes.number,
};
