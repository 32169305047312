import React from 'react';
import { Motion, spring } from 'react-motion';
import FormattedNumber from '@hero/tfs/src/shared/FormattedNumber';
import { getFormatForSize } from '@hero/tfs/src/shared/FormattedNumber';

import styled from 'styled-components/macro';
import { Text as TextNode } from '@vx/text';
import { xsRegular } from '@hero/styles/typography';
import { lineColor } from '@hero/styles/colors';

const Text = styled(TextNode).attrs(({ fill }) => ({
  fill: fill ? fill : lineColor,
  textAnchor: 'end',
}))`
  ${xsRegular};
`;

export default function Legend({
  y,
  width,
  value,
  currencyType,
  sizeBy,
  title,
  color = lineColor,
}) {
  return (
    <Motion
      defaultStyle={{ x: 0, y: 0 }}
      style={{
        x: spring(width, {
          stiffness: 80,
          damping: 17,
        }),
        y: spring(y, {
          stiffness: 80,
          damping: 17,
        }),
      }}
    >
      {style => (
        <g transform={`translate(0, ${style.y})`}>
          <Text y={-8} x={-25} fill={color}>
            {title}
          </Text>
          <FormattedNumber
            value={value}
            format={getFormatForSize(sizeBy, currencyType)}
          >
            {formatted => (
              <Text y={8} x={-25} fill={color}>
                {formatted}
              </Text>
            )}
          </FormattedNumber>
        </g>
      )}
    </Motion>
  );
}
