import React from 'react';
import styled from 'styled-components/macro';
import { rgba } from 'polished';
import { Box } from 'jsxstyle';
import DropzoneComponent from 'react-dropzone';
import ClearIcon from '@material-ui/icons/Clear';
import Label from '@hero/core/Label';
import { inputBorder, grey50, white } from '@hero/styles/colors';
import { blueRhino } from '@hero/styles/colors-v4';
import { sizes } from '@hero/styles/typography';

const UploadText = styled.h1`
  font-size: ${sizes.m3};
  font-weight: bold;
  line-height: 26px;
  text-align: center;
  padding: 0 40px;
  margin: -27px 0 20px;
`;

export const AddIcon = styled.span`
  height: 96px;
  width: 35px;
  font-size: 60px;
  font-weight: 300;
  line-height: 72px;
  margin: 41px 0 0 0;
  display: inline-block;
  &::after {
    content: '+';
  }
`;

const dropzoneActiveClassName = 'dropzone-active';
const Dropzone = styled(DropzoneComponent)`
  position: absolute !important;
  top: 0;
  left: 0;
  text-align: center;
  border: 1px dashed ${inputBorder};
  width: 199px;
  height: 199px;
  color: transparent;
  cursor: pointer;
  border-radius: 4px;
  &.${dropzoneActiveClassName} {
    background: ${grey50};
  }

  &:hover {
    color: ${blueRhino};
    background-color: rgba(255, 255, 255, 0.7);
  }
`;

const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const ClearBtn = styled(ClearIcon)`
  position: absolute;
  font-size: 40px;
  cursor: pointer;
  background: ${rgba(white, 0.9)};
  border-radius: 4px;
  top: 0;
  left: 0;
`;

const ImgWrapper = styled.div`
  height: 199px;
  width: 199px;
  margin: 0 0 23px 0;
  border: 1px solid ${inputBorder};
  border-radius: 4px;
  overflow: hidden;
  align-content: center;
  display: flex;
  justify-content: center;
  position: relative;
`;

export default class AccountImage extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.file !== prevProps.file && prevProps.file !== null) {
      URL.revokeObjectURL(prevProps.file);
    }
  }

  render() {
    const {
      file,
      onFileDrop,
      onClearFile,
      userData,
      onCurrentUserImageDelete,
      currentUserImageDeleted,
    } = this.props;

    return (
      <>
        <Label htmlFor="manage-my-account-name">Profile image</Label>

        <Box position="relative">
          {file && (
            <ImgWrapper>
              <Img src={URL.createObjectURL(file)} />
              <ClearBtn onClick={onClearFile} />
            </ImgWrapper>
          )}

          {userData.profile_picture && !currentUserImageDeleted && !file && (
            <ImgWrapper>
              <Img src={userData.profile_picture} />
            </ImgWrapper>
          )}

          <Dropzone
            onDrop={onFileDrop}
            activeClassName={dropzoneActiveClassName}
            multiple={false}
            accept="image/*"
          >
            <AddIcon />
            <UploadText>
              {file || (userData.profile_picture && !currentUserImageDeleted)
                ? 'Upload different image'
                : 'Upload image'}
            </UploadText>
          </Dropzone>

          {file && <ClearBtn onClick={onClearFile} />}

          {userData.profile_picture && !currentUserImageDeleted && !file && (
            <ClearBtn onClick={onCurrentUserImageDelete} />
          )}
        </Box>
      </>
    );
  }
}
