import React from 'react';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { rgba } from 'polished';

import { grey250 } from '@hero/styles/colors';
import { santasGrey, puertoRico } from '@hero/styles/colors-v4';

const StyledSwitch = withStyles({
  root: {
    width: '32px',
    height: '18px',
    padding: 0,
  },
  switchBase: {
    padding: 2,
    color: santasGrey,
    '&$checked': {
      transform: 'translateX(14px)',
      '& + $track': {
        backgroundColor: rgba(puertoRico, 0.8),
      },
    },
  },
  colorSecondary: {
    '&$checked': {
      transform: 'translateX(14px)',
    },
  },
  thumb: {
    height: '14px',
    width: '14px',
    borderRadius: '8px',
  },
  track: {
    backgroundColor: grey250,
    borderRadius: '9px',
  },
})(Switch);

const MaterialSwitch = React.forwardRef(function(props, ref) {
  return <StyledSwitch ref={ref} {...props} />;
});

export default MaterialSwitch;
