import React from 'react';
import mapValues from 'lodash/mapValues';
import uniqBy from 'lodash/uniqBy';
import isFinite from 'lodash/isFinite';
import omit from 'lodash/omit';
import { Route } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Dialog } from '@material-ui/core';

import Fetch from '@hero/tfs/src/shared/Fetch';
import Editor from '@hero/tfs/src/shared/Editor';
import SearchParams from '@hero/tfs/src/shared/SearchParams';
import {
  addDefaultEntries,
  removeDefaultEntries,
} from '@hero/tfs/src/shared/ConfigEditor';

import useUsers from '@hero/tfs/src/shared/hooks/useUsers';
import useConfig from '@hero/tfs/src/shared/hooks/useConfig';
import { TIME_TARGET_TYPE } from '@hero/tfs/src/shared/targetTypes';
import TfsEditor from './TfsEditor';

function createTransformation({ config }) {
  return {
    name: '',
    purpose: '',
    description: '',
    cost: '',
    currency_unit: 'USD',
    time_unit: 'Weeks',
    target_type: TIME_TARGET_TYPE,
    target_current: '',
    target_target: '',
    lead: [],
    configuration: config,
  };
}

export const TfsEditorContext = React.createContext(null);

export default function TfsHost({ children }) {
  const [lastUpdatedAt, setLastUpdatedAt] = React.useState(Date.now());
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <TfsEditorContext.Provider value={lastUpdatedAt}>
        {children}
      </TfsEditorContext.Provider>
      <Route>
        {({ history }) => (
          <SearchParams>
            {({ params, setParams }) => {
              if (params.action === 'create-tfs') {
                return (
                  <NewTfsEditor
                    onCreate={data => {
                      setParams(p => omit(p, ['action']));
                      setLastUpdatedAt(Date.now());
                      history.push(`/transformations/${data.data.ref_id}`);
                      enqueueSnackbar('Transformation created successfully', {
                        variant: 'success',
                      });
                    }}
                    onCancel={() => setParams(p => omit(p, ['action']))}
                  />
                );
              }

              if (params.action === 'update-tfs') {
                return (
                  <ExistingTfsEditor
                    transformationId={params.tfsId}
                    onUpdate={() => {
                      setParams(p => omit(p, ['action', 'tfsId']));
                      setLastUpdatedAt(Date.now());
                      enqueueSnackbar('Transformation updated successfully', {
                        variant: 'success',
                      });
                    }}
                    onCancel={() =>
                      setParams(p => omit(p, ['action', 'tfsId']))
                    }
                  />
                );
              }

              return null;
            }}
          </SearchParams>
        )}
      </Route>
    </>
  );
}

function NewTfsEditor({ onCreate, onCancel }) {
  const users = useUsers();
  const config = useConfig();

  if (!config) {
    return null;
  }
  return (
    <Fetch.POST
      manual
      url="/api/transformation/create"
      onFetch={({ data }) => onCreate(data)}
    >
      {({ error, doRequest: doCreate }) => (
        <Dialog
          open={true}
          scroll="paper"
          maxWidth="md"
          onClose={onCancel}
          aria-labelledby="New Transformation editor"
        >
          <Editor
            initialValue={createTransformation({
              config: removeDefaultEntries(config),
            })}
            onSubmit={value => {
              if (isTfsValid(value)) {
                doCreate({
                  body: {
                    ...trimName(removeTimeUnitIfNeeded(value)),
                    configuration: addDefaultEntries(
                      removeEmptyEntries(value.configuration)
                    ),
                  },
                });
              }
            }}
          >
            {({ value, onChange }) => (
              <TfsEditor
                title="New Transformation"
                users={users}
                transformation={value}
                onChange={newVal =>
                  onChange(
                    resetToDefaultTargetTypeIfNeeded(
                      addTimeUnitIfNeeded(newVal)
                    )
                  )
                }
                onCancel={onCancel}
                isTfsValid={isTfsValid}
                error={error}
              />
            )}
          </Editor>
        </Dialog>
      )}
    </Fetch.POST>
  );
}

function ExistingTfsEditor({ transformationId, onUpdate, onCancel }) {
  const users = useUsers();

  return (
    <Fetch.POST
      url="/api/transformation/read"
      body={{
        query: {
          ref_id: transformationId,
        },
      }}
      render={({ data: transformation }) => (
        <Fetch.POST
          manual
          url="/api/transformation/update"
          onFetch={({ data }) => onUpdate(data)}
        >
          {({ error, doRequest: doUpdate }) => (
            <Dialog
              open={true}
              scroll="paper"
              maxWidth="lg"
              onClose={onCancel}
              aria-labelledby="Edit Transformation editor"
            >
              <Editor
                initialValue={{
                  ...transformation,
                  configuration: removeDefaultEntries(
                    transformation.configuration
                  ),
                }}
                onSubmit={value => {
                  if (isTfsValid(value)) {
                    doUpdate({
                      body: {
                        ...trimName(removeTimeUnitIfNeeded(value)),
                        configuration: addDefaultEntries(
                          removeEmptyEntries(value.configuration)
                        ),
                      },
                    });
                  }
                }}
              >
                {({ value, onChange }) => (
                  <TfsEditor
                    title="Update Transformation"
                    users={users}
                    transformation={value}
                    onChange={newVal =>
                      onChange(
                        resetToDefaultTargetTypeIfNeeded(
                          addTimeUnitIfNeeded(newVal)
                        )
                      )
                    }
                    onCancel={onCancel}
                    isTfsValid={isTfsValid}
                    error={error}
                  />
                )}
              </Editor>
            </Dialog>
          )}
        </Fetch.POST>
      )}
    />
  );
}

function isTfsValid({
  name,
  cost,
  lead,
  target_current,
  target_target,
  configuration,
}) {
  return (
    Boolean(name.trim()) &&
    isFinite(parseFloat(cost)) &&
    parseFloat(cost) !== 0 &&
    lead.length > 0 &&
    isFinite(parseFloat(target_current)) &&
    isFinite(parseFloat(target_target)) &&
    isValidConfig(configuration)
  );
}

function isValidConfig(config) {
  const sectionKeys = [
    'stage_gate',
    'region',
    'business_unit',
    'workstream',
    'resource_type',
    'status',
  ];

  return sectionKeys.map(key => config[key]).every(x => isValidSection(x));
}

function isValidSection(entries) {
  return uniqBy(entries, 'name').length === entries.length;
}

function removeEmptyEntries(config) {
  return mapValues(config, value => {
    if (Array.isArray(value)) {
      return value.filter(entry => entry.name);
    }

    return value;
  });
}

function removeTimeUnitIfNeeded({ time_unit, ...tfs }) {
  if (tfs.target_type !== TIME_TARGET_TYPE) {
    return {
      ...tfs,
      time_unit: null,
    };
  }

  return { ...tfs, time_unit };
}

function addTimeUnitIfNeeded({ time_unit, ...tfs }) {
  if (tfs.target_type === TIME_TARGET_TYPE && !time_unit) {
    return {
      ...tfs,
      time_unit: 'Weeks',
    };
  }

  return { ...tfs, time_unit };
}

function resetToDefaultTargetTypeIfNeeded(tfs) {
  if (isBuiltInTargetType(tfs.target_type)) {
    return tfs;
  }

  const targetTypeExists = tfs.configuration.custom_target_types.some(
    ({ _id }) => _id === tfs.target_type
  );

  if (targetTypeExists) {
    return tfs;
  }

  return {
    ...tfs,
    target_type: TIME_TARGET_TYPE,
    time_unit: 'Weeks',
  };
}

function isBuiltInTargetType(targetType) {
  return ['0', '1'].includes(String(targetType));
}

function trimName({ name, ...tfs }) {
  return {
    ...tfs,
    name: name.trim(),
  };
}
