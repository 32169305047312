import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import ServerError from './ServerError';

export const FetchContext = React.createContext({
  fetch: function fetch(url, { method, params = {}, body, headers = {} }) {
    const token = localStorage.getItem('id_token');
    const u = new URL(url, process.env.REACT_APP_API_URL);
    const p = new URLSearchParams();
    const isPlainObject = ['[object Object]', '[object Array]'].includes(
      Object.prototype.toString.call(body)
    );

    for (const [key, value] of Object.entries(params)) {
      p.append(key, value);
    }

    u.search = p.toString();

    return global
      .fetch(u, {
        method,
        ...(body &&
          (isPlainObject ? { body: JSON.stringify(body) } : { body })),
        headers: {
          Authorization: token,
          ...(isPlainObject && { 'Content-Type': 'application/json' }),
          ...headers,
        },
      })
      .then(rsp => {
        if (!rsp.ok) {
          return rsp.json().then(data => {
            throw new ServerError(rsp, data);
          });
        }

        return rsp.json().then(data => {
          if (!data.ok) {
            throw new ServerError(rsp, data);
          }

          return data;
        });
      });
  },
});

export function withFetch(WrappedComponent, defaultProps = {}) {
  return class WithFetch extends Component {
    render() {
      return (
        <HeroFetch manual {...defaultProps}>
          {({ doRequest }) => (
            <WrappedComponent {...this.props} fetch={doRequest} />
          )}
        </HeroFetch>
      );
    }
  };
}

// HeroFetch adds token to every request, so you don't need to do that every time
export default class HeroFetch extends Component {
  state = {
    data: null,
    error: null,
    loading: null,
  };

  lastReqId = 1;
  activeReqs = [];

  componentDidMount() {
    this._mounted = true;

    if (!this.props.manual) {
      this.fetch(this.props);
    }
  }

  componentDidUpdate(prevProps) {
    const hasPropsChanged = ['url', 'method', 'params', 'body', 'headers'].some(
      key => !isEqual(this.props[key], prevProps[key])
    );

    if (!this.props.manual && hasPropsChanged) {
      this.fetch(this.props);
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  fetch = ({ url, ...options }) => {
    const reqId = this.lastReqId++;
    this.activeReqs.push(reqId);
    this.setState({ loading: true });

    return this.context
      .fetch(url, options)
      .then(data => {
        // Request can be superceeded by the newer request
        // Using list of active requests to identify stale one
        // TODO: use AbortController to cancel requests
        const isStateData =
          this.activeReqs[this.activeReqs.length - 1] !== reqId;

        // Request is done, let's remove it from the list of active requests
        this.activeReqs = this.activeReqs.filter(x => x !== reqId);

        if (this._mounted && !isStateData) {
          this.setState(
            {
              data,
              error: null,
              loading: false,
            },
            () => {
              if (typeof this.props.onFetch === 'function') {
                this.props.onFetch(this.state);
              }
            }
          );
        }

        return data;
      })
      .catch(error => {
        // Request is done, let's remove it from the list of active requests
        this.activeReqs = this.activeReqs.filter(x => x !== reqId);

        if (this._mounted) {
          this.setState(
            {
              data: null,
              error,
              loading: false,
            },
            () => {
              if (typeof this.props.onError === 'function') {
                this.props.onError(this.state);
              }
            }
          );
        }

        throw error;
      });
  };

  doRequest = options => this.fetch({ ...this.props, ...options });

  reset = () => {
    this.setState({ data: null, error: null, loading: false });
  };

  render() {
    const childProps = {
      ...this.state,
      doRequest: this.doRequest,
      reset: this.reset,
    };

    if (typeof this.props.render === 'function') {
      if (this.state.data) {
        return this.props.render(this.state.data, childProps);
      }

      return null;
    }

    if (typeof this.props.children === 'function') {
      return this.props.children(childProps);
    }

    return this.props.children || null;
  }
}

HeroFetch.contextType = FetchContext;

HeroFetch.POST = function HeroFetchPOST({ options, ...restProps }) {
  return <HeroFetch method="POST" {...restProps} />;
};

HeroFetch.PUT = function HeroFetchPUT({ options, ...restProps }) {
  return <HeroFetch method="PUT" {...restProps} />;
};

HeroFetch.DELETE = function HeroFetchDELETE({ options, ...restProps }) {
  return <HeroFetch method="DELETE" {...restProps} />;
};

HeroFetch.propTypes = {
  method: PropTypes.oneOf(['HEAD', 'GET', 'POST', 'PUT', 'DELETE']),
  params: PropTypes.object,
  onFetch: PropTypes.func,
  onError: PropTypes.func,
};

HeroFetch.defaultProps = {
  method: 'GET',
};

let reqId = 1;

HeroFetch.Provider = class FetchProvider extends Component {
  state = {
    queue: [],
  };

  componentWillUnmount() {
    this._unmounted = true;
  }

  fetch = (...args) => {
    const id = reqId++;

    this.setState(({ queue }) => ({
      queue: queue.concat(id),
    }));

    return this.context
      .fetch(...args)
      .then(rsp => {
        if (!this._unmounted) {
          this.setState(({ queue }) => ({
            queue: queue.filter(x => x !== id),
          }));
        }

        return rsp;
      })
      .catch(error => {
        if (!this._unmounted) {
          this.setState(({ queue }) => ({
            queue: queue.filter(x => x !== id),
          }));
        }

        if (typeof this.props.onError === 'function') {
          this.props.onError(error);
        }

        throw error;
      });
  };

  render() {
    const { fallback, render } = this.props;
    const loading = this.state.queue.length > 0;

    return (
      <Fragment>
        {typeof render === 'function' && this.props.render({ loading })}
        {fallback && loading && fallback}
        <FetchContext.Provider value={{ fetch: this.fetch }}>
          {this.props.children}
        </FetchContext.Provider>
      </Fragment>
    );
  }
};

HeroFetch.Provider.contextType = FetchContext;
