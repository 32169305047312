import { rgba } from 'polished';

export const blueRhino = '#2B4262';
export const redMandy = '#EB5255';
export const greenCarribean = '#00B8AC';
export const yellowBroom = '#FFE014';
export const black = '#000000';
export const greyScorpion = '#565656';
export const silverChalice = '#A6A6A6';
export const greyManatee = '#8B93A6';
export const whiteCatskill = '#F5F7FA';
export const catskillWhite = '#DFE7F0';
export const santasGrey = '#9799AA';
export const bayOfMany = '#314260';
export const puertoRico = '#4DB5AC';
export const ghostGrey = '#BABAC6';
export const romanRed = '#DA5D59';
export const mahoganyRed = '#B8322E';
export const quartz = '#F2F5FB';
export const appleBlossom = '#AD4948';
export const whiteLilac = '#E2E1E6';
export const fairPink = rgba(romanRed, 0.1);
export const limeade = '#5CB335';
export const creamCan = '#F2C754';
export const java = '#22988E';
export const mustard = '#FEE151';
export const mountainMeadow = '#18A599';
export const pattensBlue = '#D3DEF0';
export const pictonBlue = '#5990CA';
export const gainsboro = '#E6E6E6';
export const chambray = '#4C5D79';
export const waikawaGrey = '#637593';
export const jordyBlue = '#7DB1E6';
export const lightningYellow = '#F9A12A';
export const mistyRose = '#FFEDEC';
export const solitude = '#DEE2EA';
export const curiousBlue = '#3B8AB4';
export const neonCarrot = '#ff9234';
