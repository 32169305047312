import React from 'react';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { Box } from 'jsxstyle';

export default React.forwardRef(function TrackingDurationField(
  { id, value, onChange, ...props },
  ref
) {
  const [focusedInput, setFocusedInput] = React.useState(null);

  return (
    <Box {...props}>
      <DateRangePicker
        innerRef={ref}
        startDate={value[0] ? moment(value[0]) : moment()}
        endDate={value[1] ? moment(value[1]) : moment()}
        onDatesChange={({ startDate, endDate }) =>
          onChange([
            startDate ? startDate.toISOString() : value[0],
            endDate ? endDate.toISOString() : value[1],
          ])
        }
        focusedInput={focusedInput}
        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
        isOutsideRange={date => {
          if (focusedInput === 'endDate') {
            return date.isBefore(value[0]);
          } else {
            return date.isAfter(value[1]);
          }
        }}
        startDateId={`${id}-start`}
        endDateId={`${id}-end`}
        initialVisibleMonth={() => {
          if (focusedInput === 'endDate') {
            return moment(value[1]);
          } else {
            return moment(value[0]);
          }
        }}
        displayFormat="MMM D, YYYY"
      />
    </Box>
  );
});
