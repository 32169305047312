import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';

import { Text } from '@hero/styles/typography-v5';
import { romanRed } from '@hero/styles/colors-v4';

import LightTooltip from '@hero/tfs/src/shared/LightTooltip';
import { determineRedistribute } from '@hero/tfs/src/12-initiative/DistributeTracking';
import useInitiativeRouting from '@hero/tfs/src/shared/hooks/useInitiativeRouting';

const styles = {
  errorIconContianer: {
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
  },
  errorIcon: {
    color: romanRed,
    position: 'absolute',
    left: -28,
    top: -2,
  },
};

export default function TrackingErrorBadge({
  tfsId,
  ref_id,
  outcome_value,
  outcome_duration,
  outcome_tracking,
  outcome_period,
  cost,
  budget_duration,
  budget_tracking,
  budget_period,
  errorIconStyles,
}) {
  const { show: outcomeError } = determineRedistribute({
    value: outcome_value,
    duration: outcome_duration,
    tracking: outcome_tracking,
    period: outcome_period,
  });

  const { show: budgetError } = determineRedistribute({
    value: cost,
    duration: budget_duration,
    tracking: budget_tracking,
    period: budget_period,
  });

  const { startEditing } = useInitiativeRouting();

  return (
    <div>
      {(outcomeError || budgetError) && (
        <LightTooltip
          title={
            <>
              {outcomeError && (
                <Text size="p1" as="div" fontWeight="bold">
                  There is a discrepancy in the Outcome tracking. Correct this
                  by opening the Initiative page.
                </Text>
              )}
              {budgetError && (
                <Text size="p1" as="div" fontWeight="bold" marginTop="2px">
                  There is a discrepancy in the Budget tracking. Correct this by
                  opening the Initiative page.
                </Text>
              )}
            </>
          }
          placement="top"
        >
          <div
            style={styles.errorIconContianer}
            onClick={() => tfsId && ref_id && startEditing(tfsId, ref_id)}
          >
            <ErrorIcon style={{ ...styles.errorIcon, ...errorIconStyles }} />
          </div>
        </LightTooltip>
      )}
    </div>
  );
}
