import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import capitalize from 'lodash/capitalize';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { TableCell, TableRow } from '@hero/tfs/src/shared/table';
import Avatar from '@hero/tfs/src/shared/UserAvatar';
import { blueRhino } from '@hero/styles/colors-v4';

const STATUS_COLORS = {
  active: '#50E3C2',
  disabled: '#EB5255',
};

const StatusLabel = styled.span.attrs(props => ({
  children: props.status,
}))`
  position: relative;
  text-transform: capitalize;
  margin-left: 10px;
  ::after {
    position: absolute;
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: ${props => STATUS_COLORS[props.status]};
    left: -12px;
    top: 5px;
  }
`;

const UserCell = styled.span`
  display: inline-flex;
  align-items: center;
`;

const styles = theme => ({
  button: {
    color: blueRhino,
    textTransform: 'none',
    fontSize: 15,
  },

  menuItem: {
    color: blueRhino,
    fontSize: 15,
  },
});

class ActionsMenu extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const {
      classes,
      status,
      role,
      can,
      onActivate,
      onDeactivate,
      onDelete,
      onInvite,
      onChangeRole,
    } = this.props;

    return (
      <Fragment>
        <Button
          className={classes.button}
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          Choose action
        </Button>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {status === 'pending' && can.includes('activate') && (
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                this.handleClose();
                onActivate();
              }}
            >
              Approve
            </MenuItem>
          )}
          {status === 'disabled' && can.includes('activate') && (
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                this.handleClose();
                onActivate();
              }}
            >
              Activate
            </MenuItem>
          )}
          {status === 'active' && can.includes('deactivate') && (
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                this.handleClose();
                onDeactivate();
              }}
            >
              Deactivate
            </MenuItem>
          )}
          {status === 'invited' && can.includes('invite') && (
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                this.handleClose();
                onInvite();
              }}
            >
              Resend Invitation
            </MenuItem>
          )}
          {status !== 'active' && can.includes('delete') && (
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                this.handleClose();
                onDelete();
              }}
            >
              Delete
            </MenuItem>
          )}
          {role !== 'admin' && can.includes('make-admin') && (
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                this.handleClose();
                onChangeRole('admin');
              }}
            >
              Make Admin
            </MenuItem>
          )}
          {role !== 'member' && can.includes('make-member') && (
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                this.handleClose();
                onChangeRole('member');
              }}
            >
              Make Member
            </MenuItem>
          )}
          {role !== 'owner' && can.includes('make-owner') && (
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                this.handleClose();
                onChangeRole('owner');
              }}
            >
              Make Owner
            </MenuItem>
          )}
        </Menu>
      </Fragment>
    );
  }
}

ActionsMenu.defaultProps = {
  can: ['activate', 'deactivate', 'delete', 'invite'],
};

ActionsMenu.propTypes = {
  can: PropTypes.arrayOf(
    PropTypes.oneOf([
      'activate',
      'deactivate',
      'delete',
      'invite',
      'make-admin',
      'make-member',
      'make-owner',
    ])
  ),
};

ActionsMenu = withStyles(styles)(ActionsMenu);

export default function UserItem({
  user,
  rowCellClassName,
  can,
  onActivate,
  onDeactivate,
  onDelete,
  onInvite,
  onChangeRole,
}) {
  return (
    <TableRow hover>
      <TableCell
        className={rowCellClassName}
        component="th"
        scope="row"
        padding="default"
      >
        <UserCell>
          <Avatar user={user} />
          <span style={{ marginLeft: 15 }}>{user.username}</span>
        </UserCell>
      </TableCell>
      <TableCell className={rowCellClassName}>{user.email}</TableCell>
      <TableCell className={rowCellClassName}>
        {capitalize(user.role)}
      </TableCell>
      <TableCell className={rowCellClassName}>
        <StatusLabel status={user.status} />
      </TableCell>
      <TableCell className={rowCellClassName}>
        {can.filter(permission => permission !== 'invite').length > 0 && (
          <ActionsMenu
            can={can}
            status={user.status}
            role={user.role}
            onDeactivate={onDeactivate}
            onActivate={onActivate}
            onDelete={onDelete}
            onInvite={onInvite}
            onChangeRole={onChangeRole}
          />
        )}
      </TableCell>
    </TableRow>
  );
}
