import React from 'react';

export const ListViewContext = React.createContext(null);

export default function ListViewHost({ children }) {
  const [refreshedOn, refreshListView] = React.useState(Date.now());

  return (
    <ListViewContext.Provider value={{ refreshedOn, refreshListView }}>
      {children}
    </ListViewContext.Provider>
  );
}
