import React from 'react';
import { TooltipWithBounds } from '@vx/tooltip';
import { Box } from 'jsxstyle';
import styled from 'styled-components/macro';
import { format, isBefore } from 'date-fns';

import { santasGrey, gainsboro, bayOfMany } from '@hero/styles/colors-v4';
import { Text } from '@hero/styles/typography-v5';

import {
  formatNumber,
  NumberFormatContext,
} from '@hero/tfs/src/shared/FormattedNumber';
import { useLocale } from '@hero/tfs/src/shared/locale';
import { getProjectedValue } from './ProgressChart';

export const TooltipWithArrow = styled(TooltipWithBounds)`
  position: relative;
  border-radius: 1px;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
`;

export default function DataTooltip({
  currentData,
  xScale,
  yScale,
  marginLeft,
  label,
  asOfData,
  showPlanned = false,
}) {
  const { type, unit } = React.useContext(NumberFormatContext);
  const [locale] = useLocale();

  if (!currentData || !asOfData) {
    return null;
  }

  const { planned, actual, date } = currentData;

  const showProjected = !isBefore(date, asOfData.date);

  const top = yScale(
    showPlanned ? (planned > actual ? planned : actual) : actual
  );

  return (
    <TooltipWithArrow top={top - 100} left={xScale(date) + marginLeft - 10}>
      <Box
        padding="6px 12px"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Text
          size="overline"
          color={bayOfMany}
          textTransform="uppercase"
          marginBottom="8px"
          fontWeight="600"
        >
          {format(date, 'MMM d, YYYY', {
            awareOfUnicodeTokens: true,
          })}
        </Text>
        <Box display="flex" alignItems="center" justifyContent="center">
          {showPlanned && (
            <>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Text
                  size="overline"
                  textTransform="uppercase"
                  color={santasGrey}
                >
                  Planned
                </Text>
                <Text as="span" size="p1" fontWeight="700" marginTop="2px">
                  {formatNumber({
                    value: planned,
                    type,
                    unit,
                    locale,
                  })}
                </Text>
              </Box>

              <Box
                width="1px"
                height="33px"
                backgroundColor={gainsboro}
                margin="0 12px"
              />
            </>
          )}
          <Box display="flex" flexDirection="column" alignItems="center">
            <Text size="overline" textTransform="uppercase" color={santasGrey}>
              {showProjected ? 'Projected' : label || 'Actual'}
            </Text>
            <Text as="span" size="p1" fontWeight="700" marginTop="2px">
              {formatNumber({
                value: showProjected
                  ? getProjectedValue({
                      planned,
                      asOfPlanned: asOfData.planned,
                      asOfActual: asOfData.actual,
                    })
                  : actual,
                type,
                unit,
                locale,
              })}
            </Text>
          </Box>
        </Box>
      </Box>
    </TooltipWithArrow>
  );
}
