import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import styled from 'styled-components/macro';
import { greenCarribean } from '@hero/styles/colors-v4';
import { Box } from 'jsxstyle';

const PlusCircle = styled.div`
  display: inline-block;
  padding: 5px;
  border-radius: 50%;
  box-shadow: 0 2px 9px 0 #cdd2da;
  height: 38px;
  min-width: 38px;
  background-color: ${greenCarribean};
  color: white;
  position: relative;
  transform: ${props => (props.open ? 'rotate(-45deg)' : 'rotate(0deg)')};
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  ::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 2px;
    width: 17px;
    background: white;
  }

  ::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 17px;
    width: 2px;
    background: white;
  }
`;

export default function PlusPopover({ children }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = !!anchorEl;
  const onClose = () => setAnchorEl(null);

  return (
    <>
      <PlusCircle
        onClick={event => {
          setAnchorEl(event.currentTarget);
        }}
        open={isOpen}
      />
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -15,
          horizontal: 'right',
        }}
      >
        <Box>{children({ onClose })}</Box>
      </Popover>
    </>
  );
}
