import React from 'react';
import styled from 'styled-components';

import BirdIcon from '@hero/core/icons/Bird';
import Header from '@hero/core/Header';
import { mustard } from '@hero/styles/colors-v4';

const NavMenu = styled.nav`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: -0.5rem;
`;

const Link = styled.a`
  padding-left: 15px;
  padding-right: 15px;
  font-family: 'bebas-regular', Lato, sans-serif;
  color: ${props => (props.isActive ? mustard : 'white')};
  text-transform: uppercase;
  font-size: 19px;
  text-decoration: none;
  display: block;
  padding: 0.5rem 1rem;
  margin: 0 2vw;
  word-spacing: 3px;
  letter-spacing: 1px;
  &:hover,
  &:focus {
    color: ${mustard};
    text-decoration: underline;
  }
`;

export const NavMenuItems = () => (
  <NavMenu>
    <Link href="https://herotransformation.com/" rel="noopener noreferrer">
      Book
    </Link>
    <Link
      href="https://herotransformation.com/framework"
      rel="noopener noreferrer"
    >
      Framework
    </Link>
    <Link href="/" isActive>
      Software
    </Link>
    <Link
      href="https://herotransformation.com/training"
      rel="noopener noreferrer"
    >
      Training
    </Link>
    <Link
      href="https://herotransformation.com/#menu_home"
      rel="noopener noreferrer"
    >
      Contact
    </Link>
  </NavMenu>
);

const Nav = ({ fixed, ...props }) => (
  <Header fixed={fixed} {...props}>
    <a href="/" title="home" style={{ marginTop: '-0.5rem' }}>
      <BirdIcon width={89} height={22} />
    </a>
    <NavMenuItems />
  </Header>
);

export default Nav;
