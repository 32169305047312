import pick from 'lodash/pick';
import uuid from 'uuid/v4';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import differenceInHours from 'date-fns/differenceInHours';

const BLOCKER_BACKLOG = 'backlog';
const BLOCKER_IP = 'inprogress';
const BLOCKER_DONE = 'done';

export function isBlockerDone(blocker) {
  if (!blocker.state || blocker.state.length === 0) {
    return false;
  }

  return blocker.state[blocker.state.length - 1].state_id === BLOCKER_DONE;
}

export function createDefaultBlocker({ configData, initiatives }) {
  return {
    clientId: uuid(),
    id: uuid(),
    created_at: Date.now(),
    name: '',
    description: '',
    type: 'blocker',
    proposed_solution: '',
    state: [
      {
        state_id:
          configData.blocker_state.length > 0
            ? configData.blocker_state[0]._id
            : BLOCKER_BACKLOG,
      },
    ],
    initiatives:
      initiatives.length > 0
        ? [pick(initiatives[0], ['name', 'ref_id', 'outcome_value'])]
        : [],
  };
}

export function isValidBlocker(blocker) {
  return blocker.name && blocker.initiatives.length > 0;
}

export function getValidInitForBlocker(initiative) {
  return pick(initiative, ['name', 'ref_id', 'outcome_value']);
}

export function getValidBlocker(blocker) {
  const { id, ...rest } = blocker;
  return { ...rest };
}

export function fromatDistanceInUnits(t1, t2, unit = 'day') {
  return formatDistanceStrict(
    t1,
    t2,
    differenceInHours(t1, t2) > 23 ? { unit } : {}
  );
}

export function getBlockerStats(blocker) {
  const { created_at, state } = blocker;
  const stats = {
    leadTime: null,
    responseTime: null,
    cycleTime: null,
  };

  const lastState = state.length > 0 ? state[state.length - 1] : null;

  const ipState =
    lastState && lastState.state_id === BLOCKER_IP ? lastState : null;

  const doneState = isBlockerDone({
    state: state,
  })
    ? state[state.length - 1]
    : null;

  stats['leadTime'] = fromatDistanceInUnits(
    doneState ? new Date(doneState.timestamp) : new Date(),
    new Date(created_at)
  );

  if (ipState || doneState) {
    stats['responseTime'] = fromatDistanceInUnits(
      new Date(ipState ? ipState.timestamp : doneState.timestamp),
      new Date(created_at)
    );
  }

  if (ipState) {
    stats['cycleTime'] = fromatDistanceInUnits(
      new Date(),
      new Date(ipState.timestamp)
    );
  }

  if (doneState) {
    stats['cycleTime'] = fromatDistanceInUnits(
      new Date(doneState.timestamp),
      new Date(ipState ? ipState.timestamp : doneState.timestamp)
    );
  }

  return stats;
}

export function getTotalBlockedOutcome(blocker) {
  const { initiatives } = blocker;
  return initiatives
    ? initiatives.reduce((sum, x) => sum + x.outcome_value, 0)
    : 0;
}
