import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Grid as JSXGrid, Box } from 'jsxstyle';
import { waikawaGrey, solitude } from '@hero/styles/colors-v4';
import { Text } from '@hero/styles/typography-v5';
import { scaffolding } from '@hero/styles/spacings';
import { darken } from 'polished';

export const GROUP_BY_SEP = '::';

export function groupByMulti(list, props, sep = '::') {
  return list.reduce((grouped, item) => {
    const key = props
      .reduce((keyAcc, prop) => {
        keyAcc.push(item[prop]);
        return keyAcc;
      }, [])
      .join(sep);

    if (!grouped[key]) {
      grouped[key] = [item];
    } else {
      grouped[key].push(item);
    }
    return grouped;
  }, {});
}

export class Board extends Component {
  onDragEnd = ({ source, destination }) => {
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      this.props.onChange(
        reorder(
          this.props.value,
          source.droppableId,
          this.props.value[source.droppableId],
          source.index,
          destination.index
        ),
        { source, destination }
      );
    } else {
      this.props.onChange(
        move(
          this.props.value,
          this.props.value[source.droppableId] || [],
          this.props.value[destination.droppableId] || [],
          source,
          destination
        ),
        { source, destination }
      );
    }
  };

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        {this.props.children}
      </DragDropContext>
    );
  }
}

Board.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export function Grid({ noOfColumns, children }) {
  return (
    <JSXGrid
      gridTemplateColumns={[...Array(noOfColumns).keys()]
        .map(e => 'minmax(240px, 1fr)')
        .join(' ')}
      gridTemplateRows="auto"
      columnGap="16px"
    >
      {children}
    </JSXGrid>
  );
}

export function GridHeadRow({ name, count, noOfColumns }) {
  return (
    <Box
      backgroundColor={waikawaGrey}
      position="sticky"
      top="0"
      zIndex="1"
      height={48}
      padding={`${scaffolding.xs} ${scaffolding.md}`}
      gridColumn={`span ${noOfColumns}`}
    >
      <Text size="h3" color="#FFFFFF" textTransform="uppercase">
        {name} {count && <span>({count})</span>}
      </Text>
    </Box>
  );
}
export function Separator({ noOfColumns, ...props }) {
  return <Box height="8px" gridColumn={`span ${noOfColumns}`} {...props} />;
}

export const ColumnHead = styled.div`
  border-radius: 1px 1px 0 0;
  min-height: 40px;
  padding: 12px ${scaffolding.md};
  background-color: ${solitude};
  position: sticky;
  top: 48px;
`;

const ColumnDroppable = styled.div`
  min-height: 130px;
  height: calc(100% - 40px);
  padding: 0 8px 16px 8px;
  transition: background-color 0.3s ease-in;
  background-color: ${props =>
    props.isDraggingOver ? darken(0.1, solitude) : solitude};
`;

export function Column({ id, children, ...props }) {
  return (
    <Droppable droppableId={id}>
      {(provided, snapshot) => (
        <ColumnDroppable
          id={id}
          ref={provided.innerRef}
          className="droppable-column"
          isDraggingOver={snapshot.isDraggingOver}
          {...props}
        >
          {children}
          {provided.placeholder}
        </ColumnDroppable>
      )}
    </Droppable>
  );
}

export function Card({ _id, index, color, onClick, children }) {
  return (
    <Draggable key={_id} draggableId={_id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            userSelect: 'none',
            backgroundColor: snapshot.isDragging ? '#dfe7f0' : 'transparent',
            marginBottom: '8px',
            ...provided.draggableProps.style,
          }}
          onClick={onClick}
        >
          <Box
            display="flex"
            flexDirection="column"
            id={_id}
            minHeight="80px"
            padding="8px 16px 12px 16px"
            backgroundColor="white"
            boxShadow="0 2px 4px 0 rgba(49,66,96,0.1)"
            borderTop={`16px solid ${color}`}
          >
            {children}
          </Box>
        </div>
      )}
    </Draggable>
  );
}

function reorder(value, droppableId, list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return { initByWS: { ...value, [droppableId]: result }, init: removed };
}

function move(
  value,
  source,
  destination,
  droppableSource,
  droppableDestination
) {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  const [newWorkstreamId, newStageId] = droppableDestination.droppableId.split(
    GROUP_BY_SEP
  );

  removed.stage_gate = newStageId;
  removed.workstream = newWorkstreamId;

  destClone.splice(droppableDestination.index, 0, removed);
  const result = { ...value };
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return { initByWS: result, init: removed };
}
