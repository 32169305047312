import React from 'react';
import { Box } from 'jsxstyle';
import { T1, LLato } from '@hero/styles/typography';

import UserMenu, { DL, DT } from '@hero/tfs/src/shared/UserMenu';
import Menu from '@hero/tfs/src/shared/Menu';
import UserAvatar from '@hero/tfs/src/shared/UserAvatar';
import { blueRhino } from '@hero/styles/colors-v4';
import EditText from './EditText';

export default function SetupSection({ initiative, onChange, config, users }) {
  return (
    <Box background="white" width="100%" padding="24px 32px">
      <T1 color={blueRhino}>
        <EditText
          value={initiative.name}
          onChange={name => onChange({ name })}
        />
      </T1>
      <Box marginTop="24px" marginBottom="32px">
        <LLato color={blueRhino}>
          <EditText
            value={initiative.description || ''}
            onChange={description => onChange({ description })}
            placeholder="Enter description.."
          />
        </LLato>
      </Box>
      <DL>
        <DT>Workstream</DT>
        <Menu
          items={config.workstream}
          selected={initiative.workstream}
          onSelect={id => onChange({ workstream: id })}
        />
      </DL>
      <DL>
        <DT>Stage</DT>
        <Menu
          items={config.stage_gate}
          selected={initiative.stage_gate}
          onSelect={id => onChange({ stage_gate: id })}
        />
      </DL>

      <DL>
        <DT>Business Unit</DT>
        <Menu
          items={config.business_unit}
          selected={initiative.business_unit}
          onSelect={id => onChange({ business_unit: id })}
        />
      </DL>

      <DL>
        <DT>Difficulty</DT>
        <Menu
          items={config.difficulty}
          selected={initiative.difficulty}
          onSelect={id => onChange({ difficulty: id })}
        />
      </DL>

      <DL>
        <DT>Region</DT>
        <Menu
          items={config.region}
          selected={initiative.region}
          onSelect={id => onChange({ region: id })}
        />
      </DL>

      <DL>
        <Box float="left" marginRight={10}>
          <UserAvatar user={initiative.lead || {}} />
        </Box>

        <DT>Lead</DT>
        <UserMenu
          maxWidth="300px"
          users={users}
          selected={initiative.lead && initiative.lead.user_id}
          onSelect={id => onChange({ lead: users.find(x => x.user_id === id) })}
        />
      </DL>

      <DL>
        <Box float="left" marginRight={10}>
          <UserAvatar user={initiative.sponsor || {}} />
        </Box>

        <DT>Sponsor</DT>
        <UserMenu
          users={users}
          selected={initiative.sponsor && initiative.sponsor.user_id}
          onSelect={id =>
            onChange({ sponsor: users.find(x => x.user_id === id) })
          }
        />
      </DL>

      <DL>
        <Box float="left" marginRight={10}>
          <UserAvatar user={initiative.approver || {}} />
        </Box>
        <DT>Approver</DT>
        <UserMenu
          users={users}
          selected={initiative.approver && initiative.approver.user_id}
          onSelect={id =>
            onChange({ approver: users.find(x => x.user_id === id) })
          }
        />
      </DL>
    </Box>
  );
}
