import React from 'react';
import styled from 'styled-components/macro';
import { Box } from 'jsxstyle';

import { fading } from '@hero/styles/animations';
import { quartz } from '@hero/styles/colors-v4';

const sizeToStyle = {
  title: {
    height: '28px',
    width: '80%',
  },
  description: {
    height: '80px',
    width: '100%',
  },
  p: {
    height: '15px',
    width: '100%',
  },
  label: {
    height: '10px',
    width: '80px',
  },
  value: {
    height: '24px',
    width: '100%',
  },
  user: {
    height: '44px',
    width: '44px',
  },
  header: {
    height: '25px',
    width: '165px',
    marginBottom: '32px',
  },
  input: {
    height: '32px',
    width: '100%',
  },
  button: {
    height: '32px',
    width: '136px',
  },
  chart: {
    height: '72px',
    width: '100%',
  },
  blockerRow: {
    height: '122px',
    width: '100%',
    marginTop: '42px',
  },
  riskRow: {
    height: '92px',
    width: '100%',
    marginTop: '42px',
  },
  tableHeader: {
    height: '50px',
    width: '100%',
    marginTop: '3px',
    marginBottom: '3px',
  },
  tableRow: {
    height: '36px',
    width: '100%',
    marginTop: '4px',
    marginBottom: '4px',
  },
  card: {
    height: '118px',
    width: '100%',
  },
  h2: {
    height: '43px',
    width: '100%',
  },
  h3: {
    height: '30px',
    width: '80px',
  },
  h6: {
    height: '18px',
    width: '100px',
  },
  dashBoardChart: {
    height: '139px',
    width: '100%',
  },
  progressBar: {
    height: '4px',
    width: '100%',
  },
};

const StyledBox = styled(Box)`
  animation: ${fading} 1.5s infinite;
  border-radius: 1px;
`;

export function PlaceholderLoader({ type, ...props }) {
  return (
    <StyledBox backgroundColor={quartz} {...sizeToStyle[type]} {...props} />
  );
}

export function DropdownLoader({ width = '100%', ...props }) {
  return (
    <Box {...props}>
      <PlaceholderLoader type="label" marginBottom="10px" />
      <PlaceholderLoader type="value" marginBottom="24px" width={width} />
    </Box>
  );
}

export function UserLoader({ width = '100%' }) {
  return (
    <Box display="flex">
      <PlaceholderLoader type="user" />
      <Box
        display="flex"
        flexDirection="column"
        marginLeft="10px"
        width={width}
      >
        <PlaceholderLoader type="label" marginBottom="10px" />
        <PlaceholderLoader type="value" marginBottom="24px" width="100%" />
      </Box>
    </Box>
  );
}

export function StatsLoader(props) {
  return (
    <Box display="flex" {...props}>
      <PlaceholderLoader type="user" />
      <Box display="flex" flexDirection="column" marginLeft="10px">
        <PlaceholderLoader type="label" marginBottom="8px" />
        <PlaceholderLoader type="value" width="100%" />
      </Box>
    </Box>
  );
}

export function BlockerLoader(props) {
  const blockerCols = new Array(3).fill(0);
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      {...props}
    >
      {blockerCols.map((b, index) => (
        <Box key={index} display="flex" flexDirection="column">
          <PlaceholderLoader type="label" width="27px" marginBottom={4} />
          <PlaceholderLoader type="value" width="20px" />
        </Box>
      ))}
    </Box>
  );
}

export function ColumnLoader({
  columnNumber,
  noOfCards = 1,
  columnColor,
  columnContentStyles,
  isLast,
  ...props
}) {
  const cards = new Array(noOfCards).fill(0).map((c, index) => index);
  return (
    <Box
      display="flex"
      flexDirection="column"
      marginRight={isLast ? '0' : '16px'}
      {...props}
    >
      <Box
        height="40px"
        paddingLeft="32px"
        backgroundColor="white"
        display="flex"
        alignItems="center"
      >
        <PlaceholderLoader type="title" />
      </Box>
      <Box padding="8px" backgroundColor={columnColor} {...columnContentStyles}>
        {cards.map(c => (
          <Box
            key={`${columnNumber}-${c}`}
            height="118px"
            padding="8px 24px 12px"
            marginBottom="8px"
            backgroundColor="white"
          >
            <PlaceholderLoader type="p" marginBottom="2px" />
            <PlaceholderLoader type="p" marginBottom="8px" />
            <PlaceholderLoader type="h3" />
          </Box>
        ))}
      </Box>
    </Box>
  );
}
