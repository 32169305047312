import React, { useContext } from 'react';
import { Box } from 'jsxstyle';
import styled from 'styled-components/macro';
import { Link, Route } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import { Container, Row, Col } from 'react-grid-system';
import TimerIcon from '@material-ui/icons/Timer';
import UpdateIcon from '@material-ui/icons/Update';
import BuildIcon from '@material-ui/icons/Build';
import pluralize from 'pluralize';

import { Text } from '@hero/styles/typography-v5';
import { romanRed, curiousBlue } from '@hero/styles/colors-v4';
import BlockerCard from '@hero/tfs/src/03-blockers/BlockerCard';
import EditBlockerContainer from '@hero/tfs/src/03-blockers/BlockerForm';
import { BlockersContext } from '@hero/tfs/src/03-blockers/BlockersHost';
import { BACKLOG } from '@hero/tfs/src/shared/StatusLegend';

import BlockerTime from '../03-blockers/BlockerTime';
import { scaffolding } from '@hero/styles/spacings';

const NoBlockersSection = styled.div`
  flex-grow: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export function BlockerProgressStats({ blockerStats, ...props }) {
  const {
    reactionTime,
    cycleTime,
    leadTime,
    latestReactionTime,
    latestCycleTime,
    latestLeadTime,
    minReactionTime,
    maxReactionTime,
    minCycleTime,
    maxCycleTime,
    minLeadTime,
    maxLeadTime,
    total,
  } = blockerStats;
  return (
    <Container fluid style={{ margin: 0, padding: 0 }}>
      <Row align="center">
        <Col sm={12} md={4}>
          <BlockerTime
            Icon={TimerIcon}
            title="Lead Time"
            overall={leadTime}
            latestTime={latestLeadTime}
            average={total ? leadTime / total : 0}
            max={maxLeadTime}
            min={minLeadTime}
            paddingBottom={8}
          />
        </Col>
        <Col sm={12} md={4}>
          <BlockerTime
            Icon={UpdateIcon}
            title="Response Time"
            overall={reactionTime}
            latestTime={latestReactionTime}
            average={total ? reactionTime / total : 0}
            max={maxReactionTime}
            min={minReactionTime}
            paddingBottom={8}
          />
        </Col>
        <Col sm={12} md={4}>
          <BlockerTime
            Icon={BuildIcon}
            title="Cycle Time"
            overall={cycleTime}
            latestTime={latestCycleTime}
            average={total ? cycleTime / total : 0}
            max={maxCycleTime}
            min={minCycleTime}
            paddingBottom={8}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default function BlockerSection({ stats, tfs, configData }) {
  const totalOutcome = Math.abs(tfs.target_target - tfs.target_current);

  const { refreshBlockers } = useContext(BlockersContext);

  const { topBlockers } = stats;
  const sortedTopBlockers = orderBy(
    topBlockers,
    ['outcome_value'],
    ['desc']
  ).slice(0, 3);

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection="column"
        backgroundColor="rgba(218, 93, 89, .05)"
        padding="14px 32px 24px 32px"
        margin="0 -32px -24px -32px"
      >
        {stats.topBlockers.length > 0 ? (
          <>
            <Box display="flex" alignItems="center">
              <Text
                as="span"
                size="overline"
                color={romanRed}
                textTransform="uppercase"
              >
                Top {pluralize('Blocker', stats.topBlockers.length, true)}
              </Text>
            </Box>
            <Container fluid style={{ margin: 0, padding: 0 }}>
              <Row align="center">
                {sortedTopBlockers.map(item => {
                  const blockerState = configData.blocker_state.find(
                    blocker =>
                      blocker._id === item.state[item.state.length - 1].state_id
                  );

                  // need a Box here since BlockerCard.Row pass alignment styles to BlockerCard
                  return (
                    <Col
                      key={item.ref_id}
                      xs={12}
                      lg={4}
                      style={{ marginTop: '16px' }}
                    >
                      <Link
                        to={`/transformations/${
                          tfs.ref_id
                        }/overview/top-blockers/${item.ref_id}`}
                      >
                        <BlockerCard
                          key={item.ref_id}
                          name={item.name}
                          initiatives={item.initiatives}
                          totalOutcome={totalOutcome}
                          createdAt={item.created_at}
                          deadline={item.deadline}
                          lastUpdatedAt={
                            item.last_update || new Date().toISOString()
                          }
                          state={item.state}
                          status={blockerState ? blockerState.name : BACKLOG}
                          minHeight={125}
                        />
                      </Link>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </>
        ) : (
          <NoBlockersSection>
            <Text size="overline" color={romanRed} textTransform="uppercase">
              No blockers
            </Text>
          </NoBlockersSection>
        )}
        <Link to={`/transformations/${tfs.ref_id}/blockers`}>
          <Text
            as="div"
            textAlign="center"
            fontFamily="Montserrat, sans-serif"
            fontWeight="600"
            color={curiousBlue}
            marginTop={scaffolding.sm}
            textDecoration="underline"
          >
            Go to Blockers page
          </Text>
        </Link>
      </Box>

      <Route
        path="/transformations/:tfsId/overview/top-blockers/:blockerId"
        render={({ match, history }) => (
          <EditBlockerContainer
            tfsId={match.params.tfsId}
            isOpen={true}
            configData={configData}
            closeModal={() =>
              history.push(`/transformations/${match.params.tfsId}/overview`)
            }
            onBlockerSubmit={() => refreshBlockers(Date.now())}
            blockerValue={stats.topBlockers.find(
              x => x.ref_id === match.params.blockerId
            )}
          />
        )}
      />
    </Box>
  );
}

function StatNumber({ done, total, ...props }) {
  return (
    <Box display="flex" marginRight="24px" {...props}>
      <Text
        as="span"
        size="overline"
        color={romanRed}
        textTransform="uppercase"
      >
        {done} / {total}
      </Text>
    </Box>
  );
}

StatNumber.defaultProps = {
  total: 0,
  done: 0,
};
