import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { blueRhino } from '@hero/styles/colors-v4';
import { Box } from 'jsxstyle';

const Icon = styled(Box)`
  display: flex;
  align-items: center;
  margin-right: 14px;
  background-color: ${props => props.color};
  border-radius: 5.2px;
  width: 54px;
  height: 54px;
  min-width: 54px;
  min-height: 54px;
  box-shadow: 0 3px 12px 0 rgba(43, 66, 98, 0.21);

  svg {
    width: ${props => props.iconWidth};
    height: ${props => props.iconHeight};
    color: white;
    margin: 0 auto;
  }
`;

const Link = styled(NavLink)`
  display: flex;
  padding: 8px;
  color: ${blueRhino};
  font-family: Montserrat;
  font-size: 14px;
  letter-spacing: -0.19px;
  line-height: 19px;
  background: rgba(43, 66, 98, 0);
  transition: background 0.1s ease-in-out;
  border-radius: 1px;
  max-width: 300px;
  max-height: 90px;
  overflow: hidden;
`;

const LinkTitle = styled(Box)`
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 5px;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const LinkHelper = styled(Box)`
  color: #718096;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`;

const ListItem = styled.li`
  border-bottom: 2px solid #f5f7fa;
  padding: 20px;

  &:last-child {
    border-bottom: none;
  }

  :hover {
    background: rgba(43, 66, 98, 0.05);
  }
`;

export default function AddLink({
  to,
  icon: ItemIcon,
  text,
  description,
  onClick,
  color,
  iconWidth,
  iconHeight,
}) {
  return (
    <ListItem>
      <Link exact to={to} onClick={onClick}>
        <Icon color={color} iconWidth={iconWidth} iconHeight={iconHeight}>
          <ItemIcon />
        </Icon>
        <Box>
          <LinkTitle>{text}</LinkTitle>
          <LinkHelper>{description}</LinkHelper>
        </Box>
      </Link>
    </ListItem>
  );
}

AddLink.defaultProps = {
  to: '#',
  color: blueRhino,
  iconWidth: '40px',
  iconHeight: '40px',
};
