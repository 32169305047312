import { css } from 'styled-components/macro';

import BebasRegular from './fonts/Bebas/bebas-regular.woff2';

export const fonts = css`
  @font-face {
    font-family: 'bebas-regular';
    src: url(${BebasRegular}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }
`;
