import React from 'react';
import { white, colorError } from '@hero/styles/colors';

const ErrorIcon = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="#d8d8db"
    viewBox="0 0 45 45"
    {...props}
  >
    <g>
      <circle
        stroke={colorError}
        fill={colorError}
        strokeWidth="4"
        cx="22.4472656"
        cy="22.4472656"
        r="20.4472656"
      />
    </g>
    <path
      d="M12.9472656,12.9472656 L31.4540211,31.4540211"
      stroke={white}
      strokeWidth="2"
      strokeLinecap="square"
    />
    <path
      d="M31.9540211,13.4472656 L13.4472656,31.9540211"
      stroke={white}
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);

export default ErrorIcon;
