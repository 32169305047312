import styled from 'styled-components/macro';
import MUITableCell from '@material-ui/core/TableCell';
import MUITableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import {
  santasGrey,
  blueRhino,
  bayOfMany,
  quartz,
  solitude,
} from '@hero/styles/colors-v4';

export const styles = theme => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    overflowX: 'scroll',
  },
  table: {
    minWidth: 768,
    borderCollapse: 'separate',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    minWidth: '200px',
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export const TableRow = withStyles({
  root: {
    height: '48px',
    '&:nth-of-type(odd)': {
      backgroundColor: quartz,
    },
  },
  head: {
    height: '28px',
  },
})(MUITableRow);

export const TableHeadRow = withStyles({
  root: {
    height: '28px',
  },
})(MUITableRow);

export const TableCell = withStyles(() => ({
  head: {
    textTransform: 'uppercase',
    position: 'sticky',
    top: '0',
    backgroundColor: 'white',
    padding: '0 8px 2px 8px',
    border: 'none',
    color: bayOfMany,
    fontSize: '10px',
    lineHeight: '12px',
    borderBottom: `1px solid ${santasGrey}`,
    minWidth: '150px',
  },
  body: {
    padding: '15px 8px',
    border: 'none',
    whiteSpace: 'nowrap',
    color: blueRhino,
    fontSize: '15px',
    lineHeight: '18px',
    borderRight: `1px solid ${solitude}`,
    ':last-child': {
      borderRight: 'none',
    },
  },
}))(MUITableCell);

export const TableScrollWrapper = styled.div`
  height: 100%;
`;

export const TableMessageCell = styled(MUITableCell).attrs({
  colSpan: props => (props.colSpan ? props.colSpan : 20),
})``;
