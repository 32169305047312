import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import isEqual from 'lodash/isEqual';

export const DEFAULT_VALUE = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

export function getDefaultValue(text) {
  if (isString(text) || isNumber(text)) {
    return [{ type: 'paragraph', children: [{ text }] }];
  }

  if (isEmpty(text)) {
    return DEFAULT_VALUE;
  }

  return text;
}

export function isDefultValue(val) {
  return isEqual(DEFAULT_VALUE, val);
}
