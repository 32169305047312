import React, { useContext } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { Box } from 'jsxstyle';
import { bayOfMany } from '@hero/styles/colors-v4';
import { Text } from '@hero/styles/typography-v5';
import { scaffolding } from '@hero/styles/spacings';

import FormattedNumber from '@hero/tfs/src/shared/FormattedNumber';
import useStats from '@hero/tfs/src/shared/hooks/useStats';
import useInitiativesByTfs from '@hero/tfs/src/shared/hooks/useInitiativesByTfs';
import { FINANCE_TARGET_TYPE } from '@hero/tfs/src/shared/targetTypes';

import { TfsEditorContext } from '@hero/tfs/src/10-transformations-editor/TfsHost';
import { InitiativeContext } from '@hero/tfs/src/12-initiative/InitiativeEditorHost';
import { BlockersContext } from '@hero/tfs/src/03-blockers/BlockersHost';
import BlockerStatsRow from '@hero/tfs/src/03-blockers/BlockerStatsRow';
import { makeData } from '@hero/tfs/src/02-overview/ProgressChart';

import Card, { Header, Content } from './Card';
import Area, { FormattedNumberProvider } from './Area';
import Bridge from './Bridge';
import TfsDashboardLoader from './TfsDashboardLoader';
import TrackingAreaLegend from './TrackingAreaLegend';
import StatsLegend from './StatsLegend';
import BlockerSection from './BlockerSection';
import ROILegends from './ROILegends';
import {
  getActualOutcomeByDate,
  getActualCostByDate,
  getFutureBudgetTotal,
  getProjectedOutcome,
  getProjectedBudget,
  TYPES,
} from './shared';

function TfsDashboard({ tfs }) {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const lastUpdatedTfsAt = React.useContext(TfsEditorContext);
  const { refreshedOn } = useContext(InitiativeContext);
  const { refreshedOn: blockersRefreshedon } = useContext(BlockersContext);

  const workstreamId = urlParams.get('workstream');

  const initiatives = useInitiativesByTfs({
    tfsId: tfs.ref_id,
    workstream: workstreamId,
    key: refreshedOn,
  });
  const [stats] = useStats(
    tfs.ref_id,
    workstreamId,
    lastUpdatedTfsAt + refreshedOn + blockersRefreshedon
  );

  const loading = !initiatives || !stats;

  if (loading) {
    return <TfsDashboardLoader />;
  }

  const {
    ref_id,
    target_target,
    target_current,
    target_type,
    configuration,
    cost,
    hurdle_rate,
    ws_hurdle_rate,
  } = tfs;

  const tfsOutcome = Math.abs(target_target - target_current);

  const initiativesByWorkstream = initiatives.filter(x =>
    doesMatchWorkstream(x, workstreamId)
  );

  const workstream = configuration.workstream.find(
    x => String(x._id) === workstreamId
  );

  const targetOutcome = workstream ? workstream.outcome || 0 : tfsOutcome;
  const targetCost = workstream ? workstream.cost || 0 : cost;

  const plannedOutcome = initiativesByWorkstream
    .map(i => i.outcome_value)
    .reduce((a, b) => a + b, 0);
  const plannedBudget = initiativesByWorkstream
    .map(i => i.cost)
    .reduce((a, b) => a + b, 0);

  const outcomeProgressData = makeData(initiativesByWorkstream, {
    type: 'outcome',
  });

  const budgetProgressData = makeData(initiativesByWorkstream, {
    type: 'budget',
  });

  const projectedOutcome = getProjectedOutcome(
    plannedOutcome,
    outcomeProgressData
  );
  const projectedBudget = getProjectedBudget(plannedBudget, budgetProgressData);

  const actualOutcome = getActualOutcomeByDate(outcomeProgressData);
  const actualCost = getActualCostByDate(budgetProgressData);
  const futureBudget = getFutureBudgetTotal(budgetProgressData);

  return (
    <FormattedNumber.TfsProvider tfs={tfs}>
      <Box width="100%" transition="height 0.66s ease-out">
        <Card backgroundColor="white" marginBottom="24px">
          <Header>
            <Text size="h4">
              {workstream ? workstream.name : 'Transformation'} Outcome
            </Text>
            <TrackingAreaLegend />
          </Header>
          <Content color={bayOfMany}>
            <StatsLegend
              type={TYPES.outcome}
              target={targetOutcome}
              planned={plannedOutcome}
              actual={actualOutcome}
              projected={projectedOutcome}
            />
            <Box width="100%">
              <Bridge
                showShortfall={!workstream}
                tfsId={ref_id}
                tfsOutcome={targetOutcome}
                statusList={configuration.status}
                stats={stats}
              />
            </Box>

            <Box width="100%" marginTop={32}>
              <Area
                type={TYPES.outcome}
                target={targetOutcome}
                planned={plannedOutcome}
                tfs={tfs}
                data={outcomeProgressData}
              />
            </Box>
          </Content>
        </Card>

        <Card backgroundColor="white" marginBottom="24px">
          <Header>
            <Text size="h4">
              {workstream ? workstream.name : 'Transformation'} Budget
            </Text>
            <TrackingAreaLegend />
          </Header>

          <Content color={bayOfMany}>
            <FormattedNumberProvider type="budget" tfs={tfs}>
              <StatsLegend
                type={TYPES.budget}
                target={targetCost}
                planned={plannedBudget}
                actual={actualCost}
                projected={projectedBudget}
              />
            </FormattedNumberProvider>
            <Box width="100%">
              <Area
                type={TYPES.budget}
                target={targetCost}
                planned={plannedBudget}
                tfs={tfs}
                data={budgetProgressData}
              />
            </Box>
          </Content>
        </Card>
        {target_type === FINANCE_TARGET_TYPE && (
          <Card backgroundColor="white" margin="20px 0">
            <Header>
              <Text size="h4">
                {workstream ? workstream.name : 'Transformation'} ROI
              </Text>
            </Header>
            <Content>
              <ROILegends
                outcome={targetOutcome}
                cost={targetCost}
                hurdleRate={workstream ? ws_hurdle_rate : hurdle_rate}
                actualOutcome={actualOutcome}
                futureBudget={futureBudget}
              />
            </Content>
          </Card>
        )}
        <Card backgroundColor="white" margin="20px 0">
          <Header>
            <Text size="h4">Blockers</Text>
          </Header>
          <Content>
            <Box marginTop={scaffolding.xs1} marginBottom={scaffolding.lg}>
              <BlockerStatsRow
                doFormat
                variant="big"
                leadTime={stats.blockerStats.leadTime}
                reactionTime={stats.blockerStats.reactionTime}
                cycleTime={stats.blockerStats.cycleTime}
                justifyContent="flex-start"
              />
            </Box>
            <BlockerSection
              stats={stats}
              tfs={tfs}
              configData={configuration}
            />
          </Content>
        </Card>
      </Box>
    </FormattedNumber.TfsProvider>
  );
}

function doesMatchWorkstream(initiative, workstream) {
  if (workstream == null) {
    return true;
  }

  return String(initiative.workstream) === workstream;
}

export default withRouter(TfsDashboard);
