import React from 'react';
import styled from 'styled-components/macro';
import pick from 'lodash/pick';

import { santasGrey } from '@hero/styles/colors-v4';
import TrackingErrorBadge from '@hero/tfs/src/12-initiative/TrackingErrorBadge';

import {
  ZINDEXS,
  ROW_PADDING_OFFSET,
  GroupWrapper,
  GroupTitle,
  UL,
  StripeLabel,
} from './shared';

const LabelLI = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 16px;
  margin-bottom: 9px;
`;

const SidebarBox = styled.div`
  position: sticky;
  left: 0;
  z-index: ${ZINDEXS.sideBar};
  background-color: #fff;
  height: ${props => (props.height ? `${props.height}px` : 'auto')};
  width: 180px;
  border-right: 1px solid ${santasGrey};
`;

const SidebarGroupWrapper = styled(GroupWrapper)`
  && {
    border-radius: 1px 0 0 1px;
  }
`;

const SidebarStripeLabel = styled(StripeLabel)`
  left: auto;
  width: auto;
  line-height: 18px;
  background: none;
  font-weight: ${props => (props.highlight ? 'bold' : '500')};
  transition: font-weight 0.2s ease-in;
  max-width: ${props => props.sidebarWidth - 20}px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
`;

export default function GanttChartSidebar({
  tfsId,
  data,
  view,
  periodMultiplier,
  zeroDate,
  scaleSize,
  scrollLeft,
  leftMargin,
  rowHeight,
  width,
  stripId,
  onInitiativeClick,
  onMouseOver,
  onMouseOut,
}) {
  const refId = stripId && stripId.split('-')[1];

  return (
    <SidebarBox>
      {data.map(dataGroup => (
        <SidebarGroupWrapper key={dataGroup.label}>
          <GroupTitle>
            {!dataGroup.label || dataGroup.label === 'null'
              ? 'None'
              : dataGroup.label}
            &nbsp; ({dataGroup.data.length})
          </GroupTitle>
          <UL
            height={
              (dataGroup.data.length * rowHeight || 0) + ROW_PADDING_OFFSET
            }
          >
            {dataGroup.data.map(item => (
              <SidebarStripeTitle
                key={item.ref_id}
                tfsId={tfsId}
                data={item}
                zeroDate={zeroDate}
                view={view}
                scaleSize={scaleSize}
                periodMultiplier={periodMultiplier}
                leftMargin={leftMargin}
                scrollLeft={scrollLeft}
                sidebarWidth={width}
                highlight={refId === item.ref_id}
                onClick={onInitiativeClick}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
              />
            ))}
          </UL>
        </SidebarGroupWrapper>
      ))}
    </SidebarBox>
  );
}

function SidebarStripeTitle({
  tfsId,
  data,
  sidebarWidth,
  highlight,
  onClick,
  onMouseOver,
  onMouseOut,
}) {
  return (
    <LabelLI>
      <TrackingErrorBadge
        tfsId={tfsId}
        errorIconStyles={{
          position: 'initial',
          fontSize: 16,
          marginRight: 4,
        }}
        {...pick(data, [
          'ref_id',
          'outcome_value',
          'outcome_duration',
          'outcome_tracking',
          'outcome_period',
          'cost',
          'budget_duration',
          'budget_tracking',
          'budget_period',
        ])}
      />
      <SidebarStripeLabel
        sidebarWidth={sidebarWidth}
        highlight={highlight}
        title={data.name}
        onClick={() => onClick(data)}
        onMouseOver={() => onMouseOver(data.ref_id)}
        onMouseOut={onMouseOut}
      >
        {data.name}
      </SidebarStripeLabel>
    </LabelLI>
  );
}
