import styled, { css } from 'styled-components/macro';

import { navHeight } from '@hero/website/src/styles/spacings';
import { bayOfMany } from '@hero/styles/colors-v4';

const fixedHeader = css`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
`;

export const Wrapper = styled.header`
  padding: 1.75rem 20px;
  max-height: ${navHeight}px;
  min-height: ${navHeight}px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
  background: ${props => props.background || bayOfMany};
  ${props => props.fixed && fixedHeader};
`;
