// Inspired by https://codepen.io/ispal/pen/mVaaJe
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components/macro';
import { yellow, navigationItem, primary } from '@hero/styles/colors';

const barColor = navigationItem;
const ballColor = yellow;
const PROGRESS_DELAY = 1000;

const ball = keyframes`
	0% {
		transform: translate(0, 0);
	}
	5% {
		transform: translate(8px, -14px);
	}
	10% {
		transform: translate(15px, -10px)
	}
	17% {
		transform: translate(23px, -24px)
	}
	20% {
		transform: translate(30px, -20px)
	}
	27% {
		transform: translate(38px, -34px)
	}
	30% {
		transform: translate(45px, -30px)
	}
	37% {
		transform: translate(53px, -44px)
	}
	40% {
		transform: translate(60px, -40px)
	}
	50% {
		transform: translate(60px, 0)
	}
	57% {
		transform: translate(53px, -14px)
	}
	60% {
		transform: translate(45px, -10px)
	}
	67% {
		transform: translate(37px, -24px)
	}
	70% {
		transform: translate(30px, -20px)
	}
	77% {
		transform: translate(22px, -34px)
	}
	80% {
		transform: translate(15px, -30px)
	}
	87% {
		transform: translate(7px, -44px)
	}
	90% {
		transform: translate(0, -40px)
	}
	100% {
		transform: translate(0, 0);
	}
`;
const barUp1 = keyframes`
	0% {
		transform: scale(1, .2);
	}
	40%{
		transform: scale(1, .2);
	}
	50% {
		transform: scale(1, 1);
	}
	90% {
		transform: scale(1,1);
	}
	100% {
		transform: scale(1,.2);
	}
`;
const barUp2 = keyframes`
	0% {
		transform: scale(1, .4);
	}
	40% {
		transform: scale(1, .4);
	}
	50% {
		transform: scale(1, .8);
	}
	90% {
		transform: scale(1, .8);
	}
	100% {
		transform: scale(1, .4);
	}
`;
const barUp3 = keyframes`
	0% {
		transform: scale(1, .6);
	}
	100% {
		transform: scale(1, .6);
	}
`;
const barUp4 = keyframes`
	0% {
		transform: scale(1, .8);
	}
	40% {
		transform: scale(1, .8);
	}
	50% {
		transform: scale(1, .4);
	}
	90% {
		transform: scale(1, .4);
	}
	100% {
		transform: scale(1, .8);
	}

`;
const barUp5 = keyframes`

	0% {
		transform: scale(1, 1);
	}
	40% {
		transform: scale(1, 1);
	}
	50% {
		transform: scale(1, .2);
	}
	90% {
		transform: scale(1, .2);
	}
	100% {
		transform: scale(1, 1);
	}
`;

const Loader = styled.div`
  position: relative;
  width: 75px;
  height: 100px;
`;

const Bar = styled.div`
  position: absolute;
  bottom: 0;
  width: 10px;
  height: 50%;
  background: ${barColor};
  transform-origin: center bottom;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);

  &:nth-child(1) {
    left: 0;
    transform: scale(1, 0.2);
    animation: ${barUp1} 4s infinite;
  }

  &:nth-child(2) {
    left: 15px;
    transform: scale(1, 0.4);
    animation: ${barUp2} 4s infinite;
  }

  &:nth-child(3) {
    left: 30px;
    transform: scale(1, 0.6);
    animation: ${barUp3} 4s infinite;
  }

  &:nth-child(4) {
    left: 45px;
    transform: scale(1, 0.8);
    animation: ${barUp4} 4s infinite;
  }

  &:nth-child(5) {
    left: 60px;
    transform: scale(1, 1);
    animation: ${barUp5} 4s infinite;
  }
`;

const Ball = styled.div`
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 10px;
  height: 10px;
  background: ${ballColor};
  border-radius: 50%;
  animation: ${ball} 4s infinite;
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const Label = styled.span`
  margin-top: 16px;
  font-weight: bold;
  color: ${primary};
`;

export default class LoadingIndicator extends Component {
  state = { loading: false };

  componentWillUnmount() {
    this.unmounted = true;
  }

  componentDidMount() {
    if (this.props.loading) {
      this.delayedProgressTimeout = setTimeout(
        this.showLoading,
        this.props.delay || PROGRESS_DELAY
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.loading && this.props.loading) {
      this.delayedProgressTimeout = setTimeout(
        this.showLoading,
        PROGRESS_DELAY
      );
    } else if (prevProps.loading && !this.props.loading) {
      clearTimeout(this.delayedProgressTimeout);
      this.hideLoading();
    }
  }

  render() {
    const { label, render, ...props } = this.props;

    if (this.state.loading && this.props.loading) {
      return (
        <Wrapper {...props}>
          <Loader>
            <Bar />
            <Bar />
            <Bar />
            <Bar />
            <Bar />
            <Ball />
          </Loader>
          <Label>{label}</Label>
        </Wrapper>
      );
    } else if (!this.props.loading) {
      return render ? render() : null;
    }

    return null;
  }

  showLoading = () => {
    if (this.unmounted) return;
    this.setState({ loading: true });
  };

  hideLoading = () => {
    if (this.unmounted) return;
    this.setState({ loading: false });
  };
}

LoadingIndicator.propTypes = {
  loading: PropTypes.bool,
  label: PropTypes.node,
  delay: PropTypes.number,
};

LoadingIndicator.defaultProps = {
  label: 'Loading...',
  delay: 1000,
};
