import React from 'react';
import { useSnackbar } from 'notistack';
import { Box } from 'jsxstyle';
import { Container, Row, Col } from 'react-grid-system';

import { Text } from '@hero/styles/typography-v5';
import { pattensBlue } from '@hero/styles/colors-v4';
import MaterialSwitch from '@hero/core/MaterialSwitch';
import Fetch from '@hero/tfs/src/shared/Fetch';

import EditorAutosave from '@hero/core/EditorAutosave';
import FormattedNumber from '@hero/tfs/src/shared/FormattedNumber';
import {
  createDefaultBlocker,
  getValidBlocker,
} from '@hero/tfs/src/03-blockers/shared';

import useRiskIssueBlockers from '../shared/hooks/useRiskIssueBlockers';
import AddRisk from './AddRisk';
import KeyRisk from './KeyRisk';
import AddBlockersSection from './AddBlockerSection';
import BlockerRow from './BlockerRow';

export default function KeyRisksSection({ tfs, initiative, onAddBlocker }) {
  const [refreshRisksOn, setRefreshRisks] = React.useState(Date.now());
  const [hideResolved, setHideResolved] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const keyRisks = useRiskIssueBlockers(initiative.ref_id, refreshRisksOn);
  const initBlockers = keyRisks
    ? keyRisks.filter(r => r.type === 'blocker')
    : [];
  const handleRefreshRisks = () => {
    setRefreshRisks(Date.now());
  };

  return (
    <FormattedNumber.TfsProvider tfs={tfs}>
      <Box backgroundColor="white">
        <Container fluid style={{ padding: '16px 32px 16px 32px' }}>
          <Row align="center" justify="between">
            <Col sm={12} md={6} lg={7}>
              <Text size="h4">
                Risks, Issues & Blockers ({keyRisks && keyRisks.length})
              </Text>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <MaterialSwitch
                  id="hide-reoslved-risks"
                  checked={hideResolved}
                  onChange={ev => setHideResolved(ev.target.checked)}
                />
                <label htmlFor="hide-reoslved-risks">
                  <Text marginLeft="4px">Hide resolved</Text>
                </label>
              </Box>
            </Col>
          </Row>
        </Container>
        <Box
          display="flex"
          padding="0 32px 24px 32px"
          justifyContent="space-between"
        >
          <AddRisk
            tfs={tfs}
            initiative={initiative}
            refreshRisks={handleRefreshRisks}
          />
          <Fetch.POST
            manual
            url="/api/blocker/create"
            onError={() =>
              enqueueSnackbar('Failed to create the blocker', {
                variant: 'error',
              })
            }
          >
            {({ doRequest: createBlocker }) => (
              <AddBlockersSection
                initiative={initiative}
                tfs={tfs}
                blocker={createDefaultBlocker({
                  configData: tfs.configuration,
                  initiatives: [initiative],
                })}
                initBlockers={initBlockers}
                onAddBlocker={blocker => {
                  Promise.all([
                    createBlocker({
                      body: getValidBlocker(blocker),
                    }),
                  ]).then(() => {
                    onAddBlocker();
                    handleRefreshRisks();
                  });
                }}
                onAddBlockers={() => {
                  handleRefreshRisks();
                }}
              />
            )}
          </Fetch.POST>
        </Box>
        {keyRisks && keyRisks.length > 0 && (
          <Box padding="8px 8px 16px 8px" backgroundColor={pattensBlue}>
            {keyRisks.map(keyRisk => (
              <Fetch.PUT
                key={keyRisk.ref_id}
                manual
                url={`/api/blocker/archive`}
                onFetch={handleRefreshRisks}
                onError={() =>
                  enqueueSnackbar(
                    `Failed to delete key risk ${keyRisk.ref_id}`,
                    {
                      variant: 'error',
                    }
                  )
                }
              >
                {({ loading, doRequest: doDelete }) => (
                  <KeyRiskEditor initialValue={keyRisk}>
                    {({ value, onChange }) =>
                      value.type === 'blocker' ? (
                        <BlockerRow
                          tfs={tfs}
                          blocker={value}
                          onEditUpdate={blocker => {
                            onChange(latestValue => ({
                              ...latestValue,
                              ...blocker,
                            }));
                          }}
                          onTextUpdate={description =>
                            onChange(latestValue => ({
                              ...latestValue,
                              description,
                            }))
                          }
                          onDelete={() => {
                            handleRefreshRisks();
                          }}
                        />
                      ) : hideResolved && value.resolved ? null : (
                        <KeyRisk
                          tfs={tfs}
                          initiative={initiative}
                          text={value.description}
                          type={value.type}
                          resolved={value.resolved}
                          onDelete={() =>
                            doDelete({
                              body: { ref_id: value.ref_id },
                            })
                          }
                          onResolve={resolved => {
                            onChange(latestValue => ({
                              ...latestValue,
                              resolved,
                            }));
                          }}
                          onTextUpdate={description =>
                            onChange(latestValue => ({
                              ...latestValue,
                              description,
                            }))
                          }
                          onTypeChange={type =>
                            onChange(latestValue => ({ ...latestValue, type }))
                          }
                          deletingInProgress={loading}
                          onAddBlocker={blocker => {
                            onChange(latestValue => ({
                              ...latestValue,
                              ...blocker,
                            }));
                          }}
                        />
                      )
                    }
                  </KeyRiskEditor>
                )}
              </Fetch.PUT>
            ))}
          </Box>
        )}
      </Box>
    </FormattedNumber.TfsProvider>
  );
}

function KeyRiskEditor({ initialValue, children }) {
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Fetch.POST
      manual
      key={initialValue}
      url={`/api/blocker/update`}
      onError={() =>
        enqueueSnackbar(`Failed to save key risk ${initialValue.ref_id}`, {
          variant: 'error',
        })
      }
    >
      {({ doRequest: doUpdate }) => (
        <EditorAutosave
          key={initialValue}
          initialValue={initialValue}
          onSubmit={newKeyRisk => doUpdate({ body: newKeyRisk })}
          children={children}
        />
      )}
    </Fetch.POST>
  );
}
