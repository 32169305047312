import React from 'react';
import { Link } from 'react-router-dom';
import { initStatusColors } from '@hero/styles/colors';
import { romanRed, puertoRico } from '@hero/styles/colors-v4';
import DashBridge from './DashBridge';

export default function TfsProgressBridgeChart({
  showShortfall,
  tfsOutcome,
  stats,
  statusList,
  tfsId,
}) {
  const {
    backlog,
    inProgress,
    inProgressIssue,
    blocked,
    done,
  } = stats.outcomeStats;
  const initsOutcome = backlog + inProgress + inProgressIssue + blocked + done;
  const isSurplus = tfsOutcome < initsOutcome;
  const deltaOutcome = showShortfall ? Math.abs(tfsOutcome - initsOutcome) : 0;
  const ipIssueStatus = statusList.find(s => s._id === '1');

  return (
    <DashBridge
      targetOutcome={tfsOutcome}
      height={130}
      isSurplus={isSurplus}
      deltaOutcome={deltaOutcome}
    >
      <BridgeItem
        tfsId={tfsId}
        id="backlog"
        outcome={stats.outcomeStats.backlog}
        count={stats.initiativeStats.backlog}
        label="To Do"
        bgColor={initStatusColors.backlog}
      />
      <BridgeItem
        tfsId={tfsId}
        id="inProgress"
        outcome={stats.outcomeStats.inProgress}
        count={stats.initiativeStats.inProgress}
        label="In Progress"
        bgColor={initStatusColors['2']}
      />
      <BridgeItem
        tfsId={tfsId}
        id="inProgressIssue"
        outcome={stats.outcomeStats.inProgressIssue}
        count={stats.initiativeStats.inProgressIssue}
        label={ipIssueStatus ? ipIssueStatus.name : ''}
        bgColor={initStatusColors['1']}
      />
      <BridgeItem
        tfsId={tfsId}
        id="blocked"
        outcome={stats.outcomeStats.blocked}
        count={stats.initiativeStats.blocked}
        label="Blocked"
        bgColor={initStatusColors.blocked}
      />
      <BridgeItem
        tfsId={tfsId}
        id="done"
        outcome={stats.outcomeStats.done}
        count={stats.initiativeStats.done}
        label="Done"
        bgColor={initStatusColors.done}
      />
      {/* {deltaOutcome > 0 && ( */}
      <DashBridge.Item
        tfsId={tfsId}
        isSurplus={isSurplus}
        id="shortfall"
        outcome={deltaOutcome}
        label={isSurplus ? 'surplus' : 'shortfall'}
        bgColor={isSurplus ? puertoRico : romanRed}
      />
      {/* )} */}
    </DashBridge>
  );
}

function BridgeItem({ id, tfsId, ...props }) {
  return (
    <Link
      style={{
        display: 'block',
        position: 'relative',
        flex: '1 1 0',
        margin: '0 1px',
      }}
      to={`/transformations/${tfsId}/list?filter=${encodeURIComponent(
        'state=' + id
      )}`}
    >
      <DashBridge.Item {...props} />
    </Link>
  );
}
