import React, { useState } from 'react';
import { Box } from 'jsxstyle';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@reach/tabs';

import Dialog, { DngrActionBtn } from '@hero/core/Dialog';

import Editor from '@hero/tfs/src/shared/Editor';
import Fetch from '@hero/tfs/src/shared/Fetch';
import { appleBlossom } from '@hero/styles/colors-v4';
import { Text } from '@hero/styles/typography-v5';

import {
  BlockerForm,
  BlockerHeader,
} from '@hero/tfs/src/03-blockers/BlockerForm';
import { isValidBlocker } from '@hero/tfs/src/03-blockers/shared';
import LinkExistingBlockers from './LinkExistingBlockers';

export default function AddBlockersSection({
  label = 'Add blocker',
  initiative,
  blocker,
  tfs,
  initBlockers,
  onAddBlocker,
  onAddBlockers,
}) {
  const [isBlockerFormOpen, setBlockerFormOpen] = React.useState(false);

  return (
    <Box marginLeft="24px">
      <DngrActionBtn
        style={{ minWidth: '119px', maxWidth: '119px', marginBottom: 0 }}
        onClick={() => {
          setBlockerFormOpen(true);
        }}
      >
        {label}
      </DngrActionBtn>
      <AddBlockerPopOver
        initiative={initiative}
        initBlockers={initBlockers}
        tfsId={tfs.ref_id}
        config={tfs.configuration}
        isOpen={isBlockerFormOpen}
        blocker={blocker}
        onClose={() => setBlockerFormOpen(false)}
        onAddBlocker={blocker => {
          setBlockerFormOpen(false);
          onAddBlocker(blocker);
        }}
        onAddBlockers={blockers => {
          setBlockerFormOpen(false);
          onAddBlockers(blockers);
        }}
      />
    </Box>
  );
}

export function AddBlockerPopOver({
  initiative,
  isOpen,
  loading,
  blocker,
  tfsId,
  config,
  initBlockers,
  onAddBlocker,
  onAddBlockers,
  onClose,
}) {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    isOpen && (
      <Dialog
        isOpen={isOpen}
        width="840px"
        isDismissable
        onClose={onClose}
        position="flex-start"
        headerColor={appleBlossom}
        header={<BlockerHeader blocker={blocker} />}
      >
        <Tabs index={tabIndex} onChange={setTabIndex}>
          <TabList>
            <Tab>
              <Text size="p1" fontWeight="bold" color="inherit">
                Create new blocker
              </Text>
            </Tab>
            <Tab>
              <Text size="p1" fontWeight="bold" color="inherit">
                Link to existing
              </Text>
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <NewBlockerForm
                blocker={blocker}
                tfsId={tfsId}
                configData={config}
                onAddBlocker={onAddBlocker}
                loading={loading}
              />
            </TabPanel>
            <TabPanel>
              <LinkExistingBlockers
                loading={loading}
                tfsId={tfsId}
                tfsConfig={config}
                initiative={initiative}
                initBlockers={initBlockers}
                onAddBlockers={onAddBlockers}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Dialog>
    )
  );
}

function NewBlockerForm({ blocker, tfsId, configData, onAddBlocker, loading }) {
  return (
    <Editor
      id="create-blocker-initiative"
      initialValue={blocker}
      onSubmit={(blocker, event) => {
        event.stopPropagation();

        if (isValidBlocker(blocker)) {
          onAddBlocker(blocker);
        }
      }}
    >
      {({ value, onChange }) => (
        <Fetch.POST
          url="/api/initiative/read-by-transformation-id"
          body={{
            query: { ref_id: tfsId },
          }}
        >
          {({ data }) => (
            <BlockerForm
              form="create-blocker-initiative"
              value={value}
              onChange={onChange}
              onStatusChange={changes => {
                changes['timestamp'] = Date.now();
                onChange({
                  ...value,
                  state: value.state.concat(changes),
                });
              }}
              loading={loading}
              configData={configData}
              initiatives={data ? data.data : []}
            />
          )}
        </Fetch.POST>
      )}
    </Editor>
  );
}

export function BlockedRiskPopover({
  isOpen,
  loading,
  blocker,
  tfsId,
  config,
  onAddBlocker,
  onClose,
}) {
  return (
    isOpen && (
      <Dialog
        isOpen={isOpen}
        width="840px"
        isDismissable
        onClose={onClose}
        position="flex-start"
        headerColor={appleBlossom}
        header={<BlockerHeader blocker={blocker} />}
      >
        <NewBlockerForm
          blocker={blocker}
          tfsId={tfsId}
          configData={config}
          onAddBlocker={onAddBlocker}
          loading={loading}
        />
      </Dialog>
    )
  );
}
