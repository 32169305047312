import React from 'react';
import { LoginContext } from '@hero/tfs/src/shared/LoginContextProvider';

export const LocaleContext = React.createContext([
  navigator.language,
  () => {},
]);

export function LocaleProvider({ children }) {
  const { locale } = React.useContext(LoginContext);
  const state = React.useState(locale || navigator.language);

  React.useEffect(() => {
    if (locale) {
      state[1](locale);
    }
  }, [locale]);

  return (
    <LocaleContext.Provider value={state}>
      {React.cloneElement(children, { locale: state[0] })}
    </LocaleContext.Provider>
  );
}

export function useLocale() {
  return React.useContext(LocaleContext);
}
