import React from 'react';

export default function useNotifications(lastEventTime) {
  // fetch all notifications for last 7 days
  const [lastRequestTime, setLastRequestTime] = React.useState(
    Date.now() - 7 * 24 * 60 * 60 * 1000
  );
  const [notifications, setNotifications] = React.useState([]);

  React.useEffect(() => {
    if (lastRequestTime < lastEventTime) {
      setLastRequestTime(lastEventTime);

      const u = new URL('/api/notification', process.env.REACT_APP_API_URL);

      u.searchParams.append('since', lastRequestTime);
      u.searchParams.append('limit', 100);
      u.searchParams.append('offset', 0);

      fetch(u, {
        headers: {
          Authorization: localStorage.getItem('id_token'),
        },
      })
        .then(rsp => rsp.json())
        .then(data => {
          if (data.ok) {
            setNotifications(xs => data.data.concat(xs));
          } else {
            console.log('Broken data');
          }
        })
        .catch(err => console.log(err));
    }
  }, [lastRequestTime, lastEventTime]);

  return notifications;
}
