import React from 'react';
import { Box, Col, Row } from 'jsxstyle';
import { Text } from '@hero/styles/typography-v5';
import { santasGrey, romanRed, puertoRico } from '@hero/styles/colors-v4';

import { getBridgeBgStyles, getESLinearGradientStyles } from './DashBridge';

export default function TrackingAreaLegend(props) {
  return (
    <Box position="absolute" right={32} top={32} {...props}>
      <Col>
        <Row alignItems="center" justifyContent="flex-end" marginBottom={8}>
          <Text size="overline" textTransform="uppercase">
            Excess
          </Text>
          <Box
            width={32}
            background={santasGrey}
            marginLeft={8}
            {...getBridgeBgStyles({
              height: '8px',
            })}
            {...getESLinearGradientStyles({ bgColor: puertoRico })}
          />
        </Row>
        <Row alignItems="center" justifyContent="flex-end">
          <Text size="overline" textTransform="uppercase">
            Shortfall
          </Text>
          <Box
            width={32}
            background={santasGrey}
            marginLeft={8}
            {...getBridgeBgStyles({
              height: '8px',
            })}
            {...getESLinearGradientStyles({ bgColor: romanRed })}
          />
        </Row>
      </Col>
    </Box>
  );
}
