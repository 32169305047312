import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import useApiUrl from './useApiUrl';
import useToken from './useToken';

export default function useBlockersByTfs(tfsId, workstreamId, key) {
  const [blockers, setBlockers] = useState(null);
  const token = useToken();
  const apiUrl = useApiUrl();
  const { enqueueSnackbar } = useSnackbar();
  const searchParams = workstreamId ? `?workstream=${workstreamId}` : '';
  useEffect(() => {
    fetch(
      new URL(
        `/api/blocker/${tfsId}/read-by-transformation-id${searchParams}`,
        apiUrl
      ),
      {
        method: 'GET',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
      }
    )
      .then(rsp => rsp.json())
      .then(data => {
        if (data.ok) {
          setBlockers(data.data);
        } else {
          setBlockers([]);
          console.log('Broken data');
        }
      })
      .catch(err => {
        setBlockers([]);
        console.log(err);
        enqueueSnackbar('Failed to fetch the transformation stats', {
          variant: 'error',
        });
      });
  }, [tfsId, workstreamId, token, apiUrl, key]);

  return blockers;
}
